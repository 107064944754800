$mini-sidebar-width: 80px;
/*******************
Main sidebar
******************/

.mat-sidenav {
  width: 260px !important;
  padding-top: 0px !important;
}

#snav {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);

  .menu-name {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .mat-drawer-inner-container {
    overflow: visible;
    height: auto;
  }

  .mat-mdc-nav-list .mat-mdc-list-item {
    height: auto;
    padding: 0px 16px !important;
    display: block;

    &:hover {
      background: none;
    }

    .mdc-list-item__content {
      display: block;
      padding: 0px 0px;

      &:hover {
        background: none;

        >a {
          color: $themecolor;
        }
      }
    }

    a {
      height: 35px;
      padding: 0 10px;
      margin-bottom: 5px;
      align-items: center;
      display: flex;
      font-size: 15px;
      white-space: nowrap;
      color: $sidebar-text;

      &:hover{
        color: $themecolor;
      }

      mat-icon:not(.dd-icon) {
        margin-right: 8px;
      }

      mat-icon {
        color: $sidebar-icons;
      }

      .dd-icon {
        font-size: 16px;
        width: 16px;
        transition: 0.2s ease-in;
        margin: 5px 0 5px 5px;
        height: 16px;
      }
    }

    &.selected>.mdc-list-item__content>.mat-mdc-list-item-unscoped-content>a {
      background: $themecolor-alt;
      border-radius: $radius;
      color: $white;

      mat-icon {
        color: $white;
      }

      .dd-icon {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }

    &.selected .sub-item .mdc-list-item__content a.selected {
      background: transparent;
      color: $inverse;
      font-weight: 500;
    }
  }

  .app-sidebar>.mat-mdc-nav-list>.mat-mdc-list-item>.mdc-list-item__content {
    //padding: 0px 15px;
  }

  .mat-mdc-nav-list .sub-item {
    display: none;
    margin-top: -15px;

    a {
      padding-left: 40px;
      height: 50px;
      margin-bottom: 0px;
    }

    .child-sub-item {
      display: none;
      margin-top: -15px;

      a {
        padding: 0 0 0 55px;
        height: 50px;
        margin-bottom: 0px;
      }
    }
  }

  .separator {
    cursor: text;
    font-size: 17px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 500;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.5);
    color: #fff !important;
    padding: 6px 15px;
  }

  .separator:hover::before {
    background: none!important;
  }

  .separator-root:hover::before {
    background:none !important;
  }

  .selected {
    .sub-item {
      display: block;

      .selected+ {
        .child-sub-item {
          display: block;
        }
      }
    }
  }
}
/*******************
user profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;

  .profile-img {
    width: 50px;
    margin-left: 30px;
    padding: 31px 0;
    border-radius: 100%;

    &::before {
      -webkit-animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
      position: absolute;
      content: '';
      width: 50px;
      height: 50px;
      top: 31px;
      margin: 0 auto;
      border-radius: 50%;
      z-index: 0;
    }

    @-webkit-keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  .profile-text {
    position: relative;
    white-space: nowrap;

    >span {
      color: #000!important;
      font-size: $font-16;
      font-family: 'Poppins';
      display: inline;
      font-weight: 500;

      &:after {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
}

/*============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */

@media (min-width: 1024px) {
  .minisidebar #snav {
    .mat-drawer-backdrop {
      display: none;
    }

    &.mat-sidenav~.mat-drawer-content {
      margin-left: $mini-sidebar-width;
    }

    &.mat-sidenav {
      -webkit-transition: width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
        transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
      transition: width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
        transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    }

    &.mat-sidenav:not(:hover) {
      width: $mini-sidebar-width;

      .sub-menu,
      .dd-icon,
      .selected .sub-item {
        display: none;
        visibility: hidden;
      }

      .user-profile {
        .profile-img {
          margin-left: 15px;
        }

        .profile-text {
          visibility: hidden;
        }
      }

      .separator span {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 40px;
        display: block;
      }

      .mat-mdc-list-item>.mdc-list-item__content>a {
        padding-right: 0;
        padding-left: 0;
        text-align: center;
      }

      .mat-mdc-list-item>.mdc-list-item__content>a>span:not(.menu-badge),
      .mat-mdc-list-item>.mdc-list-item__content>a>.menu-caret {
        display: none;
      }

      .mat-mdc-list-item>.mdc-list-item__content>a>span.menu-badge {
        position: absolute;
        top: 10px;
        right: 16px;
      }

      .mat-mdc-list-item>.mdc-list-item__content>a>.material-icons {
        width: $mini-sidebar-width !important;
        padding: 0;
        margin: 0;
      }
    }
  }

  [dir='rtl'] {
    app-sidebar {
      display: block;
      overflow: hidden;
    }

    .minisidebar #snav.mat-sidenav~.mat-drawer-content {
      margin-left: 0px !important;
      margin-right: $mini-sidebar-width !important;
    }
  }
}

.sidebar-closed #snav {
  &.mat-sidenav~.mat-drawer-content {
    margin-left: 0px !important;
  }
}

@media (min-width: 1024px) {
  .minitoggle.mat-drawer-content {
    margin-left: 0 !important;
  }
}

// .mat-ripple-element {
//   position: absolute!important;
//   top: 50% !important;
//   left: 50% !important;
//   //width: 85%!important;
//   height: 85%!important;
//   border-radius: inherit!important;
//   transform: translate(-50%, -50%) !important;
// }

// .separator-root .mat-ripple-element {
//   opacity: 0 !important;
// }