// kendo-angular-popup
@import "@progress/kendo-theme-default/scss/popup/_index.scss";

// kendo-angular-tooltip
@import "@progress/kendo-theme-default/scss/tooltip/_index.scss";

// PanelBar and Grid styles
@import "@progress/kendo-theme-default/scss/panelbar/_index.scss";
@import "@progress/kendo-theme-default/scss/grid/_index.scss";

// kendo-angular-buttons
@import "@progress/kendo-theme-default/scss/button/_index.scss";

// kendo-angular-dateinputs
//@import "@progress/kendo-theme-default/scss/datetime/_index.scss";
@import "@progress/kendo-theme-default/scss/dateinput/_index.scss";

// kendo-angular-dropdowns
@import "@progress/kendo-theme-default/scss/autocomplete/_index.scss";
@import "@progress/kendo-theme-default/scss/combobox/_index.scss";
@import "@progress/kendo-theme-default/scss/dropdownlist/_index.scss";
@import "@progress/kendo-theme-default/scss/dropdowntree/_index.scss";
@import "@progress/kendo-theme-default/scss/multiselect/_index.scss";

// kendo-angular-inputs
@import "@progress/kendo-theme-default/scss/checkbox/_index.scss";
@import "@progress/kendo-theme-default/scss/colorpicker/_index.scss";
@import "@progress/kendo-theme-default/scss/forms/_index.scss";
@import "@progress/kendo-theme-default/scss/input/_index.scss";
@import "@progress/kendo-theme-default/scss/maskedtextbox/_index.scss";
@import "@progress/kendo-theme-default/scss/numerictextbox/_index.scss";
@import "@progress/kendo-theme-default/scss/radio/_index.scss";
@import "@progress/kendo-theme-default/scss/slider/_index.scss";
@import "@progress/kendo-theme-default/scss/switch/_index.scss";
@import "@progress/kendo-theme-default/scss/textarea/_index.scss";
@import "@progress/kendo-theme-default/scss/textbox/_index.scss";
@import "@progress/kendo-theme-default/scss/validator/_index.scss";

// kendo-angular-layout
@import "@progress/kendo-theme-default/scss/splitter/_index.scss";

// kendo-angular-charts
@import "@progress/kendo-theme-default/scss/dataviz/_index.scss";

//  PopOver----------------------------------------------
.k-popover {
    border-radius: 6px;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-size: 14px;
    font-family: inherit;
    line-height: 1.4285714286;
    display: flex;
    flex-flow: column nowrap;
    z-index: 12000;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-popover *,
.k-popover *::before,
.k-popover *::after {
    box-sizing: border-box;
}

.k-popover-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 12px 16px;
    border-width: 0 0 1px;
    border-style: solid;
    z-index: 999;
}

.k-popover-body {
    padding: 16px 16px;
}

.k-popover-actions {
    border-width: 1px 0 0;
}

.k-popover-callout {
    margin: 0;
    width: 20px;
    height: 20px;
    border-width: 1px 1px 0 0;
    border-style: solid;
    position: absolute;
}

.k-popover-callout.k-callout-n {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

.k-popover-callout.k-callout-e {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%) rotate(135deg) scaleX(-1);
}

.k-popover-callout.k-callout-s {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(135deg);
}

.k-popover-callout.k-callout-w {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(-135deg);
}

.k-popover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #ffffff;
}

.k-popover-header {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
}

.k-popover-callout {
    border-color: rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
}

.k-popover-callout.k-callout-e {
    box-shadow: rgba(0, 0, 0, 0.03) 2px -1px 4px 0px;
}

.k-popover-callout.k-callout-s {
    box-shadow: rgba(0, 0, 0, 0.06) 2px -2px 4px 0px;
}

.k-popover-callout.k-callout-w {
    box-shadow: rgba(0, 0, 0, 0.03) 2px -1px 4px 0px;
}
//------------------------------------------------------------


// Custom ------
// .k-button, .k-button-secondary {
//     background-color: transparent;
// }

.k-popover-callout.k-callout-n {
    left: 47.5%;
    width: 35px;
    height: 35px;
}


.k-picker-solid.k-invalid, .k-picker-solid.ng-invalid.ng-touched, .k-picker-solid.ng-invalid.ng-dirty  {
    border-color: rgba(0, 0, 0, 0.16);
}


.k-grid, .k-grid-header, .k-input {
	color: #000;
}
kendo-grid-filter-cell-operators {
    button {
        width: 18px !important;
        height: 18px !important;
        padding: 2px;
    }
}