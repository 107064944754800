@charset "UTF-8";
:root {
  --kendo-color-app-surface: #ffffff;
  --kendo-color-on-app-surface: #3d3d3d;
  --kendo-color-subtle: #666666;
  --kendo-color-surface: #fafafa;
  --kendo-color-surface-alt: #ffffff;
  --kendo-color-border: rgba(0, 0, 0, 0.08);
  --kendo-color-border-alt: rgba(0, 0, 0, 0.16);
  --kendo-color-base-subtle: #ebebeb;
  --kendo-color-base-subtle-hover: #e0e0e0;
  --kendo-color-base-subtle-active: #d6d6d6;
  --kendo-color-base: #f5f5f5;
  --kendo-color-base-hover: #ebebeb;
  --kendo-color-base-active: #d6d6d6;
  --kendo-color-base-emphasis: #c2c2c2;
  --kendo-color-base-on-subtle: #3d3d3d;
  --kendo-color-on-base: #3d3d3d;
  --kendo-color-base-on-surface: #3d3d3d;
  --kendo-color-primary-subtle: #ffeceb;
  --kendo-color-primary-subtle-hover: #ffdedb;
  --kendo-color-primary-subtle-active: #ffc8c4;
  --kendo-color-primary: #ff6358;
  --kendo-color-primary-hover: #ea5a51;
  --kendo-color-primary-active: #d45349;
  --kendo-color-primary-emphasis: #ff9d97;
  --kendo-color-primary-on-subtle: #5c201c;
  --kendo-color-on-primary: #ffffff;
  --kendo-color-primary-on-surface: #ff6358;
  --kendo-color-secondary-subtle: #fafafa;
  --kendo-color-secondary-subtle-hover: #f5f5f5;
  --kendo-color-secondary-subtle-active: #ebebeb;
  --kendo-color-secondary: #666666;
  --kendo-color-secondary-hover: #525252;
  --kendo-color-secondary-active: #3d3d3d;
  --kendo-color-secondary-emphasis: #e0e0e0;
  --kendo-color-secondary-on-subtle: #141414;
  --kendo-color-on-secondary: #ffffff;
  --kendo-color-secondary-on-surface: #292929;
  --kendo-color-tertiary-subtle: #d8f1fd;
  --kendo-color-tertiary-subtle-hover: #c5eafc;
  --kendo-color-tertiary-subtle-active: #a3dffb;
  --kendo-color-tertiary: #03a9f4;
  --kendo-color-tertiary-hover: #039ae0;
  --kendo-color-tertiary-active: #028ccb;
  --kendo-color-tertiary-emphasis: #61c9f9;
  --kendo-color-tertiary-on-subtle: #023f5c;
  --kendo-color-on-tertiary: #ffffff;
  --kendo-color-tertiary-on-surface: #028ccb;
  --kendo-color-info-subtle: #d2e2fb;
  --kendo-color-info-subtle-hover: #bdd4f8;
  --kendo-color-info-subtle-active: #80acf4;
  --kendo-color-info: #0058e9;
  --kendo-color-info-hover: #0052d6;
  --kendo-color-info-active: #004ac2;
  --kendo-color-info-emphasis: #6098f2;
  --kendo-color-info-on-subtle: #002259;
  --kendo-color-on-info: #ffffff;
  --kendo-color-info-on-surface: #004ac2;
  --kendo-color-success-subtle: #dcf0d3;
  --kendo-color-success-subtle-hover: #cbe9bf;
  --kendo-color-success-subtle-active: #b7e1a5;
  --kendo-color-success: #37b400;
  --kendo-color-success-hover: #32a500;
  --kendo-color-success-active: #2d9600;
  --kendo-color-success-emphasis: #81d15f;
  --kendo-color-success-on-subtle: #1c5a00;
  --kendo-color-on-success: #ffffff;
  --kendo-color-success-on-surface: #2d9600;
  --kendo-color-warning-subtle: #fff4d3;
  --kendo-color-warning-subtle-hover: #ffeebd;
  --kendo-color-warning-subtle-active: #ffe79e;
  --kendo-color-warning: #ffc000;
  --kendo-color-warning-hover: #eaaf00;
  --kendo-color-warning-active: #d49f00;
  --kendo-color-warning-emphasis: #ffd760;
  --kendo-color-warning-on-subtle: #5e4700;
  --kendo-color-on-warning: #3d3d3d;
  --kendo-color-warning-on-surface: #ffc000;
  --kendo-color-error-subtle: #fcddda;
  --kendo-color-error-subtle-hover: #fbc8c3;
  --kendo-color-error-subtle-active: #f98b80;
  --kendo-color-error: #f31700;
  --kendo-color-error-hover: #df1600;
  --kendo-color-error-active: #ca1400;
  --kendo-color-error-emphasis: #f76f60;
  --kendo-color-error-on-subtle: #7a0c00;
  --kendo-color-on-error: #ffffff;
  --kendo-color-error-on-surface: #ca1400;
  --kendo-color-light-subtle: #fafafa;
  --kendo-color-light-subtle-hover: #f5f5f5;
  --kendo-color-light-subtle-active: #ebebeb;
  --kendo-color-light: #ebebeb;
  --kendo-color-light-hover: #e0e0e0;
  --kendo-color-light-active: #d6d6d6;
  --kendo-color-light-emphasis: #d6d6d6;
  --kendo-color-light-on-subtle: #141414;
  --kendo-color-on-light: #000000;
  --kendo-color-light-on-surface: #e0e0e0;
  --kendo-color-dark-subtle: #c2c2c2;
  --kendo-color-dark-subtle-hover: #adadad;
  --kendo-color-dark-subtle-active: #999999;
  --kendo-color-dark: #3d3d3d;
  --kendo-color-dark-hover: #292929;
  --kendo-color-dark-active: #1f1f1f;
  --kendo-color-dark-emphasis: #666666;
  --kendo-color-dark-on-subtle: #1f1f1f;
  --kendo-color-on-dark: #ffffff;
  --kendo-color-dark-on-surface: #141414;
  --kendo-color-inverse-subtle: #c2c2c2;
  --kendo-color-inverse-subtle-hover: #adadad;
  --kendo-color-inverse-subtle-active: #999999;
  --kendo-color-inverse: #3d3d3d;
  --kendo-color-inverse-hover: #292929;
  --kendo-color-inverse-active: #1f1f1f;
  --kendo-color-inverse-emphasis: #666666;
  --kendo-color-inverse-on-subtle: #1f1f1f;
  --kendo-color-on-inverse: #ffffff;
  --kendo-color-inverse-on-surface: #141414;
  --kendo-color-series-a: #ff6358;
  --kendo-color-series-a-bold: #bf4a42;
  --kendo-color-series-a-bolder: #80322c;
  --kendo-color-series-a-subtle: #ffb1ac;
  --kendo-color-series-a-subtler: #ff8a82;
  --kendo-color-series-b: #ffe162;
  --kendo-color-series-b-bold: #bfa94a;
  --kendo-color-series-b-bolder: #807131;
  --kendo-color-series-b-subtle: #fff0b1;
  --kendo-color-series-b-subtler: #ffe989;
  --kendo-color-series-c: #4cd180;
  --kendo-color-series-c-bold: #399d60;
  --kendo-color-series-c-bolder: #266940;
  --kendo-color-series-c-subtle: #a6e8c0;
  --kendo-color-series-c-subtler: #79dda0;
  --kendo-color-series-d: #4b5ffa;
  --kendo-color-series-d-bold: #3847bc;
  --kendo-color-series-d-bolder: #26307d;
  --kendo-color-series-d-subtle: #a5affd;
  --kendo-color-series-d-subtler: #7887fb;
  --kendo-color-series-e: #ac58ff;
  --kendo-color-series-e-bold: #8142bf;
  --kendo-color-series-e-bolder: #562c80;
  --kendo-color-series-e-subtle: #d6acff;
  --kendo-color-series-e-subtler: #c182ff;
  --kendo-color-series-f: #ff5892;
  --kendo-color-series-f-bold: #bf426e;
  --kendo-color-series-f-bolder: #802c49;
  --kendo-color-series-f-subtle: #ffacc9;
  --kendo-color-series-f-subtler: #ff82ae;
}

.k-sr-only {
  position: absolute;
  left: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.k-push-right-enter, .k-push-right-appear {
  transform: translate(-100%, 0);
}
.k-push-right-enter-active, .k-push-right-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-right-exit {
  transform: translate(0, 0);
}
.k-push-right-exit-active {
  transform: translate(100%, 0);
  transition: transform 300ms ease-in-out;
}

.k-push-left-enter, .k-push-left-appear {
  transform: translate(100%, 0);
}
.k-push-left-enter-active, .k-push-left-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-left-exit {
  transform: translate(0, 0);
}
.k-push-left-exit-active {
  transform: translate(-100%, 0);
  transition: transform 300ms ease-in-out;
}

.k-push-down-enter, .k-push-down-appear {
  transform: translate(0, -100%);
}
.k-push-down-enter-active, .k-push-down-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-down-exit {
  transform: translate(0, 0);
}
.k-push-down-exit-active {
  transform: translate(0, 100%);
  transition: transform 300ms ease-in-out;
}

.k-push-up-enter, .k-push-up-appear {
  transform: translate(0, 100%);
}
.k-push-up-enter-active, .k-push-up-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-up-exit {
  transform: translate(0, 0);
}
.k-push-up-exit-active {
  transform: translate(0, -100%);
  transition: transform 300ms ease-in-out;
}

.k-expand-vertical-enter, .k-expand-vertical-appear {
  transform: scaleY(0);
}
.k-expand-vertical-enter-active, .k-expand-vertical-appear-active {
  transform: scaleY(1);
  transition: transform 300ms ease-in-out;
}
.k-expand-vertical-exit {
  transform: scaleY(1);
}
.k-expand-vertical-exit-active {
  transform: scaleY(0);
  transition: transform 300ms ease-in-out;
}

.k-expand-horizontal-enter, .k-expand-horizontal-appear {
  transform: scaleX(0);
}
.k-expand-horizontal-enter-active, .k-expand-horizontal-appear-active {
  transform: scaleX(1);
  transition: transform 300ms ease-in-out;
}
.k-expand-horizontal-exit {
  transform: scaleX(1);
}
.k-expand-horizontal-exit-active {
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
}

.k-fade-enter, .k-fade-appear {
  opacity: 0;
}
.k-fade-enter-active, .k-fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
.k-fade-exit {
  opacity: 1;
}
.k-fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.k-fade-exit-active + .k-fade-exit-active, .k-fade-enter-active + .k-fade-enter-active {
  display: none;
}

.k-zoom-in-enter, .k-zoom-in-appear {
  opacity: 0;
  transform: scale(0);
}
.k-zoom-in-enter-active, .k-zoom-in-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: transform, opacity 300ms ease-in-out;
}
.k-zoom-in-exit {
  opacity: 1;
  transform: scale(1);
}
.k-zoom-in-exit-active {
  opacity: 0;
  transform: scale(2);
  transition: transform, opacity 300ms ease-in-out;
}

.k-zoom-out-enter, .k-zoom-out-appear {
  opacity: 0;
  transform: scale(2);
}
.k-zoom-out-enter-active, .k-zoom-out-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: transform, opacity 300ms ease-in-out;
}
.k-zoom-out-exit {
  opacity: 1;
  transform: scale(1);
}
.k-zoom-out-exit-active {
  opacity: 0;
  transform: scale(0);
  transition: transform, opacity 300ms ease-in-out;
}

.k-slide-in-appear {
  opacity: 0.1;
  transform: translate(0, -3em);
}
.k-slide-in-appear .k-centered {
  transform: translate(-50%, -60%);
}
.k-slide-in-appear-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 0.3s cubic-bezier(0.2, 0.6, 0.4, 1), opacity 0.3s cubic-bezier(0.2, 1, 0.2, 1);
}
.k-slide-in-appear-active .k-centered {
  transform: translate(-50%, -50%);
}

.k-slide-down-enter, .k-slide-down-appear {
  transform: translateY(-100%);
}
.k-slide-down-enter-active, .k-slide-down-appear-active {
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-down-exit {
  transform: translateY(0);
}
.k-slide-down-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-up-enter, .k-slide-up-appear {
  transform: translateY(100%);
}
.k-slide-up-enter-active, .k-slide-up-appear-active {
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-up-exit {
  transform: translateY(0);
}
.k-slide-up-exit-active {
  transform: translateY(100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-right-enter, .k-slide-right-appear {
  transform: translateX(-100%);
}
.k-slide-right-enter-active, .k-slide-right-appear-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-right-exit {
  transform: translateX(0);
}
.k-slide-right-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-left-enter, .k-slide-left-appear {
  transform: translateX(100%);
}
.k-slide-left-enter-active, .k-slide-left-appear-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-left-exit {
  transform: translateX(0);
}
.k-slide-left-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.k-reveal-vertical-enter, .k-reveal-vertical-appear {
  max-height: 0;
}
.k-reveal-vertical-enter-active, .k-reveal-vertical-appear-active {
  transition: max-height 300ms ease-in-out;
}
.k-reveal-vertical-exit-active {
  max-height: 0 !important;
  transition: max-height 300ms ease-in-out;
}

.k-reveal-horizontal-enter, .k-reveal-horizontal-appear {
  max-width: 0;
}
.k-reveal-horizontal-enter-active, .k-reveal-horizontal-appear-active {
  transition: max-width 300ms ease-in-out;
}
.k-reveal-horizontal-exit-active {
  max-width: 0 !important;
  transition: max-width 300ms ease-in-out;
}

.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
  transition: all 350ms ease-out;
}

.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}

.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}

.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}

.k-fx-zoom.k-fx-start .k-fx-next {
  transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next, .k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  transform: scale(0) !important;
}

.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}

.k-fx-slide.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-end .k-fx-next .km-content, .k-fx-slide.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer, .k-fx-slide.k-fx-end .k-fx-current .km-content, .k-fx-slide.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-end .k-fx-current .km-footer {
  transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-start .k-fx-next .km-header, .k-fx-slide.k-fx-start .k-fx-next .km-footer {
  will-change: opacity;
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  will-change: opacity;
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  transform: translateX(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translateX(0%);
}

.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: translateX(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translateX(0%);
}

.k-fx.k-fx-overlay.k-fx-start .k-fx-next, .k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  transform: translateY(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current, .k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  transform: translateY(-100%);
}

.k-theme-test-class,
.k-common-test-class {
  opacity: 0;
}

.k-hstack, .k-hbox {
  display: flex;
  flex-flow: row nowrap;
}

.k-vstack, .k-vbox {
  display: flex;
  flex-flow: column nowrap;
}

.k-spacer, .k-flex {
  flex: 1 1 auto;
}

.k-spacer-sized {
  flex: none;
}

.k-float-wrap::after, .k-floatwrap::after {
  content: "";
  display: block;
  clear: both;
}

.k-flex-layout {
  display: flex;
}

.k-grid-layout {
  display: grid;
}

.k-separator {
  width: auto;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  display: block;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  align-self: stretch;
}

.k-separator-horizontal,
.k-vstack > .k-separator,
.k-vbox > .k-separator {
  width: auto;
  height: 0;
  border-width: 1px 0 0;
}

.k-separator-vertical,
.k-hstack > .k-separator,
.k-hbox > .k-separator {
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
}

hr.k-separator {
  margin: 0;
  padding: 0;
}

.k-hidden {
  display: none !important;
}

.k-rtl {
  direction: rtl;
}

[hidden] {
  display: none !important;
}

script {
  display: none !important;
}

.k-disabled,
.k-widget[disabled],
.k-disabled {
  outline: none;
  cursor: default;
  color: var(--kendo-disabled-text, );
  background-color: var(--kendo-disabled-bg, );
  border-color: var(--kendo-disabled-border, );
  opacity: var(--kendo-disabled-opacity, 0.6);
  filter: var(--kendo-disabled-filter, grayscale(0.1));
  pointer-events: none;
  box-shadow: none;
}
.k-disabled .k-link,
.k-widget[disabled] .k-link,
.k-disabled .k-link {
  cursor: default;
  outline: 0;
}
.k-disabled [disabled],
.k-disabled .k-disabled,
.k-widget[disabled] [disabled],
.k-widget[disabled] .k-disabled,
.k-disabled [disabled],
.k-disabled .k-disabled {
  --kendo-disabled-opacity: 1;
  --kendo-disabled-filter: grayscale(0);
}

.k-hr {
  margin-block: var(--kendo-spacing-4, 1rem);
  padding: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--kendo-component-border, var(--kendo-color-border, rgba(0, 0, 0, 0.08)));
  display: block;
  float: none;
  clear: both;
}

.k-d-flex-row > .k-hr {
  margin: 0;
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
  flex: 0 0 auto;
}

.k-d-flex-col > .k-hr {
  margin: 0;
  flex: 0 0 auto;
}

.k-sprite {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.k-image {
  display: inline-block;
}

.k-reset {
  margin: 0;
  padding: 0;
  border-width: 0;
  outline: 0;
  text-decoration: none;
  font: inherit;
  list-style: none;
}

kendo-sortable {
  display: block;
}

.k-link,
.k-link:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

.k-content {
  outline: 0;
}

.k-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-no-click {
  pointer-events: none;
}

.k-pdf-export-shadow {
  position: absolute;
  overflow: hidden;
  left: -15000px;
  width: 14400px;
}

.kendo-pdf-hide-pseudo-elements::before,
.kendo-pdf-hide-pseudo-elements::after {
  display: none !important;
}

.k-dirty {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border-width: 3px;
  border-style: solid;
  border-block-start-color: currentColor;
  border-block-end-color: transparent;
  border-inline-start-color: transparent;
  border-inline-end-color: currentColor;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
}

.k-marquee {
  position: absolute;
  z-index: 100000;
}

.k-marquee-color,
.k-marquee-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.k-marquee-color {
  color: var(--kendo-neutral-160, var(--kendo-color-on-primary, #ffffff));
  background-color: var(--kendo-primary-60, var(--kendo-color-primary, #ff6358));
  border-color: var(--kendo-primary-100, var(--kendo-color-border, rgba(0, 0, 0, 0.08)));
  opacity: 0.6;
}

.k-marquee-text {
  color: var(--kendo-neutral-160, var(--kendo-color-on-primary, #ffffff));
}

.k-text-selection ::selection {
  background-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
}

.k-scrollbar {
  position: absolute;
  overflow: scroll;
}

.k-scrollbar-vertical {
  top: 0;
  right: 0;
  width: 17px;
  height: 100%;
  overflow-x: hidden;
}

.k-touch-scrollbar {
  display: none;
  position: absolute;
  z-index: 200000;
  height: 8px;
  width: 8px;
  border: 1px solid #8a8a8a;
  background-color: #858585;
}

.k-resize-handle,
.k-resize-hint {
  position: absolute;
  border-color: inherit;
  z-index: 200;
}

.k-resize-handle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-resize-handle::before {
  content: "";
  border: 0 solid;
  border-color: inherit;
}

.k-resize-n {
  width: 100%;
  height: 6px;
  flex-direction: row;
  left: 0;
  top: -3px;
  cursor: n-resize;
}

.k-resize-s {
  width: 100%;
  height: 6px;
  flex-direction: row;
  left: 0;
  bottom: -3px;
  cursor: s-resize;
}

.k-resize-w {
  width: 6px;
  height: 100%;
  flex-direction: column;
  top: 0;
  left: -3px;
  cursor: w-resize;
}

.k-resize-e {
  width: 6px;
  height: 100%;
  flex-direction: column;
  top: 0;
  right: -3px;
  cursor: e-resize;
}

.k-resize-sw,
.k-resize-se,
.k-resize-nw,
.k-resize-ne {
  width: 5px;
  height: 5px;
}

.k-resize-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
}

.k-resize-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.k-resize-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
}

.k-resize-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
}

.k-vertical-resize {
  cursor: row-resize;
}

.k-horizontal-resize {
  cursor: col-resize;
}

.k-resize-hint {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.k-resize-hint .k-resize-hint-handle {
  width: auto;
  height: 20px;
  align-self: stretch;
}
.k-resize-hint .k-resize-hint-marker {
  width: 2px;
  height: auto;
  flex: 1 1 auto;
}

.k-resize-hint-vertical {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.k-resize-hint-vertical .k-resize-hint-handle {
  width: 20px;
  height: auto;
  align-self: stretch;
}
.k-resize-hint-vertical .k-resize-hint-marker {
  width: auto;
  height: 2px;
  flex: 1 1 auto;
}

:root {
  --kendo-elevation-1: 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-2: 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-3: 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-4: 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-5: 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-6: 0 12px 14px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-7: 0 14px 16px rgba(0, 0, 0, 0.24), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-8: 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-9: 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12);
}

:root {
  --kendo-font-family: inherit;
  --kendo-font-size: 0.875rem;
  --kendo-line-height: 1.4285714286;
  --kendo-font-weight: 400;
  --kendo-letter-spacing: ;
  --kendo-font-size-xxs: 0.5rem;
  --kendo-font-size-xs: 0.625rem;
  --kendo-font-size-sm: 0.75rem;
  --kendo-font-size-md: 0.875rem;
  --kendo-font-size-lg: 1rem;
  --kendo-font-size-xl: 1.25rem;
  --kendo-line-height-xs: 1;
  --kendo-line-height-sm: 1.25;
  --kendo-line-height-md: 1.4285714286;
  --kendo-line-height-lg: 1.5;
  --kendo-font-weight-thin: 100;
  --kendo-font-weight-extra-light: 200;
  --kendo-font-weight-light: 300;
  --kendo-font-weight-normal: 400;
  --kendo-font-weight-medium: 500;
  --kendo-font-weight-semibold: 600;
  --kendo-font-weight-bold: 700;
  --kendo-letter-spacing-tightest: -0.15px;
  --kendo-letter-spacing-tighter: -0.1px;
  --kendo-letter-spacing-tight: -0.5px;
  --kendo-letter-spacing-normal: 0px;
  --kendo-letter-spacing-wide: 0.5px;
  --kendo-letter-spacing-wider: 0.1px;
  --kendo-letter-spacing-widest: 0.15px;
  --kendo-font-family-sans: Arial, Verdana, Tahoma, Trebuchet MS, Helvetica, Impact, Gill Sans;
  --kendo-font-family-serif: Times New Roman, Georgia, Garamond, Palatino, Baskerville;
  --kendo-font-family-sans-serif: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --kendo-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, Roboto Mono, Ubuntu Mono, Lucida Console, Courier New, monospace;
}

:root {
  --kendo-spacing-0: 0px;
  --kendo-spacing-1px: 1px;
  --kendo-spacing-0\.5: 0.125rem;
  --kendo-spacing-1: 0.25rem;
  --kendo-spacing-1\.5: 0.375rem;
  --kendo-spacing-2: 0.5rem;
  --kendo-spacing-2\.5: 0.625rem;
  --kendo-spacing-3: 0.75rem;
  --kendo-spacing-3\.5: 0.875rem;
  --kendo-spacing-4: 1rem;
  --kendo-spacing-4\.5: 1.125rem;
  --kendo-spacing-5: 1.25rem;
  --kendo-spacing-5\.5: 1.375rem;
  --kendo-spacing-6: 1.5rem;
  --kendo-spacing-6\.5: 1.625rem;
  --kendo-spacing-7: 1.75rem;
  --kendo-spacing-7\.5: 1.875rem;
  --kendo-spacing-8: 2rem;
  --kendo-spacing-9: 2.25rem;
  --kendo-spacing-10: 2.5rem;
  --kendo-spacing-11: 2.75rem;
  --kendo-spacing-12: 3rem;
  --kendo-spacing-13: 3.25rem;
  --kendo-spacing-14: 3.5rem;
  --kendo-spacing-15: 3.75rem;
  --kendo-spacing-16: 4rem;
  --kendo-spacing-17: 4.25rem;
  --kendo-spacing-18: 4.5rem;
  --kendo-spacing-19: 4.75rem;
  --kendo-spacing-20: 5rem;
  --kendo-spacing-21: 5.25rem;
  --kendo-spacing-22: 5.5rem;
  --kendo-spacing-23: 5.75rem;
  --kendo-spacing-24: 6rem;
  --kendo-spacing-25: 7rem;
  --kendo-spacing-26: 8rem;
  --kendo-spacing-27: 9rem;
  --kendo-spacing-28: 10rem;
  --kendo-spacing-29: 11rem;
  --kendo-spacing-30: 12rem;
}

:root {
  --kendo-border-radius-none: 0px;
  --kendo-border-radius-xs: 1px;
  --kendo-border-radius-sm: 0.125rem;
  --kendo-border-radius-md: 0.25rem;
  --kendo-border-radius-lg: 0.375rem;
  --kendo-border-radius-xl: 0.5rem;
  --kendo-border-radius-xxl: 0.75rem;
  --kendo-border-radius-xxxl: 1rem;
  --kendo-border-radius-full: 9999px;
}

.k-ratio-auto {
  aspect-ratio: auto;
}

.\!k-ratio-auto {
  aspect-ratio: auto !important;
}

.k-ratio-1 {
  aspect-ratio: 1;
}

.\!k-ratio-1 {
  aspect-ratio: 1 !important;
}

.k-ratio-square {
  aspect-ratio: 1 / 1;
}

.\!k-ratio-square {
  aspect-ratio: 1 / 1 !important;
}

.k-ratio-video {
  aspect-ratio: 16 / 9;
}

.\!k-ratio-video {
  aspect-ratio: 16 / 9 !important;
}

.k-aspect-ratio-auto {
  aspect-ratio: auto;
}

.\!k-aspect-ratio-auto {
  aspect-ratio: auto !important;
}

.k-aspect-ratio-1 {
  aspect-ratio: 1;
}

.\!k-aspect-ratio-1 {
  aspect-ratio: 1 !important;
}

.k-aspect-ratio-square {
  aspect-ratio: 1 / 1;
}

.\!k-aspect-ratio-square {
  aspect-ratio: 1 / 1 !important;
}

.k-aspect-ratio-video {
  aspect-ratio: 16 / 9;
}

.\!k-aspect-ratio-video {
  aspect-ratio: 16 / 9 !important;
}

.k-box-sizing-border {
  box-sizing: border-box;
}

.\!k-box-sizing-border {
  box-sizing: border-box !important;
}

.k-box-sizing-content {
  box-sizing: content-box;
}

.\!k-box-sizing-content {
  box-sizing: content-box !important;
}

.k-clear-left {
  clear: left;
}

.\!k-clear-left {
  clear: left !important;
}

.k-clear-right {
  clear: right;
}

.\!k-clear-right {
  clear: right !important;
}

.k-clear-both {
  clear: both;
}

.\!k-clear-both {
  clear: both !important;
}

.k-clear-none {
  clear: none;
}

.\!k-clear-none {
  clear: none !important;
}

.k-columns-1 {
  columns: 1;
}

.\!k-columns-1 {
  columns: 1 !important;
}

.k-columns-2 {
  columns: 2;
}

.\!k-columns-2 {
  columns: 2 !important;
}

.k-columns-3 {
  columns: 3;
}

.\!k-columns-3 {
  columns: 3 !important;
}

.k-columns-4 {
  columns: 4;
}

.\!k-columns-4 {
  columns: 4 !important;
}

.k-columns-5 {
  columns: 5;
}

.\!k-columns-5 {
  columns: 5 !important;
}

.k-columns-6 {
  columns: 6;
}

.\!k-columns-6 {
  columns: 6 !important;
}

.k-columns-7 {
  columns: 7;
}

.\!k-columns-7 {
  columns: 7 !important;
}

.k-columns-8 {
  columns: 8;
}

.\!k-columns-8 {
  columns: 8 !important;
}

.k-columns-9 {
  columns: 9;
}

.\!k-columns-9 {
  columns: 9 !important;
}

.k-columns-10 {
  columns: 10;
}

.\!k-columns-10 {
  columns: 10 !important;
}

.k-columns-11 {
  columns: 11;
}

.\!k-columns-11 {
  columns: 11 !important;
}

.k-columns-12 {
  columns: 12;
}

.\!k-columns-12 {
  columns: 12 !important;
}

.k-columns-auto {
  columns: auto;
}

.\!k-columns-auto {
  columns: auto !important;
}

.k-d-none {
  display: none;
}

.\!k-d-none {
  display: none !important;
}

.k-d-contents {
  display: contents;
}

.\!k-d-contents {
  display: contents !important;
}

.k-d-block {
  display: block;
}

.\!k-d-block {
  display: block !important;
}

.k-d-inline {
  display: inline;
}

.\!k-d-inline {
  display: inline !important;
}

.k-d-inline-block {
  display: inline-block;
}

.\!k-d-inline-block {
  display: inline-block !important;
}

.k-d-flex, .k-d-flex-col, .k-d-flex-row {
  display: flex;
}

.\!k-d-flex, .\!k-d-flex-col, .\!k-d-flex-row {
  display: flex !important;
}

.k-d-inline-flex {
  display: inline-flex;
}

.\!k-d-inline-flex {
  display: inline-flex !important;
}

.k-d-grid {
  display: grid;
}

.\!k-d-grid {
  display: grid !important;
}

.k-d-inline-grid {
  display: inline-grid;
}

.\!k-d-inline-grid {
  display: inline-grid !important;
}

.k-d-table {
  display: table;
}

.\!k-d-table {
  display: table !important;
}

.k-d-inline-table {
  display: inline-table;
}

.\!k-d-inline-table {
  display: inline-table !important;
}

.k-d-table-row {
  display: table-row;
}

.\!k-d-table-row {
  display: table-row !important;
}

.k-d-table-cell {
  display: table-cell;
}

.\!k-d-table-cell {
  display: table-cell !important;
}

.k-d-list-item {
  display: list-item;
}

.\!k-d-list-item {
  display: list-item !important;
}

.k-display-none {
  display: none;
}

.\!k-display-none {
  display: none !important;
}

.k-display-contents {
  display: contents;
}

.\!k-display-contents {
  display: contents !important;
}

.k-display-block {
  display: block;
}

.\!k-display-block {
  display: block !important;
}

.k-display-inline {
  display: inline;
}

.\!k-display-inline {
  display: inline !important;
}

.k-display-inline-block {
  display: inline-block;
}

.\!k-display-inline-block {
  display: inline-block !important;
}

.k-display-flex {
  display: flex;
}

.\!k-display-flex {
  display: flex !important;
}

.k-display-inline-flex {
  display: inline-flex;
}

.\!k-display-inline-flex {
  display: inline-flex !important;
}

.k-display-grid {
  display: grid;
}

.\!k-display-grid {
  display: grid !important;
}

.k-display-inline-grid {
  display: inline-grid;
}

.\!k-display-inline-grid {
  display: inline-grid !important;
}

.k-display-table {
  display: table;
}

.\!k-display-table {
  display: table !important;
}

.k-display-inline-table {
  display: inline-table;
}

.\!k-display-inline-table {
  display: inline-table !important;
}

.k-display-table-row {
  display: table-row;
}

.\!k-display-table-row {
  display: table-row !important;
}

.k-display-table-cell {
  display: table-cell;
}

.\!k-display-table-cell {
  display: table-cell !important;
}

.k-display-list-item {
  display: list-item;
}

.\!k-display-list-item {
  display: list-item !important;
}

.k-float-left {
  float: left;
}

.\!k-float-left {
  float: left !important;
}

.k-float-right {
  float: right;
}

.\!k-float-right {
  float: right !important;
}

.k-float-none {
  float: none;
}

.\!k-float-none {
  float: none !important;
}

.k-object-fit-contain {
  object-fit: contain;
}

.\!k-object-fit-contain {
  object-fit: contain !important;
}

.k-object-fit-cover {
  object-fit: cover;
}

.\!k-object-fit-cover {
  object-fit: cover !important;
}

.k-object-fit-fill {
  object-fit: fill;
}

.\!k-object-fit-fill {
  object-fit: fill !important;
}

.k-object-fit-scale-down {
  object-fit: scale-down;
}

.\!k-object-fit-scale-down {
  object-fit: scale-down !important;
}

.k-object-fit-initial {
  object-fit: initial;
}

.\!k-object-fit-initial {
  object-fit: initial !important;
}

.k-object-fit-none {
  object-fit: none;
}

.\!k-object-fit-none {
  object-fit: none !important;
}

.k-object-position-center {
  object-position: center;
}

.\!k-object-position-center {
  object-position: center !important;
}

.k-object-position-top {
  object-position: top;
}

.\!k-object-position-top {
  object-position: top !important;
}

.k-object-position-right {
  object-position: right;
}

.\!k-object-position-right {
  object-position: right !important;
}

.k-object-position-bottom {
  object-position: bottom;
}

.\!k-object-position-bottom {
  object-position: bottom !important;
}

.k-object-position-left {
  object-position: left;
}

.\!k-object-position-left {
  object-position: left !important;
}

.k-object-position-top-left {
  object-position: top left;
}

.\!k-object-position-top-left {
  object-position: top left !important;
}

.k-object-position-top-right {
  object-position: top right;
}

.\!k-object-position-top-right {
  object-position: top right !important;
}

.k-object-position-bottom-left {
  object-position: bottom left;
}

.\!k-object-position-bottom-left {
  object-position: bottom left !important;
}

.k-object-position-bottom-right {
  object-position: bottom right;
}

.\!k-object-position-bottom-right {
  object-position: bottom right !important;
}

.k-overflow-auto {
  overflow: auto;
}

.\!k-overflow-auto {
  overflow: auto !important;
}

.k-overflow-hidden {
  overflow: hidden;
}

.\!k-overflow-hidden {
  overflow: hidden !important;
}

.k-overflow-visible {
  overflow: visible;
}

.\!k-overflow-visible {
  overflow: visible !important;
}

.k-overflow-scroll {
  overflow: scroll;
}

.\!k-overflow-scroll {
  overflow: scroll !important;
}

.k-overflow-clip {
  overflow: clip;
}

.\!k-overflow-clip {
  overflow: clip !important;
}

.k-overflow-x-auto {
  overflow-x: auto;
}

.\!k-overflow-x-auto {
  overflow-x: auto !important;
}

.k-overflow-x-hidden {
  overflow-x: hidden;
}

.\!k-overflow-x-hidden {
  overflow-x: hidden !important;
}

.k-overflow-x-visible {
  overflow-x: visible;
}

.\!k-overflow-x-visible {
  overflow-x: visible !important;
}

.k-overflow-x-scroll {
  overflow-x: scroll;
}

.\!k-overflow-x-scroll {
  overflow-x: scroll !important;
}

.k-overflow-x-clip {
  overflow-x: clip;
}

.\!k-overflow-x-clip {
  overflow-x: clip !important;
}

.k-overflow-y-auto {
  overflow-y: auto;
}

.\!k-overflow-y-auto {
  overflow-y: auto !important;
}

.k-overflow-y-hidden {
  overflow-y: hidden;
}

.\!k-overflow-y-hidden {
  overflow-y: hidden !important;
}

.k-overflow-y-visible {
  overflow-y: visible;
}

.\!k-overflow-y-visible {
  overflow-y: visible !important;
}

.k-overflow-y-scroll {
  overflow-y: scroll;
}

.\!k-overflow-y-scroll {
  overflow-y: scroll !important;
}

.k-overflow-y-clip {
  overflow-y: clip;
}

.\!k-overflow-y-clip {
  overflow-y: clip !important;
}

.k-top-0 {
  top: 0px;
}

.\!k-top-0 {
  top: 0px !important;
}

.k-top-1px {
  top: 1px;
}

.\!k-top-1px {
  top: 1px !important;
}

.k-top-0\.5 {
  top: 0.125rem;
}

.\!k-top-0\.5 {
  top: 0.125rem !important;
}

.k-top-1 {
  top: 0.25rem;
}

.\!k-top-1 {
  top: 0.25rem !important;
}

.k-top-1\.5 {
  top: 0.375rem;
}

.\!k-top-1\.5 {
  top: 0.375rem !important;
}

.k-top-2 {
  top: 0.5rem;
}

.\!k-top-2 {
  top: 0.5rem !important;
}

.k-top-2\.5 {
  top: 0.625rem;
}

.\!k-top-2\.5 {
  top: 0.625rem !important;
}

.k-top-3 {
  top: 0.75rem;
}

.\!k-top-3 {
  top: 0.75rem !important;
}

.k-top-3\.5 {
  top: 0.875rem;
}

.\!k-top-3\.5 {
  top: 0.875rem !important;
}

.k-top-4 {
  top: 1rem;
}

.\!k-top-4 {
  top: 1rem !important;
}

.k-top-4\.5 {
  top: 1.125rem;
}

.\!k-top-4\.5 {
  top: 1.125rem !important;
}

.k-top-5 {
  top: 1.25rem;
}

.\!k-top-5 {
  top: 1.25rem !important;
}

.k-top-5\.5 {
  top: 1.375rem;
}

.\!k-top-5\.5 {
  top: 1.375rem !important;
}

.k-top-6 {
  top: 1.5rem;
}

.\!k-top-6 {
  top: 1.5rem !important;
}

.k-top-6\.5 {
  top: 1.625rem;
}

.\!k-top-6\.5 {
  top: 1.625rem !important;
}

.k-top-7 {
  top: 1.75rem;
}

.\!k-top-7 {
  top: 1.75rem !important;
}

.k-top-7\.5 {
  top: 1.875rem;
}

.\!k-top-7\.5 {
  top: 1.875rem !important;
}

.k-top-8 {
  top: 2rem;
}

.\!k-top-8 {
  top: 2rem !important;
}

.k-top-9 {
  top: 2.25rem;
}

.\!k-top-9 {
  top: 2.25rem !important;
}

.k-top-10 {
  top: 2.5rem;
}

.\!k-top-10 {
  top: 2.5rem !important;
}

.k-top-11 {
  top: 2.75rem;
}

.\!k-top-11 {
  top: 2.75rem !important;
}

.k-top-12 {
  top: 3rem;
}

.\!k-top-12 {
  top: 3rem !important;
}

.k-top-13 {
  top: 3.25rem;
}

.\!k-top-13 {
  top: 3.25rem !important;
}

.k-top-14 {
  top: 3.5rem;
}

.\!k-top-14 {
  top: 3.5rem !important;
}

.k-top-15 {
  top: 3.75rem;
}

.\!k-top-15 {
  top: 3.75rem !important;
}

.k-top-16 {
  top: 4rem;
}

.\!k-top-16 {
  top: 4rem !important;
}

.k-top-17 {
  top: 4.25rem;
}

.\!k-top-17 {
  top: 4.25rem !important;
}

.k-top-18 {
  top: 4.5rem;
}

.\!k-top-18 {
  top: 4.5rem !important;
}

.k-top-19 {
  top: 4.75rem;
}

.\!k-top-19 {
  top: 4.75rem !important;
}

.k-top-20 {
  top: 5rem;
}

.\!k-top-20 {
  top: 5rem !important;
}

.k-top-21 {
  top: 5.25rem;
}

.\!k-top-21 {
  top: 5.25rem !important;
}

.k-top-22 {
  top: 5.5rem;
}

.\!k-top-22 {
  top: 5.5rem !important;
}

.k-top-23 {
  top: 5.75rem;
}

.\!k-top-23 {
  top: 5.75rem !important;
}

.k-top-24 {
  top: 6rem;
}

.\!k-top-24 {
  top: 6rem !important;
}

.k-top-25 {
  top: 7rem;
}

.\!k-top-25 {
  top: 7rem !important;
}

.k-top-26 {
  top: 8rem;
}

.\!k-top-26 {
  top: 8rem !important;
}

.k-top-27 {
  top: 9rem;
}

.\!k-top-27 {
  top: 9rem !important;
}

.k-top-28 {
  top: 10rem;
}

.\!k-top-28 {
  top: 10rem !important;
}

.k-top-29 {
  top: 11rem;
}

.\!k-top-29 {
  top: 11rem !important;
}

.k-top-30 {
  top: 12rem;
}

.\!k-top-30 {
  top: 12rem !important;
}

.k-top--1 {
  top: -1px;
}

.\!k-top--1 {
  top: -1px !important;
}

.k-right-0 {
  right: 0px;
}

.\!k-right-0 {
  right: 0px !important;
}

.k-right-1px {
  right: 1px;
}

.\!k-right-1px {
  right: 1px !important;
}

.k-right-0\.5 {
  right: 0.125rem;
}

.\!k-right-0\.5 {
  right: 0.125rem !important;
}

.k-right-1 {
  right: 0.25rem;
}

.\!k-right-1 {
  right: 0.25rem !important;
}

.k-right-1\.5 {
  right: 0.375rem;
}

.\!k-right-1\.5 {
  right: 0.375rem !important;
}

.k-right-2 {
  right: 0.5rem;
}

.\!k-right-2 {
  right: 0.5rem !important;
}

.k-right-2\.5 {
  right: 0.625rem;
}

.\!k-right-2\.5 {
  right: 0.625rem !important;
}

.k-right-3 {
  right: 0.75rem;
}

.\!k-right-3 {
  right: 0.75rem !important;
}

.k-right-3\.5 {
  right: 0.875rem;
}

.\!k-right-3\.5 {
  right: 0.875rem !important;
}

.k-right-4 {
  right: 1rem;
}

.\!k-right-4 {
  right: 1rem !important;
}

.k-right-4\.5 {
  right: 1.125rem;
}

.\!k-right-4\.5 {
  right: 1.125rem !important;
}

.k-right-5 {
  right: 1.25rem;
}

.\!k-right-5 {
  right: 1.25rem !important;
}

.k-right-5\.5 {
  right: 1.375rem;
}

.\!k-right-5\.5 {
  right: 1.375rem !important;
}

.k-right-6 {
  right: 1.5rem;
}

.\!k-right-6 {
  right: 1.5rem !important;
}

.k-right-6\.5 {
  right: 1.625rem;
}

.\!k-right-6\.5 {
  right: 1.625rem !important;
}

.k-right-7 {
  right: 1.75rem;
}

.\!k-right-7 {
  right: 1.75rem !important;
}

.k-right-7\.5 {
  right: 1.875rem;
}

.\!k-right-7\.5 {
  right: 1.875rem !important;
}

.k-right-8 {
  right: 2rem;
}

.\!k-right-8 {
  right: 2rem !important;
}

.k-right-9 {
  right: 2.25rem;
}

.\!k-right-9 {
  right: 2.25rem !important;
}

.k-right-10 {
  right: 2.5rem;
}

.\!k-right-10 {
  right: 2.5rem !important;
}

.k-right-11 {
  right: 2.75rem;
}

.\!k-right-11 {
  right: 2.75rem !important;
}

.k-right-12 {
  right: 3rem;
}

.\!k-right-12 {
  right: 3rem !important;
}

.k-right-13 {
  right: 3.25rem;
}

.\!k-right-13 {
  right: 3.25rem !important;
}

.k-right-14 {
  right: 3.5rem;
}

.\!k-right-14 {
  right: 3.5rem !important;
}

.k-right-15 {
  right: 3.75rem;
}

.\!k-right-15 {
  right: 3.75rem !important;
}

.k-right-16 {
  right: 4rem;
}

.\!k-right-16 {
  right: 4rem !important;
}

.k-right-17 {
  right: 4.25rem;
}

.\!k-right-17 {
  right: 4.25rem !important;
}

.k-right-18 {
  right: 4.5rem;
}

.\!k-right-18 {
  right: 4.5rem !important;
}

.k-right-19 {
  right: 4.75rem;
}

.\!k-right-19 {
  right: 4.75rem !important;
}

.k-right-20 {
  right: 5rem;
}

.\!k-right-20 {
  right: 5rem !important;
}

.k-right-21 {
  right: 5.25rem;
}

.\!k-right-21 {
  right: 5.25rem !important;
}

.k-right-22 {
  right: 5.5rem;
}

.\!k-right-22 {
  right: 5.5rem !important;
}

.k-right-23 {
  right: 5.75rem;
}

.\!k-right-23 {
  right: 5.75rem !important;
}

.k-right-24 {
  right: 6rem;
}

.\!k-right-24 {
  right: 6rem !important;
}

.k-right-25 {
  right: 7rem;
}

.\!k-right-25 {
  right: 7rem !important;
}

.k-right-26 {
  right: 8rem;
}

.\!k-right-26 {
  right: 8rem !important;
}

.k-right-27 {
  right: 9rem;
}

.\!k-right-27 {
  right: 9rem !important;
}

.k-right-28 {
  right: 10rem;
}

.\!k-right-28 {
  right: 10rem !important;
}

.k-right-29 {
  right: 11rem;
}

.\!k-right-29 {
  right: 11rem !important;
}

.k-right-30 {
  right: 12rem;
}

.\!k-right-30 {
  right: 12rem !important;
}

.k-right--1 {
  right: -1px;
}

.\!k-right--1 {
  right: -1px !important;
}

.k-bottom-0 {
  bottom: 0px;
}

.\!k-bottom-0 {
  bottom: 0px !important;
}

.k-bottom-1px {
  bottom: 1px;
}

.\!k-bottom-1px {
  bottom: 1px !important;
}

.k-bottom-0\.5 {
  bottom: 0.125rem;
}

.\!k-bottom-0\.5 {
  bottom: 0.125rem !important;
}

.k-bottom-1 {
  bottom: 0.25rem;
}

.\!k-bottom-1 {
  bottom: 0.25rem !important;
}

.k-bottom-1\.5 {
  bottom: 0.375rem;
}

.\!k-bottom-1\.5 {
  bottom: 0.375rem !important;
}

.k-bottom-2 {
  bottom: 0.5rem;
}

.\!k-bottom-2 {
  bottom: 0.5rem !important;
}

.k-bottom-2\.5 {
  bottom: 0.625rem;
}

.\!k-bottom-2\.5 {
  bottom: 0.625rem !important;
}

.k-bottom-3 {
  bottom: 0.75rem;
}

.\!k-bottom-3 {
  bottom: 0.75rem !important;
}

.k-bottom-3\.5 {
  bottom: 0.875rem;
}

.\!k-bottom-3\.5 {
  bottom: 0.875rem !important;
}

.k-bottom-4 {
  bottom: 1rem;
}

.\!k-bottom-4 {
  bottom: 1rem !important;
}

.k-bottom-4\.5 {
  bottom: 1.125rem;
}

.\!k-bottom-4\.5 {
  bottom: 1.125rem !important;
}

.k-bottom-5 {
  bottom: 1.25rem;
}

.\!k-bottom-5 {
  bottom: 1.25rem !important;
}

.k-bottom-5\.5 {
  bottom: 1.375rem;
}

.\!k-bottom-5\.5 {
  bottom: 1.375rem !important;
}

.k-bottom-6 {
  bottom: 1.5rem;
}

.\!k-bottom-6 {
  bottom: 1.5rem !important;
}

.k-bottom-6\.5 {
  bottom: 1.625rem;
}

.\!k-bottom-6\.5 {
  bottom: 1.625rem !important;
}

.k-bottom-7 {
  bottom: 1.75rem;
}

.\!k-bottom-7 {
  bottom: 1.75rem !important;
}

.k-bottom-7\.5 {
  bottom: 1.875rem;
}

.\!k-bottom-7\.5 {
  bottom: 1.875rem !important;
}

.k-bottom-8 {
  bottom: 2rem;
}

.\!k-bottom-8 {
  bottom: 2rem !important;
}

.k-bottom-9 {
  bottom: 2.25rem;
}

.\!k-bottom-9 {
  bottom: 2.25rem !important;
}

.k-bottom-10 {
  bottom: 2.5rem;
}

.\!k-bottom-10 {
  bottom: 2.5rem !important;
}

.k-bottom-11 {
  bottom: 2.75rem;
}

.\!k-bottom-11 {
  bottom: 2.75rem !important;
}

.k-bottom-12 {
  bottom: 3rem;
}

.\!k-bottom-12 {
  bottom: 3rem !important;
}

.k-bottom-13 {
  bottom: 3.25rem;
}

.\!k-bottom-13 {
  bottom: 3.25rem !important;
}

.k-bottom-14 {
  bottom: 3.5rem;
}

.\!k-bottom-14 {
  bottom: 3.5rem !important;
}

.k-bottom-15 {
  bottom: 3.75rem;
}

.\!k-bottom-15 {
  bottom: 3.75rem !important;
}

.k-bottom-16 {
  bottom: 4rem;
}

.\!k-bottom-16 {
  bottom: 4rem !important;
}

.k-bottom-17 {
  bottom: 4.25rem;
}

.\!k-bottom-17 {
  bottom: 4.25rem !important;
}

.k-bottom-18 {
  bottom: 4.5rem;
}

.\!k-bottom-18 {
  bottom: 4.5rem !important;
}

.k-bottom-19 {
  bottom: 4.75rem;
}

.\!k-bottom-19 {
  bottom: 4.75rem !important;
}

.k-bottom-20 {
  bottom: 5rem;
}

.\!k-bottom-20 {
  bottom: 5rem !important;
}

.k-bottom-21 {
  bottom: 5.25rem;
}

.\!k-bottom-21 {
  bottom: 5.25rem !important;
}

.k-bottom-22 {
  bottom: 5.5rem;
}

.\!k-bottom-22 {
  bottom: 5.5rem !important;
}

.k-bottom-23 {
  bottom: 5.75rem;
}

.\!k-bottom-23 {
  bottom: 5.75rem !important;
}

.k-bottom-24 {
  bottom: 6rem;
}

.\!k-bottom-24 {
  bottom: 6rem !important;
}

.k-bottom-25 {
  bottom: 7rem;
}

.\!k-bottom-25 {
  bottom: 7rem !important;
}

.k-bottom-26 {
  bottom: 8rem;
}

.\!k-bottom-26 {
  bottom: 8rem !important;
}

.k-bottom-27 {
  bottom: 9rem;
}

.\!k-bottom-27 {
  bottom: 9rem !important;
}

.k-bottom-28 {
  bottom: 10rem;
}

.\!k-bottom-28 {
  bottom: 10rem !important;
}

.k-bottom-29 {
  bottom: 11rem;
}

.\!k-bottom-29 {
  bottom: 11rem !important;
}

.k-bottom-30 {
  bottom: 12rem;
}

.\!k-bottom-30 {
  bottom: 12rem !important;
}

.k-bottom--1 {
  bottom: -1px;
}

.\!k-bottom--1 {
  bottom: -1px !important;
}

.k-left-0 {
  left: 0px;
}

.\!k-left-0 {
  left: 0px !important;
}

.k-left-1px {
  left: 1px;
}

.\!k-left-1px {
  left: 1px !important;
}

.k-left-0\.5 {
  left: 0.125rem;
}

.\!k-left-0\.5 {
  left: 0.125rem !important;
}

.k-left-1 {
  left: 0.25rem;
}

.\!k-left-1 {
  left: 0.25rem !important;
}

.k-left-1\.5 {
  left: 0.375rem;
}

.\!k-left-1\.5 {
  left: 0.375rem !important;
}

.k-left-2 {
  left: 0.5rem;
}

.\!k-left-2 {
  left: 0.5rem !important;
}

.k-left-2\.5 {
  left: 0.625rem;
}

.\!k-left-2\.5 {
  left: 0.625rem !important;
}

.k-left-3 {
  left: 0.75rem;
}

.\!k-left-3 {
  left: 0.75rem !important;
}

.k-left-3\.5 {
  left: 0.875rem;
}

.\!k-left-3\.5 {
  left: 0.875rem !important;
}

.k-left-4 {
  left: 1rem;
}

.\!k-left-4 {
  left: 1rem !important;
}

.k-left-4\.5 {
  left: 1.125rem;
}

.\!k-left-4\.5 {
  left: 1.125rem !important;
}

.k-left-5 {
  left: 1.25rem;
}

.\!k-left-5 {
  left: 1.25rem !important;
}

.k-left-5\.5 {
  left: 1.375rem;
}

.\!k-left-5\.5 {
  left: 1.375rem !important;
}

.k-left-6 {
  left: 1.5rem;
}

.\!k-left-6 {
  left: 1.5rem !important;
}

.k-left-6\.5 {
  left: 1.625rem;
}

.\!k-left-6\.5 {
  left: 1.625rem !important;
}

.k-left-7 {
  left: 1.75rem;
}

.\!k-left-7 {
  left: 1.75rem !important;
}

.k-left-7\.5 {
  left: 1.875rem;
}

.\!k-left-7\.5 {
  left: 1.875rem !important;
}

.k-left-8 {
  left: 2rem;
}

.\!k-left-8 {
  left: 2rem !important;
}

.k-left-9 {
  left: 2.25rem;
}

.\!k-left-9 {
  left: 2.25rem !important;
}

.k-left-10 {
  left: 2.5rem;
}

.\!k-left-10 {
  left: 2.5rem !important;
}

.k-left-11 {
  left: 2.75rem;
}

.\!k-left-11 {
  left: 2.75rem !important;
}

.k-left-12 {
  left: 3rem;
}

.\!k-left-12 {
  left: 3rem !important;
}

.k-left-13 {
  left: 3.25rem;
}

.\!k-left-13 {
  left: 3.25rem !important;
}

.k-left-14 {
  left: 3.5rem;
}

.\!k-left-14 {
  left: 3.5rem !important;
}

.k-left-15 {
  left: 3.75rem;
}

.\!k-left-15 {
  left: 3.75rem !important;
}

.k-left-16 {
  left: 4rem;
}

.\!k-left-16 {
  left: 4rem !important;
}

.k-left-17 {
  left: 4.25rem;
}

.\!k-left-17 {
  left: 4.25rem !important;
}

.k-left-18 {
  left: 4.5rem;
}

.\!k-left-18 {
  left: 4.5rem !important;
}

.k-left-19 {
  left: 4.75rem;
}

.\!k-left-19 {
  left: 4.75rem !important;
}

.k-left-20 {
  left: 5rem;
}

.\!k-left-20 {
  left: 5rem !important;
}

.k-left-21 {
  left: 5.25rem;
}

.\!k-left-21 {
  left: 5.25rem !important;
}

.k-left-22 {
  left: 5.5rem;
}

.\!k-left-22 {
  left: 5.5rem !important;
}

.k-left-23 {
  left: 5.75rem;
}

.\!k-left-23 {
  left: 5.75rem !important;
}

.k-left-24 {
  left: 6rem;
}

.\!k-left-24 {
  left: 6rem !important;
}

.k-left-25 {
  left: 7rem;
}

.\!k-left-25 {
  left: 7rem !important;
}

.k-left-26 {
  left: 8rem;
}

.\!k-left-26 {
  left: 8rem !important;
}

.k-left-27 {
  left: 9rem;
}

.\!k-left-27 {
  left: 9rem !important;
}

.k-left-28 {
  left: 10rem;
}

.\!k-left-28 {
  left: 10rem !important;
}

.k-left-29 {
  left: 11rem;
}

.\!k-left-29 {
  left: 11rem !important;
}

.k-left-30 {
  left: 12rem;
}

.\!k-left-30 {
  left: 12rem !important;
}

.k-left--1 {
  left: -1px;
}

.\!k-left--1 {
  left: -1px !important;
}

.k-top,
.k-pos-top {
  top: 0;
}

.k-right,
.k-pos-right {
  right: 0;
}

.k-bottom,
.k-pos-bottom {
  bottom: 0;
}

.k-left,
.k-pos-left {
  left: 0;
}

.k-inset-0 {
  inset: 0;
}

.\!k-inset-0 {
  inset: 0 !important;
}

.k-inset-1 {
  inset: 1px;
}

.\!k-inset-1 {
  inset: 1px !important;
}

.k-inset--1 {
  inset: -1px;
}

.\!k-inset--1 {
  inset: -1px !important;
}

.k-inset-x-0 {
  inset-inline: 0;
}

.\!k-inset-x-0 {
  inset-inline: 0 !important;
}

.k-inset-x-1 {
  inset-inline: 1px;
}

.\!k-inset-x-1 {
  inset-inline: 1px !important;
}

.k-inset-x--1 {
  inset-inline: -1px;
}

.\!k-inset-x--1 {
  inset-inline: -1px !important;
}

.k-inset-y-0 {
  inset-block: 0;
}

.\!k-inset-y-0 {
  inset-block: 0 !important;
}

.k-inset-y-1 {
  inset-block: 1px;
}

.\!k-inset-y-1 {
  inset-block: 1px !important;
}

.k-inset-y--1 {
  inset-block: -1px;
}

.\!k-inset-y--1 {
  inset-block: -1px !important;
}

.k-top-left-0 {
  top: 0;
  left: 0;
}

.\!k-top-left-0 {
  top: 0 !important;
  left: 0 !important;
}

.k-top-left-1 {
  top: 1px;
  left: 1px;
}

.\!k-top-left-1 {
  top: 1px !important;
  left: 1px !important;
}

.k-top-left--1 {
  top: -1px;
  left: -1px;
}

.\!k-top-left--1 {
  top: -1px !important;
  left: -1px !important;
}

.k-top-right-0 {
  top: 0;
  right: 0;
}

.\!k-top-right-0 {
  top: 0 !important;
  right: 0 !important;
}

.k-top-right-1 {
  top: 1px;
  right: 1px;
}

.\!k-top-right-1 {
  top: 1px !important;
  right: 1px !important;
}

.k-top-right--1 {
  top: -1px;
  right: -1px;
}

.\!k-top-right--1 {
  top: -1px !important;
  right: -1px !important;
}

.k-bottom-left-0 {
  bottom: 0;
  left: 0;
}

.\!k-bottom-left-0 {
  bottom: 0 !important;
  left: 0 !important;
}

.k-bottom-left-1 {
  bottom: 1px;
  left: 1px;
}

.\!k-bottom-left-1 {
  bottom: 1px !important;
  left: 1px !important;
}

.k-bottom-left--1 {
  bottom: -1px;
  left: -1px;
}

.\!k-bottom-left--1 {
  bottom: -1px !important;
  left: -1px !important;
}

.k-bottom-right-0 {
  bottom: 0;
  right: 0;
}

.\!k-bottom-right-0 {
  bottom: 0 !important;
  right: 0 !important;
}

.k-bottom-right-1 {
  bottom: 1px;
  right: 1px;
}

.\!k-bottom-right-1 {
  bottom: 1px !important;
  right: 1px !important;
}

.k-bottom-right--1 {
  bottom: -1px;
  right: -1px;
}

.\!k-bottom-right--1 {
  bottom: -1px !important;
  right: -1px !important;
}

.k-top-start,
.k-pos-top-start {
  top: 0;
  inset-inline-start: 0;
}

.k-top-end,
.k-pos-top-end {
  top: 0;
  inset-inline-end: 0;
}

.k-bottom-start,
.k-pos-bottom-start {
  bottom: 0;
  inset-inline-start: 0;
}

.k-bottom-end,
.k-pos-bottom-end {
  bottom: 0;
  inset-inline-end: 0;
}

.k-top-center,
.k-pos-top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-middle-left,
.k-middle-start,
.k-pos-middle-start {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.k-middle-center {
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

.k-middle-right,
.k-middle-end,
.k-pos-middle-end {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-bottom-center,
.k-pos-bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-pos-static, .k-static {
  position: static;
}

.\!k-pos-static, .\!k-static {
  position: static !important;
}

.k-pos-relative, .k-relative {
  position: relative;
}

.\!k-pos-relative, .\!k-relative {
  position: relative !important;
}

.k-pos-absolute, .k-absolute {
  position: absolute;
}

.\!k-pos-absolute, .\!k-absolute {
  position: absolute !important;
}

.k-pos-fixed, .k-fixed {
  position: fixed;
}

.\!k-pos-fixed, .\!k-fixed {
  position: fixed !important;
}

.k-pos-sticky, .k-sticky {
  position: sticky;
}

.\!k-pos-sticky, .\!k-sticky {
  position: sticky !important;
}

.k-visibility-visible, .k-visible {
  visibility: visible;
}

.\!k-visibility-visible, .\!k-visible {
  visibility: visible !important;
}

.k-visibility-invisible {
  visibility: hidden;
}

.\!k-visibility-invisible {
  visibility: hidden !important;
}

.k-visibility-collapse {
  visibility: collapse;
}

.\!k-visibility-collapse {
  visibility: collapse !important;
}

.k-z-0 {
  z-index: 0;
}

.\!k-z-0 {
  z-index: 0 !important;
}

.k-z-10 {
  z-index: 10;
}

.\!k-z-10 {
  z-index: 10 !important;
}

.k-z-20 {
  z-index: 20;
}

.\!k-z-20 {
  z-index: 20 !important;
}

.k-z-30 {
  z-index: 30;
}

.\!k-z-30 {
  z-index: 30 !important;
}

.k-z-40 {
  z-index: 40;
}

.\!k-z-40 {
  z-index: 40 !important;
}

.k-z-50 {
  z-index: 50;
}

.\!k-z-50 {
  z-index: 50 !important;
}

.k-z-auto {
  z-index: auto;
}

.\!k-z-auto {
  z-index: auto !important;
}

.k-align-content-normal {
  align-content: normal;
}

.\!k-align-content-normal {
  align-content: normal !important;
}

.k-align-content-stretch {
  align-content: stretch;
}

.\!k-align-content-stretch {
  align-content: stretch !important;
}

.k-align-content-center {
  align-content: center;
}

.\!k-align-content-center {
  align-content: center !important;
}

.k-align-content-start {
  align-content: start;
}

.\!k-align-content-start {
  align-content: start !important;
}

.k-align-content-end {
  align-content: end;
}

.\!k-align-content-end {
  align-content: end !important;
}

.k-align-content-flex-start {
  align-content: flex-start;
}

.\!k-align-content-flex-start {
  align-content: flex-start !important;
}

.k-align-content-flex-end {
  align-content: flex-end;
}

.\!k-align-content-flex-end {
  align-content: flex-end !important;
}

.k-align-content-baseline {
  align-content: baseline;
}

.\!k-align-content-baseline {
  align-content: baseline !important;
}

.k-align-content-first-baseline {
  align-content: first baseline;
}

.\!k-align-content-first-baseline {
  align-content: first baseline !important;
}

.k-align-content-last-baseline {
  align-content: last baseline;
}

.\!k-align-content-last-baseline {
  align-content: last baseline !important;
}

.k-align-content-between {
  align-content: space-between;
}

.\!k-align-content-between {
  align-content: space-between !important;
}

.k-align-content-around {
  align-content: space-around;
}

.\!k-align-content-around {
  align-content: space-around !important;
}

.k-align-content-evenly {
  align-content: space-evenly;
}

.\!k-align-content-evenly {
  align-content: space-evenly !important;
}

.k-align-items-normal {
  align-items: normal;
}

.\!k-align-items-normal {
  align-items: normal !important;
}

.k-align-items-stretch {
  align-items: stretch;
}

.\!k-align-items-stretch {
  align-items: stretch !important;
}

.k-align-items-center {
  align-items: center;
}

.\!k-align-items-center {
  align-items: center !important;
}

.k-align-items-start {
  align-items: start;
}

.\!k-align-items-start {
  align-items: start !important;
}

.k-align-items-end {
  align-items: end;
}

.\!k-align-items-end {
  align-items: end !important;
}

.k-align-items-flex-start {
  align-items: flex-start;
}

.\!k-align-items-flex-start {
  align-items: flex-start !important;
}

.k-align-items-flex-end {
  align-items: flex-end;
}

.\!k-align-items-flex-end {
  align-items: flex-end !important;
}

.k-align-items-baseline {
  align-items: baseline;
}

.\!k-align-items-baseline {
  align-items: baseline !important;
}

.k-align-items-first-baseline {
  align-items: first baseline;
}

.\!k-align-items-first-baseline {
  align-items: first baseline !important;
}

.k-align-items-last-baseline {
  align-items: last baseline;
}

.\!k-align-items-last-baseline {
  align-items: last baseline !important;
}

.k-align-items-self-start {
  align-items: self-start;
}

.\!k-align-items-self-start {
  align-items: self-start !important;
}

.k-align-items-self-end {
  align-items: self-end;
}

.\!k-align-items-self-end {
  align-items: self-end !important;
}

.k-align-self-auto {
  align-self: auto;
}

.\!k-align-self-auto {
  align-self: auto !important;
}

.k-align-self-normal {
  align-self: normal;
}

.\!k-align-self-normal {
  align-self: normal !important;
}

.k-align-self-stretch {
  align-self: stretch;
}

.\!k-align-self-stretch {
  align-self: stretch !important;
}

.k-align-self-center {
  align-self: center;
}

.\!k-align-self-center {
  align-self: center !important;
}

.k-align-self-start {
  align-self: start;
}

.\!k-align-self-start {
  align-self: start !important;
}

.k-align-self-end {
  align-self: end;
}

.\!k-align-self-end {
  align-self: end !important;
}

.k-align-self-flex-start {
  align-self: flex-start;
}

.\!k-align-self-flex-start {
  align-self: flex-start !important;
}

.k-align-self-flex-end {
  align-self: flex-end;
}

.\!k-align-self-flex-end {
  align-self: flex-end !important;
}

.k-align-self-baseline {
  align-self: baseline;
}

.\!k-align-self-baseline {
  align-self: baseline !important;
}

.k-align-self-first-baseline {
  align-self: first baseline;
}

.\!k-align-self-first-baseline {
  align-self: first baseline !important;
}

.k-align-self-last-baseline {
  align-self: last baseline;
}

.\!k-align-self-last-baseline {
  align-self: last baseline !important;
}

.k-align-self-self-start {
  align-self: self-start;
}

.\!k-align-self-self-start {
  align-self: self-start !important;
}

.k-align-self-self-end {
  align-self: self-end;
}

.\!k-align-self-self-end {
  align-self: self-end !important;
}

.k-basis-0 {
  flex-basis: 0px;
}

.\!k-basis-0 {
  flex-basis: 0px !important;
}

.k-basis-1px {
  flex-basis: 1px;
}

.\!k-basis-1px {
  flex-basis: 1px !important;
}

.k-basis-0\.5 {
  flex-basis: 0.125rem;
}

.\!k-basis-0\.5 {
  flex-basis: 0.125rem !important;
}

.k-basis-1 {
  flex-basis: 0.25rem;
}

.\!k-basis-1 {
  flex-basis: 0.25rem !important;
}

.k-basis-1\.5 {
  flex-basis: 0.375rem;
}

.\!k-basis-1\.5 {
  flex-basis: 0.375rem !important;
}

.k-basis-2 {
  flex-basis: 0.5rem;
}

.\!k-basis-2 {
  flex-basis: 0.5rem !important;
}

.k-basis-2\.5 {
  flex-basis: 0.625rem;
}

.\!k-basis-2\.5 {
  flex-basis: 0.625rem !important;
}

.k-basis-3 {
  flex-basis: 0.75rem;
}

.\!k-basis-3 {
  flex-basis: 0.75rem !important;
}

.k-basis-3\.5 {
  flex-basis: 0.875rem;
}

.\!k-basis-3\.5 {
  flex-basis: 0.875rem !important;
}

.k-basis-4 {
  flex-basis: 1rem;
}

.\!k-basis-4 {
  flex-basis: 1rem !important;
}

.k-basis-4\.5 {
  flex-basis: 1.125rem;
}

.\!k-basis-4\.5 {
  flex-basis: 1.125rem !important;
}

.k-basis-5 {
  flex-basis: 1.25rem;
}

.\!k-basis-5 {
  flex-basis: 1.25rem !important;
}

.k-basis-5\.5 {
  flex-basis: 1.375rem;
}

.\!k-basis-5\.5 {
  flex-basis: 1.375rem !important;
}

.k-basis-6 {
  flex-basis: 1.5rem;
}

.\!k-basis-6 {
  flex-basis: 1.5rem !important;
}

.k-basis-6\.5 {
  flex-basis: 1.625rem;
}

.\!k-basis-6\.5 {
  flex-basis: 1.625rem !important;
}

.k-basis-7 {
  flex-basis: 1.75rem;
}

.\!k-basis-7 {
  flex-basis: 1.75rem !important;
}

.k-basis-7\.5 {
  flex-basis: 1.875rem;
}

.\!k-basis-7\.5 {
  flex-basis: 1.875rem !important;
}

.k-basis-8 {
  flex-basis: 2rem;
}

.\!k-basis-8 {
  flex-basis: 2rem !important;
}

.k-basis-9 {
  flex-basis: 2.25rem;
}

.\!k-basis-9 {
  flex-basis: 2.25rem !important;
}

.k-basis-10 {
  flex-basis: 2.5rem;
}

.\!k-basis-10 {
  flex-basis: 2.5rem !important;
}

.k-basis-11 {
  flex-basis: 2.75rem;
}

.\!k-basis-11 {
  flex-basis: 2.75rem !important;
}

.k-basis-12 {
  flex-basis: 3rem;
}

.\!k-basis-12 {
  flex-basis: 3rem !important;
}

.k-basis-13 {
  flex-basis: 3.25rem;
}

.\!k-basis-13 {
  flex-basis: 3.25rem !important;
}

.k-basis-14 {
  flex-basis: 3.5rem;
}

.\!k-basis-14 {
  flex-basis: 3.5rem !important;
}

.k-basis-15 {
  flex-basis: 3.75rem;
}

.\!k-basis-15 {
  flex-basis: 3.75rem !important;
}

.k-basis-16 {
  flex-basis: 4rem;
}

.\!k-basis-16 {
  flex-basis: 4rem !important;
}

.k-basis-17 {
  flex-basis: 4.25rem;
}

.\!k-basis-17 {
  flex-basis: 4.25rem !important;
}

.k-basis-18 {
  flex-basis: 4.5rem;
}

.\!k-basis-18 {
  flex-basis: 4.5rem !important;
}

.k-basis-19 {
  flex-basis: 4.75rem;
}

.\!k-basis-19 {
  flex-basis: 4.75rem !important;
}

.k-basis-20 {
  flex-basis: 5rem;
}

.\!k-basis-20 {
  flex-basis: 5rem !important;
}

.k-basis-21 {
  flex-basis: 5.25rem;
}

.\!k-basis-21 {
  flex-basis: 5.25rem !important;
}

.k-basis-22 {
  flex-basis: 5.5rem;
}

.\!k-basis-22 {
  flex-basis: 5.5rem !important;
}

.k-basis-23 {
  flex-basis: 5.75rem;
}

.\!k-basis-23 {
  flex-basis: 5.75rem !important;
}

.k-basis-24 {
  flex-basis: 6rem;
}

.\!k-basis-24 {
  flex-basis: 6rem !important;
}

.k-basis-25 {
  flex-basis: 7rem;
}

.\!k-basis-25 {
  flex-basis: 7rem !important;
}

.k-basis-26 {
  flex-basis: 8rem;
}

.\!k-basis-26 {
  flex-basis: 8rem !important;
}

.k-basis-27 {
  flex-basis: 9rem;
}

.\!k-basis-27 {
  flex-basis: 9rem !important;
}

.k-basis-28 {
  flex-basis: 10rem;
}

.\!k-basis-28 {
  flex-basis: 10rem !important;
}

.k-basis-29 {
  flex-basis: 11rem;
}

.\!k-basis-29 {
  flex-basis: 11rem !important;
}

.k-basis-30 {
  flex-basis: 12rem;
}

.\!k-basis-30 {
  flex-basis: 12rem !important;
}

.k-basis-auto {
  flex-basis: auto;
}

.\!k-basis-auto {
  flex-basis: auto !important;
}

.k-basis-1\/2 {
  flex-basis: 50%;
}

.\!k-basis-1\/2 {
  flex-basis: 50% !important;
}

.k-basis-1\/3 {
  flex-basis: 33.333333%;
}

.\!k-basis-1\/3 {
  flex-basis: 33.333333% !important;
}

.k-basis-2\/3 {
  flex-basis: 66.666667%;
}

.\!k-basis-2\/3 {
  flex-basis: 66.666667% !important;
}

.k-basis-1\/4 {
  flex-basis: 25%;
}

.\!k-basis-1\/4 {
  flex-basis: 25% !important;
}

.k-basis-2\/4 {
  flex-basis: 50%;
}

.\!k-basis-2\/4 {
  flex-basis: 50% !important;
}

.k-basis-3\/4 {
  flex-basis: 75%;
}

.\!k-basis-3\/4 {
  flex-basis: 75% !important;
}

.k-basis-1\/5 {
  flex-basis: 20%;
}

.\!k-basis-1\/5 {
  flex-basis: 20% !important;
}

.k-basis-2\/5 {
  flex-basis: 40%;
}

.\!k-basis-2\/5 {
  flex-basis: 40% !important;
}

.k-basis-3\/5 {
  flex-basis: 60%;
}

.\!k-basis-3\/5 {
  flex-basis: 60% !important;
}

.k-basis-4\/5 {
  flex-basis: 80%;
}

.\!k-basis-4\/5 {
  flex-basis: 80% !important;
}

.k-basis-1\/6 {
  flex-basis: 16.666667%;
}

.\!k-basis-1\/6 {
  flex-basis: 16.666667% !important;
}

.k-basis-2\/6 {
  flex-basis: 33.333333%;
}

.\!k-basis-2\/6 {
  flex-basis: 33.333333% !important;
}

.k-basis-3\/6 {
  flex-basis: 50%;
}

.\!k-basis-3\/6 {
  flex-basis: 50% !important;
}

.k-basis-4\/6 {
  flex-basis: 66.666667%;
}

.\!k-basis-4\/6 {
  flex-basis: 66.666667% !important;
}

.k-basis-5\/6 {
  flex-basis: 83.333333%;
}

.\!k-basis-5\/6 {
  flex-basis: 83.333333% !important;
}

.k-basis-1\/12 {
  flex-basis: 8.333333%;
}

.\!k-basis-1\/12 {
  flex-basis: 8.333333% !important;
}

.k-basis-2\/12 {
  flex-basis: 16.666667%;
}

.\!k-basis-2\/12 {
  flex-basis: 16.666667% !important;
}

.k-basis-3\/12 {
  flex-basis: 25%;
}

.\!k-basis-3\/12 {
  flex-basis: 25% !important;
}

.k-basis-4\/12 {
  flex-basis: 33.333333%;
}

.\!k-basis-4\/12 {
  flex-basis: 33.333333% !important;
}

.k-basis-5\/12 {
  flex-basis: 41.666667%;
}

.\!k-basis-5\/12 {
  flex-basis: 41.666667% !important;
}

.k-basis-6\/12 {
  flex-basis: 50%;
}

.\!k-basis-6\/12 {
  flex-basis: 50% !important;
}

.k-basis-7\/12 {
  flex-basis: 58.333333%;
}

.\!k-basis-7\/12 {
  flex-basis: 58.333333% !important;
}

.k-basis-8\/12 {
  flex-basis: 66.666667%;
}

.\!k-basis-8\/12 {
  flex-basis: 66.666667% !important;
}

.k-basis-9\/12 {
  flex-basis: 75%;
}

.\!k-basis-9\/12 {
  flex-basis: 75% !important;
}

.k-basis-10\/12 {
  flex-basis: 83.333333%;
}

.\!k-basis-10\/12 {
  flex-basis: 83.333333% !important;
}

.k-basis-11\/12 {
  flex-basis: 91.666667%;
}

.\!k-basis-11\/12 {
  flex-basis: 91.666667% !important;
}

.k-basis-full {
  flex-basis: 100%;
}

.\!k-basis-full {
  flex-basis: 100% !important;
}

.k-basis-min {
  flex-basis: min-content;
}

.\!k-basis-min {
  flex-basis: min-content !important;
}

.k-basis-max {
  flex-basis: max-content;
}

.\!k-basis-max {
  flex-basis: max-content !important;
}

.k-basis-fit {
  flex-basis: fit-content;
}

.\!k-basis-fit {
  flex-basis: fit-content !important;
}

.k-flex-basis-0 {
  flex-basis: 0px;
}

.\!k-flex-basis-0 {
  flex-basis: 0px !important;
}

.k-flex-basis-1px {
  flex-basis: 1px;
}

.\!k-flex-basis-1px {
  flex-basis: 1px !important;
}

.k-flex-basis-0\.5 {
  flex-basis: 0.125rem;
}

.\!k-flex-basis-0\.5 {
  flex-basis: 0.125rem !important;
}

.k-flex-basis-1 {
  flex-basis: 0.25rem;
}

.\!k-flex-basis-1 {
  flex-basis: 0.25rem !important;
}

.k-flex-basis-1\.5 {
  flex-basis: 0.375rem;
}

.\!k-flex-basis-1\.5 {
  flex-basis: 0.375rem !important;
}

.k-flex-basis-2 {
  flex-basis: 0.5rem;
}

.\!k-flex-basis-2 {
  flex-basis: 0.5rem !important;
}

.k-flex-basis-2\.5 {
  flex-basis: 0.625rem;
}

.\!k-flex-basis-2\.5 {
  flex-basis: 0.625rem !important;
}

.k-flex-basis-3 {
  flex-basis: 0.75rem;
}

.\!k-flex-basis-3 {
  flex-basis: 0.75rem !important;
}

.k-flex-basis-3\.5 {
  flex-basis: 0.875rem;
}

.\!k-flex-basis-3\.5 {
  flex-basis: 0.875rem !important;
}

.k-flex-basis-4 {
  flex-basis: 1rem;
}

.\!k-flex-basis-4 {
  flex-basis: 1rem !important;
}

.k-flex-basis-4\.5 {
  flex-basis: 1.125rem;
}

.\!k-flex-basis-4\.5 {
  flex-basis: 1.125rem !important;
}

.k-flex-basis-5 {
  flex-basis: 1.25rem;
}

.\!k-flex-basis-5 {
  flex-basis: 1.25rem !important;
}

.k-flex-basis-5\.5 {
  flex-basis: 1.375rem;
}

.\!k-flex-basis-5\.5 {
  flex-basis: 1.375rem !important;
}

.k-flex-basis-6 {
  flex-basis: 1.5rem;
}

.\!k-flex-basis-6 {
  flex-basis: 1.5rem !important;
}

.k-flex-basis-6\.5 {
  flex-basis: 1.625rem;
}

.\!k-flex-basis-6\.5 {
  flex-basis: 1.625rem !important;
}

.k-flex-basis-7 {
  flex-basis: 1.75rem;
}

.\!k-flex-basis-7 {
  flex-basis: 1.75rem !important;
}

.k-flex-basis-7\.5 {
  flex-basis: 1.875rem;
}

.\!k-flex-basis-7\.5 {
  flex-basis: 1.875rem !important;
}

.k-flex-basis-8 {
  flex-basis: 2rem;
}

.\!k-flex-basis-8 {
  flex-basis: 2rem !important;
}

.k-flex-basis-9 {
  flex-basis: 2.25rem;
}

.\!k-flex-basis-9 {
  flex-basis: 2.25rem !important;
}

.k-flex-basis-10 {
  flex-basis: 2.5rem;
}

.\!k-flex-basis-10 {
  flex-basis: 2.5rem !important;
}

.k-flex-basis-11 {
  flex-basis: 2.75rem;
}

.\!k-flex-basis-11 {
  flex-basis: 2.75rem !important;
}

.k-flex-basis-12 {
  flex-basis: 3rem;
}

.\!k-flex-basis-12 {
  flex-basis: 3rem !important;
}

.k-flex-basis-13 {
  flex-basis: 3.25rem;
}

.\!k-flex-basis-13 {
  flex-basis: 3.25rem !important;
}

.k-flex-basis-14 {
  flex-basis: 3.5rem;
}

.\!k-flex-basis-14 {
  flex-basis: 3.5rem !important;
}

.k-flex-basis-15 {
  flex-basis: 3.75rem;
}

.\!k-flex-basis-15 {
  flex-basis: 3.75rem !important;
}

.k-flex-basis-16 {
  flex-basis: 4rem;
}

.\!k-flex-basis-16 {
  flex-basis: 4rem !important;
}

.k-flex-basis-17 {
  flex-basis: 4.25rem;
}

.\!k-flex-basis-17 {
  flex-basis: 4.25rem !important;
}

.k-flex-basis-18 {
  flex-basis: 4.5rem;
}

.\!k-flex-basis-18 {
  flex-basis: 4.5rem !important;
}

.k-flex-basis-19 {
  flex-basis: 4.75rem;
}

.\!k-flex-basis-19 {
  flex-basis: 4.75rem !important;
}

.k-flex-basis-20 {
  flex-basis: 5rem;
}

.\!k-flex-basis-20 {
  flex-basis: 5rem !important;
}

.k-flex-basis-21 {
  flex-basis: 5.25rem;
}

.\!k-flex-basis-21 {
  flex-basis: 5.25rem !important;
}

.k-flex-basis-22 {
  flex-basis: 5.5rem;
}

.\!k-flex-basis-22 {
  flex-basis: 5.5rem !important;
}

.k-flex-basis-23 {
  flex-basis: 5.75rem;
}

.\!k-flex-basis-23 {
  flex-basis: 5.75rem !important;
}

.k-flex-basis-24 {
  flex-basis: 6rem;
}

.\!k-flex-basis-24 {
  flex-basis: 6rem !important;
}

.k-flex-basis-25 {
  flex-basis: 7rem;
}

.\!k-flex-basis-25 {
  flex-basis: 7rem !important;
}

.k-flex-basis-26 {
  flex-basis: 8rem;
}

.\!k-flex-basis-26 {
  flex-basis: 8rem !important;
}

.k-flex-basis-27 {
  flex-basis: 9rem;
}

.\!k-flex-basis-27 {
  flex-basis: 9rem !important;
}

.k-flex-basis-28 {
  flex-basis: 10rem;
}

.\!k-flex-basis-28 {
  flex-basis: 10rem !important;
}

.k-flex-basis-29 {
  flex-basis: 11rem;
}

.\!k-flex-basis-29 {
  flex-basis: 11rem !important;
}

.k-flex-basis-30 {
  flex-basis: 12rem;
}

.\!k-flex-basis-30 {
  flex-basis: 12rem !important;
}

.k-flex-basis-auto {
  flex-basis: auto;
}

.\!k-flex-basis-auto {
  flex-basis: auto !important;
}

.k-flex-basis-1\/2 {
  flex-basis: 50%;
}

.\!k-flex-basis-1\/2 {
  flex-basis: 50% !important;
}

.k-flex-basis-1\/3 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-1\/3 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-2\/3 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-2\/3 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-1\/4 {
  flex-basis: 25%;
}

.\!k-flex-basis-1\/4 {
  flex-basis: 25% !important;
}

.k-flex-basis-2\/4 {
  flex-basis: 50%;
}

.\!k-flex-basis-2\/4 {
  flex-basis: 50% !important;
}

.k-flex-basis-3\/4 {
  flex-basis: 75%;
}

.\!k-flex-basis-3\/4 {
  flex-basis: 75% !important;
}

.k-flex-basis-1\/5 {
  flex-basis: 20%;
}

.\!k-flex-basis-1\/5 {
  flex-basis: 20% !important;
}

.k-flex-basis-2\/5 {
  flex-basis: 40%;
}

.\!k-flex-basis-2\/5 {
  flex-basis: 40% !important;
}

.k-flex-basis-3\/5 {
  flex-basis: 60%;
}

.\!k-flex-basis-3\/5 {
  flex-basis: 60% !important;
}

.k-flex-basis-4\/5 {
  flex-basis: 80%;
}

.\!k-flex-basis-4\/5 {
  flex-basis: 80% !important;
}

.k-flex-basis-1\/6 {
  flex-basis: 16.666667%;
}

.\!k-flex-basis-1\/6 {
  flex-basis: 16.666667% !important;
}

.k-flex-basis-2\/6 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-2\/6 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-3\/6 {
  flex-basis: 50%;
}

.\!k-flex-basis-3\/6 {
  flex-basis: 50% !important;
}

.k-flex-basis-4\/6 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-4\/6 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-5\/6 {
  flex-basis: 83.333333%;
}

.\!k-flex-basis-5\/6 {
  flex-basis: 83.333333% !important;
}

.k-flex-basis-1\/12 {
  flex-basis: 8.333333%;
}

.\!k-flex-basis-1\/12 {
  flex-basis: 8.333333% !important;
}

.k-flex-basis-2\/12 {
  flex-basis: 16.666667%;
}

.\!k-flex-basis-2\/12 {
  flex-basis: 16.666667% !important;
}

.k-flex-basis-3\/12 {
  flex-basis: 25%;
}

.\!k-flex-basis-3\/12 {
  flex-basis: 25% !important;
}

.k-flex-basis-4\/12 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-4\/12 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-5\/12 {
  flex-basis: 41.666667%;
}

.\!k-flex-basis-5\/12 {
  flex-basis: 41.666667% !important;
}

.k-flex-basis-6\/12 {
  flex-basis: 50%;
}

.\!k-flex-basis-6\/12 {
  flex-basis: 50% !important;
}

.k-flex-basis-7\/12 {
  flex-basis: 58.333333%;
}

.\!k-flex-basis-7\/12 {
  flex-basis: 58.333333% !important;
}

.k-flex-basis-8\/12 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-8\/12 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-9\/12 {
  flex-basis: 75%;
}

.\!k-flex-basis-9\/12 {
  flex-basis: 75% !important;
}

.k-flex-basis-10\/12 {
  flex-basis: 83.333333%;
}

.\!k-flex-basis-10\/12 {
  flex-basis: 83.333333% !important;
}

.k-flex-basis-11\/12 {
  flex-basis: 91.666667%;
}

.\!k-flex-basis-11\/12 {
  flex-basis: 91.666667% !important;
}

.k-flex-basis-full {
  flex-basis: 100%;
}

.\!k-flex-basis-full {
  flex-basis: 100% !important;
}

.k-flex-basis-min {
  flex-basis: min-content;
}

.\!k-flex-basis-min {
  flex-basis: min-content !important;
}

.k-flex-basis-max {
  flex-basis: max-content;
}

.\!k-flex-basis-max {
  flex-basis: max-content !important;
}

.k-flex-basis-fit {
  flex-basis: fit-content;
}

.\!k-flex-basis-fit {
  flex-basis: fit-content !important;
}

.k-flex-row, .k-d-flex-row {
  flex-direction: row;
}

.\!k-flex-row, .\!k-d-flex-row, .\!k-d-flex-col {
  flex-direction: row !important;
}

.k-flex-row-reverse {
  flex-direction: row-reverse;
}

.\!k-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.k-flex-col, .k-flex-column, .k-d-flex-col {
  flex-direction: column;
}

.\!k-flex-col {
  flex-direction: column !important;
}

.k-flex-col-reverse, .k-flex-column-reverse {
  flex-direction: column-reverse;
}

.\!k-flex-col-reverse {
  flex-direction: column-reverse !important;
}

.k-grow {
  flex-grow: 1;
}

.\!k-grow {
  flex-grow: 1 !important;
}

.k-grow-0 {
  flex-grow: 0;
}

.\!k-grow-0 {
  flex-grow: 0 !important;
}

.k-flex-grow {
  flex-grow: 1;
}

.\!k-flex-grow {
  flex-grow: 1 !important;
}

.k-flex-grow-0 {
  flex-grow: 0;
}

.\!k-flex-grow-0 {
  flex-grow: 0 !important;
}

.k-shrink {
  flex-shrink: 1;
}

.\!k-shrink {
  flex-shrink: 1 !important;
}

.k-shrink-0 {
  flex-shrink: 0;
}

.\!k-shrink-0 {
  flex-shrink: 0 !important;
}

.k-flex-shrink {
  flex-shrink: 1;
}

.\!k-flex-shrink {
  flex-shrink: 1 !important;
}

.k-flex-shrink-0 {
  flex-shrink: 0;
}

.\!k-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.k-flex-wrap {
  flex-wrap: wrap;
}

.\!k-flex-wrap {
  flex-wrap: wrap !important;
}

.k-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.\!k-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.k-flex-nowrap {
  flex-wrap: nowrap;
}

.\!k-flex-nowrap {
  flex-wrap: nowrap !important;
}

.k-flex-1 {
  flex: 1 1 0%;
}

.\!k-flex-1 {
  flex: 1 1 0% !important;
}

.k-flex-auto {
  flex: 1 1 auto;
}

.\!k-flex-auto {
  flex: 1 1 auto !important;
}

.k-flex-initial {
  flex: 0 1 auto;
}

.\!k-flex-initial {
  flex: 0 1 auto !important;
}

.k-flex-none {
  flex: none;
}

.\!k-flex-none {
  flex: none !important;
}

.k-gap-0 {
  gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-0 {
  gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-1px {
  gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-1px {
  gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-0\.5 {
  gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-0\.5 {
  gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-1 {
  gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-1 {
  gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-1\.5 {
  gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-1\.5 {
  gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-2 {
  gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-2 {
  gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-2\.5 {
  gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-2\.5 {
  gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-3 {
  gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-3 {
  gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-3\.5 {
  gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-3\.5 {
  gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-4 {
  gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-4 {
  gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-4\.5 {
  gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-4\.5 {
  gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-5 {
  gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-5 {
  gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-5\.5 {
  gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-5\.5 {
  gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-6 {
  gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-6 {
  gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-6\.5 {
  gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-6\.5 {
  gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-7 {
  gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-7 {
  gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-7\.5 {
  gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-7\.5 {
  gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-8 {
  gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-8 {
  gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-9 {
  gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-9 {
  gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-10 {
  gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-10 {
  gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-11 {
  gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-11 {
  gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-12 {
  gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-12 {
  gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-13 {
  gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-13 {
  gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-14 {
  gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-14 {
  gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-15 {
  gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-15 {
  gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-16 {
  gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-16 {
  gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-17 {
  gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-17 {
  gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-18 {
  gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-18 {
  gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-19 {
  gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-19 {
  gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-20 {
  gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-20 {
  gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-21 {
  gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-21 {
  gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-22 {
  gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-22 {
  gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-23 {
  gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-23 {
  gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-24 {
  gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-24 {
  gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-25 {
  gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-25 {
  gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-26 {
  gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-26 {
  gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-27 {
  gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-27 {
  gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-28 {
  gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-28 {
  gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-29 {
  gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-29 {
  gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-30 {
  gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-30 {
  gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-xs {
  gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-xs {
  gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-sm {
  gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-sm {
  gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-md {
  gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-md {
  gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-lg {
  gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-lg {
  gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-xl {
  gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-xl {
  gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-thin {
  gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-thin {
  gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-hair {
  gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-hair {
  gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-gap-x-0 {
  column-gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-x-0 {
  column-gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-x-1px {
  column-gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-x-1px {
  column-gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-x-0\.5 {
  column-gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-x-0\.5 {
  column-gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-x-1 {
  column-gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-x-1 {
  column-gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-x-1\.5 {
  column-gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-x-1\.5 {
  column-gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-x-2 {
  column-gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-x-2 {
  column-gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-x-2\.5 {
  column-gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-x-2\.5 {
  column-gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-x-3 {
  column-gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-x-3 {
  column-gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-x-3\.5 {
  column-gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-x-3\.5 {
  column-gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-x-4 {
  column-gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-x-4 {
  column-gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-x-4\.5 {
  column-gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-x-4\.5 {
  column-gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-x-5 {
  column-gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-x-5 {
  column-gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-x-5\.5 {
  column-gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-x-5\.5 {
  column-gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-x-6 {
  column-gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-x-6 {
  column-gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-x-6\.5 {
  column-gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-x-6\.5 {
  column-gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-x-7 {
  column-gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-x-7 {
  column-gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-x-7\.5 {
  column-gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-x-7\.5 {
  column-gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-x-8 {
  column-gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-x-8 {
  column-gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-x-9 {
  column-gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-x-9 {
  column-gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-x-10 {
  column-gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-x-10 {
  column-gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-x-11 {
  column-gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-x-11 {
  column-gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-x-12 {
  column-gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-x-12 {
  column-gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-x-13 {
  column-gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-x-13 {
  column-gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-x-14 {
  column-gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-x-14 {
  column-gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-x-15 {
  column-gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-x-15 {
  column-gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-x-16 {
  column-gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-x-16 {
  column-gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-x-17 {
  column-gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-x-17 {
  column-gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-x-18 {
  column-gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-x-18 {
  column-gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-x-19 {
  column-gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-x-19 {
  column-gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-x-20 {
  column-gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-x-20 {
  column-gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-x-21 {
  column-gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-x-21 {
  column-gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-x-22 {
  column-gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-x-22 {
  column-gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-x-23 {
  column-gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-x-23 {
  column-gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-x-24 {
  column-gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-x-24 {
  column-gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-x-25 {
  column-gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-x-25 {
  column-gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-x-26 {
  column-gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-x-26 {
  column-gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-x-27 {
  column-gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-x-27 {
  column-gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-x-28 {
  column-gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-x-28 {
  column-gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-x-29 {
  column-gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-x-29 {
  column-gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-x-30 {
  column-gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-x-30 {
  column-gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-x-xs {
  column-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-x-xs {
  column-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-x-sm {
  column-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-x-sm {
  column-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-x-md {
  column-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-x-md {
  column-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-x-lg {
  column-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-x-lg {
  column-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-x-xl {
  column-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-x-xl {
  column-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-x-thin {
  column-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-x-thin {
  column-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-x-hair {
  column-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-x-hair {
  column-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-gap-y-0 {
  row-gap: var(--kendo-spacing-0, 0px);
}

.\!k-gap-y-0 {
  row-gap: var(--kendo-spacing-0, 0px) !important;
}

.k-gap-y-1px {
  row-gap: var(--kendo-spacing-1px, 1px);
}

.\!k-gap-y-1px {
  row-gap: var(--kendo-spacing-1px, 1px) !important;
}

.k-gap-y-0\.5 {
  row-gap: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-gap-y-0\.5 {
  row-gap: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-gap-y-1 {
  row-gap: var(--kendo-spacing-1, 0.25rem);
}

.\!k-gap-y-1 {
  row-gap: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-gap-y-1\.5 {
  row-gap: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-gap-y-1\.5 {
  row-gap: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-gap-y-2 {
  row-gap: var(--kendo-spacing-2, 0.5rem);
}

.\!k-gap-y-2 {
  row-gap: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-gap-y-2\.5 {
  row-gap: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-gap-y-2\.5 {
  row-gap: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-gap-y-3 {
  row-gap: var(--kendo-spacing-3, 0.75rem);
}

.\!k-gap-y-3 {
  row-gap: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-gap-y-3\.5 {
  row-gap: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-gap-y-3\.5 {
  row-gap: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-gap-y-4 {
  row-gap: var(--kendo-spacing-4, 1rem);
}

.\!k-gap-y-4 {
  row-gap: var(--kendo-spacing-4, 1rem) !important;
}

.k-gap-y-4\.5 {
  row-gap: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-gap-y-4\.5 {
  row-gap: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-gap-y-5 {
  row-gap: var(--kendo-spacing-5, 1.25rem);
}

.\!k-gap-y-5 {
  row-gap: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-gap-y-5\.5 {
  row-gap: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-gap-y-5\.5 {
  row-gap: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-gap-y-6 {
  row-gap: var(--kendo-spacing-6, 1.5rem);
}

.\!k-gap-y-6 {
  row-gap: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-gap-y-6\.5 {
  row-gap: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-gap-y-6\.5 {
  row-gap: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-gap-y-7 {
  row-gap: var(--kendo-spacing-7, 1.75rem);
}

.\!k-gap-y-7 {
  row-gap: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-gap-y-7\.5 {
  row-gap: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-gap-y-7\.5 {
  row-gap: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-gap-y-8 {
  row-gap: var(--kendo-spacing-8, 2rem);
}

.\!k-gap-y-8 {
  row-gap: var(--kendo-spacing-8, 2rem) !important;
}

.k-gap-y-9 {
  row-gap: var(--kendo-spacing-9, 2.25rem);
}

.\!k-gap-y-9 {
  row-gap: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-gap-y-10 {
  row-gap: var(--kendo-spacing-10, 2.5rem);
}

.\!k-gap-y-10 {
  row-gap: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-gap-y-11 {
  row-gap: var(--kendo-spacing-11, 2.75rem);
}

.\!k-gap-y-11 {
  row-gap: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-gap-y-12 {
  row-gap: var(--kendo-spacing-12, 3rem);
}

.\!k-gap-y-12 {
  row-gap: var(--kendo-spacing-12, 3rem) !important;
}

.k-gap-y-13 {
  row-gap: var(--kendo-spacing-13, 3.25rem);
}

.\!k-gap-y-13 {
  row-gap: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-gap-y-14 {
  row-gap: var(--kendo-spacing-14, 3.5rem);
}

.\!k-gap-y-14 {
  row-gap: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-gap-y-15 {
  row-gap: var(--kendo-spacing-15, 3.75rem);
}

.\!k-gap-y-15 {
  row-gap: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-gap-y-16 {
  row-gap: var(--kendo-spacing-16, 4rem);
}

.\!k-gap-y-16 {
  row-gap: var(--kendo-spacing-16, 4rem) !important;
}

.k-gap-y-17 {
  row-gap: var(--kendo-spacing-17, 4.25rem);
}

.\!k-gap-y-17 {
  row-gap: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-gap-y-18 {
  row-gap: var(--kendo-spacing-18, 4.5rem);
}

.\!k-gap-y-18 {
  row-gap: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-gap-y-19 {
  row-gap: var(--kendo-spacing-19, 4.75rem);
}

.\!k-gap-y-19 {
  row-gap: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-gap-y-20 {
  row-gap: var(--kendo-spacing-20, 5rem);
}

.\!k-gap-y-20 {
  row-gap: var(--kendo-spacing-20, 5rem) !important;
}

.k-gap-y-21 {
  row-gap: var(--kendo-spacing-21, 5.25rem);
}

.\!k-gap-y-21 {
  row-gap: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-gap-y-22 {
  row-gap: var(--kendo-spacing-22, 5.5rem);
}

.\!k-gap-y-22 {
  row-gap: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-gap-y-23 {
  row-gap: var(--kendo-spacing-23, 5.75rem);
}

.\!k-gap-y-23 {
  row-gap: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-gap-y-24 {
  row-gap: var(--kendo-spacing-24, 6rem);
}

.\!k-gap-y-24 {
  row-gap: var(--kendo-spacing-24, 6rem) !important;
}

.k-gap-y-25 {
  row-gap: var(--kendo-spacing-25, 7rem);
}

.\!k-gap-y-25 {
  row-gap: var(--kendo-spacing-25, 7rem) !important;
}

.k-gap-y-26 {
  row-gap: var(--kendo-spacing-26, 8rem);
}

.\!k-gap-y-26 {
  row-gap: var(--kendo-spacing-26, 8rem) !important;
}

.k-gap-y-27 {
  row-gap: var(--kendo-spacing-27, 9rem);
}

.\!k-gap-y-27 {
  row-gap: var(--kendo-spacing-27, 9rem) !important;
}

.k-gap-y-28 {
  row-gap: var(--kendo-spacing-28, 10rem);
}

.\!k-gap-y-28 {
  row-gap: var(--kendo-spacing-28, 10rem) !important;
}

.k-gap-y-29 {
  row-gap: var(--kendo-spacing-29, 11rem);
}

.\!k-gap-y-29 {
  row-gap: var(--kendo-spacing-29, 11rem) !important;
}

.k-gap-y-30 {
  row-gap: var(--kendo-spacing-30, 12rem);
}

.\!k-gap-y-30 {
  row-gap: var(--kendo-spacing-30, 12rem) !important;
}

.k-gap-y-xs {
  row-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-gap-y-xs {
  row-gap: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-gap-y-sm {
  row-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-gap-y-sm {
  row-gap: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-gap-y-md {
  row-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-gap-y-md {
  row-gap: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-gap-y-lg {
  row-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-gap-y-lg {
  row-gap: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-gap-y-xl {
  row-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-gap-y-xl {
  row-gap: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-gap-y-thin {
  row-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-gap-y-thin {
  row-gap: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-gap-y-hair {
  row-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-gap-y-hair {
  row-gap: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-grid-auto-cols-auto {
  grid-auto-columns: auto;
}

.\!k-grid-auto-cols-auto {
  grid-auto-columns: auto !important;
}

.k-grid-auto-cols-min {
  grid-auto-columns: min-content;
}

.\!k-grid-auto-cols-min {
  grid-auto-columns: min-content !important;
}

.k-grid-auto-cols-max {
  grid-auto-columns: max-content;
}

.\!k-grid-auto-cols-max {
  grid-auto-columns: max-content !important;
}

.k-grid-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.\!k-grid-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr) !important;
}

.k-grid-flow-row {
  grid-auto-flow: row;
}

.\!k-grid-flow-row {
  grid-auto-flow: row !important;
}

.k-grid-flow-col {
  grid-auto-flow: column;
}

.\!k-grid-flow-col {
  grid-auto-flow: column !important;
}

.k-grid-flow-dense {
  grid-auto-flow: dense;
}

.\!k-grid-flow-dense {
  grid-auto-flow: dense !important;
}

.k-grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.\!k-grid-flow-row-dense {
  grid-auto-flow: row dense !important;
}

.k-grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.\!k-grid-flow-col-dense {
  grid-auto-flow: column dense !important;
}

.k-grid-flow-unset {
  grid-auto-flow: unset;
}

.\!k-grid-flow-unset {
  grid-auto-flow: unset !important;
}

.k-grid-auto-rows-auto {
  grid-auto-rows: auto;
}

.\!k-grid-auto-rows-auto {
  grid-auto-rows: auto !important;
}

.k-grid-auto-rows-min {
  grid-auto-rows: min-content;
}

.\!k-grid-auto-rows-min {
  grid-auto-rows: min-content !important;
}

.k-grid-auto-rows-max {
  grid-auto-rows: max-content;
}

.\!k-grid-auto-rows-max {
  grid-auto-rows: max-content !important;
}

.k-grid-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.\!k-grid-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr) !important;
}

.k-col-start-1 {
  grid-column-start: 1;
}

.\!k-col-start-1 {
  grid-column-start: 1 !important;
}

.k-col-start-2 {
  grid-column-start: 2;
}

.\!k-col-start-2 {
  grid-column-start: 2 !important;
}

.k-col-start-3 {
  grid-column-start: 3;
}

.\!k-col-start-3 {
  grid-column-start: 3 !important;
}

.k-col-start-4 {
  grid-column-start: 4;
}

.\!k-col-start-4 {
  grid-column-start: 4 !important;
}

.k-col-start-5 {
  grid-column-start: 5;
}

.\!k-col-start-5 {
  grid-column-start: 5 !important;
}

.k-col-start-6 {
  grid-column-start: 6;
}

.\!k-col-start-6 {
  grid-column-start: 6 !important;
}

.k-col-start-7 {
  grid-column-start: 7;
}

.\!k-col-start-7 {
  grid-column-start: 7 !important;
}

.k-col-start-8 {
  grid-column-start: 8;
}

.\!k-col-start-8 {
  grid-column-start: 8 !important;
}

.k-col-start-9 {
  grid-column-start: 9;
}

.\!k-col-start-9 {
  grid-column-start: 9 !important;
}

.k-col-start-10 {
  grid-column-start: 10;
}

.\!k-col-start-10 {
  grid-column-start: 10 !important;
}

.k-col-start-11 {
  grid-column-start: 11;
}

.\!k-col-start-11 {
  grid-column-start: 11 !important;
}

.k-col-start-12 {
  grid-column-start: 12;
}

.\!k-col-start-12 {
  grid-column-start: 12 !important;
}

.k-col-start-13 {
  grid-column-start: 13;
}

.\!k-col-start-13 {
  grid-column-start: 13 !important;
}

.k-col-start--1 {
  grid-column-start: -1;
}

.\!k-col-start--1 {
  grid-column-start: -1 !important;
}

.k-col-start--2 {
  grid-column-start: -2;
}

.\!k-col-start--2 {
  grid-column-start: -2 !important;
}

.k-col-start--3 {
  grid-column-start: -3;
}

.\!k-col-start--3 {
  grid-column-start: -3 !important;
}

.k-col-start--4 {
  grid-column-start: -4;
}

.\!k-col-start--4 {
  grid-column-start: -4 !important;
}

.k-col-start--5 {
  grid-column-start: -5;
}

.\!k-col-start--5 {
  grid-column-start: -5 !important;
}

.k-col-start--6 {
  grid-column-start: -6;
}

.\!k-col-start--6 {
  grid-column-start: -6 !important;
}

.k-col-start--7 {
  grid-column-start: -7;
}

.\!k-col-start--7 {
  grid-column-start: -7 !important;
}

.k-col-start--8 {
  grid-column-start: -8;
}

.\!k-col-start--8 {
  grid-column-start: -8 !important;
}

.k-col-start--9 {
  grid-column-start: -9;
}

.\!k-col-start--9 {
  grid-column-start: -9 !important;
}

.k-col-start--10 {
  grid-column-start: -10;
}

.\!k-col-start--10 {
  grid-column-start: -10 !important;
}

.k-col-start--11 {
  grid-column-start: -11;
}

.\!k-col-start--11 {
  grid-column-start: -11 !important;
}

.k-col-start--12 {
  grid-column-start: -12;
}

.\!k-col-start--12 {
  grid-column-start: -12 !important;
}

.k-col-start--13 {
  grid-column-start: -13;
}

.\!k-col-start--13 {
  grid-column-start: -13 !important;
}

.k-col-start-auto {
  grid-column-start: auto;
}

.\!k-col-start-auto {
  grid-column-start: auto !important;
}

.k-col-end-1 {
  grid-column-end: 1;
}

.\!k-col-end-1 {
  grid-column-end: 1 !important;
}

.k-col-end-2 {
  grid-column-end: 2;
}

.\!k-col-end-2 {
  grid-column-end: 2 !important;
}

.k-col-end-3 {
  grid-column-end: 3;
}

.\!k-col-end-3 {
  grid-column-end: 3 !important;
}

.k-col-end-4 {
  grid-column-end: 4;
}

.\!k-col-end-4 {
  grid-column-end: 4 !important;
}

.k-col-end-5 {
  grid-column-end: 5;
}

.\!k-col-end-5 {
  grid-column-end: 5 !important;
}

.k-col-end-6 {
  grid-column-end: 6;
}

.\!k-col-end-6 {
  grid-column-end: 6 !important;
}

.k-col-end-7 {
  grid-column-end: 7;
}

.\!k-col-end-7 {
  grid-column-end: 7 !important;
}

.k-col-end-8 {
  grid-column-end: 8;
}

.\!k-col-end-8 {
  grid-column-end: 8 !important;
}

.k-col-end-9 {
  grid-column-end: 9;
}

.\!k-col-end-9 {
  grid-column-end: 9 !important;
}

.k-col-end-10 {
  grid-column-end: 10;
}

.\!k-col-end-10 {
  grid-column-end: 10 !important;
}

.k-col-end-11 {
  grid-column-end: 11;
}

.\!k-col-end-11 {
  grid-column-end: 11 !important;
}

.k-col-end-12 {
  grid-column-end: 12;
}

.\!k-col-end-12 {
  grid-column-end: 12 !important;
}

.k-col-end-13 {
  grid-column-end: 13;
}

.\!k-col-end-13 {
  grid-column-end: 13 !important;
}

.k-col-end--1 {
  grid-column-end: -1;
}

.\!k-col-end--1 {
  grid-column-end: -1 !important;
}

.k-col-end--2 {
  grid-column-end: -2;
}

.\!k-col-end--2 {
  grid-column-end: -2 !important;
}

.k-col-end--3 {
  grid-column-end: -3;
}

.\!k-col-end--3 {
  grid-column-end: -3 !important;
}

.k-col-end--4 {
  grid-column-end: -4;
}

.\!k-col-end--4 {
  grid-column-end: -4 !important;
}

.k-col-end--5 {
  grid-column-end: -5;
}

.\!k-col-end--5 {
  grid-column-end: -5 !important;
}

.k-col-end--6 {
  grid-column-end: -6;
}

.\!k-col-end--6 {
  grid-column-end: -6 !important;
}

.k-col-end--7 {
  grid-column-end: -7;
}

.\!k-col-end--7 {
  grid-column-end: -7 !important;
}

.k-col-end--8 {
  grid-column-end: -8;
}

.\!k-col-end--8 {
  grid-column-end: -8 !important;
}

.k-col-end--9 {
  grid-column-end: -9;
}

.\!k-col-end--9 {
  grid-column-end: -9 !important;
}

.k-col-end--10 {
  grid-column-end: -10;
}

.\!k-col-end--10 {
  grid-column-end: -10 !important;
}

.k-col-end--11 {
  grid-column-end: -11;
}

.\!k-col-end--11 {
  grid-column-end: -11 !important;
}

.k-col-end--12 {
  grid-column-end: -12;
}

.\!k-col-end--12 {
  grid-column-end: -12 !important;
}

.k-col-end--13 {
  grid-column-end: -13;
}

.\!k-col-end--13 {
  grid-column-end: -13 !important;
}

.k-col-end-auto {
  grid-column-end: auto;
}

.\!k-col-end-auto {
  grid-column-end: auto !important;
}

.k-col-span-1 {
  grid-column: span 1/span 1;
}

.\!k-col-span-1 {
  grid-column: span 1/span 1 !important;
}

.k-col-span-2 {
  grid-column: span 2/span 2;
}

.\!k-col-span-2 {
  grid-column: span 2/span 2 !important;
}

.k-col-span-3 {
  grid-column: span 3/span 3;
}

.\!k-col-span-3 {
  grid-column: span 3/span 3 !important;
}

.k-col-span-4 {
  grid-column: span 4/span 4;
}

.\!k-col-span-4 {
  grid-column: span 4/span 4 !important;
}

.k-col-span-5 {
  grid-column: span 5/span 5;
}

.\!k-col-span-5 {
  grid-column: span 5/span 5 !important;
}

.k-col-span-6 {
  grid-column: span 6/span 6;
}

.\!k-col-span-6 {
  grid-column: span 6/span 6 !important;
}

.k-col-span-7 {
  grid-column: span 7/span 7;
}

.\!k-col-span-7 {
  grid-column: span 7/span 7 !important;
}

.k-col-span-8 {
  grid-column: span 8/span 8;
}

.\!k-col-span-8 {
  grid-column: span 8/span 8 !important;
}

.k-col-span-9 {
  grid-column: span 9/span 9;
}

.\!k-col-span-9 {
  grid-column: span 9/span 9 !important;
}

.k-col-span-10 {
  grid-column: span 10/span 10;
}

.\!k-col-span-10 {
  grid-column: span 10/span 10 !important;
}

.k-col-span-11 {
  grid-column: span 11/span 11;
}

.\!k-col-span-11 {
  grid-column: span 11/span 11 !important;
}

.k-col-span-12 {
  grid-column: span 12/span 12;
}

.\!k-col-span-12 {
  grid-column: span 12/span 12 !important;
}

.k-col-span-full {
  grid-column: 1 / -1;
}

.\!k-col-span-full {
  grid-column: 1 / -1 !important;
}

.k-col-span-auto {
  grid-column: auto;
}

.\!k-col-span-auto {
  grid-column: auto !important;
}

.k-colspan-1 {
  grid-column: span 1/span 1;
}

.\!k-colspan-1 {
  grid-column: span 1/span 1 !important;
}

.k-colspan-2 {
  grid-column: span 2/span 2;
}

.\!k-colspan-2 {
  grid-column: span 2/span 2 !important;
}

.k-colspan-3 {
  grid-column: span 3/span 3;
}

.\!k-colspan-3 {
  grid-column: span 3/span 3 !important;
}

.k-colspan-4 {
  grid-column: span 4/span 4;
}

.\!k-colspan-4 {
  grid-column: span 4/span 4 !important;
}

.k-colspan-5 {
  grid-column: span 5/span 5;
}

.\!k-colspan-5 {
  grid-column: span 5/span 5 !important;
}

.k-colspan-6 {
  grid-column: span 6/span 6;
}

.\!k-colspan-6 {
  grid-column: span 6/span 6 !important;
}

.k-colspan-7 {
  grid-column: span 7/span 7;
}

.\!k-colspan-7 {
  grid-column: span 7/span 7 !important;
}

.k-colspan-8 {
  grid-column: span 8/span 8;
}

.\!k-colspan-8 {
  grid-column: span 8/span 8 !important;
}

.k-colspan-9 {
  grid-column: span 9/span 9;
}

.\!k-colspan-9 {
  grid-column: span 9/span 9 !important;
}

.k-colspan-10 {
  grid-column: span 10/span 10;
}

.\!k-colspan-10 {
  grid-column: span 10/span 10 !important;
}

.k-colspan-11 {
  grid-column: span 11/span 11;
}

.\!k-colspan-11 {
  grid-column: span 11/span 11 !important;
}

.k-colspan-12 {
  grid-column: span 12/span 12;
}

.\!k-colspan-12 {
  grid-column: span 12/span 12 !important;
}

.k-colspan-full, .k-colspan-all {
  grid-column: 1 / -1;
}

.\!k-colspan-full, .\!k-colspan-all {
  grid-column: 1 / -1 !important;
}

.k-colspan-auto {
  grid-column: auto;
}

.\!k-colspan-auto {
  grid-column: auto !important;
}

.k-row-start-1 {
  grid-row-start: 1;
}

.\!k-row-start-1 {
  grid-row-start: 1 !important;
}

.k-row-start-2 {
  grid-row-start: 2;
}

.\!k-row-start-2 {
  grid-row-start: 2 !important;
}

.k-row-start-3 {
  grid-row-start: 3;
}

.\!k-row-start-3 {
  grid-row-start: 3 !important;
}

.k-row-start-4 {
  grid-row-start: 4;
}

.\!k-row-start-4 {
  grid-row-start: 4 !important;
}

.k-row-start-5 {
  grid-row-start: 5;
}

.\!k-row-start-5 {
  grid-row-start: 5 !important;
}

.k-row-start-6 {
  grid-row-start: 6;
}

.\!k-row-start-6 {
  grid-row-start: 6 !important;
}

.k-row-start-7 {
  grid-row-start: 7;
}

.\!k-row-start-7 {
  grid-row-start: 7 !important;
}

.k-row-start-8 {
  grid-row-start: 8;
}

.\!k-row-start-8 {
  grid-row-start: 8 !important;
}

.k-row-start-9 {
  grid-row-start: 9;
}

.\!k-row-start-9 {
  grid-row-start: 9 !important;
}

.k-row-start-10 {
  grid-row-start: 10;
}

.\!k-row-start-10 {
  grid-row-start: 10 !important;
}

.k-row-start-11 {
  grid-row-start: 11;
}

.\!k-row-start-11 {
  grid-row-start: 11 !important;
}

.k-row-start-12 {
  grid-row-start: 12;
}

.\!k-row-start-12 {
  grid-row-start: 12 !important;
}

.k-row-start-13 {
  grid-row-start: 13;
}

.\!k-row-start-13 {
  grid-row-start: 13 !important;
}

.k-row-start--1 {
  grid-row-start: -1;
}

.\!k-row-start--1 {
  grid-row-start: -1 !important;
}

.k-row-start--2 {
  grid-row-start: -2;
}

.\!k-row-start--2 {
  grid-row-start: -2 !important;
}

.k-row-start--3 {
  grid-row-start: -3;
}

.\!k-row-start--3 {
  grid-row-start: -3 !important;
}

.k-row-start--4 {
  grid-row-start: -4;
}

.\!k-row-start--4 {
  grid-row-start: -4 !important;
}

.k-row-start--5 {
  grid-row-start: -5;
}

.\!k-row-start--5 {
  grid-row-start: -5 !important;
}

.k-row-start--6 {
  grid-row-start: -6;
}

.\!k-row-start--6 {
  grid-row-start: -6 !important;
}

.k-row-start--7 {
  grid-row-start: -7;
}

.\!k-row-start--7 {
  grid-row-start: -7 !important;
}

.k-row-start--8 {
  grid-row-start: -8;
}

.\!k-row-start--8 {
  grid-row-start: -8 !important;
}

.k-row-start--9 {
  grid-row-start: -9;
}

.\!k-row-start--9 {
  grid-row-start: -9 !important;
}

.k-row-start--10 {
  grid-row-start: -10;
}

.\!k-row-start--10 {
  grid-row-start: -10 !important;
}

.k-row-start--11 {
  grid-row-start: -11;
}

.\!k-row-start--11 {
  grid-row-start: -11 !important;
}

.k-row-start--12 {
  grid-row-start: -12;
}

.\!k-row-start--12 {
  grid-row-start: -12 !important;
}

.k-row-start--13 {
  grid-row-start: -13;
}

.\!k-row-start--13 {
  grid-row-start: -13 !important;
}

.k-row-start-auto {
  grid-row-start: auto;
}

.\!k-row-start-auto {
  grid-row-start: auto !important;
}

.k-row-end-1 {
  grid-row-end: 1;
}

.\!k-row-end-1 {
  grid-row-end: 1 !important;
}

.k-row-end-2 {
  grid-row-end: 2;
}

.\!k-row-end-2 {
  grid-row-end: 2 !important;
}

.k-row-end-3 {
  grid-row-end: 3;
}

.\!k-row-end-3 {
  grid-row-end: 3 !important;
}

.k-row-end-4 {
  grid-row-end: 4;
}

.\!k-row-end-4 {
  grid-row-end: 4 !important;
}

.k-row-end-5 {
  grid-row-end: 5;
}

.\!k-row-end-5 {
  grid-row-end: 5 !important;
}

.k-row-end-6 {
  grid-row-end: 6;
}

.\!k-row-end-6 {
  grid-row-end: 6 !important;
}

.k-row-end-7 {
  grid-row-end: 7;
}

.\!k-row-end-7 {
  grid-row-end: 7 !important;
}

.k-row-end-8 {
  grid-row-end: 8;
}

.\!k-row-end-8 {
  grid-row-end: 8 !important;
}

.k-row-end-9 {
  grid-row-end: 9;
}

.\!k-row-end-9 {
  grid-row-end: 9 !important;
}

.k-row-end-10 {
  grid-row-end: 10;
}

.\!k-row-end-10 {
  grid-row-end: 10 !important;
}

.k-row-end-11 {
  grid-row-end: 11;
}

.\!k-row-end-11 {
  grid-row-end: 11 !important;
}

.k-row-end-12 {
  grid-row-end: 12;
}

.\!k-row-end-12 {
  grid-row-end: 12 !important;
}

.k-row-end-13 {
  grid-row-end: 13;
}

.\!k-row-end-13 {
  grid-row-end: 13 !important;
}

.k-row-end--1 {
  grid-row-end: -1;
}

.\!k-row-end--1 {
  grid-row-end: -1 !important;
}

.k-row-end--2 {
  grid-row-end: -2;
}

.\!k-row-end--2 {
  grid-row-end: -2 !important;
}

.k-row-end--3 {
  grid-row-end: -3;
}

.\!k-row-end--3 {
  grid-row-end: -3 !important;
}

.k-row-end--4 {
  grid-row-end: -4;
}

.\!k-row-end--4 {
  grid-row-end: -4 !important;
}

.k-row-end--5 {
  grid-row-end: -5;
}

.\!k-row-end--5 {
  grid-row-end: -5 !important;
}

.k-row-end--6 {
  grid-row-end: -6;
}

.\!k-row-end--6 {
  grid-row-end: -6 !important;
}

.k-row-end--7 {
  grid-row-end: -7;
}

.\!k-row-end--7 {
  grid-row-end: -7 !important;
}

.k-row-end--8 {
  grid-row-end: -8;
}

.\!k-row-end--8 {
  grid-row-end: -8 !important;
}

.k-row-end--9 {
  grid-row-end: -9;
}

.\!k-row-end--9 {
  grid-row-end: -9 !important;
}

.k-row-end--10 {
  grid-row-end: -10;
}

.\!k-row-end--10 {
  grid-row-end: -10 !important;
}

.k-row-end--11 {
  grid-row-end: -11;
}

.\!k-row-end--11 {
  grid-row-end: -11 !important;
}

.k-row-end--12 {
  grid-row-end: -12;
}

.\!k-row-end--12 {
  grid-row-end: -12 !important;
}

.k-row-end--13 {
  grid-row-end: -13;
}

.\!k-row-end--13 {
  grid-row-end: -13 !important;
}

.k-row-end-auto {
  grid-row-end: auto;
}

.\!k-row-end-auto {
  grid-row-end: auto !important;
}

.k-row-span-1 {
  grid-row: span 1/span 1;
}

.\!k-row-span-1 {
  grid-row: span 1/span 1 !important;
}

.k-row-span-2 {
  grid-row: span 2/span 2;
}

.\!k-row-span-2 {
  grid-row: span 2/span 2 !important;
}

.k-row-span-3 {
  grid-row: span 3/span 3;
}

.\!k-row-span-3 {
  grid-row: span 3/span 3 !important;
}

.k-row-span-4 {
  grid-row: span 4/span 4;
}

.\!k-row-span-4 {
  grid-row: span 4/span 4 !important;
}

.k-row-span-5 {
  grid-row: span 5/span 5;
}

.\!k-row-span-5 {
  grid-row: span 5/span 5 !important;
}

.k-row-span-6 {
  grid-row: span 6/span 6;
}

.\!k-row-span-6 {
  grid-row: span 6/span 6 !important;
}

.k-row-span-7 {
  grid-row: span 7/span 7;
}

.\!k-row-span-7 {
  grid-row: span 7/span 7 !important;
}

.k-row-span-8 {
  grid-row: span 8/span 8;
}

.\!k-row-span-8 {
  grid-row: span 8/span 8 !important;
}

.k-row-span-9 {
  grid-row: span 9/span 9;
}

.\!k-row-span-9 {
  grid-row: span 9/span 9 !important;
}

.k-row-span-10 {
  grid-row: span 10/span 10;
}

.\!k-row-span-10 {
  grid-row: span 10/span 10 !important;
}

.k-row-span-11 {
  grid-row: span 11/span 11;
}

.\!k-row-span-11 {
  grid-row: span 11/span 11 !important;
}

.k-row-span-12 {
  grid-row: span 12/span 12;
}

.\!k-row-span-12 {
  grid-row: span 12/span 12 !important;
}

.k-row-span-full {
  grid-row: span 1/span -1;
}

.\!k-row-span-full {
  grid-row: span 1/span -1 !important;
}

.k-row-span-auto {
  grid-row: auto;
}

.\!k-row-span-auto {
  grid-row: auto !important;
}

.k-rowspan-1 {
  grid-row: span 1/span 1;
}

.\!k-rowspan-1 {
  grid-row: span 1/span 1 !important;
}

.k-rowspan-2 {
  grid-row: span 2/span 2;
}

.\!k-rowspan-2 {
  grid-row: span 2/span 2 !important;
}

.k-rowspan-3 {
  grid-row: span 3/span 3;
}

.\!k-rowspan-3 {
  grid-row: span 3/span 3 !important;
}

.k-rowspan-4 {
  grid-row: span 4/span 4;
}

.\!k-rowspan-4 {
  grid-row: span 4/span 4 !important;
}

.k-rowspan-5 {
  grid-row: span 5/span 5;
}

.\!k-rowspan-5 {
  grid-row: span 5/span 5 !important;
}

.k-rowspan-6 {
  grid-row: span 6/span 6;
}

.\!k-rowspan-6 {
  grid-row: span 6/span 6 !important;
}

.k-rowspan-7 {
  grid-row: span 7/span 7;
}

.\!k-rowspan-7 {
  grid-row: span 7/span 7 !important;
}

.k-rowspan-8 {
  grid-row: span 8/span 8;
}

.\!k-rowspan-8 {
  grid-row: span 8/span 8 !important;
}

.k-rowspan-9 {
  grid-row: span 9/span 9;
}

.\!k-rowspan-9 {
  grid-row: span 9/span 9 !important;
}

.k-rowspan-10 {
  grid-row: span 10/span 10;
}

.\!k-rowspan-10 {
  grid-row: span 10/span 10 !important;
}

.k-rowspan-11 {
  grid-row: span 11/span 11;
}

.\!k-rowspan-11 {
  grid-row: span 11/span 11 !important;
}

.k-rowspan-12 {
  grid-row: span 12/span 12;
}

.\!k-rowspan-12 {
  grid-row: span 12/span 12 !important;
}

.k-rowspan-full, .k-rowspan-all {
  grid-row: span 1/span -1;
}

.\!k-rowspan-full, .\!k-rowspan-all {
  grid-row: span 1/span -1 !important;
}

.k-rowspan-auto {
  grid-row: auto;
}

.\!k-rowspan-auto {
  grid-row: auto !important;
}

.k-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.\!k-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.\!k-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.\!k-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.\!k-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.\!k-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.\!k-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.\!k-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.\!k-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.\!k-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.\!k-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.\!k-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.\!k-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-cols-none {
  grid-template-columns: none;
}

.\!k-grid-cols-none {
  grid-template-columns: none !important;
}

.k-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.\!k-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.\!k-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.\!k-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.\!k-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.\!k-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.\!k-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.\!k-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.\!k-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

.\!k-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}

.\!k-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr));
}

.\!k-grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr));
}

.\!k-grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-rows-none {
  grid-template-rows: none;
}

.\!k-grid-rows-none {
  grid-template-rows: none !important;
}

.k-justify-content-normal {
  justify-content: normal;
}

.\!k-justify-content-normal {
  justify-content: normal !important;
}

.k-justify-content-stretch {
  justify-content: stretch;
}

.\!k-justify-content-stretch {
  justify-content: stretch !important;
}

.k-justify-content-center {
  justify-content: center;
}

.\!k-justify-content-center {
  justify-content: center !important;
}

.k-justify-content-start {
  justify-content: start;
}

.\!k-justify-content-start {
  justify-content: start !important;
}

.k-justify-content-end {
  justify-content: end;
}

.\!k-justify-content-end {
  justify-content: end !important;
}

.k-justify-content-flex-start {
  justify-content: flex-start;
}

.\!k-justify-content-flex-start {
  justify-content: flex-start !important;
}

.k-justify-content-flex-end {
  justify-content: flex-end;
}

.\!k-justify-content-flex-end {
  justify-content: flex-end !important;
}

.k-justify-content-left {
  justify-content: left;
}

.\!k-justify-content-left {
  justify-content: left !important;
}

.k-justify-content-right {
  justify-content: right;
}

.\!k-justify-content-right {
  justify-content: right !important;
}

.k-justify-content-baseline {
  justify-content: baseline;
}

.\!k-justify-content-baseline {
  justify-content: baseline !important;
}

.k-justify-content-first-baseline {
  justify-content: first baseline;
}

.\!k-justify-content-first-baseline {
  justify-content: first baseline !important;
}

.k-justify-content-last-baseline {
  justify-content: last baseline;
}

.\!k-justify-content-last-baseline {
  justify-content: last baseline !important;
}

.k-justify-content-between {
  justify-content: space-between;
}

.\!k-justify-content-between {
  justify-content: space-between !important;
}

.k-justify-content-around {
  justify-content: space-around;
}

.\!k-justify-content-around {
  justify-content: space-around !important;
}

.k-justify-content-evenly {
  justify-content: space-evenly;
}

.\!k-justify-content-evenly {
  justify-content: space-evenly !important;
}

.k-justify-content-stretch > * {
  flex: 1 0 0%;
}

.\!.k-justify-content-stretch > * {
  flex: 1 0 0% !important;
}

.k-justify-items-normal {
  justify-items: normal;
}

.\!k-justify-items-normal {
  justify-items: normal !important;
}

.k-justify-items-stretch {
  justify-items: stretch;
}

.\!k-justify-items-stretch {
  justify-items: stretch !important;
}

.k-justify-items-center {
  justify-items: center;
}

.\!k-justify-items-center {
  justify-items: center !important;
}

.k-justify-items-start {
  justify-items: start;
}

.\!k-justify-items-start {
  justify-items: start !important;
}

.k-justify-items-end {
  justify-items: end;
}

.\!k-justify-items-end {
  justify-items: end !important;
}

.k-justify-items-flex-start {
  justify-items: flex-start;
}

.\!k-justify-items-flex-start {
  justify-items: flex-start !important;
}

.k-justify-items-flex-end {
  justify-items: flex-end;
}

.\!k-justify-items-flex-end {
  justify-items: flex-end !important;
}

.k-justify-items-self-start {
  justify-items: self-start;
}

.\!k-justify-items-self-start {
  justify-items: self-start !important;
}

.k-justify-items-self-end {
  justify-items: self-end;
}

.\!k-justify-items-self-end {
  justify-items: self-end !important;
}

.k-justify-items-left {
  justify-items: left;
}

.\!k-justify-items-left {
  justify-items: left !important;
}

.k-justify-items-right {
  justify-items: right;
}

.\!k-justify-items-right {
  justify-items: right !important;
}

.k-justify-items-baseline {
  justify-items: baseline;
}

.\!k-justify-items-baseline {
  justify-items: baseline !important;
}

.k-justify-items-first-baseline {
  justify-items: first baseline;
}

.\!k-justify-items-first-baseline {
  justify-items: first baseline !important;
}

.k-justify-items-last-baseline {
  justify-items: last baseline;
}

.\!k-justify-items-last-baseline {
  justify-items: last baseline !important;
}

.k-justify-self-auto {
  justify-self: auto;
}

.\!k-justify-self-auto {
  justify-self: auto !important;
}

.k-justify-self-normal {
  justify-self: normal;
}

.\!k-justify-self-normal {
  justify-self: normal !important;
}

.k-justify-self-stretch {
  justify-self: stretch;
}

.\!k-justify-self-stretch {
  justify-self: stretch !important;
}

.k-justify-self-center {
  justify-self: center;
}

.\!k-justify-self-center {
  justify-self: center !important;
}

.k-justify-self-start {
  justify-self: start;
}

.\!k-justify-self-start {
  justify-self: start !important;
}

.k-justify-self-end {
  justify-self: end;
}

.\!k-justify-self-end {
  justify-self: end !important;
}

.k-justify-self-flex-start {
  justify-self: flex-start;
}

.\!k-justify-self-flex-start {
  justify-self: flex-start !important;
}

.k-justify-self-flex-end {
  justify-self: flex-end;
}

.\!k-justify-self-flex-end {
  justify-self: flex-end !important;
}

.k-justify-self-self-start {
  justify-self: self-start;
}

.\!k-justify-self-self-start {
  justify-self: self-start !important;
}

.k-justify-self-self-end {
  justify-self: self-end;
}

.\!k-justify-self-self-end {
  justify-self: self-end !important;
}

.k-justify-self-baseline {
  justify-self: baseline;
}

.\!k-justify-self-baseline {
  justify-self: baseline !important;
}

.k-justify-self-first-baseline {
  justify-self: first baseline;
}

.\!k-justify-self-first-baseline {
  justify-self: first baseline !important;
}

.k-justify-self-last-baseline {
  justify-self: last baseline;
}

.\!k-justify-self-last-baseline {
  justify-self: last baseline !important;
}

.k-order-1 {
  order: 1;
}

.\!k-order-1 {
  order: 1 !important;
}

.k-order-2 {
  order: 2;
}

.\!k-order-2 {
  order: 2 !important;
}

.k-order-3 {
  order: 3;
}

.\!k-order-3 {
  order: 3 !important;
}

.k-order-4 {
  order: 4;
}

.\!k-order-4 {
  order: 4 !important;
}

.k-order-5 {
  order: 5;
}

.\!k-order-5 {
  order: 5 !important;
}

.k-order-6 {
  order: 6;
}

.\!k-order-6 {
  order: 6 !important;
}

.k-order-7 {
  order: 7;
}

.\!k-order-7 {
  order: 7 !important;
}

.k-order-8 {
  order: 8;
}

.\!k-order-8 {
  order: 8 !important;
}

.k-order-9 {
  order: 9;
}

.\!k-order-9 {
  order: 9 !important;
}

.k-order-10 {
  order: 10;
}

.\!k-order-10 {
  order: 10 !important;
}

.k-order-11 {
  order: 11;
}

.\!k-order-11 {
  order: 11 !important;
}

.k-order-12 {
  order: 12;
}

.\!k-order-12 {
  order: 12 !important;
}

.k-order-first {
  order: -9999;
}

.\!k-order-first {
  order: -9999 !important;
}

.k-order-last {
  order: 9999;
}

.\!k-order-last {
  order: 9999 !important;
}

.k-order-none {
  order: 0;
}

.\!k-order-none {
  order: 0 !important;
}

.k-place-content-normal {
  place-content: normal;
}

.\!k-place-content-normal {
  place-content: normal !important;
}

.k-place-content-stretch {
  place-content: stretch;
}

.\!k-place-content-stretch {
  place-content: stretch !important;
}

.k-place-content-center {
  place-content: center;
}

.\!k-place-content-center {
  place-content: center !important;
}

.k-place-content-start {
  place-content: start;
}

.\!k-place-content-start {
  place-content: start !important;
}

.k-place-content-end {
  place-content: end;
}

.\!k-place-content-end {
  place-content: end !important;
}

.k-place-content-flex-start {
  place-content: flex-start;
}

.\!k-place-content-flex-start {
  place-content: flex-start !important;
}

.k-place-content-flex-end {
  place-content: flex-end;
}

.\!k-place-content-flex-end {
  place-content: flex-end !important;
}

.k-place-content-baseline {
  place-content: baseline;
}

.\!k-place-content-baseline {
  place-content: baseline !important;
}

.k-place-content-between {
  place-content: space-between;
}

.\!k-place-content-between {
  place-content: space-between !important;
}

.k-place-content-around {
  place-content: space-around;
}

.\!k-place-content-around {
  place-content: space-around !important;
}

.k-place-content-evenly {
  place-content: space-evenly;
}

.\!k-place-content-evenly {
  place-content: space-evenly !important;
}

.k-place-items-normal {
  place-items: normal;
}

.\!k-place-items-normal {
  place-items: normal !important;
}

.k-place-items-stretch {
  place-items: stretch;
}

.\!k-place-items-stretch {
  place-items: stretch !important;
}

.k-place-items-center {
  place-items: center;
}

.\!k-place-items-center {
  place-items: center !important;
}

.k-place-items-start {
  place-items: start;
}

.\!k-place-items-start {
  place-items: start !important;
}

.k-place-items-end {
  place-items: end;
}

.\!k-place-items-end {
  place-items: end !important;
}

.k-place-items-flex-start {
  place-items: flex-start;
}

.\!k-place-items-flex-start {
  place-items: flex-start !important;
}

.k-place-items-flex-end {
  place-items: flex-end;
}

.\!k-place-items-flex-end {
  place-items: flex-end !important;
}

.k-place-items-self-start {
  place-items: self-start;
}

.\!k-place-items-self-start {
  place-items: self-start !important;
}

.k-place-items-self-end {
  place-items: self-end;
}

.\!k-place-items-self-end {
  place-items: self-end !important;
}

.k-place-items-baseline {
  place-items: baseline;
}

.\!k-place-items-baseline {
  place-items: baseline !important;
}

.k-place-self-auto {
  place-self: auto;
}

.\!k-place-self-auto {
  place-self: auto !important;
}

.k-place-self-normal {
  place-self: normal;
}

.\!k-place-self-normal {
  place-self: normal !important;
}

.k-place-self-stretch {
  place-self: stretch;
}

.\!k-place-self-stretch {
  place-self: stretch !important;
}

.k-place-self-center {
  place-self: center;
}

.\!k-place-self-center {
  place-self: center !important;
}

.k-place-self-start {
  place-self: start;
}

.\!k-place-self-start {
  place-self: start !important;
}

.k-place-self-end {
  place-self: end;
}

.\!k-place-self-end {
  place-self: end !important;
}

.k-place-self-flex-start {
  place-self: flex-start;
}

.\!k-place-self-flex-start {
  place-self: flex-start !important;
}

.k-place-self-flex-end {
  place-self: flex-end;
}

.\!k-place-self-flex-end {
  place-self: flex-end !important;
}

.k-place-self-self-start {
  place-self: self-start;
}

.\!k-place-self-self-start {
  place-self: self-start !important;
}

.k-place-self-self-end {
  place-self: self-end;
}

.\!k-place-self-self-end {
  place-self: self-end !important;
}

.k-place-self-baseline {
  place-self: baseline;
}

.\!k-place-self-baseline {
  place-self: baseline !important;
}

.k-m-0 {
  margin: var(--kendo-spacing-0, 0px);
}

.\!k-m-0 {
  margin: var(--kendo-spacing-0, 0px) !important;
}

.k-m-1px {
  margin: var(--kendo-spacing-1px, 1px);
}

.\!k-m-1px {
  margin: var(--kendo-spacing-1px, 1px) !important;
}

.k-m-0\.5 {
  margin: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-m-0\.5 {
  margin: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-m-1 {
  margin: var(--kendo-spacing-1, 0.25rem);
}

.\!k-m-1 {
  margin: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-m-1\.5 {
  margin: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-m-1\.5 {
  margin: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-m-2 {
  margin: var(--kendo-spacing-2, 0.5rem);
}

.\!k-m-2 {
  margin: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-m-2\.5 {
  margin: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-m-2\.5 {
  margin: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-m-3 {
  margin: var(--kendo-spacing-3, 0.75rem);
}

.\!k-m-3 {
  margin: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-m-3\.5 {
  margin: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-m-3\.5 {
  margin: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-m-4 {
  margin: var(--kendo-spacing-4, 1rem);
}

.\!k-m-4 {
  margin: var(--kendo-spacing-4, 1rem) !important;
}

.k-m-4\.5 {
  margin: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-m-4\.5 {
  margin: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-m-5 {
  margin: var(--kendo-spacing-5, 1.25rem);
}

.\!k-m-5 {
  margin: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-m-5\.5 {
  margin: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-m-5\.5 {
  margin: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-m-6 {
  margin: var(--kendo-spacing-6, 1.5rem);
}

.\!k-m-6 {
  margin: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-m-6\.5 {
  margin: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-m-6\.5 {
  margin: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-m-7 {
  margin: var(--kendo-spacing-7, 1.75rem);
}

.\!k-m-7 {
  margin: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-m-7\.5 {
  margin: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-m-7\.5 {
  margin: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-m-8 {
  margin: var(--kendo-spacing-8, 2rem);
}

.\!k-m-8 {
  margin: var(--kendo-spacing-8, 2rem) !important;
}

.k-m-9 {
  margin: var(--kendo-spacing-9, 2.25rem);
}

.\!k-m-9 {
  margin: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-m-10 {
  margin: var(--kendo-spacing-10, 2.5rem);
}

.\!k-m-10 {
  margin: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-m-11 {
  margin: var(--kendo-spacing-11, 2.75rem);
}

.\!k-m-11 {
  margin: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-m-12 {
  margin: var(--kendo-spacing-12, 3rem);
}

.\!k-m-12 {
  margin: var(--kendo-spacing-12, 3rem) !important;
}

.k-m-13 {
  margin: var(--kendo-spacing-13, 3.25rem);
}

.\!k-m-13 {
  margin: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-m-14 {
  margin: var(--kendo-spacing-14, 3.5rem);
}

.\!k-m-14 {
  margin: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-m-15 {
  margin: var(--kendo-spacing-15, 3.75rem);
}

.\!k-m-15 {
  margin: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-m-16 {
  margin: var(--kendo-spacing-16, 4rem);
}

.\!k-m-16 {
  margin: var(--kendo-spacing-16, 4rem) !important;
}

.k-m-17 {
  margin: var(--kendo-spacing-17, 4.25rem);
}

.\!k-m-17 {
  margin: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-m-18 {
  margin: var(--kendo-spacing-18, 4.5rem);
}

.\!k-m-18 {
  margin: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-m-19 {
  margin: var(--kendo-spacing-19, 4.75rem);
}

.\!k-m-19 {
  margin: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-m-20 {
  margin: var(--kendo-spacing-20, 5rem);
}

.\!k-m-20 {
  margin: var(--kendo-spacing-20, 5rem) !important;
}

.k-m-21 {
  margin: var(--kendo-spacing-21, 5.25rem);
}

.\!k-m-21 {
  margin: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-m-22 {
  margin: var(--kendo-spacing-22, 5.5rem);
}

.\!k-m-22 {
  margin: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-m-23 {
  margin: var(--kendo-spacing-23, 5.75rem);
}

.\!k-m-23 {
  margin: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-m-24 {
  margin: var(--kendo-spacing-24, 6rem);
}

.\!k-m-24 {
  margin: var(--kendo-spacing-24, 6rem) !important;
}

.k-m-25 {
  margin: var(--kendo-spacing-25, 7rem);
}

.\!k-m-25 {
  margin: var(--kendo-spacing-25, 7rem) !important;
}

.k-m-26 {
  margin: var(--kendo-spacing-26, 8rem);
}

.\!k-m-26 {
  margin: var(--kendo-spacing-26, 8rem) !important;
}

.k-m-27 {
  margin: var(--kendo-spacing-27, 9rem);
}

.\!k-m-27 {
  margin: var(--kendo-spacing-27, 9rem) !important;
}

.k-m-28 {
  margin: var(--kendo-spacing-28, 10rem);
}

.\!k-m-28 {
  margin: var(--kendo-spacing-28, 10rem) !important;
}

.k-m-29 {
  margin: var(--kendo-spacing-29, 11rem);
}

.\!k-m-29 {
  margin: var(--kendo-spacing-29, 11rem) !important;
}

.k-m-30 {
  margin: var(--kendo-spacing-30, 12rem);
}

.\!k-m-30 {
  margin: var(--kendo-spacing-30, 12rem) !important;
}

.k-m--0 {
  margin: var(--kendo-spacing--0, 0px);
}

.\!k-m--0 {
  margin: var(--kendo-spacing--0, 0px) !important;
}

.k-m--1px {
  margin: var(--kendo-spacing--1px, -1px);
}

.\!k-m--1px {
  margin: var(--kendo-spacing--1px, -1px) !important;
}

.k-m--0\.5 {
  margin: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-m--0\.5 {
  margin: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-m--1 {
  margin: var(--kendo-spacing--1, -0.25rem);
}

.\!k-m--1 {
  margin: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-m--1\.5 {
  margin: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-m--1\.5 {
  margin: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-m--2 {
  margin: var(--kendo-spacing--2, -0.5rem);
}

.\!k-m--2 {
  margin: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-m--2\.5 {
  margin: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-m--2\.5 {
  margin: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-m--3 {
  margin: var(--kendo-spacing--3, -0.75rem);
}

.\!k-m--3 {
  margin: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-m--3\.5 {
  margin: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-m--3\.5 {
  margin: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-m--4 {
  margin: var(--kendo-spacing--4, -1rem);
}

.\!k-m--4 {
  margin: var(--kendo-spacing--4, -1rem) !important;
}

.k-m--4\.5 {
  margin: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-m--4\.5 {
  margin: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-m--5 {
  margin: var(--kendo-spacing--5, -1.25rem);
}

.\!k-m--5 {
  margin: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-m--5\.5 {
  margin: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-m--5\.5 {
  margin: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-m--6 {
  margin: var(--kendo-spacing--6, -1.5rem);
}

.\!k-m--6 {
  margin: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-m--6\.5 {
  margin: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-m--6\.5 {
  margin: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-m--7 {
  margin: var(--kendo-spacing--7, -1.75rem);
}

.\!k-m--7 {
  margin: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-m--7\.5 {
  margin: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-m--7\.5 {
  margin: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-m--8 {
  margin: var(--kendo-spacing--8, -2rem);
}

.\!k-m--8 {
  margin: var(--kendo-spacing--8, -2rem) !important;
}

.k-m--9 {
  margin: var(--kendo-spacing--9, -2.25rem);
}

.\!k-m--9 {
  margin: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-m--10 {
  margin: var(--kendo-spacing--10, -2.5rem);
}

.\!k-m--10 {
  margin: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-m--11 {
  margin: var(--kendo-spacing--11, -2.75rem);
}

.\!k-m--11 {
  margin: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-m--12 {
  margin: var(--kendo-spacing--12, -3rem);
}

.\!k-m--12 {
  margin: var(--kendo-spacing--12, -3rem) !important;
}

.k-m--13 {
  margin: var(--kendo-spacing--13, -3.25rem);
}

.\!k-m--13 {
  margin: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-m--14 {
  margin: var(--kendo-spacing--14, -3.5rem);
}

.\!k-m--14 {
  margin: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-m--15 {
  margin: var(--kendo-spacing--15, -3.75rem);
}

.\!k-m--15 {
  margin: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-m--16 {
  margin: var(--kendo-spacing--16, -4rem);
}

.\!k-m--16 {
  margin: var(--kendo-spacing--16, -4rem) !important;
}

.k-m--17 {
  margin: var(--kendo-spacing--17, -4.25rem);
}

.\!k-m--17 {
  margin: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-m--18 {
  margin: var(--kendo-spacing--18, -4.5rem);
}

.\!k-m--18 {
  margin: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-m--19 {
  margin: var(--kendo-spacing--19, -4.75rem);
}

.\!k-m--19 {
  margin: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-m--20 {
  margin: var(--kendo-spacing--20, -5rem);
}

.\!k-m--20 {
  margin: var(--kendo-spacing--20, -5rem) !important;
}

.k-m--21 {
  margin: var(--kendo-spacing--21, -5.25rem);
}

.\!k-m--21 {
  margin: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-m--22 {
  margin: var(--kendo-spacing--22, -5.5rem);
}

.\!k-m--22 {
  margin: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-m--23 {
  margin: var(--kendo-spacing--23, -5.75rem);
}

.\!k-m--23 {
  margin: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-m--24 {
  margin: var(--kendo-spacing--24, -6rem);
}

.\!k-m--24 {
  margin: var(--kendo-spacing--24, -6rem) !important;
}

.k-m--25 {
  margin: var(--kendo-spacing--25, -7rem);
}

.\!k-m--25 {
  margin: var(--kendo-spacing--25, -7rem) !important;
}

.k-m--26 {
  margin: var(--kendo-spacing--26, -8rem);
}

.\!k-m--26 {
  margin: var(--kendo-spacing--26, -8rem) !important;
}

.k-m--27 {
  margin: var(--kendo-spacing--27, -9rem);
}

.\!k-m--27 {
  margin: var(--kendo-spacing--27, -9rem) !important;
}

.k-m--28 {
  margin: var(--kendo-spacing--28, -10rem);
}

.\!k-m--28 {
  margin: var(--kendo-spacing--28, -10rem) !important;
}

.k-m--29 {
  margin: var(--kendo-spacing--29, -11rem);
}

.\!k-m--29 {
  margin: var(--kendo-spacing--29, -11rem) !important;
}

.k-m--30 {
  margin: var(--kendo-spacing--30, -12rem);
}

.\!k-m--30 {
  margin: var(--kendo-spacing--30, -12rem) !important;
}

.k-m-xs {
  margin: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-m-xs {
  margin: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-m-sm {
  margin: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-m-sm {
  margin: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-m-md {
  margin: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-m-md {
  margin: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-m-lg {
  margin: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-m-lg {
  margin: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-m-xl {
  margin: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-m-xl {
  margin: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-m-thin {
  margin: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-m-thin {
  margin: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-m-hair {
  margin: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-m-hair {
  margin: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-m-auto {
  margin: var(--kendo-spacing-auto, auto);
}

.\!k-m-auto {
  margin: var(--kendo-spacing-auto, auto) !important;
}

.k-mt-0 {
  margin-top: var(--kendo-spacing-0, 0px);
}

.\!k-mt-0 {
  margin-top: var(--kendo-spacing-0, 0px) !important;
}

.k-mt-1px {
  margin-top: var(--kendo-spacing-1px, 1px);
}

.\!k-mt-1px {
  margin-top: var(--kendo-spacing-1px, 1px) !important;
}

.k-mt-0\.5 {
  margin-top: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mt-0\.5 {
  margin-top: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mt-1 {
  margin-top: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mt-1 {
  margin-top: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mt-1\.5 {
  margin-top: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mt-1\.5 {
  margin-top: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mt-2 {
  margin-top: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mt-2 {
  margin-top: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mt-2\.5 {
  margin-top: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mt-2\.5 {
  margin-top: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mt-3 {
  margin-top: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mt-3 {
  margin-top: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mt-3\.5 {
  margin-top: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mt-3\.5 {
  margin-top: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mt-4 {
  margin-top: var(--kendo-spacing-4, 1rem);
}

.\!k-mt-4 {
  margin-top: var(--kendo-spacing-4, 1rem) !important;
}

.k-mt-4\.5 {
  margin-top: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mt-4\.5 {
  margin-top: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mt-5 {
  margin-top: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mt-5 {
  margin-top: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mt-5\.5 {
  margin-top: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mt-5\.5 {
  margin-top: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mt-6 {
  margin-top: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mt-6 {
  margin-top: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mt-6\.5 {
  margin-top: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mt-6\.5 {
  margin-top: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mt-7 {
  margin-top: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mt-7 {
  margin-top: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mt-7\.5 {
  margin-top: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mt-7\.5 {
  margin-top: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mt-8 {
  margin-top: var(--kendo-spacing-8, 2rem);
}

.\!k-mt-8 {
  margin-top: var(--kendo-spacing-8, 2rem) !important;
}

.k-mt-9 {
  margin-top: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mt-9 {
  margin-top: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mt-10 {
  margin-top: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mt-10 {
  margin-top: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mt-11 {
  margin-top: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mt-11 {
  margin-top: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mt-12 {
  margin-top: var(--kendo-spacing-12, 3rem);
}

.\!k-mt-12 {
  margin-top: var(--kendo-spacing-12, 3rem) !important;
}

.k-mt-13 {
  margin-top: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mt-13 {
  margin-top: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mt-14 {
  margin-top: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mt-14 {
  margin-top: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mt-15 {
  margin-top: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mt-15 {
  margin-top: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mt-16 {
  margin-top: var(--kendo-spacing-16, 4rem);
}

.\!k-mt-16 {
  margin-top: var(--kendo-spacing-16, 4rem) !important;
}

.k-mt-17 {
  margin-top: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mt-17 {
  margin-top: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mt-18 {
  margin-top: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mt-18 {
  margin-top: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mt-19 {
  margin-top: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mt-19 {
  margin-top: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mt-20 {
  margin-top: var(--kendo-spacing-20, 5rem);
}

.\!k-mt-20 {
  margin-top: var(--kendo-spacing-20, 5rem) !important;
}

.k-mt-21 {
  margin-top: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mt-21 {
  margin-top: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mt-22 {
  margin-top: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mt-22 {
  margin-top: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mt-23 {
  margin-top: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mt-23 {
  margin-top: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mt-24 {
  margin-top: var(--kendo-spacing-24, 6rem);
}

.\!k-mt-24 {
  margin-top: var(--kendo-spacing-24, 6rem) !important;
}

.k-mt-25 {
  margin-top: var(--kendo-spacing-25, 7rem);
}

.\!k-mt-25 {
  margin-top: var(--kendo-spacing-25, 7rem) !important;
}

.k-mt-26 {
  margin-top: var(--kendo-spacing-26, 8rem);
}

.\!k-mt-26 {
  margin-top: var(--kendo-spacing-26, 8rem) !important;
}

.k-mt-27 {
  margin-top: var(--kendo-spacing-27, 9rem);
}

.\!k-mt-27 {
  margin-top: var(--kendo-spacing-27, 9rem) !important;
}

.k-mt-28 {
  margin-top: var(--kendo-spacing-28, 10rem);
}

.\!k-mt-28 {
  margin-top: var(--kendo-spacing-28, 10rem) !important;
}

.k-mt-29 {
  margin-top: var(--kendo-spacing-29, 11rem);
}

.\!k-mt-29 {
  margin-top: var(--kendo-spacing-29, 11rem) !important;
}

.k-mt-30 {
  margin-top: var(--kendo-spacing-30, 12rem);
}

.\!k-mt-30 {
  margin-top: var(--kendo-spacing-30, 12rem) !important;
}

.k-mt--0 {
  margin-top: var(--kendo-spacing--0, 0px);
}

.\!k-mt--0 {
  margin-top: var(--kendo-spacing--0, 0px) !important;
}

.k-mt--1px {
  margin-top: var(--kendo-spacing--1px, -1px);
}

.\!k-mt--1px {
  margin-top: var(--kendo-spacing--1px, -1px) !important;
}

.k-mt--0\.5 {
  margin-top: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mt--0\.5 {
  margin-top: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mt--1 {
  margin-top: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mt--1 {
  margin-top: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mt--1\.5 {
  margin-top: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mt--1\.5 {
  margin-top: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mt--2 {
  margin-top: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mt--2 {
  margin-top: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mt--2\.5 {
  margin-top: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mt--2\.5 {
  margin-top: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mt--3 {
  margin-top: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mt--3 {
  margin-top: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mt--3\.5 {
  margin-top: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mt--3\.5 {
  margin-top: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mt--4 {
  margin-top: var(--kendo-spacing--4, -1rem);
}

.\!k-mt--4 {
  margin-top: var(--kendo-spacing--4, -1rem) !important;
}

.k-mt--4\.5 {
  margin-top: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mt--4\.5 {
  margin-top: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mt--5 {
  margin-top: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mt--5 {
  margin-top: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mt--5\.5 {
  margin-top: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mt--5\.5 {
  margin-top: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mt--6 {
  margin-top: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mt--6 {
  margin-top: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mt--6\.5 {
  margin-top: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mt--6\.5 {
  margin-top: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mt--7 {
  margin-top: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mt--7 {
  margin-top: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mt--7\.5 {
  margin-top: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mt--7\.5 {
  margin-top: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mt--8 {
  margin-top: var(--kendo-spacing--8, -2rem);
}

.\!k-mt--8 {
  margin-top: var(--kendo-spacing--8, -2rem) !important;
}

.k-mt--9 {
  margin-top: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mt--9 {
  margin-top: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mt--10 {
  margin-top: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mt--10 {
  margin-top: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mt--11 {
  margin-top: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mt--11 {
  margin-top: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mt--12 {
  margin-top: var(--kendo-spacing--12, -3rem);
}

.\!k-mt--12 {
  margin-top: var(--kendo-spacing--12, -3rem) !important;
}

.k-mt--13 {
  margin-top: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mt--13 {
  margin-top: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mt--14 {
  margin-top: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mt--14 {
  margin-top: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mt--15 {
  margin-top: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mt--15 {
  margin-top: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mt--16 {
  margin-top: var(--kendo-spacing--16, -4rem);
}

.\!k-mt--16 {
  margin-top: var(--kendo-spacing--16, -4rem) !important;
}

.k-mt--17 {
  margin-top: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mt--17 {
  margin-top: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mt--18 {
  margin-top: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mt--18 {
  margin-top: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mt--19 {
  margin-top: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mt--19 {
  margin-top: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mt--20 {
  margin-top: var(--kendo-spacing--20, -5rem);
}

.\!k-mt--20 {
  margin-top: var(--kendo-spacing--20, -5rem) !important;
}

.k-mt--21 {
  margin-top: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mt--21 {
  margin-top: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mt--22 {
  margin-top: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mt--22 {
  margin-top: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mt--23 {
  margin-top: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mt--23 {
  margin-top: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mt--24 {
  margin-top: var(--kendo-spacing--24, -6rem);
}

.\!k-mt--24 {
  margin-top: var(--kendo-spacing--24, -6rem) !important;
}

.k-mt--25 {
  margin-top: var(--kendo-spacing--25, -7rem);
}

.\!k-mt--25 {
  margin-top: var(--kendo-spacing--25, -7rem) !important;
}

.k-mt--26 {
  margin-top: var(--kendo-spacing--26, -8rem);
}

.\!k-mt--26 {
  margin-top: var(--kendo-spacing--26, -8rem) !important;
}

.k-mt--27 {
  margin-top: var(--kendo-spacing--27, -9rem);
}

.\!k-mt--27 {
  margin-top: var(--kendo-spacing--27, -9rem) !important;
}

.k-mt--28 {
  margin-top: var(--kendo-spacing--28, -10rem);
}

.\!k-mt--28 {
  margin-top: var(--kendo-spacing--28, -10rem) !important;
}

.k-mt--29 {
  margin-top: var(--kendo-spacing--29, -11rem);
}

.\!k-mt--29 {
  margin-top: var(--kendo-spacing--29, -11rem) !important;
}

.k-mt--30 {
  margin-top: var(--kendo-spacing--30, -12rem);
}

.\!k-mt--30 {
  margin-top: var(--kendo-spacing--30, -12rem) !important;
}

.k-mt-xs {
  margin-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mt-xs {
  margin-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mt-sm {
  margin-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mt-sm {
  margin-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mt-md {
  margin-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mt-md {
  margin-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mt-lg {
  margin-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mt-lg {
  margin-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mt-xl {
  margin-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mt-xl {
  margin-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mt-thin {
  margin-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mt-thin {
  margin-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mt-hair {
  margin-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mt-hair {
  margin-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mt-auto {
  margin-top: var(--kendo-spacing-auto, auto);
}

.\!k-mt-auto {
  margin-top: var(--kendo-spacing-auto, auto) !important;
}

.k-mr-0 {
  margin-right: var(--kendo-spacing-0, 0px);
}

.\!k-mr-0 {
  margin-right: var(--kendo-spacing-0, 0px) !important;
}

.k-mr-1px {
  margin-right: var(--kendo-spacing-1px, 1px);
}

.\!k-mr-1px {
  margin-right: var(--kendo-spacing-1px, 1px) !important;
}

.k-mr-0\.5 {
  margin-right: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mr-0\.5 {
  margin-right: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mr-1 {
  margin-right: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mr-1 {
  margin-right: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mr-1\.5 {
  margin-right: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mr-1\.5 {
  margin-right: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mr-2 {
  margin-right: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mr-2 {
  margin-right: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mr-2\.5 {
  margin-right: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mr-2\.5 {
  margin-right: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mr-3 {
  margin-right: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mr-3 {
  margin-right: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mr-3\.5 {
  margin-right: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mr-3\.5 {
  margin-right: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mr-4 {
  margin-right: var(--kendo-spacing-4, 1rem);
}

.\!k-mr-4 {
  margin-right: var(--kendo-spacing-4, 1rem) !important;
}

.k-mr-4\.5 {
  margin-right: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mr-4\.5 {
  margin-right: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mr-5 {
  margin-right: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mr-5 {
  margin-right: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mr-5\.5 {
  margin-right: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mr-5\.5 {
  margin-right: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mr-6 {
  margin-right: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mr-6 {
  margin-right: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mr-6\.5 {
  margin-right: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mr-6\.5 {
  margin-right: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mr-7 {
  margin-right: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mr-7 {
  margin-right: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mr-7\.5 {
  margin-right: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mr-7\.5 {
  margin-right: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mr-8 {
  margin-right: var(--kendo-spacing-8, 2rem);
}

.\!k-mr-8 {
  margin-right: var(--kendo-spacing-8, 2rem) !important;
}

.k-mr-9 {
  margin-right: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mr-9 {
  margin-right: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mr-10 {
  margin-right: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mr-10 {
  margin-right: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mr-11 {
  margin-right: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mr-11 {
  margin-right: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mr-12 {
  margin-right: var(--kendo-spacing-12, 3rem);
}

.\!k-mr-12 {
  margin-right: var(--kendo-spacing-12, 3rem) !important;
}

.k-mr-13 {
  margin-right: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mr-13 {
  margin-right: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mr-14 {
  margin-right: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mr-14 {
  margin-right: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mr-15 {
  margin-right: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mr-15 {
  margin-right: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mr-16 {
  margin-right: var(--kendo-spacing-16, 4rem);
}

.\!k-mr-16 {
  margin-right: var(--kendo-spacing-16, 4rem) !important;
}

.k-mr-17 {
  margin-right: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mr-17 {
  margin-right: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mr-18 {
  margin-right: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mr-18 {
  margin-right: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mr-19 {
  margin-right: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mr-19 {
  margin-right: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mr-20 {
  margin-right: var(--kendo-spacing-20, 5rem);
}

.\!k-mr-20 {
  margin-right: var(--kendo-spacing-20, 5rem) !important;
}

.k-mr-21 {
  margin-right: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mr-21 {
  margin-right: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mr-22 {
  margin-right: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mr-22 {
  margin-right: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mr-23 {
  margin-right: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mr-23 {
  margin-right: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mr-24 {
  margin-right: var(--kendo-spacing-24, 6rem);
}

.\!k-mr-24 {
  margin-right: var(--kendo-spacing-24, 6rem) !important;
}

.k-mr-25 {
  margin-right: var(--kendo-spacing-25, 7rem);
}

.\!k-mr-25 {
  margin-right: var(--kendo-spacing-25, 7rem) !important;
}

.k-mr-26 {
  margin-right: var(--kendo-spacing-26, 8rem);
}

.\!k-mr-26 {
  margin-right: var(--kendo-spacing-26, 8rem) !important;
}

.k-mr-27 {
  margin-right: var(--kendo-spacing-27, 9rem);
}

.\!k-mr-27 {
  margin-right: var(--kendo-spacing-27, 9rem) !important;
}

.k-mr-28 {
  margin-right: var(--kendo-spacing-28, 10rem);
}

.\!k-mr-28 {
  margin-right: var(--kendo-spacing-28, 10rem) !important;
}

.k-mr-29 {
  margin-right: var(--kendo-spacing-29, 11rem);
}

.\!k-mr-29 {
  margin-right: var(--kendo-spacing-29, 11rem) !important;
}

.k-mr-30 {
  margin-right: var(--kendo-spacing-30, 12rem);
}

.\!k-mr-30 {
  margin-right: var(--kendo-spacing-30, 12rem) !important;
}

.k-mr--0 {
  margin-right: var(--kendo-spacing--0, 0px);
}

.\!k-mr--0 {
  margin-right: var(--kendo-spacing--0, 0px) !important;
}

.k-mr--1px {
  margin-right: var(--kendo-spacing--1px, -1px);
}

.\!k-mr--1px {
  margin-right: var(--kendo-spacing--1px, -1px) !important;
}

.k-mr--0\.5 {
  margin-right: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mr--0\.5 {
  margin-right: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mr--1 {
  margin-right: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mr--1 {
  margin-right: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mr--1\.5 {
  margin-right: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mr--1\.5 {
  margin-right: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mr--2 {
  margin-right: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mr--2 {
  margin-right: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mr--2\.5 {
  margin-right: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mr--2\.5 {
  margin-right: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mr--3 {
  margin-right: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mr--3 {
  margin-right: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mr--3\.5 {
  margin-right: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mr--3\.5 {
  margin-right: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mr--4 {
  margin-right: var(--kendo-spacing--4, -1rem);
}

.\!k-mr--4 {
  margin-right: var(--kendo-spacing--4, -1rem) !important;
}

.k-mr--4\.5 {
  margin-right: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mr--4\.5 {
  margin-right: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mr--5 {
  margin-right: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mr--5 {
  margin-right: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mr--5\.5 {
  margin-right: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mr--5\.5 {
  margin-right: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mr--6 {
  margin-right: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mr--6 {
  margin-right: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mr--6\.5 {
  margin-right: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mr--6\.5 {
  margin-right: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mr--7 {
  margin-right: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mr--7 {
  margin-right: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mr--7\.5 {
  margin-right: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mr--7\.5 {
  margin-right: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mr--8 {
  margin-right: var(--kendo-spacing--8, -2rem);
}

.\!k-mr--8 {
  margin-right: var(--kendo-spacing--8, -2rem) !important;
}

.k-mr--9 {
  margin-right: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mr--9 {
  margin-right: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mr--10 {
  margin-right: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mr--10 {
  margin-right: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mr--11 {
  margin-right: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mr--11 {
  margin-right: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mr--12 {
  margin-right: var(--kendo-spacing--12, -3rem);
}

.\!k-mr--12 {
  margin-right: var(--kendo-spacing--12, -3rem) !important;
}

.k-mr--13 {
  margin-right: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mr--13 {
  margin-right: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mr--14 {
  margin-right: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mr--14 {
  margin-right: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mr--15 {
  margin-right: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mr--15 {
  margin-right: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mr--16 {
  margin-right: var(--kendo-spacing--16, -4rem);
}

.\!k-mr--16 {
  margin-right: var(--kendo-spacing--16, -4rem) !important;
}

.k-mr--17 {
  margin-right: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mr--17 {
  margin-right: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mr--18 {
  margin-right: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mr--18 {
  margin-right: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mr--19 {
  margin-right: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mr--19 {
  margin-right: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mr--20 {
  margin-right: var(--kendo-spacing--20, -5rem);
}

.\!k-mr--20 {
  margin-right: var(--kendo-spacing--20, -5rem) !important;
}

.k-mr--21 {
  margin-right: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mr--21 {
  margin-right: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mr--22 {
  margin-right: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mr--22 {
  margin-right: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mr--23 {
  margin-right: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mr--23 {
  margin-right: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mr--24 {
  margin-right: var(--kendo-spacing--24, -6rem);
}

.\!k-mr--24 {
  margin-right: var(--kendo-spacing--24, -6rem) !important;
}

.k-mr--25 {
  margin-right: var(--kendo-spacing--25, -7rem);
}

.\!k-mr--25 {
  margin-right: var(--kendo-spacing--25, -7rem) !important;
}

.k-mr--26 {
  margin-right: var(--kendo-spacing--26, -8rem);
}

.\!k-mr--26 {
  margin-right: var(--kendo-spacing--26, -8rem) !important;
}

.k-mr--27 {
  margin-right: var(--kendo-spacing--27, -9rem);
}

.\!k-mr--27 {
  margin-right: var(--kendo-spacing--27, -9rem) !important;
}

.k-mr--28 {
  margin-right: var(--kendo-spacing--28, -10rem);
}

.\!k-mr--28 {
  margin-right: var(--kendo-spacing--28, -10rem) !important;
}

.k-mr--29 {
  margin-right: var(--kendo-spacing--29, -11rem);
}

.\!k-mr--29 {
  margin-right: var(--kendo-spacing--29, -11rem) !important;
}

.k-mr--30 {
  margin-right: var(--kendo-spacing--30, -12rem);
}

.\!k-mr--30 {
  margin-right: var(--kendo-spacing--30, -12rem) !important;
}

.k-mr-xs {
  margin-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mr-xs {
  margin-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mr-sm {
  margin-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mr-sm {
  margin-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mr-md {
  margin-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mr-md {
  margin-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mr-lg {
  margin-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mr-lg {
  margin-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mr-xl {
  margin-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mr-xl {
  margin-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mr-thin {
  margin-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mr-thin {
  margin-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mr-hair {
  margin-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mr-hair {
  margin-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mr-auto {
  margin-right: var(--kendo-spacing-auto, auto);
}

.\!k-mr-auto {
  margin-right: var(--kendo-spacing-auto, auto) !important;
}

.k-mb-0 {
  margin-bottom: var(--kendo-spacing-0, 0px);
}

.\!k-mb-0 {
  margin-bottom: var(--kendo-spacing-0, 0px) !important;
}

.k-mb-1px {
  margin-bottom: var(--kendo-spacing-1px, 1px);
}

.\!k-mb-1px {
  margin-bottom: var(--kendo-spacing-1px, 1px) !important;
}

.k-mb-0\.5 {
  margin-bottom: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mb-0\.5 {
  margin-bottom: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mb-1 {
  margin-bottom: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mb-1 {
  margin-bottom: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mb-1\.5 {
  margin-bottom: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mb-1\.5 {
  margin-bottom: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mb-2 {
  margin-bottom: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mb-2 {
  margin-bottom: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mb-2\.5 {
  margin-bottom: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mb-2\.5 {
  margin-bottom: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mb-3 {
  margin-bottom: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mb-3 {
  margin-bottom: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mb-3\.5 {
  margin-bottom: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mb-3\.5 {
  margin-bottom: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mb-4 {
  margin-bottom: var(--kendo-spacing-4, 1rem);
}

.\!k-mb-4 {
  margin-bottom: var(--kendo-spacing-4, 1rem) !important;
}

.k-mb-4\.5 {
  margin-bottom: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mb-4\.5 {
  margin-bottom: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mb-5 {
  margin-bottom: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mb-5 {
  margin-bottom: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mb-5\.5 {
  margin-bottom: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mb-5\.5 {
  margin-bottom: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mb-6 {
  margin-bottom: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mb-6 {
  margin-bottom: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mb-6\.5 {
  margin-bottom: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mb-6\.5 {
  margin-bottom: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mb-7 {
  margin-bottom: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mb-7 {
  margin-bottom: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mb-7\.5 {
  margin-bottom: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mb-7\.5 {
  margin-bottom: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mb-8 {
  margin-bottom: var(--kendo-spacing-8, 2rem);
}

.\!k-mb-8 {
  margin-bottom: var(--kendo-spacing-8, 2rem) !important;
}

.k-mb-9 {
  margin-bottom: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mb-9 {
  margin-bottom: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mb-10 {
  margin-bottom: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mb-10 {
  margin-bottom: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mb-11 {
  margin-bottom: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mb-11 {
  margin-bottom: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mb-12 {
  margin-bottom: var(--kendo-spacing-12, 3rem);
}

.\!k-mb-12 {
  margin-bottom: var(--kendo-spacing-12, 3rem) !important;
}

.k-mb-13 {
  margin-bottom: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mb-13 {
  margin-bottom: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mb-14 {
  margin-bottom: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mb-14 {
  margin-bottom: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mb-15 {
  margin-bottom: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mb-15 {
  margin-bottom: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mb-16 {
  margin-bottom: var(--kendo-spacing-16, 4rem);
}

.\!k-mb-16 {
  margin-bottom: var(--kendo-spacing-16, 4rem) !important;
}

.k-mb-17 {
  margin-bottom: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mb-17 {
  margin-bottom: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mb-18 {
  margin-bottom: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mb-18 {
  margin-bottom: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mb-19 {
  margin-bottom: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mb-19 {
  margin-bottom: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mb-20 {
  margin-bottom: var(--kendo-spacing-20, 5rem);
}

.\!k-mb-20 {
  margin-bottom: var(--kendo-spacing-20, 5rem) !important;
}

.k-mb-21 {
  margin-bottom: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mb-21 {
  margin-bottom: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mb-22 {
  margin-bottom: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mb-22 {
  margin-bottom: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mb-23 {
  margin-bottom: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mb-23 {
  margin-bottom: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mb-24 {
  margin-bottom: var(--kendo-spacing-24, 6rem);
}

.\!k-mb-24 {
  margin-bottom: var(--kendo-spacing-24, 6rem) !important;
}

.k-mb-25 {
  margin-bottom: var(--kendo-spacing-25, 7rem);
}

.\!k-mb-25 {
  margin-bottom: var(--kendo-spacing-25, 7rem) !important;
}

.k-mb-26 {
  margin-bottom: var(--kendo-spacing-26, 8rem);
}

.\!k-mb-26 {
  margin-bottom: var(--kendo-spacing-26, 8rem) !important;
}

.k-mb-27 {
  margin-bottom: var(--kendo-spacing-27, 9rem);
}

.\!k-mb-27 {
  margin-bottom: var(--kendo-spacing-27, 9rem) !important;
}

.k-mb-28 {
  margin-bottom: var(--kendo-spacing-28, 10rem);
}

.\!k-mb-28 {
  margin-bottom: var(--kendo-spacing-28, 10rem) !important;
}

.k-mb-29 {
  margin-bottom: var(--kendo-spacing-29, 11rem);
}

.\!k-mb-29 {
  margin-bottom: var(--kendo-spacing-29, 11rem) !important;
}

.k-mb-30 {
  margin-bottom: var(--kendo-spacing-30, 12rem);
}

.\!k-mb-30 {
  margin-bottom: var(--kendo-spacing-30, 12rem) !important;
}

.k-mb--0 {
  margin-bottom: var(--kendo-spacing--0, 0px);
}

.\!k-mb--0 {
  margin-bottom: var(--kendo-spacing--0, 0px) !important;
}

.k-mb--1px {
  margin-bottom: var(--kendo-spacing--1px, -1px);
}

.\!k-mb--1px {
  margin-bottom: var(--kendo-spacing--1px, -1px) !important;
}

.k-mb--0\.5 {
  margin-bottom: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mb--0\.5 {
  margin-bottom: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mb--1 {
  margin-bottom: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mb--1 {
  margin-bottom: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mb--1\.5 {
  margin-bottom: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mb--1\.5 {
  margin-bottom: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mb--2 {
  margin-bottom: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mb--2 {
  margin-bottom: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mb--2\.5 {
  margin-bottom: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mb--2\.5 {
  margin-bottom: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mb--3 {
  margin-bottom: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mb--3 {
  margin-bottom: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mb--3\.5 {
  margin-bottom: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mb--3\.5 {
  margin-bottom: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mb--4 {
  margin-bottom: var(--kendo-spacing--4, -1rem);
}

.\!k-mb--4 {
  margin-bottom: var(--kendo-spacing--4, -1rem) !important;
}

.k-mb--4\.5 {
  margin-bottom: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mb--4\.5 {
  margin-bottom: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mb--5 {
  margin-bottom: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mb--5 {
  margin-bottom: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mb--5\.5 {
  margin-bottom: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mb--5\.5 {
  margin-bottom: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mb--6 {
  margin-bottom: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mb--6 {
  margin-bottom: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mb--6\.5 {
  margin-bottom: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mb--6\.5 {
  margin-bottom: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mb--7 {
  margin-bottom: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mb--7 {
  margin-bottom: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mb--7\.5 {
  margin-bottom: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mb--7\.5 {
  margin-bottom: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mb--8 {
  margin-bottom: var(--kendo-spacing--8, -2rem);
}

.\!k-mb--8 {
  margin-bottom: var(--kendo-spacing--8, -2rem) !important;
}

.k-mb--9 {
  margin-bottom: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mb--9 {
  margin-bottom: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mb--10 {
  margin-bottom: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mb--10 {
  margin-bottom: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mb--11 {
  margin-bottom: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mb--11 {
  margin-bottom: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mb--12 {
  margin-bottom: var(--kendo-spacing--12, -3rem);
}

.\!k-mb--12 {
  margin-bottom: var(--kendo-spacing--12, -3rem) !important;
}

.k-mb--13 {
  margin-bottom: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mb--13 {
  margin-bottom: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mb--14 {
  margin-bottom: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mb--14 {
  margin-bottom: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mb--15 {
  margin-bottom: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mb--15 {
  margin-bottom: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mb--16 {
  margin-bottom: var(--kendo-spacing--16, -4rem);
}

.\!k-mb--16 {
  margin-bottom: var(--kendo-spacing--16, -4rem) !important;
}

.k-mb--17 {
  margin-bottom: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mb--17 {
  margin-bottom: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mb--18 {
  margin-bottom: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mb--18 {
  margin-bottom: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mb--19 {
  margin-bottom: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mb--19 {
  margin-bottom: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mb--20 {
  margin-bottom: var(--kendo-spacing--20, -5rem);
}

.\!k-mb--20 {
  margin-bottom: var(--kendo-spacing--20, -5rem) !important;
}

.k-mb--21 {
  margin-bottom: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mb--21 {
  margin-bottom: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mb--22 {
  margin-bottom: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mb--22 {
  margin-bottom: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mb--23 {
  margin-bottom: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mb--23 {
  margin-bottom: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mb--24 {
  margin-bottom: var(--kendo-spacing--24, -6rem);
}

.\!k-mb--24 {
  margin-bottom: var(--kendo-spacing--24, -6rem) !important;
}

.k-mb--25 {
  margin-bottom: var(--kendo-spacing--25, -7rem);
}

.\!k-mb--25 {
  margin-bottom: var(--kendo-spacing--25, -7rem) !important;
}

.k-mb--26 {
  margin-bottom: var(--kendo-spacing--26, -8rem);
}

.\!k-mb--26 {
  margin-bottom: var(--kendo-spacing--26, -8rem) !important;
}

.k-mb--27 {
  margin-bottom: var(--kendo-spacing--27, -9rem);
}

.\!k-mb--27 {
  margin-bottom: var(--kendo-spacing--27, -9rem) !important;
}

.k-mb--28 {
  margin-bottom: var(--kendo-spacing--28, -10rem);
}

.\!k-mb--28 {
  margin-bottom: var(--kendo-spacing--28, -10rem) !important;
}

.k-mb--29 {
  margin-bottom: var(--kendo-spacing--29, -11rem);
}

.\!k-mb--29 {
  margin-bottom: var(--kendo-spacing--29, -11rem) !important;
}

.k-mb--30 {
  margin-bottom: var(--kendo-spacing--30, -12rem);
}

.\!k-mb--30 {
  margin-bottom: var(--kendo-spacing--30, -12rem) !important;
}

.k-mb-xs {
  margin-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mb-xs {
  margin-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mb-sm {
  margin-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mb-sm {
  margin-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mb-md {
  margin-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mb-md {
  margin-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mb-lg {
  margin-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mb-lg {
  margin-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mb-xl {
  margin-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mb-xl {
  margin-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mb-thin {
  margin-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mb-thin {
  margin-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mb-hair {
  margin-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mb-hair {
  margin-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mb-auto {
  margin-bottom: var(--kendo-spacing-auto, auto);
}

.\!k-mb-auto {
  margin-bottom: var(--kendo-spacing-auto, auto) !important;
}

.k-ml-0 {
  margin-left: var(--kendo-spacing-0, 0px);
}

.\!k-ml-0 {
  margin-left: var(--kendo-spacing-0, 0px) !important;
}

.k-ml-1px {
  margin-left: var(--kendo-spacing-1px, 1px);
}

.\!k-ml-1px {
  margin-left: var(--kendo-spacing-1px, 1px) !important;
}

.k-ml-0\.5 {
  margin-left: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-ml-0\.5 {
  margin-left: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-ml-1 {
  margin-left: var(--kendo-spacing-1, 0.25rem);
}

.\!k-ml-1 {
  margin-left: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-ml-1\.5 {
  margin-left: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-ml-1\.5 {
  margin-left: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-ml-2 {
  margin-left: var(--kendo-spacing-2, 0.5rem);
}

.\!k-ml-2 {
  margin-left: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-ml-2\.5 {
  margin-left: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-ml-2\.5 {
  margin-left: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-ml-3 {
  margin-left: var(--kendo-spacing-3, 0.75rem);
}

.\!k-ml-3 {
  margin-left: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-ml-3\.5 {
  margin-left: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-ml-3\.5 {
  margin-left: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-ml-4 {
  margin-left: var(--kendo-spacing-4, 1rem);
}

.\!k-ml-4 {
  margin-left: var(--kendo-spacing-4, 1rem) !important;
}

.k-ml-4\.5 {
  margin-left: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-ml-4\.5 {
  margin-left: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-ml-5 {
  margin-left: var(--kendo-spacing-5, 1.25rem);
}

.\!k-ml-5 {
  margin-left: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-ml-5\.5 {
  margin-left: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-ml-5\.5 {
  margin-left: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-ml-6 {
  margin-left: var(--kendo-spacing-6, 1.5rem);
}

.\!k-ml-6 {
  margin-left: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-ml-6\.5 {
  margin-left: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-ml-6\.5 {
  margin-left: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-ml-7 {
  margin-left: var(--kendo-spacing-7, 1.75rem);
}

.\!k-ml-7 {
  margin-left: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-ml-7\.5 {
  margin-left: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-ml-7\.5 {
  margin-left: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-ml-8 {
  margin-left: var(--kendo-spacing-8, 2rem);
}

.\!k-ml-8 {
  margin-left: var(--kendo-spacing-8, 2rem) !important;
}

.k-ml-9 {
  margin-left: var(--kendo-spacing-9, 2.25rem);
}

.\!k-ml-9 {
  margin-left: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-ml-10 {
  margin-left: var(--kendo-spacing-10, 2.5rem);
}

.\!k-ml-10 {
  margin-left: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-ml-11 {
  margin-left: var(--kendo-spacing-11, 2.75rem);
}

.\!k-ml-11 {
  margin-left: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-ml-12 {
  margin-left: var(--kendo-spacing-12, 3rem);
}

.\!k-ml-12 {
  margin-left: var(--kendo-spacing-12, 3rem) !important;
}

.k-ml-13 {
  margin-left: var(--kendo-spacing-13, 3.25rem);
}

.\!k-ml-13 {
  margin-left: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-ml-14 {
  margin-left: var(--kendo-spacing-14, 3.5rem);
}

.\!k-ml-14 {
  margin-left: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-ml-15 {
  margin-left: var(--kendo-spacing-15, 3.75rem);
}

.\!k-ml-15 {
  margin-left: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-ml-16 {
  margin-left: var(--kendo-spacing-16, 4rem);
}

.\!k-ml-16 {
  margin-left: var(--kendo-spacing-16, 4rem) !important;
}

.k-ml-17 {
  margin-left: var(--kendo-spacing-17, 4.25rem);
}

.\!k-ml-17 {
  margin-left: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-ml-18 {
  margin-left: var(--kendo-spacing-18, 4.5rem);
}

.\!k-ml-18 {
  margin-left: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-ml-19 {
  margin-left: var(--kendo-spacing-19, 4.75rem);
}

.\!k-ml-19 {
  margin-left: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-ml-20 {
  margin-left: var(--kendo-spacing-20, 5rem);
}

.\!k-ml-20 {
  margin-left: var(--kendo-spacing-20, 5rem) !important;
}

.k-ml-21 {
  margin-left: var(--kendo-spacing-21, 5.25rem);
}

.\!k-ml-21 {
  margin-left: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-ml-22 {
  margin-left: var(--kendo-spacing-22, 5.5rem);
}

.\!k-ml-22 {
  margin-left: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-ml-23 {
  margin-left: var(--kendo-spacing-23, 5.75rem);
}

.\!k-ml-23 {
  margin-left: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-ml-24 {
  margin-left: var(--kendo-spacing-24, 6rem);
}

.\!k-ml-24 {
  margin-left: var(--kendo-spacing-24, 6rem) !important;
}

.k-ml-25 {
  margin-left: var(--kendo-spacing-25, 7rem);
}

.\!k-ml-25 {
  margin-left: var(--kendo-spacing-25, 7rem) !important;
}

.k-ml-26 {
  margin-left: var(--kendo-spacing-26, 8rem);
}

.\!k-ml-26 {
  margin-left: var(--kendo-spacing-26, 8rem) !important;
}

.k-ml-27 {
  margin-left: var(--kendo-spacing-27, 9rem);
}

.\!k-ml-27 {
  margin-left: var(--kendo-spacing-27, 9rem) !important;
}

.k-ml-28 {
  margin-left: var(--kendo-spacing-28, 10rem);
}

.\!k-ml-28 {
  margin-left: var(--kendo-spacing-28, 10rem) !important;
}

.k-ml-29 {
  margin-left: var(--kendo-spacing-29, 11rem);
}

.\!k-ml-29 {
  margin-left: var(--kendo-spacing-29, 11rem) !important;
}

.k-ml-30 {
  margin-left: var(--kendo-spacing-30, 12rem);
}

.\!k-ml-30 {
  margin-left: var(--kendo-spacing-30, 12rem) !important;
}

.k-ml--0 {
  margin-left: var(--kendo-spacing--0, 0px);
}

.\!k-ml--0 {
  margin-left: var(--kendo-spacing--0, 0px) !important;
}

.k-ml--1px {
  margin-left: var(--kendo-spacing--1px, -1px);
}

.\!k-ml--1px {
  margin-left: var(--kendo-spacing--1px, -1px) !important;
}

.k-ml--0\.5 {
  margin-left: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-ml--0\.5 {
  margin-left: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-ml--1 {
  margin-left: var(--kendo-spacing--1, -0.25rem);
}

.\!k-ml--1 {
  margin-left: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-ml--1\.5 {
  margin-left: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-ml--1\.5 {
  margin-left: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-ml--2 {
  margin-left: var(--kendo-spacing--2, -0.5rem);
}

.\!k-ml--2 {
  margin-left: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-ml--2\.5 {
  margin-left: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-ml--2\.5 {
  margin-left: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-ml--3 {
  margin-left: var(--kendo-spacing--3, -0.75rem);
}

.\!k-ml--3 {
  margin-left: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-ml--3\.5 {
  margin-left: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-ml--3\.5 {
  margin-left: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-ml--4 {
  margin-left: var(--kendo-spacing--4, -1rem);
}

.\!k-ml--4 {
  margin-left: var(--kendo-spacing--4, -1rem) !important;
}

.k-ml--4\.5 {
  margin-left: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-ml--4\.5 {
  margin-left: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-ml--5 {
  margin-left: var(--kendo-spacing--5, -1.25rem);
}

.\!k-ml--5 {
  margin-left: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-ml--5\.5 {
  margin-left: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-ml--5\.5 {
  margin-left: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-ml--6 {
  margin-left: var(--kendo-spacing--6, -1.5rem);
}

.\!k-ml--6 {
  margin-left: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-ml--6\.5 {
  margin-left: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-ml--6\.5 {
  margin-left: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-ml--7 {
  margin-left: var(--kendo-spacing--7, -1.75rem);
}

.\!k-ml--7 {
  margin-left: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-ml--7\.5 {
  margin-left: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-ml--7\.5 {
  margin-left: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-ml--8 {
  margin-left: var(--kendo-spacing--8, -2rem);
}

.\!k-ml--8 {
  margin-left: var(--kendo-spacing--8, -2rem) !important;
}

.k-ml--9 {
  margin-left: var(--kendo-spacing--9, -2.25rem);
}

.\!k-ml--9 {
  margin-left: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-ml--10 {
  margin-left: var(--kendo-spacing--10, -2.5rem);
}

.\!k-ml--10 {
  margin-left: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-ml--11 {
  margin-left: var(--kendo-spacing--11, -2.75rem);
}

.\!k-ml--11 {
  margin-left: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-ml--12 {
  margin-left: var(--kendo-spacing--12, -3rem);
}

.\!k-ml--12 {
  margin-left: var(--kendo-spacing--12, -3rem) !important;
}

.k-ml--13 {
  margin-left: var(--kendo-spacing--13, -3.25rem);
}

.\!k-ml--13 {
  margin-left: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-ml--14 {
  margin-left: var(--kendo-spacing--14, -3.5rem);
}

.\!k-ml--14 {
  margin-left: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-ml--15 {
  margin-left: var(--kendo-spacing--15, -3.75rem);
}

.\!k-ml--15 {
  margin-left: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-ml--16 {
  margin-left: var(--kendo-spacing--16, -4rem);
}

.\!k-ml--16 {
  margin-left: var(--kendo-spacing--16, -4rem) !important;
}

.k-ml--17 {
  margin-left: var(--kendo-spacing--17, -4.25rem);
}

.\!k-ml--17 {
  margin-left: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-ml--18 {
  margin-left: var(--kendo-spacing--18, -4.5rem);
}

.\!k-ml--18 {
  margin-left: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-ml--19 {
  margin-left: var(--kendo-spacing--19, -4.75rem);
}

.\!k-ml--19 {
  margin-left: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-ml--20 {
  margin-left: var(--kendo-spacing--20, -5rem);
}

.\!k-ml--20 {
  margin-left: var(--kendo-spacing--20, -5rem) !important;
}

.k-ml--21 {
  margin-left: var(--kendo-spacing--21, -5.25rem);
}

.\!k-ml--21 {
  margin-left: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-ml--22 {
  margin-left: var(--kendo-spacing--22, -5.5rem);
}

.\!k-ml--22 {
  margin-left: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-ml--23 {
  margin-left: var(--kendo-spacing--23, -5.75rem);
}

.\!k-ml--23 {
  margin-left: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-ml--24 {
  margin-left: var(--kendo-spacing--24, -6rem);
}

.\!k-ml--24 {
  margin-left: var(--kendo-spacing--24, -6rem) !important;
}

.k-ml--25 {
  margin-left: var(--kendo-spacing--25, -7rem);
}

.\!k-ml--25 {
  margin-left: var(--kendo-spacing--25, -7rem) !important;
}

.k-ml--26 {
  margin-left: var(--kendo-spacing--26, -8rem);
}

.\!k-ml--26 {
  margin-left: var(--kendo-spacing--26, -8rem) !important;
}

.k-ml--27 {
  margin-left: var(--kendo-spacing--27, -9rem);
}

.\!k-ml--27 {
  margin-left: var(--kendo-spacing--27, -9rem) !important;
}

.k-ml--28 {
  margin-left: var(--kendo-spacing--28, -10rem);
}

.\!k-ml--28 {
  margin-left: var(--kendo-spacing--28, -10rem) !important;
}

.k-ml--29 {
  margin-left: var(--kendo-spacing--29, -11rem);
}

.\!k-ml--29 {
  margin-left: var(--kendo-spacing--29, -11rem) !important;
}

.k-ml--30 {
  margin-left: var(--kendo-spacing--30, -12rem);
}

.\!k-ml--30 {
  margin-left: var(--kendo-spacing--30, -12rem) !important;
}

.k-ml-xs {
  margin-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-ml-xs {
  margin-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-ml-sm {
  margin-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-ml-sm {
  margin-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-ml-md {
  margin-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-ml-md {
  margin-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-ml-lg {
  margin-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-ml-lg {
  margin-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-ml-xl {
  margin-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-ml-xl {
  margin-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-ml-thin {
  margin-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-ml-thin {
  margin-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-ml-hair {
  margin-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-ml-hair {
  margin-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-ml-auto {
  margin-left: var(--kendo-spacing-auto, auto);
}

.\!k-ml-auto {
  margin-left: var(--kendo-spacing-auto, auto) !important;
}

.k-mx-0 {
  margin-inline: var(--kendo-spacing-0, 0px);
}

.\!k-mx-0 {
  margin-inline: var(--kendo-spacing-0, 0px) !important;
}

.k-mx-1px {
  margin-inline: var(--kendo-spacing-1px, 1px);
}

.\!k-mx-1px {
  margin-inline: var(--kendo-spacing-1px, 1px) !important;
}

.k-mx-0\.5 {
  margin-inline: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-mx-0\.5 {
  margin-inline: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-mx-1 {
  margin-inline: var(--kendo-spacing-1, 0.25rem);
}

.\!k-mx-1 {
  margin-inline: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-mx-1\.5 {
  margin-inline: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-mx-1\.5 {
  margin-inline: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-mx-2 {
  margin-inline: var(--kendo-spacing-2, 0.5rem);
}

.\!k-mx-2 {
  margin-inline: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-mx-2\.5 {
  margin-inline: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-mx-2\.5 {
  margin-inline: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-mx-3 {
  margin-inline: var(--kendo-spacing-3, 0.75rem);
}

.\!k-mx-3 {
  margin-inline: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-mx-3\.5 {
  margin-inline: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-mx-3\.5 {
  margin-inline: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-mx-4 {
  margin-inline: var(--kendo-spacing-4, 1rem);
}

.\!k-mx-4 {
  margin-inline: var(--kendo-spacing-4, 1rem) !important;
}

.k-mx-4\.5 {
  margin-inline: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-mx-4\.5 {
  margin-inline: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-mx-5 {
  margin-inline: var(--kendo-spacing-5, 1.25rem);
}

.\!k-mx-5 {
  margin-inline: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-mx-5\.5 {
  margin-inline: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-mx-5\.5 {
  margin-inline: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-mx-6 {
  margin-inline: var(--kendo-spacing-6, 1.5rem);
}

.\!k-mx-6 {
  margin-inline: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-mx-6\.5 {
  margin-inline: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-mx-6\.5 {
  margin-inline: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-mx-7 {
  margin-inline: var(--kendo-spacing-7, 1.75rem);
}

.\!k-mx-7 {
  margin-inline: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-mx-7\.5 {
  margin-inline: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-mx-7\.5 {
  margin-inline: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-mx-8 {
  margin-inline: var(--kendo-spacing-8, 2rem);
}

.\!k-mx-8 {
  margin-inline: var(--kendo-spacing-8, 2rem) !important;
}

.k-mx-9 {
  margin-inline: var(--kendo-spacing-9, 2.25rem);
}

.\!k-mx-9 {
  margin-inline: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-mx-10 {
  margin-inline: var(--kendo-spacing-10, 2.5rem);
}

.\!k-mx-10 {
  margin-inline: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-mx-11 {
  margin-inline: var(--kendo-spacing-11, 2.75rem);
}

.\!k-mx-11 {
  margin-inline: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-mx-12 {
  margin-inline: var(--kendo-spacing-12, 3rem);
}

.\!k-mx-12 {
  margin-inline: var(--kendo-spacing-12, 3rem) !important;
}

.k-mx-13 {
  margin-inline: var(--kendo-spacing-13, 3.25rem);
}

.\!k-mx-13 {
  margin-inline: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-mx-14 {
  margin-inline: var(--kendo-spacing-14, 3.5rem);
}

.\!k-mx-14 {
  margin-inline: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-mx-15 {
  margin-inline: var(--kendo-spacing-15, 3.75rem);
}

.\!k-mx-15 {
  margin-inline: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-mx-16 {
  margin-inline: var(--kendo-spacing-16, 4rem);
}

.\!k-mx-16 {
  margin-inline: var(--kendo-spacing-16, 4rem) !important;
}

.k-mx-17 {
  margin-inline: var(--kendo-spacing-17, 4.25rem);
}

.\!k-mx-17 {
  margin-inline: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-mx-18 {
  margin-inline: var(--kendo-spacing-18, 4.5rem);
}

.\!k-mx-18 {
  margin-inline: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-mx-19 {
  margin-inline: var(--kendo-spacing-19, 4.75rem);
}

.\!k-mx-19 {
  margin-inline: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-mx-20 {
  margin-inline: var(--kendo-spacing-20, 5rem);
}

.\!k-mx-20 {
  margin-inline: var(--kendo-spacing-20, 5rem) !important;
}

.k-mx-21 {
  margin-inline: var(--kendo-spacing-21, 5.25rem);
}

.\!k-mx-21 {
  margin-inline: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-mx-22 {
  margin-inline: var(--kendo-spacing-22, 5.5rem);
}

.\!k-mx-22 {
  margin-inline: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-mx-23 {
  margin-inline: var(--kendo-spacing-23, 5.75rem);
}

.\!k-mx-23 {
  margin-inline: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-mx-24 {
  margin-inline: var(--kendo-spacing-24, 6rem);
}

.\!k-mx-24 {
  margin-inline: var(--kendo-spacing-24, 6rem) !important;
}

.k-mx-25 {
  margin-inline: var(--kendo-spacing-25, 7rem);
}

.\!k-mx-25 {
  margin-inline: var(--kendo-spacing-25, 7rem) !important;
}

.k-mx-26 {
  margin-inline: var(--kendo-spacing-26, 8rem);
}

.\!k-mx-26 {
  margin-inline: var(--kendo-spacing-26, 8rem) !important;
}

.k-mx-27 {
  margin-inline: var(--kendo-spacing-27, 9rem);
}

.\!k-mx-27 {
  margin-inline: var(--kendo-spacing-27, 9rem) !important;
}

.k-mx-28 {
  margin-inline: var(--kendo-spacing-28, 10rem);
}

.\!k-mx-28 {
  margin-inline: var(--kendo-spacing-28, 10rem) !important;
}

.k-mx-29 {
  margin-inline: var(--kendo-spacing-29, 11rem);
}

.\!k-mx-29 {
  margin-inline: var(--kendo-spacing-29, 11rem) !important;
}

.k-mx-30 {
  margin-inline: var(--kendo-spacing-30, 12rem);
}

.\!k-mx-30 {
  margin-inline: var(--kendo-spacing-30, 12rem) !important;
}

.k-mx--0 {
  margin-inline: var(--kendo-spacing--0, 0px);
}

.\!k-mx--0 {
  margin-inline: var(--kendo-spacing--0, 0px) !important;
}

.k-mx--1px {
  margin-inline: var(--kendo-spacing--1px, -1px);
}

.\!k-mx--1px {
  margin-inline: var(--kendo-spacing--1px, -1px) !important;
}

.k-mx--0\.5 {
  margin-inline: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-mx--0\.5 {
  margin-inline: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-mx--1 {
  margin-inline: var(--kendo-spacing--1, -0.25rem);
}

.\!k-mx--1 {
  margin-inline: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-mx--1\.5 {
  margin-inline: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-mx--1\.5 {
  margin-inline: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-mx--2 {
  margin-inline: var(--kendo-spacing--2, -0.5rem);
}

.\!k-mx--2 {
  margin-inline: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-mx--2\.5 {
  margin-inline: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-mx--2\.5 {
  margin-inline: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-mx--3 {
  margin-inline: var(--kendo-spacing--3, -0.75rem);
}

.\!k-mx--3 {
  margin-inline: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-mx--3\.5 {
  margin-inline: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-mx--3\.5 {
  margin-inline: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-mx--4 {
  margin-inline: var(--kendo-spacing--4, -1rem);
}

.\!k-mx--4 {
  margin-inline: var(--kendo-spacing--4, -1rem) !important;
}

.k-mx--4\.5 {
  margin-inline: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-mx--4\.5 {
  margin-inline: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-mx--5 {
  margin-inline: var(--kendo-spacing--5, -1.25rem);
}

.\!k-mx--5 {
  margin-inline: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-mx--5\.5 {
  margin-inline: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-mx--5\.5 {
  margin-inline: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-mx--6 {
  margin-inline: var(--kendo-spacing--6, -1.5rem);
}

.\!k-mx--6 {
  margin-inline: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-mx--6\.5 {
  margin-inline: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-mx--6\.5 {
  margin-inline: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-mx--7 {
  margin-inline: var(--kendo-spacing--7, -1.75rem);
}

.\!k-mx--7 {
  margin-inline: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-mx--7\.5 {
  margin-inline: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-mx--7\.5 {
  margin-inline: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-mx--8 {
  margin-inline: var(--kendo-spacing--8, -2rem);
}

.\!k-mx--8 {
  margin-inline: var(--kendo-spacing--8, -2rem) !important;
}

.k-mx--9 {
  margin-inline: var(--kendo-spacing--9, -2.25rem);
}

.\!k-mx--9 {
  margin-inline: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-mx--10 {
  margin-inline: var(--kendo-spacing--10, -2.5rem);
}

.\!k-mx--10 {
  margin-inline: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-mx--11 {
  margin-inline: var(--kendo-spacing--11, -2.75rem);
}

.\!k-mx--11 {
  margin-inline: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-mx--12 {
  margin-inline: var(--kendo-spacing--12, -3rem);
}

.\!k-mx--12 {
  margin-inline: var(--kendo-spacing--12, -3rem) !important;
}

.k-mx--13 {
  margin-inline: var(--kendo-spacing--13, -3.25rem);
}

.\!k-mx--13 {
  margin-inline: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-mx--14 {
  margin-inline: var(--kendo-spacing--14, -3.5rem);
}

.\!k-mx--14 {
  margin-inline: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-mx--15 {
  margin-inline: var(--kendo-spacing--15, -3.75rem);
}

.\!k-mx--15 {
  margin-inline: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-mx--16 {
  margin-inline: var(--kendo-spacing--16, -4rem);
}

.\!k-mx--16 {
  margin-inline: var(--kendo-spacing--16, -4rem) !important;
}

.k-mx--17 {
  margin-inline: var(--kendo-spacing--17, -4.25rem);
}

.\!k-mx--17 {
  margin-inline: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-mx--18 {
  margin-inline: var(--kendo-spacing--18, -4.5rem);
}

.\!k-mx--18 {
  margin-inline: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-mx--19 {
  margin-inline: var(--kendo-spacing--19, -4.75rem);
}

.\!k-mx--19 {
  margin-inline: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-mx--20 {
  margin-inline: var(--kendo-spacing--20, -5rem);
}

.\!k-mx--20 {
  margin-inline: var(--kendo-spacing--20, -5rem) !important;
}

.k-mx--21 {
  margin-inline: var(--kendo-spacing--21, -5.25rem);
}

.\!k-mx--21 {
  margin-inline: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-mx--22 {
  margin-inline: var(--kendo-spacing--22, -5.5rem);
}

.\!k-mx--22 {
  margin-inline: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-mx--23 {
  margin-inline: var(--kendo-spacing--23, -5.75rem);
}

.\!k-mx--23 {
  margin-inline: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-mx--24 {
  margin-inline: var(--kendo-spacing--24, -6rem);
}

.\!k-mx--24 {
  margin-inline: var(--kendo-spacing--24, -6rem) !important;
}

.k-mx--25 {
  margin-inline: var(--kendo-spacing--25, -7rem);
}

.\!k-mx--25 {
  margin-inline: var(--kendo-spacing--25, -7rem) !important;
}

.k-mx--26 {
  margin-inline: var(--kendo-spacing--26, -8rem);
}

.\!k-mx--26 {
  margin-inline: var(--kendo-spacing--26, -8rem) !important;
}

.k-mx--27 {
  margin-inline: var(--kendo-spacing--27, -9rem);
}

.\!k-mx--27 {
  margin-inline: var(--kendo-spacing--27, -9rem) !important;
}

.k-mx--28 {
  margin-inline: var(--kendo-spacing--28, -10rem);
}

.\!k-mx--28 {
  margin-inline: var(--kendo-spacing--28, -10rem) !important;
}

.k-mx--29 {
  margin-inline: var(--kendo-spacing--29, -11rem);
}

.\!k-mx--29 {
  margin-inline: var(--kendo-spacing--29, -11rem) !important;
}

.k-mx--30 {
  margin-inline: var(--kendo-spacing--30, -12rem);
}

.\!k-mx--30 {
  margin-inline: var(--kendo-spacing--30, -12rem) !important;
}

.k-mx-xs {
  margin-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-mx-xs {
  margin-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-mx-sm {
  margin-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-mx-sm {
  margin-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-mx-md {
  margin-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-mx-md {
  margin-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-mx-lg {
  margin-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-mx-lg {
  margin-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-mx-xl {
  margin-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-mx-xl {
  margin-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-mx-thin {
  margin-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-mx-thin {
  margin-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-mx-hair {
  margin-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-mx-hair {
  margin-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-mx-auto {
  margin-inline: var(--kendo-spacing-auto, auto);
}

.\!k-mx-auto {
  margin-inline: var(--kendo-spacing-auto, auto) !important;
}

.k-my-0 {
  margin-block: var(--kendo-spacing-0, 0px);
}

.\!k-my-0 {
  margin-block: var(--kendo-spacing-0, 0px) !important;
}

.k-my-1px {
  margin-block: var(--kendo-spacing-1px, 1px);
}

.\!k-my-1px {
  margin-block: var(--kendo-spacing-1px, 1px) !important;
}

.k-my-0\.5 {
  margin-block: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-my-0\.5 {
  margin-block: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-my-1 {
  margin-block: var(--kendo-spacing-1, 0.25rem);
}

.\!k-my-1 {
  margin-block: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-my-1\.5 {
  margin-block: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-my-1\.5 {
  margin-block: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-my-2 {
  margin-block: var(--kendo-spacing-2, 0.5rem);
}

.\!k-my-2 {
  margin-block: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-my-2\.5 {
  margin-block: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-my-2\.5 {
  margin-block: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-my-3 {
  margin-block: var(--kendo-spacing-3, 0.75rem);
}

.\!k-my-3 {
  margin-block: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-my-3\.5 {
  margin-block: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-my-3\.5 {
  margin-block: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-my-4 {
  margin-block: var(--kendo-spacing-4, 1rem);
}

.\!k-my-4 {
  margin-block: var(--kendo-spacing-4, 1rem) !important;
}

.k-my-4\.5 {
  margin-block: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-my-4\.5 {
  margin-block: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-my-5 {
  margin-block: var(--kendo-spacing-5, 1.25rem);
}

.\!k-my-5 {
  margin-block: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-my-5\.5 {
  margin-block: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-my-5\.5 {
  margin-block: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-my-6 {
  margin-block: var(--kendo-spacing-6, 1.5rem);
}

.\!k-my-6 {
  margin-block: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-my-6\.5 {
  margin-block: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-my-6\.5 {
  margin-block: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-my-7 {
  margin-block: var(--kendo-spacing-7, 1.75rem);
}

.\!k-my-7 {
  margin-block: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-my-7\.5 {
  margin-block: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-my-7\.5 {
  margin-block: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-my-8 {
  margin-block: var(--kendo-spacing-8, 2rem);
}

.\!k-my-8 {
  margin-block: var(--kendo-spacing-8, 2rem) !important;
}

.k-my-9 {
  margin-block: var(--kendo-spacing-9, 2.25rem);
}

.\!k-my-9 {
  margin-block: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-my-10 {
  margin-block: var(--kendo-spacing-10, 2.5rem);
}

.\!k-my-10 {
  margin-block: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-my-11 {
  margin-block: var(--kendo-spacing-11, 2.75rem);
}

.\!k-my-11 {
  margin-block: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-my-12 {
  margin-block: var(--kendo-spacing-12, 3rem);
}

.\!k-my-12 {
  margin-block: var(--kendo-spacing-12, 3rem) !important;
}

.k-my-13 {
  margin-block: var(--kendo-spacing-13, 3.25rem);
}

.\!k-my-13 {
  margin-block: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-my-14 {
  margin-block: var(--kendo-spacing-14, 3.5rem);
}

.\!k-my-14 {
  margin-block: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-my-15 {
  margin-block: var(--kendo-spacing-15, 3.75rem);
}

.\!k-my-15 {
  margin-block: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-my-16 {
  margin-block: var(--kendo-spacing-16, 4rem);
}

.\!k-my-16 {
  margin-block: var(--kendo-spacing-16, 4rem) !important;
}

.k-my-17 {
  margin-block: var(--kendo-spacing-17, 4.25rem);
}

.\!k-my-17 {
  margin-block: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-my-18 {
  margin-block: var(--kendo-spacing-18, 4.5rem);
}

.\!k-my-18 {
  margin-block: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-my-19 {
  margin-block: var(--kendo-spacing-19, 4.75rem);
}

.\!k-my-19 {
  margin-block: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-my-20 {
  margin-block: var(--kendo-spacing-20, 5rem);
}

.\!k-my-20 {
  margin-block: var(--kendo-spacing-20, 5rem) !important;
}

.k-my-21 {
  margin-block: var(--kendo-spacing-21, 5.25rem);
}

.\!k-my-21 {
  margin-block: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-my-22 {
  margin-block: var(--kendo-spacing-22, 5.5rem);
}

.\!k-my-22 {
  margin-block: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-my-23 {
  margin-block: var(--kendo-spacing-23, 5.75rem);
}

.\!k-my-23 {
  margin-block: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-my-24 {
  margin-block: var(--kendo-spacing-24, 6rem);
}

.\!k-my-24 {
  margin-block: var(--kendo-spacing-24, 6rem) !important;
}

.k-my-25 {
  margin-block: var(--kendo-spacing-25, 7rem);
}

.\!k-my-25 {
  margin-block: var(--kendo-spacing-25, 7rem) !important;
}

.k-my-26 {
  margin-block: var(--kendo-spacing-26, 8rem);
}

.\!k-my-26 {
  margin-block: var(--kendo-spacing-26, 8rem) !important;
}

.k-my-27 {
  margin-block: var(--kendo-spacing-27, 9rem);
}

.\!k-my-27 {
  margin-block: var(--kendo-spacing-27, 9rem) !important;
}

.k-my-28 {
  margin-block: var(--kendo-spacing-28, 10rem);
}

.\!k-my-28 {
  margin-block: var(--kendo-spacing-28, 10rem) !important;
}

.k-my-29 {
  margin-block: var(--kendo-spacing-29, 11rem);
}

.\!k-my-29 {
  margin-block: var(--kendo-spacing-29, 11rem) !important;
}

.k-my-30 {
  margin-block: var(--kendo-spacing-30, 12rem);
}

.\!k-my-30 {
  margin-block: var(--kendo-spacing-30, 12rem) !important;
}

.k-my--0 {
  margin-block: var(--kendo-spacing--0, 0px);
}

.\!k-my--0 {
  margin-block: var(--kendo-spacing--0, 0px) !important;
}

.k-my--1px {
  margin-block: var(--kendo-spacing--1px, -1px);
}

.\!k-my--1px {
  margin-block: var(--kendo-spacing--1px, -1px) !important;
}

.k-my--0\.5 {
  margin-block: var(--kendo-spacing--0\.5, -0.125rem);
}

.\!k-my--0\.5 {
  margin-block: var(--kendo-spacing--0\.5, -0.125rem) !important;
}

.k-my--1 {
  margin-block: var(--kendo-spacing--1, -0.25rem);
}

.\!k-my--1 {
  margin-block: var(--kendo-spacing--1, -0.25rem) !important;
}

.k-my--1\.5 {
  margin-block: var(--kendo-spacing--1\.5, -0.375rem);
}

.\!k-my--1\.5 {
  margin-block: var(--kendo-spacing--1\.5, -0.375rem) !important;
}

.k-my--2 {
  margin-block: var(--kendo-spacing--2, -0.5rem);
}

.\!k-my--2 {
  margin-block: var(--kendo-spacing--2, -0.5rem) !important;
}

.k-my--2\.5 {
  margin-block: var(--kendo-spacing--2\.5, -0.625rem);
}

.\!k-my--2\.5 {
  margin-block: var(--kendo-spacing--2\.5, -0.625rem) !important;
}

.k-my--3 {
  margin-block: var(--kendo-spacing--3, -0.75rem);
}

.\!k-my--3 {
  margin-block: var(--kendo-spacing--3, -0.75rem) !important;
}

.k-my--3\.5 {
  margin-block: var(--kendo-spacing--3\.5, -0.875rem);
}

.\!k-my--3\.5 {
  margin-block: var(--kendo-spacing--3\.5, -0.875rem) !important;
}

.k-my--4 {
  margin-block: var(--kendo-spacing--4, -1rem);
}

.\!k-my--4 {
  margin-block: var(--kendo-spacing--4, -1rem) !important;
}

.k-my--4\.5 {
  margin-block: var(--kendo-spacing--4\.5, -1.125rem);
}

.\!k-my--4\.5 {
  margin-block: var(--kendo-spacing--4\.5, -1.125rem) !important;
}

.k-my--5 {
  margin-block: var(--kendo-spacing--5, -1.25rem);
}

.\!k-my--5 {
  margin-block: var(--kendo-spacing--5, -1.25rem) !important;
}

.k-my--5\.5 {
  margin-block: var(--kendo-spacing--5\.5, -1.375rem);
}

.\!k-my--5\.5 {
  margin-block: var(--kendo-spacing--5\.5, -1.375rem) !important;
}

.k-my--6 {
  margin-block: var(--kendo-spacing--6, -1.5rem);
}

.\!k-my--6 {
  margin-block: var(--kendo-spacing--6, -1.5rem) !important;
}

.k-my--6\.5 {
  margin-block: var(--kendo-spacing--6\.5, -1.625rem);
}

.\!k-my--6\.5 {
  margin-block: var(--kendo-spacing--6\.5, -1.625rem) !important;
}

.k-my--7 {
  margin-block: var(--kendo-spacing--7, -1.75rem);
}

.\!k-my--7 {
  margin-block: var(--kendo-spacing--7, -1.75rem) !important;
}

.k-my--7\.5 {
  margin-block: var(--kendo-spacing--7\.5, -1.875rem);
}

.\!k-my--7\.5 {
  margin-block: var(--kendo-spacing--7\.5, -1.875rem) !important;
}

.k-my--8 {
  margin-block: var(--kendo-spacing--8, -2rem);
}

.\!k-my--8 {
  margin-block: var(--kendo-spacing--8, -2rem) !important;
}

.k-my--9 {
  margin-block: var(--kendo-spacing--9, -2.25rem);
}

.\!k-my--9 {
  margin-block: var(--kendo-spacing--9, -2.25rem) !important;
}

.k-my--10 {
  margin-block: var(--kendo-spacing--10, -2.5rem);
}

.\!k-my--10 {
  margin-block: var(--kendo-spacing--10, -2.5rem) !important;
}

.k-my--11 {
  margin-block: var(--kendo-spacing--11, -2.75rem);
}

.\!k-my--11 {
  margin-block: var(--kendo-spacing--11, -2.75rem) !important;
}

.k-my--12 {
  margin-block: var(--kendo-spacing--12, -3rem);
}

.\!k-my--12 {
  margin-block: var(--kendo-spacing--12, -3rem) !important;
}

.k-my--13 {
  margin-block: var(--kendo-spacing--13, -3.25rem);
}

.\!k-my--13 {
  margin-block: var(--kendo-spacing--13, -3.25rem) !important;
}

.k-my--14 {
  margin-block: var(--kendo-spacing--14, -3.5rem);
}

.\!k-my--14 {
  margin-block: var(--kendo-spacing--14, -3.5rem) !important;
}

.k-my--15 {
  margin-block: var(--kendo-spacing--15, -3.75rem);
}

.\!k-my--15 {
  margin-block: var(--kendo-spacing--15, -3.75rem) !important;
}

.k-my--16 {
  margin-block: var(--kendo-spacing--16, -4rem);
}

.\!k-my--16 {
  margin-block: var(--kendo-spacing--16, -4rem) !important;
}

.k-my--17 {
  margin-block: var(--kendo-spacing--17, -4.25rem);
}

.\!k-my--17 {
  margin-block: var(--kendo-spacing--17, -4.25rem) !important;
}

.k-my--18 {
  margin-block: var(--kendo-spacing--18, -4.5rem);
}

.\!k-my--18 {
  margin-block: var(--kendo-spacing--18, -4.5rem) !important;
}

.k-my--19 {
  margin-block: var(--kendo-spacing--19, -4.75rem);
}

.\!k-my--19 {
  margin-block: var(--kendo-spacing--19, -4.75rem) !important;
}

.k-my--20 {
  margin-block: var(--kendo-spacing--20, -5rem);
}

.\!k-my--20 {
  margin-block: var(--kendo-spacing--20, -5rem) !important;
}

.k-my--21 {
  margin-block: var(--kendo-spacing--21, -5.25rem);
}

.\!k-my--21 {
  margin-block: var(--kendo-spacing--21, -5.25rem) !important;
}

.k-my--22 {
  margin-block: var(--kendo-spacing--22, -5.5rem);
}

.\!k-my--22 {
  margin-block: var(--kendo-spacing--22, -5.5rem) !important;
}

.k-my--23 {
  margin-block: var(--kendo-spacing--23, -5.75rem);
}

.\!k-my--23 {
  margin-block: var(--kendo-spacing--23, -5.75rem) !important;
}

.k-my--24 {
  margin-block: var(--kendo-spacing--24, -6rem);
}

.\!k-my--24 {
  margin-block: var(--kendo-spacing--24, -6rem) !important;
}

.k-my--25 {
  margin-block: var(--kendo-spacing--25, -7rem);
}

.\!k-my--25 {
  margin-block: var(--kendo-spacing--25, -7rem) !important;
}

.k-my--26 {
  margin-block: var(--kendo-spacing--26, -8rem);
}

.\!k-my--26 {
  margin-block: var(--kendo-spacing--26, -8rem) !important;
}

.k-my--27 {
  margin-block: var(--kendo-spacing--27, -9rem);
}

.\!k-my--27 {
  margin-block: var(--kendo-spacing--27, -9rem) !important;
}

.k-my--28 {
  margin-block: var(--kendo-spacing--28, -10rem);
}

.\!k-my--28 {
  margin-block: var(--kendo-spacing--28, -10rem) !important;
}

.k-my--29 {
  margin-block: var(--kendo-spacing--29, -11rem);
}

.\!k-my--29 {
  margin-block: var(--kendo-spacing--29, -11rem) !important;
}

.k-my--30 {
  margin-block: var(--kendo-spacing--30, -12rem);
}

.\!k-my--30 {
  margin-block: var(--kendo-spacing--30, -12rem) !important;
}

.k-my-xs {
  margin-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-my-xs {
  margin-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-my-sm {
  margin-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-my-sm {
  margin-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-my-md {
  margin-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-my-md {
  margin-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-my-lg {
  margin-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-my-lg {
  margin-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-my-xl {
  margin-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-my-xl {
  margin-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-my-thin {
  margin-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-my-thin {
  margin-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-my-hair {
  margin-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-my-hair {
  margin-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-my-auto {
  margin-block: var(--kendo-spacing-auto, auto);
}

.\!k-my-auto {
  margin-block: var(--kendo-spacing-auto, auto) !important;
}

.k-p-0 {
  padding: var(--kendo-spacing-0, 0px);
}

.\!k-p-0 {
  padding: var(--kendo-spacing-0, 0px) !important;
}

.k-p-1px {
  padding: var(--kendo-spacing-1px, 1px);
}

.\!k-p-1px {
  padding: var(--kendo-spacing-1px, 1px) !important;
}

.k-p-0\.5 {
  padding: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-p-0\.5 {
  padding: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-p-1 {
  padding: var(--kendo-spacing-1, 0.25rem);
}

.\!k-p-1 {
  padding: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-p-1\.5 {
  padding: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-p-1\.5 {
  padding: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-p-2 {
  padding: var(--kendo-spacing-2, 0.5rem);
}

.\!k-p-2 {
  padding: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-p-2\.5 {
  padding: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-p-2\.5 {
  padding: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-p-3 {
  padding: var(--kendo-spacing-3, 0.75rem);
}

.\!k-p-3 {
  padding: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-p-3\.5 {
  padding: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-p-3\.5 {
  padding: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-p-4 {
  padding: var(--kendo-spacing-4, 1rem);
}

.\!k-p-4 {
  padding: var(--kendo-spacing-4, 1rem) !important;
}

.k-p-4\.5 {
  padding: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-p-4\.5 {
  padding: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-p-5 {
  padding: var(--kendo-spacing-5, 1.25rem);
}

.\!k-p-5 {
  padding: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-p-5\.5 {
  padding: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-p-5\.5 {
  padding: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-p-6 {
  padding: var(--kendo-spacing-6, 1.5rem);
}

.\!k-p-6 {
  padding: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-p-6\.5 {
  padding: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-p-6\.5 {
  padding: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-p-7 {
  padding: var(--kendo-spacing-7, 1.75rem);
}

.\!k-p-7 {
  padding: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-p-7\.5 {
  padding: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-p-7\.5 {
  padding: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-p-8 {
  padding: var(--kendo-spacing-8, 2rem);
}

.\!k-p-8 {
  padding: var(--kendo-spacing-8, 2rem) !important;
}

.k-p-9 {
  padding: var(--kendo-spacing-9, 2.25rem);
}

.\!k-p-9 {
  padding: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-p-10 {
  padding: var(--kendo-spacing-10, 2.5rem);
}

.\!k-p-10 {
  padding: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-p-11 {
  padding: var(--kendo-spacing-11, 2.75rem);
}

.\!k-p-11 {
  padding: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-p-12 {
  padding: var(--kendo-spacing-12, 3rem);
}

.\!k-p-12 {
  padding: var(--kendo-spacing-12, 3rem) !important;
}

.k-p-13 {
  padding: var(--kendo-spacing-13, 3.25rem);
}

.\!k-p-13 {
  padding: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-p-14 {
  padding: var(--kendo-spacing-14, 3.5rem);
}

.\!k-p-14 {
  padding: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-p-15 {
  padding: var(--kendo-spacing-15, 3.75rem);
}

.\!k-p-15 {
  padding: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-p-16 {
  padding: var(--kendo-spacing-16, 4rem);
}

.\!k-p-16 {
  padding: var(--kendo-spacing-16, 4rem) !important;
}

.k-p-17 {
  padding: var(--kendo-spacing-17, 4.25rem);
}

.\!k-p-17 {
  padding: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-p-18 {
  padding: var(--kendo-spacing-18, 4.5rem);
}

.\!k-p-18 {
  padding: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-p-19 {
  padding: var(--kendo-spacing-19, 4.75rem);
}

.\!k-p-19 {
  padding: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-p-20 {
  padding: var(--kendo-spacing-20, 5rem);
}

.\!k-p-20 {
  padding: var(--kendo-spacing-20, 5rem) !important;
}

.k-p-21 {
  padding: var(--kendo-spacing-21, 5.25rem);
}

.\!k-p-21 {
  padding: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-p-22 {
  padding: var(--kendo-spacing-22, 5.5rem);
}

.\!k-p-22 {
  padding: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-p-23 {
  padding: var(--kendo-spacing-23, 5.75rem);
}

.\!k-p-23 {
  padding: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-p-24 {
  padding: var(--kendo-spacing-24, 6rem);
}

.\!k-p-24 {
  padding: var(--kendo-spacing-24, 6rem) !important;
}

.k-p-25 {
  padding: var(--kendo-spacing-25, 7rem);
}

.\!k-p-25 {
  padding: var(--kendo-spacing-25, 7rem) !important;
}

.k-p-26 {
  padding: var(--kendo-spacing-26, 8rem);
}

.\!k-p-26 {
  padding: var(--kendo-spacing-26, 8rem) !important;
}

.k-p-27 {
  padding: var(--kendo-spacing-27, 9rem);
}

.\!k-p-27 {
  padding: var(--kendo-spacing-27, 9rem) !important;
}

.k-p-28 {
  padding: var(--kendo-spacing-28, 10rem);
}

.\!k-p-28 {
  padding: var(--kendo-spacing-28, 10rem) !important;
}

.k-p-29 {
  padding: var(--kendo-spacing-29, 11rem);
}

.\!k-p-29 {
  padding: var(--kendo-spacing-29, 11rem) !important;
}

.k-p-30 {
  padding: var(--kendo-spacing-30, 12rem);
}

.\!k-p-30 {
  padding: var(--kendo-spacing-30, 12rem) !important;
}

.k-p-xs {
  padding: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-p-xs {
  padding: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-p-sm {
  padding: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-p-sm {
  padding: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-p-md {
  padding: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-p-md {
  padding: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-p-lg {
  padding: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-p-lg {
  padding: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-p-xl {
  padding: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-p-xl {
  padding: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-p-thin {
  padding: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-p-thin {
  padding: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-p-hair {
  padding: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-p-hair {
  padding: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-pt-0 {
  padding-top: var(--kendo-spacing-0, 0px);
}

.\!k-pt-0 {
  padding-top: var(--kendo-spacing-0, 0px) !important;
}

.k-pt-1px {
  padding-top: var(--kendo-spacing-1px, 1px);
}

.\!k-pt-1px {
  padding-top: var(--kendo-spacing-1px, 1px) !important;
}

.k-pt-0\.5 {
  padding-top: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-pt-0\.5 {
  padding-top: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-pt-1 {
  padding-top: var(--kendo-spacing-1, 0.25rem);
}

.\!k-pt-1 {
  padding-top: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-pt-1\.5 {
  padding-top: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-pt-1\.5 {
  padding-top: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-pt-2 {
  padding-top: var(--kendo-spacing-2, 0.5rem);
}

.\!k-pt-2 {
  padding-top: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-pt-2\.5 {
  padding-top: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-pt-2\.5 {
  padding-top: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-pt-3 {
  padding-top: var(--kendo-spacing-3, 0.75rem);
}

.\!k-pt-3 {
  padding-top: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-pt-3\.5 {
  padding-top: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-pt-3\.5 {
  padding-top: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-pt-4 {
  padding-top: var(--kendo-spacing-4, 1rem);
}

.\!k-pt-4 {
  padding-top: var(--kendo-spacing-4, 1rem) !important;
}

.k-pt-4\.5 {
  padding-top: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-pt-4\.5 {
  padding-top: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-pt-5 {
  padding-top: var(--kendo-spacing-5, 1.25rem);
}

.\!k-pt-5 {
  padding-top: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-pt-5\.5 {
  padding-top: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-pt-5\.5 {
  padding-top: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-pt-6 {
  padding-top: var(--kendo-spacing-6, 1.5rem);
}

.\!k-pt-6 {
  padding-top: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-pt-6\.5 {
  padding-top: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-pt-6\.5 {
  padding-top: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-pt-7 {
  padding-top: var(--kendo-spacing-7, 1.75rem);
}

.\!k-pt-7 {
  padding-top: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-pt-7\.5 {
  padding-top: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-pt-7\.5 {
  padding-top: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-pt-8 {
  padding-top: var(--kendo-spacing-8, 2rem);
}

.\!k-pt-8 {
  padding-top: var(--kendo-spacing-8, 2rem) !important;
}

.k-pt-9 {
  padding-top: var(--kendo-spacing-9, 2.25rem);
}

.\!k-pt-9 {
  padding-top: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-pt-10 {
  padding-top: var(--kendo-spacing-10, 2.5rem);
}

.\!k-pt-10 {
  padding-top: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-pt-11 {
  padding-top: var(--kendo-spacing-11, 2.75rem);
}

.\!k-pt-11 {
  padding-top: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-pt-12 {
  padding-top: var(--kendo-spacing-12, 3rem);
}

.\!k-pt-12 {
  padding-top: var(--kendo-spacing-12, 3rem) !important;
}

.k-pt-13 {
  padding-top: var(--kendo-spacing-13, 3.25rem);
}

.\!k-pt-13 {
  padding-top: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-pt-14 {
  padding-top: var(--kendo-spacing-14, 3.5rem);
}

.\!k-pt-14 {
  padding-top: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-pt-15 {
  padding-top: var(--kendo-spacing-15, 3.75rem);
}

.\!k-pt-15 {
  padding-top: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-pt-16 {
  padding-top: var(--kendo-spacing-16, 4rem);
}

.\!k-pt-16 {
  padding-top: var(--kendo-spacing-16, 4rem) !important;
}

.k-pt-17 {
  padding-top: var(--kendo-spacing-17, 4.25rem);
}

.\!k-pt-17 {
  padding-top: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-pt-18 {
  padding-top: var(--kendo-spacing-18, 4.5rem);
}

.\!k-pt-18 {
  padding-top: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-pt-19 {
  padding-top: var(--kendo-spacing-19, 4.75rem);
}

.\!k-pt-19 {
  padding-top: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-pt-20 {
  padding-top: var(--kendo-spacing-20, 5rem);
}

.\!k-pt-20 {
  padding-top: var(--kendo-spacing-20, 5rem) !important;
}

.k-pt-21 {
  padding-top: var(--kendo-spacing-21, 5.25rem);
}

.\!k-pt-21 {
  padding-top: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-pt-22 {
  padding-top: var(--kendo-spacing-22, 5.5rem);
}

.\!k-pt-22 {
  padding-top: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-pt-23 {
  padding-top: var(--kendo-spacing-23, 5.75rem);
}

.\!k-pt-23 {
  padding-top: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-pt-24 {
  padding-top: var(--kendo-spacing-24, 6rem);
}

.\!k-pt-24 {
  padding-top: var(--kendo-spacing-24, 6rem) !important;
}

.k-pt-25 {
  padding-top: var(--kendo-spacing-25, 7rem);
}

.\!k-pt-25 {
  padding-top: var(--kendo-spacing-25, 7rem) !important;
}

.k-pt-26 {
  padding-top: var(--kendo-spacing-26, 8rem);
}

.\!k-pt-26 {
  padding-top: var(--kendo-spacing-26, 8rem) !important;
}

.k-pt-27 {
  padding-top: var(--kendo-spacing-27, 9rem);
}

.\!k-pt-27 {
  padding-top: var(--kendo-spacing-27, 9rem) !important;
}

.k-pt-28 {
  padding-top: var(--kendo-spacing-28, 10rem);
}

.\!k-pt-28 {
  padding-top: var(--kendo-spacing-28, 10rem) !important;
}

.k-pt-29 {
  padding-top: var(--kendo-spacing-29, 11rem);
}

.\!k-pt-29 {
  padding-top: var(--kendo-spacing-29, 11rem) !important;
}

.k-pt-30 {
  padding-top: var(--kendo-spacing-30, 12rem);
}

.\!k-pt-30 {
  padding-top: var(--kendo-spacing-30, 12rem) !important;
}

.k-pt-xs {
  padding-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-pt-xs {
  padding-top: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-pt-sm {
  padding-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-pt-sm {
  padding-top: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-pt-md {
  padding-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-pt-md {
  padding-top: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-pt-lg {
  padding-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-pt-lg {
  padding-top: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-pt-xl {
  padding-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-pt-xl {
  padding-top: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-pt-thin {
  padding-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-pt-thin {
  padding-top: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-pt-hair {
  padding-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-pt-hair {
  padding-top: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-pr-0 {
  padding-right: var(--kendo-spacing-0, 0px);
}

.\!k-pr-0 {
  padding-right: var(--kendo-spacing-0, 0px) !important;
}

.k-pr-1px {
  padding-right: var(--kendo-spacing-1px, 1px);
}

.\!k-pr-1px {
  padding-right: var(--kendo-spacing-1px, 1px) !important;
}

.k-pr-0\.5 {
  padding-right: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-pr-0\.5 {
  padding-right: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-pr-1 {
  padding-right: var(--kendo-spacing-1, 0.25rem);
}

.\!k-pr-1 {
  padding-right: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-pr-1\.5 {
  padding-right: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-pr-1\.5 {
  padding-right: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-pr-2 {
  padding-right: var(--kendo-spacing-2, 0.5rem);
}

.\!k-pr-2 {
  padding-right: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-pr-2\.5 {
  padding-right: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-pr-2\.5 {
  padding-right: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-pr-3 {
  padding-right: var(--kendo-spacing-3, 0.75rem);
}

.\!k-pr-3 {
  padding-right: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-pr-3\.5 {
  padding-right: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-pr-3\.5 {
  padding-right: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-pr-4 {
  padding-right: var(--kendo-spacing-4, 1rem);
}

.\!k-pr-4 {
  padding-right: var(--kendo-spacing-4, 1rem) !important;
}

.k-pr-4\.5 {
  padding-right: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-pr-4\.5 {
  padding-right: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-pr-5 {
  padding-right: var(--kendo-spacing-5, 1.25rem);
}

.\!k-pr-5 {
  padding-right: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-pr-5\.5 {
  padding-right: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-pr-5\.5 {
  padding-right: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-pr-6 {
  padding-right: var(--kendo-spacing-6, 1.5rem);
}

.\!k-pr-6 {
  padding-right: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-pr-6\.5 {
  padding-right: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-pr-6\.5 {
  padding-right: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-pr-7 {
  padding-right: var(--kendo-spacing-7, 1.75rem);
}

.\!k-pr-7 {
  padding-right: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-pr-7\.5 {
  padding-right: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-pr-7\.5 {
  padding-right: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-pr-8 {
  padding-right: var(--kendo-spacing-8, 2rem);
}

.\!k-pr-8 {
  padding-right: var(--kendo-spacing-8, 2rem) !important;
}

.k-pr-9 {
  padding-right: var(--kendo-spacing-9, 2.25rem);
}

.\!k-pr-9 {
  padding-right: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-pr-10 {
  padding-right: var(--kendo-spacing-10, 2.5rem);
}

.\!k-pr-10 {
  padding-right: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-pr-11 {
  padding-right: var(--kendo-spacing-11, 2.75rem);
}

.\!k-pr-11 {
  padding-right: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-pr-12 {
  padding-right: var(--kendo-spacing-12, 3rem);
}

.\!k-pr-12 {
  padding-right: var(--kendo-spacing-12, 3rem) !important;
}

.k-pr-13 {
  padding-right: var(--kendo-spacing-13, 3.25rem);
}

.\!k-pr-13 {
  padding-right: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-pr-14 {
  padding-right: var(--kendo-spacing-14, 3.5rem);
}

.\!k-pr-14 {
  padding-right: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-pr-15 {
  padding-right: var(--kendo-spacing-15, 3.75rem);
}

.\!k-pr-15 {
  padding-right: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-pr-16 {
  padding-right: var(--kendo-spacing-16, 4rem);
}

.\!k-pr-16 {
  padding-right: var(--kendo-spacing-16, 4rem) !important;
}

.k-pr-17 {
  padding-right: var(--kendo-spacing-17, 4.25rem);
}

.\!k-pr-17 {
  padding-right: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-pr-18 {
  padding-right: var(--kendo-spacing-18, 4.5rem);
}

.\!k-pr-18 {
  padding-right: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-pr-19 {
  padding-right: var(--kendo-spacing-19, 4.75rem);
}

.\!k-pr-19 {
  padding-right: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-pr-20 {
  padding-right: var(--kendo-spacing-20, 5rem);
}

.\!k-pr-20 {
  padding-right: var(--kendo-spacing-20, 5rem) !important;
}

.k-pr-21 {
  padding-right: var(--kendo-spacing-21, 5.25rem);
}

.\!k-pr-21 {
  padding-right: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-pr-22 {
  padding-right: var(--kendo-spacing-22, 5.5rem);
}

.\!k-pr-22 {
  padding-right: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-pr-23 {
  padding-right: var(--kendo-spacing-23, 5.75rem);
}

.\!k-pr-23 {
  padding-right: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-pr-24 {
  padding-right: var(--kendo-spacing-24, 6rem);
}

.\!k-pr-24 {
  padding-right: var(--kendo-spacing-24, 6rem) !important;
}

.k-pr-25 {
  padding-right: var(--kendo-spacing-25, 7rem);
}

.\!k-pr-25 {
  padding-right: var(--kendo-spacing-25, 7rem) !important;
}

.k-pr-26 {
  padding-right: var(--kendo-spacing-26, 8rem);
}

.\!k-pr-26 {
  padding-right: var(--kendo-spacing-26, 8rem) !important;
}

.k-pr-27 {
  padding-right: var(--kendo-spacing-27, 9rem);
}

.\!k-pr-27 {
  padding-right: var(--kendo-spacing-27, 9rem) !important;
}

.k-pr-28 {
  padding-right: var(--kendo-spacing-28, 10rem);
}

.\!k-pr-28 {
  padding-right: var(--kendo-spacing-28, 10rem) !important;
}

.k-pr-29 {
  padding-right: var(--kendo-spacing-29, 11rem);
}

.\!k-pr-29 {
  padding-right: var(--kendo-spacing-29, 11rem) !important;
}

.k-pr-30 {
  padding-right: var(--kendo-spacing-30, 12rem);
}

.\!k-pr-30 {
  padding-right: var(--kendo-spacing-30, 12rem) !important;
}

.k-pr-xs {
  padding-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-pr-xs {
  padding-right: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-pr-sm {
  padding-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-pr-sm {
  padding-right: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-pr-md {
  padding-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-pr-md {
  padding-right: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-pr-lg {
  padding-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-pr-lg {
  padding-right: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-pr-xl {
  padding-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-pr-xl {
  padding-right: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-pr-thin {
  padding-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-pr-thin {
  padding-right: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-pr-hair {
  padding-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-pr-hair {
  padding-right: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-pb-0 {
  padding-bottom: var(--kendo-spacing-0, 0px);
}

.\!k-pb-0 {
  padding-bottom: var(--kendo-spacing-0, 0px) !important;
}

.k-pb-1px {
  padding-bottom: var(--kendo-spacing-1px, 1px);
}

.\!k-pb-1px {
  padding-bottom: var(--kendo-spacing-1px, 1px) !important;
}

.k-pb-0\.5 {
  padding-bottom: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-pb-0\.5 {
  padding-bottom: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-pb-1 {
  padding-bottom: var(--kendo-spacing-1, 0.25rem);
}

.\!k-pb-1 {
  padding-bottom: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-pb-1\.5 {
  padding-bottom: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-pb-1\.5 {
  padding-bottom: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-pb-2 {
  padding-bottom: var(--kendo-spacing-2, 0.5rem);
}

.\!k-pb-2 {
  padding-bottom: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-pb-2\.5 {
  padding-bottom: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-pb-2\.5 {
  padding-bottom: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-pb-3 {
  padding-bottom: var(--kendo-spacing-3, 0.75rem);
}

.\!k-pb-3 {
  padding-bottom: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-pb-3\.5 {
  padding-bottom: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-pb-3\.5 {
  padding-bottom: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-pb-4 {
  padding-bottom: var(--kendo-spacing-4, 1rem);
}

.\!k-pb-4 {
  padding-bottom: var(--kendo-spacing-4, 1rem) !important;
}

.k-pb-4\.5 {
  padding-bottom: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-pb-4\.5 {
  padding-bottom: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-pb-5 {
  padding-bottom: var(--kendo-spacing-5, 1.25rem);
}

.\!k-pb-5 {
  padding-bottom: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-pb-5\.5 {
  padding-bottom: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-pb-5\.5 {
  padding-bottom: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-pb-6 {
  padding-bottom: var(--kendo-spacing-6, 1.5rem);
}

.\!k-pb-6 {
  padding-bottom: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-pb-6\.5 {
  padding-bottom: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-pb-6\.5 {
  padding-bottom: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-pb-7 {
  padding-bottom: var(--kendo-spacing-7, 1.75rem);
}

.\!k-pb-7 {
  padding-bottom: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-pb-7\.5 {
  padding-bottom: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-pb-7\.5 {
  padding-bottom: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-pb-8 {
  padding-bottom: var(--kendo-spacing-8, 2rem);
}

.\!k-pb-8 {
  padding-bottom: var(--kendo-spacing-8, 2rem) !important;
}

.k-pb-9 {
  padding-bottom: var(--kendo-spacing-9, 2.25rem);
}

.\!k-pb-9 {
  padding-bottom: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-pb-10 {
  padding-bottom: var(--kendo-spacing-10, 2.5rem);
}

.\!k-pb-10 {
  padding-bottom: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-pb-11 {
  padding-bottom: var(--kendo-spacing-11, 2.75rem);
}

.\!k-pb-11 {
  padding-bottom: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-pb-12 {
  padding-bottom: var(--kendo-spacing-12, 3rem);
}

.\!k-pb-12 {
  padding-bottom: var(--kendo-spacing-12, 3rem) !important;
}

.k-pb-13 {
  padding-bottom: var(--kendo-spacing-13, 3.25rem);
}

.\!k-pb-13 {
  padding-bottom: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-pb-14 {
  padding-bottom: var(--kendo-spacing-14, 3.5rem);
}

.\!k-pb-14 {
  padding-bottom: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-pb-15 {
  padding-bottom: var(--kendo-spacing-15, 3.75rem);
}

.\!k-pb-15 {
  padding-bottom: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-pb-16 {
  padding-bottom: var(--kendo-spacing-16, 4rem);
}

.\!k-pb-16 {
  padding-bottom: var(--kendo-spacing-16, 4rem) !important;
}

.k-pb-17 {
  padding-bottom: var(--kendo-spacing-17, 4.25rem);
}

.\!k-pb-17 {
  padding-bottom: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-pb-18 {
  padding-bottom: var(--kendo-spacing-18, 4.5rem);
}

.\!k-pb-18 {
  padding-bottom: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-pb-19 {
  padding-bottom: var(--kendo-spacing-19, 4.75rem);
}

.\!k-pb-19 {
  padding-bottom: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-pb-20 {
  padding-bottom: var(--kendo-spacing-20, 5rem);
}

.\!k-pb-20 {
  padding-bottom: var(--kendo-spacing-20, 5rem) !important;
}

.k-pb-21 {
  padding-bottom: var(--kendo-spacing-21, 5.25rem);
}

.\!k-pb-21 {
  padding-bottom: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-pb-22 {
  padding-bottom: var(--kendo-spacing-22, 5.5rem);
}

.\!k-pb-22 {
  padding-bottom: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-pb-23 {
  padding-bottom: var(--kendo-spacing-23, 5.75rem);
}

.\!k-pb-23 {
  padding-bottom: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-pb-24 {
  padding-bottom: var(--kendo-spacing-24, 6rem);
}

.\!k-pb-24 {
  padding-bottom: var(--kendo-spacing-24, 6rem) !important;
}

.k-pb-25 {
  padding-bottom: var(--kendo-spacing-25, 7rem);
}

.\!k-pb-25 {
  padding-bottom: var(--kendo-spacing-25, 7rem) !important;
}

.k-pb-26 {
  padding-bottom: var(--kendo-spacing-26, 8rem);
}

.\!k-pb-26 {
  padding-bottom: var(--kendo-spacing-26, 8rem) !important;
}

.k-pb-27 {
  padding-bottom: var(--kendo-spacing-27, 9rem);
}

.\!k-pb-27 {
  padding-bottom: var(--kendo-spacing-27, 9rem) !important;
}

.k-pb-28 {
  padding-bottom: var(--kendo-spacing-28, 10rem);
}

.\!k-pb-28 {
  padding-bottom: var(--kendo-spacing-28, 10rem) !important;
}

.k-pb-29 {
  padding-bottom: var(--kendo-spacing-29, 11rem);
}

.\!k-pb-29 {
  padding-bottom: var(--kendo-spacing-29, 11rem) !important;
}

.k-pb-30 {
  padding-bottom: var(--kendo-spacing-30, 12rem);
}

.\!k-pb-30 {
  padding-bottom: var(--kendo-spacing-30, 12rem) !important;
}

.k-pb-xs {
  padding-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-pb-xs {
  padding-bottom: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-pb-sm {
  padding-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-pb-sm {
  padding-bottom: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-pb-md {
  padding-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-pb-md {
  padding-bottom: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-pb-lg {
  padding-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-pb-lg {
  padding-bottom: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-pb-xl {
  padding-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-pb-xl {
  padding-bottom: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-pb-thin {
  padding-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-pb-thin {
  padding-bottom: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-pb-hair {
  padding-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-pb-hair {
  padding-bottom: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-pl-0 {
  padding-left: var(--kendo-spacing-0, 0px);
}

.\!k-pl-0 {
  padding-left: var(--kendo-spacing-0, 0px) !important;
}

.k-pl-1px {
  padding-left: var(--kendo-spacing-1px, 1px);
}

.\!k-pl-1px {
  padding-left: var(--kendo-spacing-1px, 1px) !important;
}

.k-pl-0\.5 {
  padding-left: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-pl-0\.5 {
  padding-left: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-pl-1 {
  padding-left: var(--kendo-spacing-1, 0.25rem);
}

.\!k-pl-1 {
  padding-left: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-pl-1\.5 {
  padding-left: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-pl-1\.5 {
  padding-left: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-pl-2 {
  padding-left: var(--kendo-spacing-2, 0.5rem);
}

.\!k-pl-2 {
  padding-left: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-pl-2\.5 {
  padding-left: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-pl-2\.5 {
  padding-left: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-pl-3 {
  padding-left: var(--kendo-spacing-3, 0.75rem);
}

.\!k-pl-3 {
  padding-left: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-pl-3\.5 {
  padding-left: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-pl-3\.5 {
  padding-left: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-pl-4 {
  padding-left: var(--kendo-spacing-4, 1rem);
}

.\!k-pl-4 {
  padding-left: var(--kendo-spacing-4, 1rem) !important;
}

.k-pl-4\.5 {
  padding-left: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-pl-4\.5 {
  padding-left: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-pl-5 {
  padding-left: var(--kendo-spacing-5, 1.25rem);
}

.\!k-pl-5 {
  padding-left: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-pl-5\.5 {
  padding-left: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-pl-5\.5 {
  padding-left: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-pl-6 {
  padding-left: var(--kendo-spacing-6, 1.5rem);
}

.\!k-pl-6 {
  padding-left: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-pl-6\.5 {
  padding-left: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-pl-6\.5 {
  padding-left: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-pl-7 {
  padding-left: var(--kendo-spacing-7, 1.75rem);
}

.\!k-pl-7 {
  padding-left: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-pl-7\.5 {
  padding-left: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-pl-7\.5 {
  padding-left: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-pl-8 {
  padding-left: var(--kendo-spacing-8, 2rem);
}

.\!k-pl-8 {
  padding-left: var(--kendo-spacing-8, 2rem) !important;
}

.k-pl-9 {
  padding-left: var(--kendo-spacing-9, 2.25rem);
}

.\!k-pl-9 {
  padding-left: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-pl-10 {
  padding-left: var(--kendo-spacing-10, 2.5rem);
}

.\!k-pl-10 {
  padding-left: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-pl-11 {
  padding-left: var(--kendo-spacing-11, 2.75rem);
}

.\!k-pl-11 {
  padding-left: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-pl-12 {
  padding-left: var(--kendo-spacing-12, 3rem);
}

.\!k-pl-12 {
  padding-left: var(--kendo-spacing-12, 3rem) !important;
}

.k-pl-13 {
  padding-left: var(--kendo-spacing-13, 3.25rem);
}

.\!k-pl-13 {
  padding-left: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-pl-14 {
  padding-left: var(--kendo-spacing-14, 3.5rem);
}

.\!k-pl-14 {
  padding-left: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-pl-15 {
  padding-left: var(--kendo-spacing-15, 3.75rem);
}

.\!k-pl-15 {
  padding-left: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-pl-16 {
  padding-left: var(--kendo-spacing-16, 4rem);
}

.\!k-pl-16 {
  padding-left: var(--kendo-spacing-16, 4rem) !important;
}

.k-pl-17 {
  padding-left: var(--kendo-spacing-17, 4.25rem);
}

.\!k-pl-17 {
  padding-left: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-pl-18 {
  padding-left: var(--kendo-spacing-18, 4.5rem);
}

.\!k-pl-18 {
  padding-left: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-pl-19 {
  padding-left: var(--kendo-spacing-19, 4.75rem);
}

.\!k-pl-19 {
  padding-left: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-pl-20 {
  padding-left: var(--kendo-spacing-20, 5rem);
}

.\!k-pl-20 {
  padding-left: var(--kendo-spacing-20, 5rem) !important;
}

.k-pl-21 {
  padding-left: var(--kendo-spacing-21, 5.25rem);
}

.\!k-pl-21 {
  padding-left: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-pl-22 {
  padding-left: var(--kendo-spacing-22, 5.5rem);
}

.\!k-pl-22 {
  padding-left: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-pl-23 {
  padding-left: var(--kendo-spacing-23, 5.75rem);
}

.\!k-pl-23 {
  padding-left: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-pl-24 {
  padding-left: var(--kendo-spacing-24, 6rem);
}

.\!k-pl-24 {
  padding-left: var(--kendo-spacing-24, 6rem) !important;
}

.k-pl-25 {
  padding-left: var(--kendo-spacing-25, 7rem);
}

.\!k-pl-25 {
  padding-left: var(--kendo-spacing-25, 7rem) !important;
}

.k-pl-26 {
  padding-left: var(--kendo-spacing-26, 8rem);
}

.\!k-pl-26 {
  padding-left: var(--kendo-spacing-26, 8rem) !important;
}

.k-pl-27 {
  padding-left: var(--kendo-spacing-27, 9rem);
}

.\!k-pl-27 {
  padding-left: var(--kendo-spacing-27, 9rem) !important;
}

.k-pl-28 {
  padding-left: var(--kendo-spacing-28, 10rem);
}

.\!k-pl-28 {
  padding-left: var(--kendo-spacing-28, 10rem) !important;
}

.k-pl-29 {
  padding-left: var(--kendo-spacing-29, 11rem);
}

.\!k-pl-29 {
  padding-left: var(--kendo-spacing-29, 11rem) !important;
}

.k-pl-30 {
  padding-left: var(--kendo-spacing-30, 12rem);
}

.\!k-pl-30 {
  padding-left: var(--kendo-spacing-30, 12rem) !important;
}

.k-pl-xs {
  padding-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-pl-xs {
  padding-left: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-pl-sm {
  padding-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-pl-sm {
  padding-left: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-pl-md {
  padding-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-pl-md {
  padding-left: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-pl-lg {
  padding-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-pl-lg {
  padding-left: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-pl-xl {
  padding-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-pl-xl {
  padding-left: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-pl-thin {
  padding-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-pl-thin {
  padding-left: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-pl-hair {
  padding-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-pl-hair {
  padding-left: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-px-0 {
  padding-inline: var(--kendo-spacing-0, 0px);
}

.\!k-px-0 {
  padding-inline: var(--kendo-spacing-0, 0px) !important;
}

.k-px-1px {
  padding-inline: var(--kendo-spacing-1px, 1px);
}

.\!k-px-1px {
  padding-inline: var(--kendo-spacing-1px, 1px) !important;
}

.k-px-0\.5 {
  padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-px-0\.5 {
  padding-inline: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-px-1 {
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.\!k-px-1 {
  padding-inline: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-px-1\.5 {
  padding-inline: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-px-1\.5 {
  padding-inline: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-px-2 {
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.\!k-px-2 {
  padding-inline: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-px-2\.5 {
  padding-inline: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-px-2\.5 {
  padding-inline: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-px-3 {
  padding-inline: var(--kendo-spacing-3, 0.75rem);
}

.\!k-px-3 {
  padding-inline: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-px-3\.5 {
  padding-inline: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-px-3\.5 {
  padding-inline: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-px-4 {
  padding-inline: var(--kendo-spacing-4, 1rem);
}

.\!k-px-4 {
  padding-inline: var(--kendo-spacing-4, 1rem) !important;
}

.k-px-4\.5 {
  padding-inline: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-px-4\.5 {
  padding-inline: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-px-5 {
  padding-inline: var(--kendo-spacing-5, 1.25rem);
}

.\!k-px-5 {
  padding-inline: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-px-5\.5 {
  padding-inline: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-px-5\.5 {
  padding-inline: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-px-6 {
  padding-inline: var(--kendo-spacing-6, 1.5rem);
}

.\!k-px-6 {
  padding-inline: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-px-6\.5 {
  padding-inline: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-px-6\.5 {
  padding-inline: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-px-7 {
  padding-inline: var(--kendo-spacing-7, 1.75rem);
}

.\!k-px-7 {
  padding-inline: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-px-7\.5 {
  padding-inline: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-px-7\.5 {
  padding-inline: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-px-8 {
  padding-inline: var(--kendo-spacing-8, 2rem);
}

.\!k-px-8 {
  padding-inline: var(--kendo-spacing-8, 2rem) !important;
}

.k-px-9 {
  padding-inline: var(--kendo-spacing-9, 2.25rem);
}

.\!k-px-9 {
  padding-inline: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-px-10 {
  padding-inline: var(--kendo-spacing-10, 2.5rem);
}

.\!k-px-10 {
  padding-inline: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-px-11 {
  padding-inline: var(--kendo-spacing-11, 2.75rem);
}

.\!k-px-11 {
  padding-inline: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-px-12 {
  padding-inline: var(--kendo-spacing-12, 3rem);
}

.\!k-px-12 {
  padding-inline: var(--kendo-spacing-12, 3rem) !important;
}

.k-px-13 {
  padding-inline: var(--kendo-spacing-13, 3.25rem);
}

.\!k-px-13 {
  padding-inline: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-px-14 {
  padding-inline: var(--kendo-spacing-14, 3.5rem);
}

.\!k-px-14 {
  padding-inline: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-px-15 {
  padding-inline: var(--kendo-spacing-15, 3.75rem);
}

.\!k-px-15 {
  padding-inline: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-px-16 {
  padding-inline: var(--kendo-spacing-16, 4rem);
}

.\!k-px-16 {
  padding-inline: var(--kendo-spacing-16, 4rem) !important;
}

.k-px-17 {
  padding-inline: var(--kendo-spacing-17, 4.25rem);
}

.\!k-px-17 {
  padding-inline: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-px-18 {
  padding-inline: var(--kendo-spacing-18, 4.5rem);
}

.\!k-px-18 {
  padding-inline: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-px-19 {
  padding-inline: var(--kendo-spacing-19, 4.75rem);
}

.\!k-px-19 {
  padding-inline: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-px-20 {
  padding-inline: var(--kendo-spacing-20, 5rem);
}

.\!k-px-20 {
  padding-inline: var(--kendo-spacing-20, 5rem) !important;
}

.k-px-21 {
  padding-inline: var(--kendo-spacing-21, 5.25rem);
}

.\!k-px-21 {
  padding-inline: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-px-22 {
  padding-inline: var(--kendo-spacing-22, 5.5rem);
}

.\!k-px-22 {
  padding-inline: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-px-23 {
  padding-inline: var(--kendo-spacing-23, 5.75rem);
}

.\!k-px-23 {
  padding-inline: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-px-24 {
  padding-inline: var(--kendo-spacing-24, 6rem);
}

.\!k-px-24 {
  padding-inline: var(--kendo-spacing-24, 6rem) !important;
}

.k-px-25 {
  padding-inline: var(--kendo-spacing-25, 7rem);
}

.\!k-px-25 {
  padding-inline: var(--kendo-spacing-25, 7rem) !important;
}

.k-px-26 {
  padding-inline: var(--kendo-spacing-26, 8rem);
}

.\!k-px-26 {
  padding-inline: var(--kendo-spacing-26, 8rem) !important;
}

.k-px-27 {
  padding-inline: var(--kendo-spacing-27, 9rem);
}

.\!k-px-27 {
  padding-inline: var(--kendo-spacing-27, 9rem) !important;
}

.k-px-28 {
  padding-inline: var(--kendo-spacing-28, 10rem);
}

.\!k-px-28 {
  padding-inline: var(--kendo-spacing-28, 10rem) !important;
}

.k-px-29 {
  padding-inline: var(--kendo-spacing-29, 11rem);
}

.\!k-px-29 {
  padding-inline: var(--kendo-spacing-29, 11rem) !important;
}

.k-px-30 {
  padding-inline: var(--kendo-spacing-30, 12rem);
}

.\!k-px-30 {
  padding-inline: var(--kendo-spacing-30, 12rem) !important;
}

.k-px-xs {
  padding-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-px-xs {
  padding-inline: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-px-sm {
  padding-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-px-sm {
  padding-inline: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-px-md {
  padding-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-px-md {
  padding-inline: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-px-lg {
  padding-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-px-lg {
  padding-inline: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-px-xl {
  padding-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-px-xl {
  padding-inline: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-px-thin {
  padding-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-px-thin {
  padding-inline: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-px-hair {
  padding-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-px-hair {
  padding-inline: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-py-0 {
  padding-block: var(--kendo-spacing-0, 0px);
}

.\!k-py-0 {
  padding-block: var(--kendo-spacing-0, 0px) !important;
}

.k-py-1px {
  padding-block: var(--kendo-spacing-1px, 1px);
}

.\!k-py-1px {
  padding-block: var(--kendo-spacing-1px, 1px) !important;
}

.k-py-0\.5 {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
}

.\!k-py-0\.5 {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem) !important;
}

.k-py-1 {
  padding-block: var(--kendo-spacing-1, 0.25rem);
}

.\!k-py-1 {
  padding-block: var(--kendo-spacing-1, 0.25rem) !important;
}

.k-py-1\.5 {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
}

.\!k-py-1\.5 {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem) !important;
}

.k-py-2 {
  padding-block: var(--kendo-spacing-2, 0.5rem);
}

.\!k-py-2 {
  padding-block: var(--kendo-spacing-2, 0.5rem) !important;
}

.k-py-2\.5 {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
}

.\!k-py-2\.5 {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem) !important;
}

.k-py-3 {
  padding-block: var(--kendo-spacing-3, 0.75rem);
}

.\!k-py-3 {
  padding-block: var(--kendo-spacing-3, 0.75rem) !important;
}

.k-py-3\.5 {
  padding-block: var(--kendo-spacing-3\.5, 0.875rem);
}

.\!k-py-3\.5 {
  padding-block: var(--kendo-spacing-3\.5, 0.875rem) !important;
}

.k-py-4 {
  padding-block: var(--kendo-spacing-4, 1rem);
}

.\!k-py-4 {
  padding-block: var(--kendo-spacing-4, 1rem) !important;
}

.k-py-4\.5 {
  padding-block: var(--kendo-spacing-4\.5, 1.125rem);
}

.\!k-py-4\.5 {
  padding-block: var(--kendo-spacing-4\.5, 1.125rem) !important;
}

.k-py-5 {
  padding-block: var(--kendo-spacing-5, 1.25rem);
}

.\!k-py-5 {
  padding-block: var(--kendo-spacing-5, 1.25rem) !important;
}

.k-py-5\.5 {
  padding-block: var(--kendo-spacing-5\.5, 1.375rem);
}

.\!k-py-5\.5 {
  padding-block: var(--kendo-spacing-5\.5, 1.375rem) !important;
}

.k-py-6 {
  padding-block: var(--kendo-spacing-6, 1.5rem);
}

.\!k-py-6 {
  padding-block: var(--kendo-spacing-6, 1.5rem) !important;
}

.k-py-6\.5 {
  padding-block: var(--kendo-spacing-6\.5, 1.625rem);
}

.\!k-py-6\.5 {
  padding-block: var(--kendo-spacing-6\.5, 1.625rem) !important;
}

.k-py-7 {
  padding-block: var(--kendo-spacing-7, 1.75rem);
}

.\!k-py-7 {
  padding-block: var(--kendo-spacing-7, 1.75rem) !important;
}

.k-py-7\.5 {
  padding-block: var(--kendo-spacing-7\.5, 1.875rem);
}

.\!k-py-7\.5 {
  padding-block: var(--kendo-spacing-7\.5, 1.875rem) !important;
}

.k-py-8 {
  padding-block: var(--kendo-spacing-8, 2rem);
}

.\!k-py-8 {
  padding-block: var(--kendo-spacing-8, 2rem) !important;
}

.k-py-9 {
  padding-block: var(--kendo-spacing-9, 2.25rem);
}

.\!k-py-9 {
  padding-block: var(--kendo-spacing-9, 2.25rem) !important;
}

.k-py-10 {
  padding-block: var(--kendo-spacing-10, 2.5rem);
}

.\!k-py-10 {
  padding-block: var(--kendo-spacing-10, 2.5rem) !important;
}

.k-py-11 {
  padding-block: var(--kendo-spacing-11, 2.75rem);
}

.\!k-py-11 {
  padding-block: var(--kendo-spacing-11, 2.75rem) !important;
}

.k-py-12 {
  padding-block: var(--kendo-spacing-12, 3rem);
}

.\!k-py-12 {
  padding-block: var(--kendo-spacing-12, 3rem) !important;
}

.k-py-13 {
  padding-block: var(--kendo-spacing-13, 3.25rem);
}

.\!k-py-13 {
  padding-block: var(--kendo-spacing-13, 3.25rem) !important;
}

.k-py-14 {
  padding-block: var(--kendo-spacing-14, 3.5rem);
}

.\!k-py-14 {
  padding-block: var(--kendo-spacing-14, 3.5rem) !important;
}

.k-py-15 {
  padding-block: var(--kendo-spacing-15, 3.75rem);
}

.\!k-py-15 {
  padding-block: var(--kendo-spacing-15, 3.75rem) !important;
}

.k-py-16 {
  padding-block: var(--kendo-spacing-16, 4rem);
}

.\!k-py-16 {
  padding-block: var(--kendo-spacing-16, 4rem) !important;
}

.k-py-17 {
  padding-block: var(--kendo-spacing-17, 4.25rem);
}

.\!k-py-17 {
  padding-block: var(--kendo-spacing-17, 4.25rem) !important;
}

.k-py-18 {
  padding-block: var(--kendo-spacing-18, 4.5rem);
}

.\!k-py-18 {
  padding-block: var(--kendo-spacing-18, 4.5rem) !important;
}

.k-py-19 {
  padding-block: var(--kendo-spacing-19, 4.75rem);
}

.\!k-py-19 {
  padding-block: var(--kendo-spacing-19, 4.75rem) !important;
}

.k-py-20 {
  padding-block: var(--kendo-spacing-20, 5rem);
}

.\!k-py-20 {
  padding-block: var(--kendo-spacing-20, 5rem) !important;
}

.k-py-21 {
  padding-block: var(--kendo-spacing-21, 5.25rem);
}

.\!k-py-21 {
  padding-block: var(--kendo-spacing-21, 5.25rem) !important;
}

.k-py-22 {
  padding-block: var(--kendo-spacing-22, 5.5rem);
}

.\!k-py-22 {
  padding-block: var(--kendo-spacing-22, 5.5rem) !important;
}

.k-py-23 {
  padding-block: var(--kendo-spacing-23, 5.75rem);
}

.\!k-py-23 {
  padding-block: var(--kendo-spacing-23, 5.75rem) !important;
}

.k-py-24 {
  padding-block: var(--kendo-spacing-24, 6rem);
}

.\!k-py-24 {
  padding-block: var(--kendo-spacing-24, 6rem) !important;
}

.k-py-25 {
  padding-block: var(--kendo-spacing-25, 7rem);
}

.\!k-py-25 {
  padding-block: var(--kendo-spacing-25, 7rem) !important;
}

.k-py-26 {
  padding-block: var(--kendo-spacing-26, 8rem);
}

.\!k-py-26 {
  padding-block: var(--kendo-spacing-26, 8rem) !important;
}

.k-py-27 {
  padding-block: var(--kendo-spacing-27, 9rem);
}

.\!k-py-27 {
  padding-block: var(--kendo-spacing-27, 9rem) !important;
}

.k-py-28 {
  padding-block: var(--kendo-spacing-28, 10rem);
}

.\!k-py-28 {
  padding-block: var(--kendo-spacing-28, 10rem) !important;
}

.k-py-29 {
  padding-block: var(--kendo-spacing-29, 11rem);
}

.\!k-py-29 {
  padding-block: var(--kendo-spacing-29, 11rem) !important;
}

.k-py-30 {
  padding-block: var(--kendo-spacing-30, 12rem);
}

.\!k-py-30 {
  padding-block: var(--kendo-spacing-30, 12rem) !important;
}

.k-py-xs {
  padding-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem));
}

.\!k-py-xs {
  padding-block: var(--kendo-spacing-xs, var(--kendo-spacing-1, 0.25rem)) !important;
}

.k-py-sm {
  padding-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem));
}

.\!k-py-sm {
  padding-block: var(--kendo-spacing-sm, var(--kendo-spacing-2, 0.5rem)) !important;
}

.k-py-md {
  padding-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem));
}

.\!k-py-md {
  padding-block: var(--kendo-spacing-md, var(--kendo-spacing-3, 0.75rem)) !important;
}

.k-py-lg {
  padding-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem));
}

.\!k-py-lg {
  padding-block: var(--kendo-spacing-lg, var(--kendo-spacing-4, 1rem)) !important;
}

.k-py-xl {
  padding-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem));
}

.\!k-py-xl {
  padding-block: var(--kendo-spacing-xl, var(--kendo-spacing-6, 1.5rem)) !important;
}

.k-py-thin {
  padding-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem));
}

.\!k-py-thin {
  padding-block: var(--kendo-spacing-thin, var(--kendo-spacing-0\.5, 0.125rem)) !important;
}

.k-py-hair {
  padding-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px));
}

.\!k-py-hair {
  padding-block: var(--kendo-spacing-hair, var(--kendo-spacing-1px, 1px)) !important;
}

.k-h-0 {
  height: 0px;
}

.\!k-h-0 {
  height: 0px !important;
}

.k-h-1px {
  height: 1px;
}

.\!k-h-1px {
  height: 1px !important;
}

.k-h-0\.5 {
  height: 0.125rem;
}

.\!k-h-0\.5 {
  height: 0.125rem !important;
}

.k-h-1 {
  height: 0.25rem;
}

.\!k-h-1 {
  height: 0.25rem !important;
}

.k-h-1\.5 {
  height: 0.375rem;
}

.\!k-h-1\.5 {
  height: 0.375rem !important;
}

.k-h-2 {
  height: 0.5rem;
}

.\!k-h-2 {
  height: 0.5rem !important;
}

.k-h-2\.5 {
  height: 0.625rem;
}

.\!k-h-2\.5 {
  height: 0.625rem !important;
}

.k-h-3 {
  height: 0.75rem;
}

.\!k-h-3 {
  height: 0.75rem !important;
}

.k-h-3\.5 {
  height: 0.875rem;
}

.\!k-h-3\.5 {
  height: 0.875rem !important;
}

.k-h-4 {
  height: 1rem;
}

.\!k-h-4 {
  height: 1rem !important;
}

.k-h-4\.5 {
  height: 1.125rem;
}

.\!k-h-4\.5 {
  height: 1.125rem !important;
}

.k-h-5 {
  height: 1.25rem;
}

.\!k-h-5 {
  height: 1.25rem !important;
}

.k-h-5\.5 {
  height: 1.375rem;
}

.\!k-h-5\.5 {
  height: 1.375rem !important;
}

.k-h-6 {
  height: 1.5rem;
}

.\!k-h-6 {
  height: 1.5rem !important;
}

.k-h-6\.5 {
  height: 1.625rem;
}

.\!k-h-6\.5 {
  height: 1.625rem !important;
}

.k-h-7 {
  height: 1.75rem;
}

.\!k-h-7 {
  height: 1.75rem !important;
}

.k-h-7\.5 {
  height: 1.875rem;
}

.\!k-h-7\.5 {
  height: 1.875rem !important;
}

.k-h-8 {
  height: 2rem;
}

.\!k-h-8 {
  height: 2rem !important;
}

.k-h-9 {
  height: 2.25rem;
}

.\!k-h-9 {
  height: 2.25rem !important;
}

.k-h-10 {
  height: 2.5rem;
}

.\!k-h-10 {
  height: 2.5rem !important;
}

.k-h-11 {
  height: 2.75rem;
}

.\!k-h-11 {
  height: 2.75rem !important;
}

.k-h-12 {
  height: 3rem;
}

.\!k-h-12 {
  height: 3rem !important;
}

.k-h-13 {
  height: 3.25rem;
}

.\!k-h-13 {
  height: 3.25rem !important;
}

.k-h-14 {
  height: 3.5rem;
}

.\!k-h-14 {
  height: 3.5rem !important;
}

.k-h-15 {
  height: 3.75rem;
}

.\!k-h-15 {
  height: 3.75rem !important;
}

.k-h-16 {
  height: 4rem;
}

.\!k-h-16 {
  height: 4rem !important;
}

.k-h-17 {
  height: 4.25rem;
}

.\!k-h-17 {
  height: 4.25rem !important;
}

.k-h-18 {
  height: 4.5rem;
}

.\!k-h-18 {
  height: 4.5rem !important;
}

.k-h-19 {
  height: 4.75rem;
}

.\!k-h-19 {
  height: 4.75rem !important;
}

.k-h-20 {
  height: 5rem;
}

.\!k-h-20 {
  height: 5rem !important;
}

.k-h-21 {
  height: 5.25rem;
}

.\!k-h-21 {
  height: 5.25rem !important;
}

.k-h-22 {
  height: 5.5rem;
}

.\!k-h-22 {
  height: 5.5rem !important;
}

.k-h-23 {
  height: 5.75rem;
}

.\!k-h-23 {
  height: 5.75rem !important;
}

.k-h-24 {
  height: 6rem;
}

.\!k-h-24 {
  height: 6rem !important;
}

.k-h-25 {
  height: 7rem;
}

.\!k-h-25 {
  height: 7rem !important;
}

.k-h-26 {
  height: 8rem;
}

.\!k-h-26 {
  height: 8rem !important;
}

.k-h-27 {
  height: 9rem;
}

.\!k-h-27 {
  height: 9rem !important;
}

.k-h-28 {
  height: 10rem;
}

.\!k-h-28 {
  height: 10rem !important;
}

.k-h-29 {
  height: 11rem;
}

.\!k-h-29 {
  height: 11rem !important;
}

.k-h-30 {
  height: 12rem;
}

.\!k-h-30 {
  height: 12rem !important;
}

.k-h-auto {
  height: auto;
}

.\!k-h-auto {
  height: auto !important;
}

.k-h-1\/2 {
  height: 50%;
}

.\!k-h-1\/2 {
  height: 50% !important;
}

.k-h-1\/3 {
  height: 33.333333%;
}

.\!k-h-1\/3 {
  height: 33.333333% !important;
}

.k-h-2\/3 {
  height: 66.666667%;
}

.\!k-h-2\/3 {
  height: 66.666667% !important;
}

.k-h-1\/4 {
  height: 25%;
}

.\!k-h-1\/4 {
  height: 25% !important;
}

.k-h-2\/4 {
  height: 50%;
}

.\!k-h-2\/4 {
  height: 50% !important;
}

.k-h-3\/4 {
  height: 75%;
}

.\!k-h-3\/4 {
  height: 75% !important;
}

.k-h-1\/5 {
  height: 20%;
}

.\!k-h-1\/5 {
  height: 20% !important;
}

.k-h-2\/5 {
  height: 40%;
}

.\!k-h-2\/5 {
  height: 40% !important;
}

.k-h-3\/5 {
  height: 60%;
}

.\!k-h-3\/5 {
  height: 60% !important;
}

.k-h-4\/5 {
  height: 80%;
}

.\!k-h-4\/5 {
  height: 80% !important;
}

.k-h-1\/6 {
  height: 16.666667%;
}

.\!k-h-1\/6 {
  height: 16.666667% !important;
}

.k-h-2\/6 {
  height: 33.333333%;
}

.\!k-h-2\/6 {
  height: 33.333333% !important;
}

.k-h-3\/6 {
  height: 50%;
}

.\!k-h-3\/6 {
  height: 50% !important;
}

.k-h-4\/6 {
  height: 66.666667%;
}

.\!k-h-4\/6 {
  height: 66.666667% !important;
}

.k-h-5\/6 {
  height: 83.333333%;
}

.\!k-h-5\/6 {
  height: 83.333333% !important;
}

.k-h-1\/12 {
  height: 8.333333%;
}

.\!k-h-1\/12 {
  height: 8.333333% !important;
}

.k-h-2\/12 {
  height: 16.666667%;
}

.\!k-h-2\/12 {
  height: 16.666667% !important;
}

.k-h-3\/12 {
  height: 25%;
}

.\!k-h-3\/12 {
  height: 25% !important;
}

.k-h-4\/12 {
  height: 33.333333%;
}

.\!k-h-4\/12 {
  height: 33.333333% !important;
}

.k-h-5\/12 {
  height: 41.666667%;
}

.\!k-h-5\/12 {
  height: 41.666667% !important;
}

.k-h-6\/12 {
  height: 50%;
}

.\!k-h-6\/12 {
  height: 50% !important;
}

.k-h-7\/12 {
  height: 58.333333%;
}

.\!k-h-7\/12 {
  height: 58.333333% !important;
}

.k-h-8\/12 {
  height: 66.666667%;
}

.\!k-h-8\/12 {
  height: 66.666667% !important;
}

.k-h-9\/12 {
  height: 75%;
}

.\!k-h-9\/12 {
  height: 75% !important;
}

.k-h-10\/12 {
  height: 83.333333%;
}

.\!k-h-10\/12 {
  height: 83.333333% !important;
}

.k-h-11\/12 {
  height: 91.666667%;
}

.\!k-h-11\/12 {
  height: 91.666667% !important;
}

.k-h-full {
  height: 100%;
}

.\!k-h-full {
  height: 100% !important;
}

.k-h-min {
  height: min-content;
}

.\!k-h-min {
  height: min-content !important;
}

.k-h-max {
  height: max-content;
}

.\!k-h-max {
  height: max-content !important;
}

.k-h-fit {
  height: fit-content;
}

.\!k-h-fit {
  height: fit-content !important;
}

.k-h-screen {
  height: 100vh;
}

.\!k-h-screen {
  height: 100vh !important;
}

.k-min-h-0 {
  min-height: 0;
}

.\!k-min-h-0 {
  min-height: 0 !important;
}

.k-min-h-full {
  min-height: 100%;
}

.\!k-min-h-full {
  min-height: 100% !important;
}

.k-min-h-screen {
  min-height: 100vh;
}

.\!k-min-h-screen {
  min-height: 100vh !important;
}

.k-min-h-min {
  min-height: min-content;
}

.\!k-min-h-min {
  min-height: min-content !important;
}

.k-min-h-max {
  min-height: max-content;
}

.\!k-min-h-max {
  min-height: max-content !important;
}

.k-min-h-fit {
  min-height: fit-content;
}

.\!k-min-h-fit {
  min-height: fit-content !important;
}

.k-max-h-none {
  max-height: none;
}

.\!k-max-h-none {
  max-height: none !important;
}

.k-max-h-0 {
  max-height: 0;
}

.\!k-max-h-0 {
  max-height: 0 !important;
}

.k-max-h-full {
  max-height: 100%;
}

.\!k-max-h-full {
  max-height: 100% !important;
}

.k-max-h-screen {
  max-height: 100vh;
}

.\!k-max-h-screen {
  max-height: 100vh !important;
}

.k-max-h-min {
  max-height: min-content;
}

.\!k-max-h-min {
  max-height: min-content !important;
}

.k-max-h-max {
  max-height: max-content;
}

.\!k-max-h-max {
  max-height: max-content !important;
}

.k-max-h-fit {
  max-height: fit-content;
}

.\!k-max-h-fit {
  max-height: fit-content !important;
}

.k-w-0 {
  width: 0px;
}

.\!k-w-0 {
  width: 0px !important;
}

.k-w-1px {
  width: 1px;
}

.\!k-w-1px {
  width: 1px !important;
}

.k-w-0\.5 {
  width: 0.125rem;
}

.\!k-w-0\.5 {
  width: 0.125rem !important;
}

.k-w-1 {
  width: 0.25rem;
}

.\!k-w-1 {
  width: 0.25rem !important;
}

.k-w-1\.5 {
  width: 0.375rem;
}

.\!k-w-1\.5 {
  width: 0.375rem !important;
}

.k-w-2 {
  width: 0.5rem;
}

.\!k-w-2 {
  width: 0.5rem !important;
}

.k-w-2\.5 {
  width: 0.625rem;
}

.\!k-w-2\.5 {
  width: 0.625rem !important;
}

.k-w-3 {
  width: 0.75rem;
}

.\!k-w-3 {
  width: 0.75rem !important;
}

.k-w-3\.5 {
  width: 0.875rem;
}

.\!k-w-3\.5 {
  width: 0.875rem !important;
}

.k-w-4 {
  width: 1rem;
}

.\!k-w-4 {
  width: 1rem !important;
}

.k-w-4\.5 {
  width: 1.125rem;
}

.\!k-w-4\.5 {
  width: 1.125rem !important;
}

.k-w-5 {
  width: 1.25rem;
}

.\!k-w-5 {
  width: 1.25rem !important;
}

.k-w-5\.5 {
  width: 1.375rem;
}

.\!k-w-5\.5 {
  width: 1.375rem !important;
}

.k-w-6 {
  width: 1.5rem;
}

.\!k-w-6 {
  width: 1.5rem !important;
}

.k-w-6\.5 {
  width: 1.625rem;
}

.\!k-w-6\.5 {
  width: 1.625rem !important;
}

.k-w-7 {
  width: 1.75rem;
}

.\!k-w-7 {
  width: 1.75rem !important;
}

.k-w-7\.5 {
  width: 1.875rem;
}

.\!k-w-7\.5 {
  width: 1.875rem !important;
}

.k-w-8 {
  width: 2rem;
}

.\!k-w-8 {
  width: 2rem !important;
}

.k-w-9 {
  width: 2.25rem;
}

.\!k-w-9 {
  width: 2.25rem !important;
}

.k-w-10 {
  width: 2.5rem;
}

.\!k-w-10 {
  width: 2.5rem !important;
}

.k-w-11 {
  width: 2.75rem;
}

.\!k-w-11 {
  width: 2.75rem !important;
}

.k-w-12 {
  width: 3rem;
}

.\!k-w-12 {
  width: 3rem !important;
}

.k-w-13 {
  width: 3.25rem;
}

.\!k-w-13 {
  width: 3.25rem !important;
}

.k-w-14 {
  width: 3.5rem;
}

.\!k-w-14 {
  width: 3.5rem !important;
}

.k-w-15 {
  width: 3.75rem;
}

.\!k-w-15 {
  width: 3.75rem !important;
}

.k-w-16 {
  width: 4rem;
}

.\!k-w-16 {
  width: 4rem !important;
}

.k-w-17 {
  width: 4.25rem;
}

.\!k-w-17 {
  width: 4.25rem !important;
}

.k-w-18 {
  width: 4.5rem;
}

.\!k-w-18 {
  width: 4.5rem !important;
}

.k-w-19 {
  width: 4.75rem;
}

.\!k-w-19 {
  width: 4.75rem !important;
}

.k-w-20 {
  width: 5rem;
}

.\!k-w-20 {
  width: 5rem !important;
}

.k-w-21 {
  width: 5.25rem;
}

.\!k-w-21 {
  width: 5.25rem !important;
}

.k-w-22 {
  width: 5.5rem;
}

.\!k-w-22 {
  width: 5.5rem !important;
}

.k-w-23 {
  width: 5.75rem;
}

.\!k-w-23 {
  width: 5.75rem !important;
}

.k-w-24 {
  width: 6rem;
}

.\!k-w-24 {
  width: 6rem !important;
}

.k-w-25 {
  width: 7rem;
}

.\!k-w-25 {
  width: 7rem !important;
}

.k-w-26 {
  width: 8rem;
}

.\!k-w-26 {
  width: 8rem !important;
}

.k-w-27 {
  width: 9rem;
}

.\!k-w-27 {
  width: 9rem !important;
}

.k-w-28 {
  width: 10rem;
}

.\!k-w-28 {
  width: 10rem !important;
}

.k-w-29 {
  width: 11rem;
}

.\!k-w-29 {
  width: 11rem !important;
}

.k-w-30 {
  width: 12rem;
}

.\!k-w-30 {
  width: 12rem !important;
}

.k-w-auto {
  width: auto;
}

.\!k-w-auto {
  width: auto !important;
}

.k-w-1\/2 {
  width: 50%;
}

.\!k-w-1\/2 {
  width: 50% !important;
}

.k-w-1\/3 {
  width: 33.333333%;
}

.\!k-w-1\/3 {
  width: 33.333333% !important;
}

.k-w-2\/3 {
  width: 66.666667%;
}

.\!k-w-2\/3 {
  width: 66.666667% !important;
}

.k-w-1\/4 {
  width: 25%;
}

.\!k-w-1\/4 {
  width: 25% !important;
}

.k-w-2\/4 {
  width: 50%;
}

.\!k-w-2\/4 {
  width: 50% !important;
}

.k-w-3\/4 {
  width: 75%;
}

.\!k-w-3\/4 {
  width: 75% !important;
}

.k-w-1\/5 {
  width: 20%;
}

.\!k-w-1\/5 {
  width: 20% !important;
}

.k-w-2\/5 {
  width: 40%;
}

.\!k-w-2\/5 {
  width: 40% !important;
}

.k-w-3\/5 {
  width: 60%;
}

.\!k-w-3\/5 {
  width: 60% !important;
}

.k-w-4\/5 {
  width: 80%;
}

.\!k-w-4\/5 {
  width: 80% !important;
}

.k-w-1\/6 {
  width: 16.666667%;
}

.\!k-w-1\/6 {
  width: 16.666667% !important;
}

.k-w-2\/6 {
  width: 33.333333%;
}

.\!k-w-2\/6 {
  width: 33.333333% !important;
}

.k-w-3\/6 {
  width: 50%;
}

.\!k-w-3\/6 {
  width: 50% !important;
}

.k-w-4\/6 {
  width: 66.666667%;
}

.\!k-w-4\/6 {
  width: 66.666667% !important;
}

.k-w-5\/6 {
  width: 83.333333%;
}

.\!k-w-5\/6 {
  width: 83.333333% !important;
}

.k-w-1\/12 {
  width: 8.333333%;
}

.\!k-w-1\/12 {
  width: 8.333333% !important;
}

.k-w-2\/12 {
  width: 16.666667%;
}

.\!k-w-2\/12 {
  width: 16.666667% !important;
}

.k-w-3\/12 {
  width: 25%;
}

.\!k-w-3\/12 {
  width: 25% !important;
}

.k-w-4\/12 {
  width: 33.333333%;
}

.\!k-w-4\/12 {
  width: 33.333333% !important;
}

.k-w-5\/12 {
  width: 41.666667%;
}

.\!k-w-5\/12 {
  width: 41.666667% !important;
}

.k-w-6\/12 {
  width: 50%;
}

.\!k-w-6\/12 {
  width: 50% !important;
}

.k-w-7\/12 {
  width: 58.333333%;
}

.\!k-w-7\/12 {
  width: 58.333333% !important;
}

.k-w-8\/12 {
  width: 66.666667%;
}

.\!k-w-8\/12 {
  width: 66.666667% !important;
}

.k-w-9\/12 {
  width: 75%;
}

.\!k-w-9\/12 {
  width: 75% !important;
}

.k-w-10\/12 {
  width: 83.333333%;
}

.\!k-w-10\/12 {
  width: 83.333333% !important;
}

.k-w-11\/12 {
  width: 91.666667%;
}

.\!k-w-11\/12 {
  width: 91.666667% !important;
}

.k-w-full {
  width: 100%;
}

.\!k-w-full {
  width: 100% !important;
}

.k-w-min {
  width: min-content;
}

.\!k-w-min {
  width: min-content !important;
}

.k-w-max {
  width: max-content;
}

.\!k-w-max {
  width: max-content !important;
}

.k-w-fit {
  width: fit-content;
}

.\!k-w-fit {
  width: fit-content !important;
}

.k-w-screen {
  width: 100vw;
}

.\!k-w-screen {
  width: 100vw !important;
}

.k-min-w-0 {
  min-width: 0;
}

.\!k-min-w-0 {
  min-width: 0 !important;
}

.k-min-w-full {
  min-width: 100%;
}

.\!k-min-w-full {
  min-width: 100% !important;
}

.k-min-w-screen {
  min-width: 100vw;
}

.\!k-min-w-screen {
  min-width: 100vw !important;
}

.k-min-w-min {
  min-width: min-content;
}

.\!k-min-w-min {
  min-width: min-content !important;
}

.k-min-w-max {
  min-width: max-content;
}

.\!k-min-w-max {
  min-width: max-content !important;
}

.k-min-w-fit {
  min-width: fit-content;
}

.\!k-min-w-fit {
  min-width: fit-content !important;
}

.k-max-w-none {
  max-width: none;
}

.\!k-max-w-none {
  max-width: none !important;
}

.k-max-w-0 {
  max-width: 0;
}

.\!k-max-w-0 {
  max-width: 0 !important;
}

.k-max-w-full {
  max-width: 100%;
}

.\!k-max-w-full {
  max-width: 100% !important;
}

.k-max-w-screen {
  max-width: 100vw;
}

.\!k-max-w-screen {
  max-width: 100vw !important;
}

.k-max-w-min {
  max-width: min-content;
}

.\!k-max-w-min {
  max-width: min-content !important;
}

.k-max-w-max {
  max-width: max-content;
}

.\!k-max-w-max {
  max-width: max-content !important;
}

.k-max-w-fit {
  max-width: fit-content;
}

.\!k-max-w-fit {
  max-width: fit-content !important;
}

.k-font-family-sans {
  font-family: var(--kendo-font-family-sans, Arial, Verdana, Tahoma, Trebuchet MS, Helvetica, Impact, Gill Sans);
}

.\!k-font-family-sans {
  font-family: var(--kendo-font-family-sans, Arial, Verdana, Tahoma, Trebuchet MS, Helvetica, Impact, Gill Sans) !important;
}

.k-font-family-serif {
  font-family: var(--kendo-font-family-serif, Times New Roman, Georgia, Garamond, Palatino, Baskerville);
}

.\!k-font-family-serif {
  font-family: var(--kendo-font-family-serif, Times New Roman, Georgia, Garamond, Palatino, Baskerville) !important;
}

.k-font-family-sans-serif {
  font-family: var(--kendo-font-family-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji);
}

.\!k-font-family-sans-serif {
  font-family: var(--kendo-font-family-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji) !important;
}

.k-font-family-monospace {
  font-family: var(--kendo-font-family-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Roboto Mono, Ubuntu Mono, Lucida Console, Courier New, monospace);
}

.\!k-font-family-monospace {
  font-family: var(--kendo-font-family-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Roboto Mono, Ubuntu Mono, Lucida Console, Courier New, monospace) !important;
}

.k-font-size-xxs {
  font-size: var(--kendo-font-size-xxs, 0.5rem);
}

.\!k-font-size-xxs {
  font-size: var(--kendo-font-size-xxs, 0.5rem) !important;
}

.k-font-size-xs {
  font-size: var(--kendo-font-size-xs, 0.625rem);
}

.\!k-font-size-xs {
  font-size: var(--kendo-font-size-xs, 0.625rem) !important;
}

.k-font-size-sm {
  font-size: var(--kendo-font-size-sm, 0.75rem);
}

.\!k-font-size-sm {
  font-size: var(--kendo-font-size-sm, 0.75rem) !important;
}

.k-font-size-md {
  font-size: var(--kendo-font-size-md, 0.875rem);
}

.\!k-font-size-md {
  font-size: var(--kendo-font-size-md, 0.875rem) !important;
}

.k-font-size-lg {
  font-size: var(--kendo-font-size-lg, 1rem);
}

.\!k-font-size-lg {
  font-size: var(--kendo-font-size-lg, 1rem) !important;
}

.k-font-size-xl {
  font-size: var(--kendo-font-size-xl, 1.25rem);
}

.\!k-font-size-xl {
  font-size: var(--kendo-font-size-xl, 1.25rem) !important;
}

.k-fs-xxs {
  font-size: var(--kendo-fs-xxs, 0.5rem);
}

.\!k-fs-xxs {
  font-size: var(--kendo-fs-xxs, 0.5rem) !important;
}

.k-fs-xs {
  font-size: var(--kendo-fs-xs, 0.625rem);
}

.\!k-fs-xs {
  font-size: var(--kendo-fs-xs, 0.625rem) !important;
}

.k-fs-sm {
  font-size: var(--kendo-fs-sm, 0.75rem);
}

.\!k-fs-sm {
  font-size: var(--kendo-fs-sm, 0.75rem) !important;
}

.k-fs-md {
  font-size: var(--kendo-fs-md, 0.875rem);
}

.\!k-fs-md {
  font-size: var(--kendo-fs-md, 0.875rem) !important;
}

.k-fs-lg {
  font-size: var(--kendo-fs-lg, 1rem);
}

.\!k-fs-lg {
  font-size: var(--kendo-fs-lg, 1rem) !important;
}

.k-fs-xl {
  font-size: var(--kendo-fs-xl, 1.25rem);
}

.\!k-fs-xl {
  font-size: var(--kendo-fs-xl, 1.25rem) !important;
}

.k-font-style-italic {
  font-style: var(--kendo-font-style-italic, italic);
}

.\!k-font-style-italic {
  font-style: var(--kendo-font-style-italic, italic) !important;
}

.k-font-style-non-italic {
  font-style: var(--kendo-font-style-non-italic, normal);
}

.\!k-font-style-non-italic {
  font-style: var(--kendo-font-style-non-italic, normal) !important;
}

.k-font-style-oblique {
  font-style: var(--kendo-font-style-oblique, oblique);
}

.\!k-font-style-oblique {
  font-style: var(--kendo-font-style-oblique, oblique) !important;
}

.k-font-thin {
  font-weight: var(--kendo-font-weight-thin, 100);
}

.\!k-font-thin {
  font-weight: var(--kendo-font-weight-thin, 100) !important;
}

.k-font-extra-light {
  font-weight: var(--kendo-font-weight-extra-light, 200);
}

.\!k-font-extra-light {
  font-weight: var(--kendo-font-weight-extra-light, 200) !important;
}

.k-font-light {
  font-weight: var(--kendo-font-weight-light, 300);
}

.\!k-font-light {
  font-weight: var(--kendo-font-weight-light, 300) !important;
}

.k-font-normal {
  font-weight: var(--kendo-font-weight-normal, 400);
}

.\!k-font-normal {
  font-weight: var(--kendo-font-weight-normal, 400) !important;
}

.k-font-medium {
  font-weight: var(--kendo-font-weight-medium, 500);
}

.\!k-font-medium {
  font-weight: var(--kendo-font-weight-medium, 500) !important;
}

.k-font-semibold {
  font-weight: var(--kendo-font-weight-semibold, 600);
}

.\!k-font-semibold {
  font-weight: var(--kendo-font-weight-semibold, 600) !important;
}

.k-font-bold {
  font-weight: var(--kendo-font-weight-bold, 700);
}

.\!k-font-bold {
  font-weight: var(--kendo-font-weight-bold, 700) !important;
}

.k-font-weight-thin {
  font-weight: var(--kendo-font-weight-thin, 100);
}

.\!k-font-weight-thin {
  font-weight: var(--kendo-font-weight-thin, 100) !important;
}

.k-font-weight-extra-light {
  font-weight: var(--kendo-font-weight-extra-light, 200);
}

.\!k-font-weight-extra-light {
  font-weight: var(--kendo-font-weight-extra-light, 200) !important;
}

.k-font-weight-light {
  font-weight: var(--kendo-font-weight-light, 300);
}

.\!k-font-weight-light {
  font-weight: var(--kendo-font-weight-light, 300) !important;
}

.k-font-weight-normal {
  font-weight: var(--kendo-font-weight-normal, 400);
}

.\!k-font-weight-normal {
  font-weight: var(--kendo-font-weight-normal, 400) !important;
}

.k-font-weight-medium {
  font-weight: var(--kendo-font-weight-medium, 500);
}

.\!k-font-weight-medium {
  font-weight: var(--kendo-font-weight-medium, 500) !important;
}

.k-font-weight-semibold {
  font-weight: var(--kendo-font-weight-semibold, 600);
}

.\!k-font-weight-semibold {
  font-weight: var(--kendo-font-weight-semibold, 600) !important;
}

.k-font-weight-bold {
  font-weight: var(--kendo-font-weight-bold, 700);
}

.\!k-font-weight-bold {
  font-weight: var(--kendo-font-weight-bold, 700) !important;
}

.k-letter-spacing-tightest {
  letter-spacing: var(--kendo-letter-spacing-tightest, -0.15px);
}

.\!k-letter-spacing-tightest {
  letter-spacing: var(--kendo-letter-spacing-tightest, -0.15px) !important;
}

.k-letter-spacing-tighter {
  letter-spacing: var(--kendo-letter-spacing-tighter, -0.1px);
}

.\!k-letter-spacing-tighter {
  letter-spacing: var(--kendo-letter-spacing-tighter, -0.1px) !important;
}

.k-letter-spacing-tight {
  letter-spacing: var(--kendo-letter-spacing-tight, -0.5px);
}

.\!k-letter-spacing-tight {
  letter-spacing: var(--kendo-letter-spacing-tight, -0.5px) !important;
}

.k-letter-spacing-normal {
  letter-spacing: var(--kendo-letter-spacing-normal, 0px);
}

.\!k-letter-spacing-normal {
  letter-spacing: var(--kendo-letter-spacing-normal, 0px) !important;
}

.k-letter-spacing-wide {
  letter-spacing: var(--kendo-letter-spacing-wide, 0.5px);
}

.\!k-letter-spacing-wide {
  letter-spacing: var(--kendo-letter-spacing-wide, 0.5px) !important;
}

.k-letter-spacing-wider {
  letter-spacing: var(--kendo-letter-spacing-wider, 0.1px);
}

.\!k-letter-spacing-wider {
  letter-spacing: var(--kendo-letter-spacing-wider, 0.1px) !important;
}

.k-letter-spacing-widest {
  letter-spacing: var(--kendo-letter-spacing-widest, 0.15px);
}

.\!k-letter-spacing-widest {
  letter-spacing: var(--kendo-letter-spacing-widest, 0.15px) !important;
}

.k-line-height-xs {
  line-height: var(--kendo-line-height-xs, 1);
}

.\!k-line-height-xs {
  line-height: var(--kendo-line-height-xs, 1) !important;
}

.k-line-height-sm {
  line-height: var(--kendo-line-height-sm, 1.25);
}

.\!k-line-height-sm {
  line-height: var(--kendo-line-height-sm, 1.25) !important;
}

.k-line-height-md {
  line-height: var(--kendo-line-height-md, 1.4285714286);
}

.\!k-line-height-md {
  line-height: var(--kendo-line-height-md, 1.4285714286) !important;
}

.k-line-height-lg {
  line-height: var(--kendo-line-height-lg, 1.5);
}

.\!k-line-height-lg {
  line-height: var(--kendo-line-height-lg, 1.5) !important;
}

.k-list-none {
  list-style-type: none;
}

.\!k-list-none {
  list-style-type: none !important;
}

.k-list-disc {
  list-style-type: disc;
}

.\!k-list-disc {
  list-style-type: disc !important;
}

.k-list-decimal {
  list-style-type: decimal;
}

.\!k-list-decimal {
  list-style-type: decimal !important;
}

.k-text-left {
  text-align: left;
}

.\!k-text-left {
  text-align: left !important;
}

.k-text-right {
  text-align: right;
}

.\!k-text-right {
  text-align: right !important;
}

.k-text-center {
  text-align: center;
}

.\!k-text-center {
  text-align: center !important;
}

.k-text-justify {
  text-align: justify;
}

.\!k-text-justify {
  text-align: justify !important;
}

.k-text-start {
  text-align: start;
}

.\!k-text-start {
  text-align: start !important;
}

.k-text-end {
  text-align: end;
}

.\!k-text-end {
  text-align: end !important;
}

.k-text-app-surface {
  color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-text-app-surface {
  color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-text-on-app-surface {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.\!k-text-on-app-surface {
  color: var(--kendo-color-on-app-surface, #3d3d3d) !important;
}

.k-text-subtle {
  color: var(--kendo-color-subtle, #666666);
}

.\!k-text-subtle {
  color: var(--kendo-color-subtle, #666666) !important;
}

.k-text-surface {
  color: var(--kendo-color-surface, #fafafa);
}

.\!k-text-surface {
  color: var(--kendo-color-surface, #fafafa) !important;
}

.k-text-surface-alt {
  color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-text-surface-alt {
  color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-text-border {
  color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.\!k-text-border {
  color: var(--kendo-color-border, rgba(0, 0, 0, 0.08)) !important;
}

.k-text-border-alt {
  color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}

.\!k-text-border-alt {
  color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16)) !important;
}

.k-text-base-subtle {
  color: var(--kendo-color-base-subtle, #ebebeb);
}

.\!k-text-base-subtle {
  color: var(--kendo-color-base-subtle, #ebebeb) !important;
}

.k-text-base-emphasis {
  color: var(--kendo-color-base-emphasis, #c2c2c2);
}

.\!k-text-base-emphasis {
  color: var(--kendo-color-base-emphasis, #c2c2c2) !important;
}

.k-text-primary-subtle {
  color: var(--kendo-color-primary-subtle, #ffeceb);
}

.\!k-text-primary-subtle {
  color: var(--kendo-color-primary-subtle, #ffeceb) !important;
}

.k-text-primary {
  color: var(--kendo-color-primary, #ff6358);
}

.\!k-text-primary {
  color: var(--kendo-color-primary, #ff6358) !important;
}

.k-text-primary-emphasis {
  color: var(--kendo-color-primary-emphasis, #ff9d97);
}

.\!k-text-primary-emphasis {
  color: var(--kendo-color-primary-emphasis, #ff9d97) !important;
}

.k-text-secondary-subtle {
  color: var(--kendo-color-secondary-subtle, #fafafa);
}

.\!k-text-secondary-subtle {
  color: var(--kendo-color-secondary-subtle, #fafafa) !important;
}

.k-text-secondary {
  color: var(--kendo-color-secondary, #666666);
}

.\!k-text-secondary {
  color: var(--kendo-color-secondary, #666666) !important;
}

.k-text-secondary-emphasis {
  color: var(--kendo-color-secondary-emphasis, #e0e0e0);
}

.\!k-text-secondary-emphasis {
  color: var(--kendo-color-secondary-emphasis, #e0e0e0) !important;
}

.k-text-tertiary-subtle {
  color: var(--kendo-color-tertiary-subtle, #d8f1fd);
}

.\!k-text-tertiary-subtle {
  color: var(--kendo-color-tertiary-subtle, #d8f1fd) !important;
}

.k-text-tertiary {
  color: var(--kendo-color-tertiary, #03a9f4);
}

.\!k-text-tertiary {
  color: var(--kendo-color-tertiary, #03a9f4) !important;
}

.k-text-tertiary-emphasis {
  color: var(--kendo-color-tertiary-emphasis, #61c9f9);
}

.\!k-text-tertiary-emphasis {
  color: var(--kendo-color-tertiary-emphasis, #61c9f9) !important;
}

.k-text-info-subtle {
  color: var(--kendo-color-info-subtle, #d2e2fb);
}

.\!k-text-info-subtle {
  color: var(--kendo-color-info-subtle, #d2e2fb) !important;
}

.k-text-info {
  color: var(--kendo-color-info, #0058e9);
}

.\!k-text-info {
  color: var(--kendo-color-info, #0058e9) !important;
}

.k-text-info-emphasis {
  color: var(--kendo-color-info-emphasis, #6098f2);
}

.\!k-text-info-emphasis {
  color: var(--kendo-color-info-emphasis, #6098f2) !important;
}

.k-text-success-subtle {
  color: var(--kendo-color-success-subtle, #dcf0d3);
}

.\!k-text-success-subtle {
  color: var(--kendo-color-success-subtle, #dcf0d3) !important;
}

.k-text-success {
  color: var(--kendo-color-success, #37b400);
}

.\!k-text-success {
  color: var(--kendo-color-success, #37b400) !important;
}

.k-text-success-emphasis {
  color: var(--kendo-color-success-emphasis, #81d15f);
}

.\!k-text-success-emphasis {
  color: var(--kendo-color-success-emphasis, #81d15f) !important;
}

.k-text-warning-subtle {
  color: var(--kendo-color-warning-subtle, #fff4d3);
}

.\!k-text-warning-subtle {
  color: var(--kendo-color-warning-subtle, #fff4d3) !important;
}

.k-text-warning {
  color: var(--kendo-color-warning, #ffc000);
}

.\!k-text-warning {
  color: var(--kendo-color-warning, #ffc000) !important;
}

.k-text-warning-emphasis {
  color: var(--kendo-color-warning-emphasis, #ffd760);
}

.\!k-text-warning-emphasis {
  color: var(--kendo-color-warning-emphasis, #ffd760) !important;
}

.k-text-error-subtle {
  color: var(--kendo-color-error-subtle, #fcddda);
}

.\!k-text-error-subtle {
  color: var(--kendo-color-error-subtle, #fcddda) !important;
}

.k-text-error {
  color: var(--kendo-color-error, #f31700);
}

.\!k-text-error {
  color: var(--kendo-color-error, #f31700) !important;
}

.k-text-error-emphasis {
  color: var(--kendo-color-error-emphasis, #f76f60);
}

.\!k-text-error-emphasis {
  color: var(--kendo-color-error-emphasis, #f76f60) !important;
}

.k-text-light-subtle {
  color: var(--kendo-color-light-subtle, #fafafa);
}

.\!k-text-light-subtle {
  color: var(--kendo-color-light-subtle, #fafafa) !important;
}

.k-text-light {
  color: var(--kendo-color-light, #ebebeb);
}

.\!k-text-light {
  color: var(--kendo-color-light, #ebebeb) !important;
}

.k-text-light-emphasis {
  color: var(--kendo-color-light-emphasis, #d6d6d6);
}

.\!k-text-light-emphasis {
  color: var(--kendo-color-light-emphasis, #d6d6d6) !important;
}

.k-text-dark-subtle {
  color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.\!k-text-dark-subtle {
  color: var(--kendo-color-dark-subtle, #c2c2c2) !important;
}

.k-text-dark {
  color: var(--kendo-color-dark, #3d3d3d);
}

.\!k-text-dark {
  color: var(--kendo-color-dark, #3d3d3d) !important;
}

.k-text-dark-emphasis {
  color: var(--kendo-color-dark-emphasis, #666666);
}

.\!k-text-dark-emphasis {
  color: var(--kendo-color-dark-emphasis, #666666) !important;
}

.k-text-inherit {
  color: var(--kendo-color-inherit, inherit);
}

.\!k-text-inherit {
  color: var(--kendo-color-inherit, inherit) !important;
}

.k-text-current {
  color: var(--kendo-color-current, currentColor);
}

.\!k-text-current {
  color: var(--kendo-color-current, currentColor) !important;
}

.k-text-transparent {
  color: var(--kendo-color-transparent, transparent);
}

.\!k-text-transparent {
  color: var(--kendo-color-transparent, transparent) !important;
}

.k-text-black {
  color: var(--kendo-color-black, black);
}

.\!k-text-black {
  color: var(--kendo-color-black, black) !important;
}

.k-text-white {
  color: var(--kendo-color-white, white);
}

.\!k-text-white {
  color: var(--kendo-color-white, white) !important;
}

.k-color-app-surface {
  color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-color-app-surface {
  color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-color-on-app-surface {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.\!k-color-on-app-surface {
  color: var(--kendo-color-on-app-surface, #3d3d3d) !important;
}

.k-color-subtle {
  color: var(--kendo-color-subtle, #666666);
}

.\!k-color-subtle {
  color: var(--kendo-color-subtle, #666666) !important;
}

.k-color-surface {
  color: var(--kendo-color-surface, #fafafa);
}

.\!k-color-surface {
  color: var(--kendo-color-surface, #fafafa) !important;
}

.k-color-surface-alt {
  color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-color-surface-alt {
  color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-color-border {
  color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.\!k-color-border {
  color: var(--kendo-color-border, rgba(0, 0, 0, 0.08)) !important;
}

.k-color-border-alt {
  color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}

.\!k-color-border-alt {
  color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16)) !important;
}

.k-color-base-subtle {
  color: var(--kendo-color-base-subtle, #ebebeb);
}

.\!k-color-base-subtle {
  color: var(--kendo-color-base-subtle, #ebebeb) !important;
}

.k-color-base-emphasis {
  color: var(--kendo-color-base-emphasis, #c2c2c2);
}

.\!k-color-base-emphasis {
  color: var(--kendo-color-base-emphasis, #c2c2c2) !important;
}

.k-color-primary-subtle {
  color: var(--kendo-color-primary-subtle, #ffeceb);
}

.\!k-color-primary-subtle {
  color: var(--kendo-color-primary-subtle, #ffeceb) !important;
}

.k-color-primary {
  color: var(--kendo-color-primary, #ff6358);
}

.\!k-color-primary {
  color: var(--kendo-color-primary, #ff6358) !important;
}

.k-color-primary-emphasis {
  color: var(--kendo-color-primary-emphasis, #ff9d97);
}

.\!k-color-primary-emphasis {
  color: var(--kendo-color-primary-emphasis, #ff9d97) !important;
}

.k-color-secondary-subtle {
  color: var(--kendo-color-secondary-subtle, #fafafa);
}

.\!k-color-secondary-subtle {
  color: var(--kendo-color-secondary-subtle, #fafafa) !important;
}

.k-color-secondary {
  color: var(--kendo-color-secondary, #666666);
}

.\!k-color-secondary {
  color: var(--kendo-color-secondary, #666666) !important;
}

.k-color-secondary-emphasis {
  color: var(--kendo-color-secondary-emphasis, #e0e0e0);
}

.\!k-color-secondary-emphasis {
  color: var(--kendo-color-secondary-emphasis, #e0e0e0) !important;
}

.k-color-tertiary-subtle {
  color: var(--kendo-color-tertiary-subtle, #d8f1fd);
}

.\!k-color-tertiary-subtle {
  color: var(--kendo-color-tertiary-subtle, #d8f1fd) !important;
}

.k-color-tertiary {
  color: var(--kendo-color-tertiary, #03a9f4);
}

.\!k-color-tertiary {
  color: var(--kendo-color-tertiary, #03a9f4) !important;
}

.k-color-tertiary-emphasis {
  color: var(--kendo-color-tertiary-emphasis, #61c9f9);
}

.\!k-color-tertiary-emphasis {
  color: var(--kendo-color-tertiary-emphasis, #61c9f9) !important;
}

.k-color-info-subtle {
  color: var(--kendo-color-info-subtle, #d2e2fb);
}

.\!k-color-info-subtle {
  color: var(--kendo-color-info-subtle, #d2e2fb) !important;
}

.k-color-info {
  color: var(--kendo-color-info, #0058e9);
}

.\!k-color-info {
  color: var(--kendo-color-info, #0058e9) !important;
}

.k-color-info-emphasis {
  color: var(--kendo-color-info-emphasis, #6098f2);
}

.\!k-color-info-emphasis {
  color: var(--kendo-color-info-emphasis, #6098f2) !important;
}

.k-color-success-subtle {
  color: var(--kendo-color-success-subtle, #dcf0d3);
}

.\!k-color-success-subtle {
  color: var(--kendo-color-success-subtle, #dcf0d3) !important;
}

.k-color-success {
  color: var(--kendo-color-success, #37b400);
}

.\!k-color-success {
  color: var(--kendo-color-success, #37b400) !important;
}

.k-color-success-emphasis {
  color: var(--kendo-color-success-emphasis, #81d15f);
}

.\!k-color-success-emphasis {
  color: var(--kendo-color-success-emphasis, #81d15f) !important;
}

.k-color-warning-subtle {
  color: var(--kendo-color-warning-subtle, #fff4d3);
}

.\!k-color-warning-subtle {
  color: var(--kendo-color-warning-subtle, #fff4d3) !important;
}

.k-color-warning {
  color: var(--kendo-color-warning, #ffc000);
}

.\!k-color-warning {
  color: var(--kendo-color-warning, #ffc000) !important;
}

.k-color-warning-emphasis {
  color: var(--kendo-color-warning-emphasis, #ffd760);
}

.\!k-color-warning-emphasis {
  color: var(--kendo-color-warning-emphasis, #ffd760) !important;
}

.k-color-error-subtle {
  color: var(--kendo-color-error-subtle, #fcddda);
}

.\!k-color-error-subtle {
  color: var(--kendo-color-error-subtle, #fcddda) !important;
}

.k-color-error {
  color: var(--kendo-color-error, #f31700);
}

.\!k-color-error {
  color: var(--kendo-color-error, #f31700) !important;
}

.k-color-error-emphasis {
  color: var(--kendo-color-error-emphasis, #f76f60);
}

.\!k-color-error-emphasis {
  color: var(--kendo-color-error-emphasis, #f76f60) !important;
}

.k-color-light-subtle {
  color: var(--kendo-color-light-subtle, #fafafa);
}

.\!k-color-light-subtle {
  color: var(--kendo-color-light-subtle, #fafafa) !important;
}

.k-color-light {
  color: var(--kendo-color-light, #ebebeb);
}

.\!k-color-light {
  color: var(--kendo-color-light, #ebebeb) !important;
}

.k-color-light-emphasis {
  color: var(--kendo-color-light-emphasis, #d6d6d6);
}

.\!k-color-light-emphasis {
  color: var(--kendo-color-light-emphasis, #d6d6d6) !important;
}

.k-color-dark-subtle {
  color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.\!k-color-dark-subtle {
  color: var(--kendo-color-dark-subtle, #c2c2c2) !important;
}

.k-color-dark {
  color: var(--kendo-color-dark, #3d3d3d);
}

.\!k-color-dark {
  color: var(--kendo-color-dark, #3d3d3d) !important;
}

.k-color-dark-emphasis {
  color: var(--kendo-color-dark-emphasis, #666666);
}

.\!k-color-dark-emphasis {
  color: var(--kendo-color-dark-emphasis, #666666) !important;
}

.k-color-inherit {
  color: var(--kendo-color-inherit, inherit);
}

.\!k-color-inherit {
  color: var(--kendo-color-inherit, inherit) !important;
}

.k-color-current {
  color: var(--kendo-color-current, currentColor);
}

.\!k-color-current {
  color: var(--kendo-color-current, currentColor) !important;
}

.k-color-transparent {
  color: var(--kendo-color-transparent, transparent);
}

.\!k-color-transparent {
  color: var(--kendo-color-transparent, transparent) !important;
}

.k-color-black {
  color: var(--kendo-color-black, black);
}

.\!k-color-black {
  color: var(--kendo-color-black, black) !important;
}

.k-color-white {
  color: var(--kendo-color-white, white);
}

.\!k-color-white {
  color: var(--kendo-color-white, white) !important;
}

.k-text-underline {
  text-decoration: underline;
}

.\!k-text-underline {
  text-decoration: underline !important;
}

.k-text-overline {
  text-decoration: overline;
}

.\!k-text-overline {
  text-decoration: overline !important;
}

.k-text-line-through {
  text-decoration: line-through;
}

.\!k-text-line-through {
  text-decoration: line-through !important;
}

.k-text-no-underline {
  text-decoration: none;
}

.\!k-text-no-underline {
  text-decoration: none !important;
}

.k-text-clip {
  text-overflow: clip;
}

.\!k-text-clip {
  text-overflow: clip !important;
}

.k-text-ellipsis {
  text-overflow: ellipsis;
}

.\!k-text-ellipsis {
  text-overflow: ellipsis !important;
}

.k-text-truncate, .k-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.\!k-text-truncate, .\!k-text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.k-text-uppercase {
  text-transform: uppercase;
}

.\!k-text-uppercase {
  text-transform: uppercase !important;
}

.k-text-lowercase {
  text-transform: lowercase;
}

.\!k-text-lowercase {
  text-transform: lowercase !important;
}

.k-text-capitalize {
  text-transform: capitalize;
}

.\!k-text-capitalize {
  text-transform: capitalize !important;
}

.k-text-normal-case {
  text-transform: none;
}

.\!k-text-normal-case {
  text-transform: none !important;
}

.k-align-baseline {
  vertical-align: baseline;
}

.\!k-align-baseline {
  vertical-align: baseline !important;
}

.k-align-top {
  vertical-align: top;
}

.\!k-align-top {
  vertical-align: top !important;
}

.k-align-middle {
  vertical-align: middle;
}

.\!k-align-middle {
  vertical-align: middle !important;
}

.k-align-bottom {
  vertical-align: bottom;
}

.\!k-align-bottom {
  vertical-align: bottom !important;
}

.k-align-text-top {
  vertical-align: text-top;
}

.\!k-align-text-top {
  vertical-align: text-top !important;
}

.k-align-text-bottom {
  vertical-align: text-bottom;
}

.\!k-align-text-bottom {
  vertical-align: text-bottom !important;
}

.k-align-sub {
  vertical-align: sub;
}

.\!k-align-sub {
  vertical-align: sub !important;
}

.k-align-super {
  vertical-align: super;
}

.\!k-align-super {
  vertical-align: super !important;
}

.k-white-space-normal {
  white-space: normal;
}

.\!k-white-space-normal {
  white-space: normal !important;
}

.k-white-space-nowrap, .k-text-nowrap {
  white-space: nowrap;
}

.\!k-white-space-nowrap {
  white-space: nowrap !important;
}

.k-white-space-pre {
  white-space: pre;
}

.\!k-white-space-pre {
  white-space: pre !important;
}

.k-white-space-pre-line {
  white-space: pre-line;
}

.\!k-white-space-pre-line {
  white-space: pre-line !important;
}

.k-white-space-pre-wrap {
  white-space: pre-wrap;
}

.\!k-white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.k-white-space-break-spaces {
  white-space: break-spaces;
}

.\!k-white-space-break-spaces {
  white-space: break-spaces !important;
}

.k-whitespace-normal {
  white-space: normal;
}

.\!k-whitespace-normal {
  white-space: normal !important;
}

.k-whitespace-nowrap {
  white-space: nowrap;
}

.\!k-whitespace-nowrap {
  white-space: nowrap !important;
}

.k-whitespace-pre {
  white-space: pre;
}

.\!k-whitespace-pre {
  white-space: pre !important;
}

.k-whitespace-pre-line {
  white-space: pre-line;
}

.\!k-whitespace-pre-line {
  white-space: pre-line !important;
}

.k-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.\!k-whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.k-whitespace-break-spaces {
  white-space: break-spaces;
}

.\!k-whitespace-break-spaces {
  white-space: break-spaces !important;
}

.k-bg-clip-border {
  background-clip: border-box;
}

.\!k-bg-clip-border {
  background-clip: border-box !important;
}

.k-bg-clip-padding {
  background-clip: padding-box;
}

.\!k-bg-clip-padding {
  background-clip: padding-box !important;
}

.k-bg-clip-content {
  background-clip: content-box;
}

.\!k-bg-clip-content {
  background-clip: content-box !important;
}

.k-bg-clip-text {
  background-clip: text;
}

.\!k-bg-clip-text {
  background-clip: text !important;
}

.k-bg-app-surface {
  background-color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-bg-app-surface {
  background-color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-bg-on-app-surface {
  background-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.\!k-bg-on-app-surface {
  background-color: var(--kendo-color-on-app-surface, #3d3d3d) !important;
}

.k-bg-subtle {
  background-color: var(--kendo-color-subtle, #666666);
}

.\!k-bg-subtle {
  background-color: var(--kendo-color-subtle, #666666) !important;
}

.k-bg-surface {
  background-color: var(--kendo-color-surface, #fafafa);
}

.\!k-bg-surface {
  background-color: var(--kendo-color-surface, #fafafa) !important;
}

.k-bg-surface-alt {
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-bg-surface-alt {
  background-color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-bg-border {
  background-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.\!k-bg-border {
  background-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08)) !important;
}

.k-bg-border-alt {
  background-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}

.\!k-bg-border-alt {
  background-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16)) !important;
}

.k-bg-base-subtle {
  background-color: var(--kendo-color-base-subtle, #ebebeb);
}

.\!k-bg-base-subtle {
  background-color: var(--kendo-color-base-subtle, #ebebeb) !important;
}

.k-bg-base-emphasis {
  background-color: var(--kendo-color-base-emphasis, #c2c2c2);
}

.\!k-bg-base-emphasis {
  background-color: var(--kendo-color-base-emphasis, #c2c2c2) !important;
}

.k-bg-primary-subtle {
  background-color: var(--kendo-color-primary-subtle, #ffeceb);
}

.\!k-bg-primary-subtle {
  background-color: var(--kendo-color-primary-subtle, #ffeceb) !important;
}

.k-bg-primary {
  background-color: var(--kendo-color-primary, #ff6358);
}

.\!k-bg-primary {
  background-color: var(--kendo-color-primary, #ff6358) !important;
}

.k-bg-primary-emphasis {
  background-color: var(--kendo-color-primary-emphasis, #ff9d97);
}

.\!k-bg-primary-emphasis {
  background-color: var(--kendo-color-primary-emphasis, #ff9d97) !important;
}

.k-bg-secondary-subtle {
  background-color: var(--kendo-color-secondary-subtle, #fafafa);
}

.\!k-bg-secondary-subtle {
  background-color: var(--kendo-color-secondary-subtle, #fafafa) !important;
}

.k-bg-secondary {
  background-color: var(--kendo-color-secondary, #666666);
}

.\!k-bg-secondary {
  background-color: var(--kendo-color-secondary, #666666) !important;
}

.k-bg-secondary-emphasis {
  background-color: var(--kendo-color-secondary-emphasis, #e0e0e0);
}

.\!k-bg-secondary-emphasis {
  background-color: var(--kendo-color-secondary-emphasis, #e0e0e0) !important;
}

.k-bg-tertiary-subtle {
  background-color: var(--kendo-color-tertiary-subtle, #d8f1fd);
}

.\!k-bg-tertiary-subtle {
  background-color: var(--kendo-color-tertiary-subtle, #d8f1fd) !important;
}

.k-bg-tertiary {
  background-color: var(--kendo-color-tertiary, #03a9f4);
}

.\!k-bg-tertiary {
  background-color: var(--kendo-color-tertiary, #03a9f4) !important;
}

.k-bg-tertiary-emphasis {
  background-color: var(--kendo-color-tertiary-emphasis, #61c9f9);
}

.\!k-bg-tertiary-emphasis {
  background-color: var(--kendo-color-tertiary-emphasis, #61c9f9) !important;
}

.k-bg-info-subtle {
  background-color: var(--kendo-color-info-subtle, #d2e2fb);
}

.\!k-bg-info-subtle {
  background-color: var(--kendo-color-info-subtle, #d2e2fb) !important;
}

.k-bg-info {
  background-color: var(--kendo-color-info, #0058e9);
}

.\!k-bg-info {
  background-color: var(--kendo-color-info, #0058e9) !important;
}

.k-bg-info-emphasis {
  background-color: var(--kendo-color-info-emphasis, #6098f2);
}

.\!k-bg-info-emphasis {
  background-color: var(--kendo-color-info-emphasis, #6098f2) !important;
}

.k-bg-success-subtle {
  background-color: var(--kendo-color-success-subtle, #dcf0d3);
}

.\!k-bg-success-subtle {
  background-color: var(--kendo-color-success-subtle, #dcf0d3) !important;
}

.k-bg-success {
  background-color: var(--kendo-color-success, #37b400);
}

.\!k-bg-success {
  background-color: var(--kendo-color-success, #37b400) !important;
}

.k-bg-success-emphasis {
  background-color: var(--kendo-color-success-emphasis, #81d15f);
}

.\!k-bg-success-emphasis {
  background-color: var(--kendo-color-success-emphasis, #81d15f) !important;
}

.k-bg-warning-subtle {
  background-color: var(--kendo-color-warning-subtle, #fff4d3);
}

.\!k-bg-warning-subtle {
  background-color: var(--kendo-color-warning-subtle, #fff4d3) !important;
}

.k-bg-warning {
  background-color: var(--kendo-color-warning, #ffc000);
}

.\!k-bg-warning {
  background-color: var(--kendo-color-warning, #ffc000) !important;
}

.k-bg-warning-emphasis {
  background-color: var(--kendo-color-warning-emphasis, #ffd760);
}

.\!k-bg-warning-emphasis {
  background-color: var(--kendo-color-warning-emphasis, #ffd760) !important;
}

.k-bg-error-subtle {
  background-color: var(--kendo-color-error-subtle, #fcddda);
}

.\!k-bg-error-subtle {
  background-color: var(--kendo-color-error-subtle, #fcddda) !important;
}

.k-bg-error {
  background-color: var(--kendo-color-error, #f31700);
}

.\!k-bg-error {
  background-color: var(--kendo-color-error, #f31700) !important;
}

.k-bg-error-emphasis {
  background-color: var(--kendo-color-error-emphasis, #f76f60);
}

.\!k-bg-error-emphasis {
  background-color: var(--kendo-color-error-emphasis, #f76f60) !important;
}

.k-bg-light-subtle {
  background-color: var(--kendo-color-light-subtle, #fafafa);
}

.\!k-bg-light-subtle {
  background-color: var(--kendo-color-light-subtle, #fafafa) !important;
}

.k-bg-light {
  background-color: var(--kendo-color-light, #ebebeb);
}

.\!k-bg-light {
  background-color: var(--kendo-color-light, #ebebeb) !important;
}

.k-bg-light-emphasis {
  background-color: var(--kendo-color-light-emphasis, #d6d6d6);
}

.\!k-bg-light-emphasis {
  background-color: var(--kendo-color-light-emphasis, #d6d6d6) !important;
}

.k-bg-dark-subtle {
  background-color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.\!k-bg-dark-subtle {
  background-color: var(--kendo-color-dark-subtle, #c2c2c2) !important;
}

.k-bg-dark {
  background-color: var(--kendo-color-dark, #3d3d3d);
}

.\!k-bg-dark {
  background-color: var(--kendo-color-dark, #3d3d3d) !important;
}

.k-bg-dark-emphasis {
  background-color: var(--kendo-color-dark-emphasis, #666666);
}

.\!k-bg-dark-emphasis {
  background-color: var(--kendo-color-dark-emphasis, #666666) !important;
}

.k-bg-inherit {
  background-color: var(--kendo-color-inherit, inherit);
}

.\!k-bg-inherit {
  background-color: var(--kendo-color-inherit, inherit) !important;
}

.k-bg-transparent {
  background-color: var(--kendo-color-transparent, transparent);
}

.\!k-bg-transparent {
  background-color: var(--kendo-color-transparent, transparent) !important;
}

.k-bg-black {
  background-color: var(--kendo-color-black, black);
}

.\!k-bg-black {
  background-color: var(--kendo-color-black, black) !important;
}

.k-bg-white {
  background-color: var(--kendo-color-white, white);
}

.\!k-bg-white {
  background-color: var(--kendo-color-white, white) !important;
}

.k-bg-center {
  background-position: center;
}

.\!k-bg-center {
  background-position: center !important;
}

.k-bg-top {
  background-position: top;
}

.\!k-bg-top {
  background-position: top !important;
}

.k-bg-right {
  background-position: right;
}

.\!k-bg-right {
  background-position: right !important;
}

.k-bg-bottom {
  background-position: bottom;
}

.\!k-bg-bottom {
  background-position: bottom !important;
}

.k-bg-left {
  background-position: left;
}

.\!k-bg-left {
  background-position: left !important;
}

.k-bg-top-left {
  background-position: top left;
}

.\!k-bg-top-left {
  background-position: top left !important;
}

.k-bg-top-right {
  background-position: top right;
}

.\!k-bg-top-right {
  background-position: top right !important;
}

.k-bg-bottom-left {
  background-position: bottom left;
}

.\!k-bg-bottom-left {
  background-position: bottom left !important;
}

.k-bg-bottom-right {
  background-position: bottom right;
}

.\!k-bg-bottom-right {
  background-position: bottom right !important;
}

.k-bg-auto {
  background-size: auto;
}

.\!k-bg-auto {
  background-size: auto !important;
}

.k-bg-cover {
  background-size: cover;
}

.\!k-bg-cover {
  background-size: cover !important;
}

.k-bg-contain {
  background-size: contain;
}

.\!k-bg-contain {
  background-size: contain !important;
}

.k-border-app-surface {
  border-color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-border-app-surface {
  border-color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-border-on-app-surface {
  border-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.\!k-border-on-app-surface {
  border-color: var(--kendo-color-on-app-surface, #3d3d3d) !important;
}

.k-border-subtle {
  border-color: var(--kendo-color-subtle, #666666);
}

.\!k-border-subtle {
  border-color: var(--kendo-color-subtle, #666666) !important;
}

.k-border-surface {
  border-color: var(--kendo-color-surface, #fafafa);
}

.\!k-border-surface {
  border-color: var(--kendo-color-surface, #fafafa) !important;
}

.k-border-surface-alt {
  border-color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-border-surface-alt {
  border-color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-border-border {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.\!k-border-border {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08)) !important;
}

.k-border-border-alt {
  border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}

.\!k-border-border-alt {
  border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16)) !important;
}

.k-border-base-subtle {
  border-color: var(--kendo-color-base-subtle, #ebebeb);
}

.\!k-border-base-subtle {
  border-color: var(--kendo-color-base-subtle, #ebebeb) !important;
}

.k-border-base-emphasis {
  border-color: var(--kendo-color-base-emphasis, #c2c2c2);
}

.\!k-border-base-emphasis {
  border-color: var(--kendo-color-base-emphasis, #c2c2c2) !important;
}

.k-border-primary-subtle {
  border-color: var(--kendo-color-primary-subtle, #ffeceb);
}

.\!k-border-primary-subtle {
  border-color: var(--kendo-color-primary-subtle, #ffeceb) !important;
}

.k-border-primary {
  border-color: var(--kendo-color-primary, #ff6358);
}

.\!k-border-primary {
  border-color: var(--kendo-color-primary, #ff6358) !important;
}

.k-border-primary-emphasis {
  border-color: var(--kendo-color-primary-emphasis, #ff9d97);
}

.\!k-border-primary-emphasis {
  border-color: var(--kendo-color-primary-emphasis, #ff9d97) !important;
}

.k-border-secondary-subtle {
  border-color: var(--kendo-color-secondary-subtle, #fafafa);
}

.\!k-border-secondary-subtle {
  border-color: var(--kendo-color-secondary-subtle, #fafafa) !important;
}

.k-border-secondary {
  border-color: var(--kendo-color-secondary, #666666);
}

.\!k-border-secondary {
  border-color: var(--kendo-color-secondary, #666666) !important;
}

.k-border-secondary-emphasis {
  border-color: var(--kendo-color-secondary-emphasis, #e0e0e0);
}

.\!k-border-secondary-emphasis {
  border-color: var(--kendo-color-secondary-emphasis, #e0e0e0) !important;
}

.k-border-tertiary-subtle {
  border-color: var(--kendo-color-tertiary-subtle, #d8f1fd);
}

.\!k-border-tertiary-subtle {
  border-color: var(--kendo-color-tertiary-subtle, #d8f1fd) !important;
}

.k-border-tertiary {
  border-color: var(--kendo-color-tertiary, #03a9f4);
}

.\!k-border-tertiary {
  border-color: var(--kendo-color-tertiary, #03a9f4) !important;
}

.k-border-tertiary-emphasis {
  border-color: var(--kendo-color-tertiary-emphasis, #61c9f9);
}

.\!k-border-tertiary-emphasis {
  border-color: var(--kendo-color-tertiary-emphasis, #61c9f9) !important;
}

.k-border-info-subtle {
  border-color: var(--kendo-color-info-subtle, #d2e2fb);
}

.\!k-border-info-subtle {
  border-color: var(--kendo-color-info-subtle, #d2e2fb) !important;
}

.k-border-info {
  border-color: var(--kendo-color-info, #0058e9);
}

.\!k-border-info {
  border-color: var(--kendo-color-info, #0058e9) !important;
}

.k-border-info-emphasis {
  border-color: var(--kendo-color-info-emphasis, #6098f2);
}

.\!k-border-info-emphasis {
  border-color: var(--kendo-color-info-emphasis, #6098f2) !important;
}

.k-border-success-subtle {
  border-color: var(--kendo-color-success-subtle, #dcf0d3);
}

.\!k-border-success-subtle {
  border-color: var(--kendo-color-success-subtle, #dcf0d3) !important;
}

.k-border-success {
  border-color: var(--kendo-color-success, #37b400);
}

.\!k-border-success {
  border-color: var(--kendo-color-success, #37b400) !important;
}

.k-border-success-emphasis {
  border-color: var(--kendo-color-success-emphasis, #81d15f);
}

.\!k-border-success-emphasis {
  border-color: var(--kendo-color-success-emphasis, #81d15f) !important;
}

.k-border-warning-subtle {
  border-color: var(--kendo-color-warning-subtle, #fff4d3);
}

.\!k-border-warning-subtle {
  border-color: var(--kendo-color-warning-subtle, #fff4d3) !important;
}

.k-border-warning {
  border-color: var(--kendo-color-warning, #ffc000);
}

.\!k-border-warning {
  border-color: var(--kendo-color-warning, #ffc000) !important;
}

.k-border-warning-emphasis {
  border-color: var(--kendo-color-warning-emphasis, #ffd760);
}

.\!k-border-warning-emphasis {
  border-color: var(--kendo-color-warning-emphasis, #ffd760) !important;
}

.k-border-error-subtle {
  border-color: var(--kendo-color-error-subtle, #fcddda);
}

.\!k-border-error-subtle {
  border-color: var(--kendo-color-error-subtle, #fcddda) !important;
}

.k-border-error {
  border-color: var(--kendo-color-error, #f31700);
}

.\!k-border-error {
  border-color: var(--kendo-color-error, #f31700) !important;
}

.k-border-error-emphasis {
  border-color: var(--kendo-color-error-emphasis, #f76f60);
}

.\!k-border-error-emphasis {
  border-color: var(--kendo-color-error-emphasis, #f76f60) !important;
}

.k-border-light-subtle {
  border-color: var(--kendo-color-light-subtle, #fafafa);
}

.\!k-border-light-subtle {
  border-color: var(--kendo-color-light-subtle, #fafafa) !important;
}

.k-border-light {
  border-color: var(--kendo-color-light, #ebebeb);
}

.\!k-border-light {
  border-color: var(--kendo-color-light, #ebebeb) !important;
}

.k-border-light-emphasis {
  border-color: var(--kendo-color-light-emphasis, #d6d6d6);
}

.\!k-border-light-emphasis {
  border-color: var(--kendo-color-light-emphasis, #d6d6d6) !important;
}

.k-border-dark-subtle {
  border-color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.\!k-border-dark-subtle {
  border-color: var(--kendo-color-dark-subtle, #c2c2c2) !important;
}

.k-border-dark {
  border-color: var(--kendo-color-dark, #3d3d3d);
}

.\!k-border-dark {
  border-color: var(--kendo-color-dark, #3d3d3d) !important;
}

.k-border-dark-emphasis {
  border-color: var(--kendo-color-dark-emphasis, #666666);
}

.\!k-border-dark-emphasis {
  border-color: var(--kendo-color-dark-emphasis, #666666) !important;
}

.k-border-inherit {
  border-color: var(--kendo-color-inherit, inherit);
}

.\!k-border-inherit {
  border-color: var(--kendo-color-inherit, inherit) !important;
}

.k-border-current {
  border-color: var(--kendo-color-current, currentColor);
}

.\!k-border-current {
  border-color: var(--kendo-color-current, currentColor) !important;
}

.k-border-transparent {
  border-color: var(--kendo-color-transparent, transparent);
}

.\!k-border-transparent {
  border-color: var(--kendo-color-transparent, transparent) !important;
}

.k-border-black {
  border-color: var(--kendo-color-black, black);
}

.\!k-border-black {
  border-color: var(--kendo-color-black, black) !important;
}

.k-border-white {
  border-color: var(--kendo-color-white, white);
}

.\!k-border-white {
  border-color: var(--kendo-color-white, white) !important;
}

.k-rounded-none {
  border-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-none {
  border-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-xs {
  border-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-xs {
  border-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-sm {
  border-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-sm {
  border-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-md {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-md {
  border-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-lg {
  border-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-lg {
  border-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-xl {
  border-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-xl {
  border-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-xxl {
  border-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-xxl {
  border-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-xxxl {
  border-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-xxxl {
  border-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-full {
  border-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-full {
  border-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-rounded-tl-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-tl-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-tl-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-tl-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-tl-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-tl-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-tl-md {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-tl-md {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-tl-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-tl-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-tl-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-tl-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-tl-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-tl-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-tl-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-tl-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-tl-full {
  border-top-left-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-tl-full {
  border-top-left-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-rounded-tr-none {
  border-top-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-tr-none {
  border-top-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-tr-xs {
  border-top-right-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-tr-xs {
  border-top-right-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-tr-sm {
  border-top-right-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-tr-sm {
  border-top-right-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-tr-md {
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-tr-md {
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-tr-lg {
  border-top-right-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-tr-lg {
  border-top-right-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-tr-xl {
  border-top-right-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-tr-xl {
  border-top-right-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-tr-xxl {
  border-top-right-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-tr-xxl {
  border-top-right-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-tr-xxxl {
  border-top-right-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-tr-xxxl {
  border-top-right-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-tr-full {
  border-top-right-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-tr-full {
  border-top-right-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-rounded-br-none {
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-br-none {
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-br-xs {
  border-bottom-right-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-br-xs {
  border-bottom-right-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-br-sm {
  border-bottom-right-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-br-sm {
  border-bottom-right-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-br-md {
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-br-md {
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-br-lg {
  border-bottom-right-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-br-lg {
  border-bottom-right-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-br-xl {
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-br-xl {
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-br-xxl {
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-br-xxl {
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-br-xxxl {
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-br-xxxl {
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-br-full {
  border-bottom-right-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-br-full {
  border-bottom-right-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-rounded-bl-none {
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-bl-none {
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-bl-xs {
  border-bottom-left-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-bl-xs {
  border-bottom-left-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-bl-sm {
  border-bottom-left-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-bl-sm {
  border-bottom-left-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-bl-md {
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-bl-md {
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-bl-lg {
  border-bottom-left-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-bl-lg {
  border-bottom-left-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-bl-xl {
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-bl-xl {
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-bl-xxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-bl-xxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-bl-xxxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-bl-xxxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-bl-full {
  border-bottom-left-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-bl-full {
  border-bottom-left-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-rounded-t-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px);
  border-top-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-t-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px) !important;
  border-top-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-t-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 1px);
  border-top-right-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-t-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 1px) !important;
  border-top-right-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-t-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 0.125rem);
  border-top-right-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-t-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
  border-top-right-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-t-md {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-t-md {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem) !important;
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-t-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 0.375rem);
  border-top-right-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-t-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
  border-top-right-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-t-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.5rem);
  border-top-right-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-t-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
  border-top-right-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-t-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 0.75rem);
  border-top-right-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-t-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
  border-top-right-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-t-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 1rem);
  border-top-right-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-t-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
  border-top-right-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-t-full {
  border-top-left-radius: var(--kendo-border-radius-full, 9999px);
  border-top-right-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-t-full {
  border-top-left-radius: var(--kendo-border-radius-full, 9999px) !important;
  border-top-right-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-rounded-r-none {
  border-top-right-radius: var(--kendo-border-radius-none, 0px);
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-r-none {
  border-top-right-radius: var(--kendo-border-radius-none, 0px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-r-xs {
  border-top-right-radius: var(--kendo-border-radius-xs, 1px);
  border-bottom-right-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-r-xs {
  border-top-right-radius: var(--kendo-border-radius-xs, 1px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-r-sm {
  border-top-right-radius: var(--kendo-border-radius-sm, 0.125rem);
  border-bottom-right-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-r-sm {
  border-top-right-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-r-md {
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-r-md {
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-r-lg {
  border-top-right-radius: var(--kendo-border-radius-lg, 0.375rem);
  border-bottom-right-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-r-lg {
  border-top-right-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-r-xl {
  border-top-right-radius: var(--kendo-border-radius-xl, 0.5rem);
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-r-xl {
  border-top-right-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-r-xxl {
  border-top-right-radius: var(--kendo-border-radius-xxl, 0.75rem);
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-r-xxl {
  border-top-right-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-r-xxxl {
  border-top-right-radius: var(--kendo-border-radius-xxxl, 1rem);
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-r-xxxl {
  border-top-right-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-r-full {
  border-top-right-radius: var(--kendo-border-radius-full, 9999px);
  border-bottom-right-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-r-full {
  border-top-right-radius: var(--kendo-border-radius-full, 9999px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-rounded-b-none {
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px);
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-b-none {
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-b-xs {
  border-bottom-left-radius: var(--kendo-border-radius-xs, 1px);
  border-bottom-right-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-b-xs {
  border-bottom-left-radius: var(--kendo-border-radius-xs, 1px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-b-sm {
  border-bottom-left-radius: var(--kendo-border-radius-sm, 0.125rem);
  border-bottom-right-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-b-sm {
  border-bottom-left-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-b-md {
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-b-md {
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-b-lg {
  border-bottom-left-radius: var(--kendo-border-radius-lg, 0.375rem);
  border-bottom-right-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-b-lg {
  border-bottom-left-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-b-xl {
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.5rem);
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-b-xl {
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-b-xxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 0.75rem);
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-b-xxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-b-xxxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 1rem);
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-b-xxxl {
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
  border-bottom-right-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-b-full {
  border-bottom-left-radius: var(--kendo-border-radius-full, 9999px);
  border-bottom-right-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-b-full {
  border-bottom-left-radius: var(--kendo-border-radius-full, 9999px) !important;
  border-bottom-right-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-rounded-l-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px);
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px);
}

.\!k-rounded-l-none {
  border-top-left-radius: var(--kendo-border-radius-none, 0px) !important;
  border-bottom-left-radius: var(--kendo-border-radius-none, 0px) !important;
}

.k-rounded-l-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 1px);
  border-bottom-left-radius: var(--kendo-border-radius-xs, 1px);
}

.\!k-rounded-l-xs {
  border-top-left-radius: var(--kendo-border-radius-xs, 1px) !important;
  border-bottom-left-radius: var(--kendo-border-radius-xs, 1px) !important;
}

.k-rounded-l-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 0.125rem);
  border-bottom-left-radius: var(--kendo-border-radius-sm, 0.125rem);
}

.\!k-rounded-l-sm {
  border-top-left-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-sm, 0.125rem) !important;
}

.k-rounded-l-md {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem);
}

.\!k-rounded-l-md {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem) !important;
}

.k-rounded-l-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 0.375rem);
  border-bottom-left-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.\!k-rounded-l-lg {
  border-top-left-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-lg, 0.375rem) !important;
}

.k-rounded-l-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.5rem);
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.5rem);
}

.\!k-rounded-l-xl {
  border-top-left-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-xl, 0.5rem) !important;
}

.k-rounded-l-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 0.75rem);
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 0.75rem);
}

.\!k-rounded-l-xxl {
  border-top-left-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-xxl, 0.75rem) !important;
}

.k-rounded-l-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 1rem);
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 1rem);
}

.\!k-rounded-l-xxxl {
  border-top-left-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
  border-bottom-left-radius: var(--kendo-border-radius-xxxl, 1rem) !important;
}

.k-rounded-l-full {
  border-top-left-radius: var(--kendo-border-radius-full, 9999px);
  border-bottom-left-radius: var(--kendo-border-radius-full, 9999px);
}

.\!k-rounded-l-full {
  border-top-left-radius: var(--kendo-border-radius-full, 9999px) !important;
  border-bottom-left-radius: var(--kendo-border-radius-full, 9999px) !important;
}

.k-border-solid {
  border-style: solid;
}

.\!k-border-solid {
  border-style: solid !important;
}

.k-border-dashed {
  border-style: dashed;
}

.\!k-border-dashed {
  border-style: dashed !important;
}

.k-border-dotted {
  border-style: dotted;
}

.\!k-border-dotted {
  border-style: dotted !important;
}

.k-border-double {
  border-style: double;
}

.\!k-border-double {
  border-style: double !important;
}

.k-border-inset {
  border-style: inset;
}

.\!k-border-inset {
  border-style: inset !important;
}

.k-border-outset {
  border-style: outset;
}

.\!k-border-outset {
  border-style: outset !important;
}

.k-border-hidden {
  border-style: hidden;
}

.\!k-border-hidden {
  border-style: hidden !important;
}

.k-border-none {
  border-style: none;
}

.\!k-border-none {
  border-style: none !important;
}

.k-border-t-solid {
  border-top-style: solid;
}

.\!k-border-t-solid {
  border-top-style: solid !important;
}

.k-border-t-dashed {
  border-top-style: dashed;
}

.\!k-border-t-dashed {
  border-top-style: dashed !important;
}

.k-border-t-dotted {
  border-top-style: dotted;
}

.\!k-border-t-dotted {
  border-top-style: dotted !important;
}

.k-border-t-double {
  border-top-style: double;
}

.\!k-border-t-double {
  border-top-style: double !important;
}

.k-border-t-inset {
  border-top-style: inset;
}

.\!k-border-t-inset {
  border-top-style: inset !important;
}

.k-border-t-outset {
  border-top-style: outset;
}

.\!k-border-t-outset {
  border-top-style: outset !important;
}

.k-border-t-hidden {
  border-top-style: hidden;
}

.\!k-border-t-hidden {
  border-top-style: hidden !important;
}

.k-border-t-none {
  border-top-style: none;
}

.\!k-border-t-none {
  border-top-style: none !important;
}

.k-border-r-solid {
  border-right-style: solid;
}

.\!k-border-r-solid {
  border-right-style: solid !important;
}

.k-border-r-dashed {
  border-right-style: dashed;
}

.\!k-border-r-dashed {
  border-right-style: dashed !important;
}

.k-border-r-dotted {
  border-right-style: dotted;
}

.\!k-border-r-dotted {
  border-right-style: dotted !important;
}

.k-border-r-double {
  border-right-style: double;
}

.\!k-border-r-double {
  border-right-style: double !important;
}

.k-border-r-inset {
  border-right-style: inset;
}

.\!k-border-r-inset {
  border-right-style: inset !important;
}

.k-border-r-outset {
  border-right-style: outset;
}

.\!k-border-r-outset {
  border-right-style: outset !important;
}

.k-border-r-hidden {
  border-right-style: hidden;
}

.\!k-border-r-hidden {
  border-right-style: hidden !important;
}

.k-border-r-none {
  border-right-style: none;
}

.\!k-border-r-none {
  border-right-style: none !important;
}

.k-border-b-solid {
  border-bottom-style: solid;
}

.\!k-border-b-solid {
  border-bottom-style: solid !important;
}

.k-border-b-dashed {
  border-bottom-style: dashed;
}

.\!k-border-b-dashed {
  border-bottom-style: dashed !important;
}

.k-border-b-dotted {
  border-bottom-style: dotted;
}

.\!k-border-b-dotted {
  border-bottom-style: dotted !important;
}

.k-border-b-double {
  border-bottom-style: double;
}

.\!k-border-b-double {
  border-bottom-style: double !important;
}

.k-border-b-inset {
  border-bottom-style: inset;
}

.\!k-border-b-inset {
  border-bottom-style: inset !important;
}

.k-border-b-outset {
  border-bottom-style: outset;
}

.\!k-border-b-outset {
  border-bottom-style: outset !important;
}

.k-border-b-hidden {
  border-bottom-style: hidden;
}

.\!k-border-b-hidden {
  border-bottom-style: hidden !important;
}

.k-border-b-none {
  border-bottom-style: none;
}

.\!k-border-b-none {
  border-bottom-style: none !important;
}

.k-border-l-solid {
  border-left-style: solid;
}

.\!k-border-l-solid {
  border-left-style: solid !important;
}

.k-border-l-dashed {
  border-left-style: dashed;
}

.\!k-border-l-dashed {
  border-left-style: dashed !important;
}

.k-border-l-dotted {
  border-left-style: dotted;
}

.\!k-border-l-dotted {
  border-left-style: dotted !important;
}

.k-border-l-double {
  border-left-style: double;
}

.\!k-border-l-double {
  border-left-style: double !important;
}

.k-border-l-inset {
  border-left-style: inset;
}

.\!k-border-l-inset {
  border-left-style: inset !important;
}

.k-border-l-outset {
  border-left-style: outset;
}

.\!k-border-l-outset {
  border-left-style: outset !important;
}

.k-border-l-hidden {
  border-left-style: hidden;
}

.\!k-border-l-hidden {
  border-left-style: hidden !important;
}

.k-border-l-none {
  border-left-style: none;
}

.\!k-border-l-none {
  border-left-style: none !important;
}

.k-border-x-solid {
  border-inline-style: solid;
}

.\!k-border-x-solid {
  border-inline-style: solid !important;
}

.k-border-x-dashed {
  border-inline-style: dashed;
}

.\!k-border-x-dashed {
  border-inline-style: dashed !important;
}

.k-border-x-dotted {
  border-inline-style: dotted;
}

.\!k-border-x-dotted {
  border-inline-style: dotted !important;
}

.k-border-x-double {
  border-inline-style: double;
}

.\!k-border-x-double {
  border-inline-style: double !important;
}

.k-border-x-inset {
  border-inline-style: inset;
}

.\!k-border-x-inset {
  border-inline-style: inset !important;
}

.k-border-x-outset {
  border-inline-style: outset;
}

.\!k-border-x-outset {
  border-inline-style: outset !important;
}

.k-border-x-hidden {
  border-inline-style: hidden;
}

.\!k-border-x-hidden {
  border-inline-style: hidden !important;
}

.k-border-x-none {
  border-inline-style: none;
}

.\!k-border-x-none {
  border-inline-style: none !important;
}

.k-border-y-solid {
  border-block-style: solid;
}

.\!k-border-y-solid {
  border-block-style: solid !important;
}

.k-border-y-dashed {
  border-block-style: dashed;
}

.\!k-border-y-dashed {
  border-block-style: dashed !important;
}

.k-border-y-dotted {
  border-block-style: dotted;
}

.\!k-border-y-dotted {
  border-block-style: dotted !important;
}

.k-border-y-double {
  border-block-style: double;
}

.\!k-border-y-double {
  border-block-style: double !important;
}

.k-border-y-inset {
  border-block-style: inset;
}

.\!k-border-y-inset {
  border-block-style: inset !important;
}

.k-border-y-outset {
  border-block-style: outset;
}

.\!k-border-y-outset {
  border-block-style: outset !important;
}

.k-border-y-hidden {
  border-block-style: hidden;
}

.\!k-border-y-hidden {
  border-block-style: hidden !important;
}

.k-border-y-none {
  border-block-style: none;
}

.\!k-border-y-none {
  border-block-style: none !important;
}

.k-border {
  border-width: 1px;
}

.\!k-border {
  border-width: 1px !important;
}

.k-border-0 {
  border-width: 0;
}

.\!k-border-0 {
  border-width: 0 !important;
}

.k-border-2 {
  border-width: 2px;
}

.\!k-border-2 {
  border-width: 2px !important;
}

.k-border-4 {
  border-width: 4px;
}

.\!k-border-4 {
  border-width: 4px !important;
}

.k-border-8 {
  border-width: 8px;
}

.\!k-border-8 {
  border-width: 8px !important;
}

.k-border-t {
  border-top-width: 1px;
}

.\!k-border-t {
  border-top-width: 1px !important;
}

.k-border-t-0 {
  border-top-width: 0;
}

.\!k-border-t-0 {
  border-top-width: 0 !important;
}

.k-border-t-2 {
  border-top-width: 2px;
}

.\!k-border-t-2 {
  border-top-width: 2px !important;
}

.k-border-t-4 {
  border-top-width: 4px;
}

.\!k-border-t-4 {
  border-top-width: 4px !important;
}

.k-border-t-8 {
  border-top-width: 8px;
}

.\!k-border-t-8 {
  border-top-width: 8px !important;
}

.k-border-r {
  border-right-width: 1px;
}

.\!k-border-r {
  border-right-width: 1px !important;
}

.k-border-r-0 {
  border-right-width: 0;
}

.\!k-border-r-0 {
  border-right-width: 0 !important;
}

.k-border-r-2 {
  border-right-width: 2px;
}

.\!k-border-r-2 {
  border-right-width: 2px !important;
}

.k-border-r-4 {
  border-right-width: 4px;
}

.\!k-border-r-4 {
  border-right-width: 4px !important;
}

.k-border-r-8 {
  border-right-width: 8px;
}

.\!k-border-r-8 {
  border-right-width: 8px !important;
}

.k-border-b {
  border-bottom-width: 1px;
}

.\!k-border-b {
  border-bottom-width: 1px !important;
}

.k-border-b-0 {
  border-bottom-width: 0;
}

.\!k-border-b-0 {
  border-bottom-width: 0 !important;
}

.k-border-b-2 {
  border-bottom-width: 2px;
}

.\!k-border-b-2 {
  border-bottom-width: 2px !important;
}

.k-border-b-4 {
  border-bottom-width: 4px;
}

.\!k-border-b-4 {
  border-bottom-width: 4px !important;
}

.k-border-b-8 {
  border-bottom-width: 8px;
}

.\!k-border-b-8 {
  border-bottom-width: 8px !important;
}

.k-border-l {
  border-left-width: 1px;
}

.\!k-border-l {
  border-left-width: 1px !important;
}

.k-border-l-0 {
  border-left-width: 0;
}

.\!k-border-l-0 {
  border-left-width: 0 !important;
}

.k-border-l-2 {
  border-left-width: 2px;
}

.\!k-border-l-2 {
  border-left-width: 2px !important;
}

.k-border-l-4 {
  border-left-width: 4px;
}

.\!k-border-l-4 {
  border-left-width: 4px !important;
}

.k-border-l-8 {
  border-left-width: 8px;
}

.\!k-border-l-8 {
  border-left-width: 8px !important;
}

.k-border-x {
  border-inline-width: 1px;
}

.\!k-border-x {
  border-inline-width: 1px !important;
}

.k-border-x-0 {
  border-inline-width: 0;
}

.\!k-border-x-0 {
  border-inline-width: 0 !important;
}

.k-border-x-2 {
  border-inline-width: 2px;
}

.\!k-border-x-2 {
  border-inline-width: 2px !important;
}

.k-border-x-4 {
  border-inline-width: 4px;
}

.\!k-border-x-4 {
  border-inline-width: 4px !important;
}

.k-border-x-8 {
  border-inline-width: 8px;
}

.\!k-border-x-8 {
  border-inline-width: 8px !important;
}

.k-border-y {
  border-block-width: 1px;
}

.\!k-border-y {
  border-block-width: 1px !important;
}

.k-border-y-0 {
  border-block-width: 0;
}

.\!k-border-y-0 {
  border-block-width: 0 !important;
}

.k-border-y-2 {
  border-block-width: 2px;
}

.\!k-border-y-2 {
  border-block-width: 2px !important;
}

.k-border-y-4 {
  border-block-width: 4px;
}

.\!k-border-y-4 {
  border-block-width: 4px !important;
}

.k-border-y-8 {
  border-block-width: 8px;
}

.\!k-border-y-8 {
  border-block-width: 8px !important;
}

.k-border-top {
  border-top-width: 1px;
}

.\!k-border-top {
  border-top-width: 1px !important;
}

.k-border-top-0 {
  border-top-width: 0;
}

.\!k-border-top-0 {
  border-top-width: 0 !important;
}

.k-border-top-2 {
  border-top-width: 2px;
}

.\!k-border-top-2 {
  border-top-width: 2px !important;
}

.k-border-top-4 {
  border-top-width: 4px;
}

.\!k-border-top-4 {
  border-top-width: 4px !important;
}

.k-border-top-8 {
  border-top-width: 8px;
}

.\!k-border-top-8 {
  border-top-width: 8px !important;
}

.k-border-right {
  border-right-width: 1px;
}

.\!k-border-right {
  border-right-width: 1px !important;
}

.k-border-right-0 {
  border-right-width: 0;
}

.\!k-border-right-0 {
  border-right-width: 0 !important;
}

.k-border-right-2 {
  border-right-width: 2px;
}

.\!k-border-right-2 {
  border-right-width: 2px !important;
}

.k-border-right-4 {
  border-right-width: 4px;
}

.\!k-border-right-4 {
  border-right-width: 4px !important;
}

.k-border-right-8 {
  border-right-width: 8px;
}

.\!k-border-right-8 {
  border-right-width: 8px !important;
}

.k-border-bottom {
  border-bottom-width: 1px;
}

.\!k-border-bottom {
  border-bottom-width: 1px !important;
}

.k-border-bottom-0 {
  border-bottom-width: 0;
}

.\!k-border-bottom-0 {
  border-bottom-width: 0 !important;
}

.k-border-bottom-2 {
  border-bottom-width: 2px;
}

.\!k-border-bottom-2 {
  border-bottom-width: 2px !important;
}

.k-border-bottom-4 {
  border-bottom-width: 4px;
}

.\!k-border-bottom-4 {
  border-bottom-width: 4px !important;
}

.k-border-bottom-8 {
  border-bottom-width: 8px;
}

.\!k-border-bottom-8 {
  border-bottom-width: 8px !important;
}

.k-border-left {
  border-left-width: 1px;
}

.\!k-border-left {
  border-left-width: 1px !important;
}

.k-border-left-0 {
  border-left-width: 0;
}

.\!k-border-left-0 {
  border-left-width: 0 !important;
}

.k-border-left-2 {
  border-left-width: 2px;
}

.\!k-border-left-2 {
  border-left-width: 2px !important;
}

.k-border-left-4 {
  border-left-width: 4px;
}

.\!k-border-left-4 {
  border-left-width: 4px !important;
}

.k-border-left-8 {
  border-left-width: 8px;
}

.\!k-border-left-8 {
  border-left-width: 8px !important;
}

.k-outline-app-surface {
  outline-color: var(--kendo-color-app-surface, #ffffff);
}

.\!k-outline-app-surface {
  outline-color: var(--kendo-color-app-surface, #ffffff) !important;
}

.k-outline-on-app-surface {
  outline-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.\!k-outline-on-app-surface {
  outline-color: var(--kendo-color-on-app-surface, #3d3d3d) !important;
}

.k-outline-subtle {
  outline-color: var(--kendo-color-subtle, #666666);
}

.\!k-outline-subtle {
  outline-color: var(--kendo-color-subtle, #666666) !important;
}

.k-outline-surface {
  outline-color: var(--kendo-color-surface, #fafafa);
}

.\!k-outline-surface {
  outline-color: var(--kendo-color-surface, #fafafa) !important;
}

.k-outline-surface-alt {
  outline-color: var(--kendo-color-surface-alt, #ffffff);
}

.\!k-outline-surface-alt {
  outline-color: var(--kendo-color-surface-alt, #ffffff) !important;
}

.k-outline-border {
  outline-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.\!k-outline-border {
  outline-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08)) !important;
}

.k-outline-border-alt {
  outline-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}

.\!k-outline-border-alt {
  outline-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16)) !important;
}

.k-outline-base-subtle {
  outline-color: var(--kendo-color-base-subtle, #ebebeb);
}

.\!k-outline-base-subtle {
  outline-color: var(--kendo-color-base-subtle, #ebebeb) !important;
}

.k-outline-base-emphasis {
  outline-color: var(--kendo-color-base-emphasis, #c2c2c2);
}

.\!k-outline-base-emphasis {
  outline-color: var(--kendo-color-base-emphasis, #c2c2c2) !important;
}

.k-outline-primary-subtle {
  outline-color: var(--kendo-color-primary-subtle, #ffeceb);
}

.\!k-outline-primary-subtle {
  outline-color: var(--kendo-color-primary-subtle, #ffeceb) !important;
}

.k-outline-primary {
  outline-color: var(--kendo-color-primary, #ff6358);
}

.\!k-outline-primary {
  outline-color: var(--kendo-color-primary, #ff6358) !important;
}

.k-outline-primary-emphasis {
  outline-color: var(--kendo-color-primary-emphasis, #ff9d97);
}

.\!k-outline-primary-emphasis {
  outline-color: var(--kendo-color-primary-emphasis, #ff9d97) !important;
}

.k-outline-secondary-subtle {
  outline-color: var(--kendo-color-secondary-subtle, #fafafa);
}

.\!k-outline-secondary-subtle {
  outline-color: var(--kendo-color-secondary-subtle, #fafafa) !important;
}

.k-outline-secondary {
  outline-color: var(--kendo-color-secondary, #666666);
}

.\!k-outline-secondary {
  outline-color: var(--kendo-color-secondary, #666666) !important;
}

.k-outline-secondary-emphasis {
  outline-color: var(--kendo-color-secondary-emphasis, #e0e0e0);
}

.\!k-outline-secondary-emphasis {
  outline-color: var(--kendo-color-secondary-emphasis, #e0e0e0) !important;
}

.k-outline-tertiary-subtle {
  outline-color: var(--kendo-color-tertiary-subtle, #d8f1fd);
}

.\!k-outline-tertiary-subtle {
  outline-color: var(--kendo-color-tertiary-subtle, #d8f1fd) !important;
}

.k-outline-tertiary {
  outline-color: var(--kendo-color-tertiary, #03a9f4);
}

.\!k-outline-tertiary {
  outline-color: var(--kendo-color-tertiary, #03a9f4) !important;
}

.k-outline-tertiary-emphasis {
  outline-color: var(--kendo-color-tertiary-emphasis, #61c9f9);
}

.\!k-outline-tertiary-emphasis {
  outline-color: var(--kendo-color-tertiary-emphasis, #61c9f9) !important;
}

.k-outline-info-subtle {
  outline-color: var(--kendo-color-info-subtle, #d2e2fb);
}

.\!k-outline-info-subtle {
  outline-color: var(--kendo-color-info-subtle, #d2e2fb) !important;
}

.k-outline-info {
  outline-color: var(--kendo-color-info, #0058e9);
}

.\!k-outline-info {
  outline-color: var(--kendo-color-info, #0058e9) !important;
}

.k-outline-info-emphasis {
  outline-color: var(--kendo-color-info-emphasis, #6098f2);
}

.\!k-outline-info-emphasis {
  outline-color: var(--kendo-color-info-emphasis, #6098f2) !important;
}

.k-outline-success-subtle {
  outline-color: var(--kendo-color-success-subtle, #dcf0d3);
}

.\!k-outline-success-subtle {
  outline-color: var(--kendo-color-success-subtle, #dcf0d3) !important;
}

.k-outline-success {
  outline-color: var(--kendo-color-success, #37b400);
}

.\!k-outline-success {
  outline-color: var(--kendo-color-success, #37b400) !important;
}

.k-outline-success-emphasis {
  outline-color: var(--kendo-color-success-emphasis, #81d15f);
}

.\!k-outline-success-emphasis {
  outline-color: var(--kendo-color-success-emphasis, #81d15f) !important;
}

.k-outline-warning-subtle {
  outline-color: var(--kendo-color-warning-subtle, #fff4d3);
}

.\!k-outline-warning-subtle {
  outline-color: var(--kendo-color-warning-subtle, #fff4d3) !important;
}

.k-outline-warning {
  outline-color: var(--kendo-color-warning, #ffc000);
}

.\!k-outline-warning {
  outline-color: var(--kendo-color-warning, #ffc000) !important;
}

.k-outline-warning-emphasis {
  outline-color: var(--kendo-color-warning-emphasis, #ffd760);
}

.\!k-outline-warning-emphasis {
  outline-color: var(--kendo-color-warning-emphasis, #ffd760) !important;
}

.k-outline-error-subtle {
  outline-color: var(--kendo-color-error-subtle, #fcddda);
}

.\!k-outline-error-subtle {
  outline-color: var(--kendo-color-error-subtle, #fcddda) !important;
}

.k-outline-error {
  outline-color: var(--kendo-color-error, #f31700);
}

.\!k-outline-error {
  outline-color: var(--kendo-color-error, #f31700) !important;
}

.k-outline-error-emphasis {
  outline-color: var(--kendo-color-error-emphasis, #f76f60);
}

.\!k-outline-error-emphasis {
  outline-color: var(--kendo-color-error-emphasis, #f76f60) !important;
}

.k-outline-light-subtle {
  outline-color: var(--kendo-color-light-subtle, #fafafa);
}

.\!k-outline-light-subtle {
  outline-color: var(--kendo-color-light-subtle, #fafafa) !important;
}

.k-outline-light {
  outline-color: var(--kendo-color-light, #ebebeb);
}

.\!k-outline-light {
  outline-color: var(--kendo-color-light, #ebebeb) !important;
}

.k-outline-light-emphasis {
  outline-color: var(--kendo-color-light-emphasis, #d6d6d6);
}

.\!k-outline-light-emphasis {
  outline-color: var(--kendo-color-light-emphasis, #d6d6d6) !important;
}

.k-outline-dark-subtle {
  outline-color: var(--kendo-color-dark-subtle, #c2c2c2);
}

.\!k-outline-dark-subtle {
  outline-color: var(--kendo-color-dark-subtle, #c2c2c2) !important;
}

.k-outline-dark {
  outline-color: var(--kendo-color-dark, #3d3d3d);
}

.\!k-outline-dark {
  outline-color: var(--kendo-color-dark, #3d3d3d) !important;
}

.k-outline-dark-emphasis {
  outline-color: var(--kendo-color-dark-emphasis, #666666);
}

.\!k-outline-dark-emphasis {
  outline-color: var(--kendo-color-dark-emphasis, #666666) !important;
}

.k-outline-inherit {
  outline-color: var(--kendo-color-inherit, inherit);
}

.\!k-outline-inherit {
  outline-color: var(--kendo-color-inherit, inherit) !important;
}

.k-outline-current {
  outline-color: var(--kendo-color-current, currentColor);
}

.\!k-outline-current {
  outline-color: var(--kendo-color-current, currentColor) !important;
}

.k-outline-transparent {
  outline-color: var(--kendo-color-transparent, transparent);
}

.\!k-outline-transparent {
  outline-color: var(--kendo-color-transparent, transparent) !important;
}

.k-outline-black {
  outline-color: var(--kendo-color-black, black);
}

.\!k-outline-black {
  outline-color: var(--kendo-color-black, black) !important;
}

.k-outline-white {
  outline-color: var(--kendo-color-white, white);
}

.\!k-outline-white {
  outline-color: var(--kendo-color-white, white) !important;
}

.k-outline-offset-0 {
  outline-offset: 0;
}

.\!k-outline-offset-0 {
  outline-offset: 0 !important;
}

.k-outline-offset-1 {
  outline-offset: 1px;
}

.\!k-outline-offset-1 {
  outline-offset: 1px !important;
}

.k-outline-offset-2 {
  outline-offset: 2px;
}

.\!k-outline-offset-2 {
  outline-offset: 2px !important;
}

.k-outline-offset-4 {
  outline-offset: 4px;
}

.\!k-outline-offset-4 {
  outline-offset: 4px !important;
}

.k-outline-offset-8 {
  outline-offset: 8px;
}

.\!k-outline-offset-8 {
  outline-offset: 8px !important;
}

.k-outline-solid {
  outline-style: solid;
}

.\!k-outline-solid {
  outline-style: solid !important;
}

.k-outline-dashed {
  outline-style: dashed;
}

.\!k-outline-dashed {
  outline-style: dashed !important;
}

.k-outline-dotted {
  outline-style: dotted;
}

.\!k-outline-dotted {
  outline-style: dotted !important;
}

.k-outline-double {
  outline-style: double;
}

.\!k-outline-double {
  outline-style: double !important;
}

.k-outline-inset {
  outline-style: inset;
}

.\!k-outline-inset {
  outline-style: inset !important;
}

.k-outline-outset {
  outline-style: outset;
}

.\!k-outline-outset {
  outline-style: outset !important;
}

.k-outline-none {
  outline-style: none;
}

.\!k-outline-none {
  outline-style: none !important;
}

.k-outline {
  outline-width: 1px;
}

.\!k-outline {
  outline-width: 1px !important;
}

.k-outline-0 {
  outline-width: 0;
}

.\!k-outline-0 {
  outline-width: 0 !important;
}

.k-outline-2 {
  outline-width: 2px;
}

.\!k-outline-2 {
  outline-width: 2px !important;
}

.k-outline-4 {
  outline-width: 4px;
}

.\!k-outline-4 {
  outline-width: 4px !important;
}

.k-outline-8 {
  outline-width: 8px;
}

.\!k-outline-8 {
  outline-width: 8px !important;
}

.k-opacity-0 {
  opacity: 0;
}

.\!k-opacity-0 {
  opacity: 0 !important;
}

.k-opacity-5 {
  opacity: 0.05;
}

.\!k-opacity-5 {
  opacity: 0.05 !important;
}

.k-opacity-10 {
  opacity: 0.1;
}

.\!k-opacity-10 {
  opacity: 0.1 !important;
}

.k-opacity-20 {
  opacity: 0.2;
}

.\!k-opacity-20 {
  opacity: 0.2 !important;
}

.k-opacity-30 {
  opacity: 0.3;
}

.\!k-opacity-30 {
  opacity: 0.3 !important;
}

.k-opacity-40 {
  opacity: 0.4;
}

.\!k-opacity-40 {
  opacity: 0.4 !important;
}

.k-opacity-50 {
  opacity: 0.5;
}

.\!k-opacity-50 {
  opacity: 0.5 !important;
}

.k-opacity-60 {
  opacity: 0.6;
}

.\!k-opacity-60 {
  opacity: 0.6 !important;
}

.k-opacity-70 {
  opacity: 0.7;
}

.\!k-opacity-70 {
  opacity: 0.7 !important;
}

.k-opacity-80 {
  opacity: 0.8;
}

.\!k-opacity-80 {
  opacity: 0.8 !important;
}

.k-opacity-90 {
  opacity: 0.9;
}

.\!k-opacity-90 {
  opacity: 0.9 !important;
}

.k-opacity-100 {
  opacity: 1;
}

.\!k-opacity-100 {
  opacity: 1 !important;
}

.k-backdrop-blur {
  backdrop-filter: blur(8px);
}

.\!k-backdrop-blur {
  backdrop-filter: blur(8px) !important;
}

.k-backdrop-blur-none {
  backdrop-filter: blur(0);
}

.\!k-backdrop-blur-none {
  backdrop-filter: blur(0) !important;
}

.k-backdrop-blur-sm {
  backdrop-filter: blur(4px);
}

.\!k-backdrop-blur-sm {
  backdrop-filter: blur(4px) !important;
}

.k-backdrop-blur-md {
  backdrop-filter: blur(6px);
}

.\!k-backdrop-blur-md {
  backdrop-filter: blur(6px) !important;
}

.k-backdrop-blur-lg {
  backdrop-filter: blur(12px);
}

.\!k-backdrop-blur-lg {
  backdrop-filter: blur(12px) !important;
}

.k-border-collapse {
  border-collapse: collapse;
}

.\!k-border-collapse {
  border-collapse: collapse !important;
}

.k-border-separate {
  border-collapse: separate;
}

.\!k-border-separate {
  border-collapse: separate !important;
}

.k-table-auto {
  table-layout: auto;
}

.\!k-table-auto {
  table-layout: auto !important;
}

.k-table-fixed {
  table-layout: fixed;
}

.\!k-table-fixed {
  table-layout: fixed !important;
}

.k-table-layout-auto {
  table-layout: auto;
}

.\!k-table-layout-auto {
  table-layout: auto !important;
}

.k-table-layout-fixed {
  table-layout: fixed;
}

.\!k-table-layout-fixed {
  table-layout: fixed !important;
}

.k-flip-h, .k-flip-x {
  transform: scaleX(-1);
}

.\!k-flip-h, .\!k-flip-x {
  transform: scaleX(-1) !important;
}

.k-flip-v, .k-flip-y {
  transform: scaleY(-1);
}

.\!k-flip-v, .\!k-flip-y {
  transform: scaleY(-1) !important;
}

.k-flip-h.k-flip-v, .k-flip-h.k-flip-y, .k-flip-v.k-flip-x, .k-flip-x.k-flip-y {
  transform: scale(-1, -1);
}

.\!k-flip-h.\!k-flip-v, .\!k-flip-h.\!k-flip-y, .\!k-flip-v.\!k-flip-x, .\!k-flip-x.\!k-flip-y {
  transform: scale(-1, -1) !important;
}

.k-origin-center {
  transform-origin: center;
}

.\!k-origin-center {
  transform-origin: center !important;
}

.k-origin-top {
  transform-origin: top;
}

.\!k-origin-top {
  transform-origin: top !important;
}

.k-origin-right {
  transform-origin: right;
}

.\!k-origin-right {
  transform-origin: right !important;
}

.k-origin-bottom {
  transform-origin: bottom;
}

.\!k-origin-bottom {
  transform-origin: bottom !important;
}

.k-origin-left {
  transform-origin: left;
}

.\!k-origin-left {
  transform-origin: left !important;
}

.k-origin-top-left {
  transform-origin: top left;
}

.\!k-origin-top-left {
  transform-origin: top left !important;
}

.k-origin-top-right {
  transform-origin: top right;
}

.\!k-origin-top-right {
  transform-origin: top right !important;
}

.k-origin-bottom-left {
  transform-origin: bottom left;
}

.\!k-origin-bottom-left {
  transform-origin: bottom left !important;
}

.k-origin-bottom-right {
  transform-origin: bottom right;
}

.\!k-origin-bottom-right {
  transform-origin: bottom right !important;
}

.k-rotate-0 {
  transform: rotate(0deg);
}

.\!k-rotate-0 {
  transform: rotate(0deg) !important;
}

.k-rotate-45 {
  transform: rotate(45deg);
}

.\!k-rotate-45 {
  transform: rotate(45deg) !important;
}

.k-rotate-90 {
  transform: rotate(90deg);
}

.\!k-rotate-90 {
  transform: rotate(90deg) !important;
}

.k-rotate-135 {
  transform: rotate(135deg);
}

.\!k-rotate-135 {
  transform: rotate(135deg) !important;
}

.k-rotate-180 {
  transform: rotate(180deg);
}

.\!k-rotate-180 {
  transform: rotate(180deg) !important;
}

.k-rotate-225 {
  transform: rotate(225deg);
}

.\!k-rotate-225 {
  transform: rotate(225deg) !important;
}

.k-rotate-270 {
  transform: rotate(270deg);
}

.\!k-rotate-270 {
  transform: rotate(270deg) !important;
}

.k-rotate-315 {
  transform: rotate(315deg);
}

.\!k-rotate-315 {
  transform: rotate(315deg) !important;
}

.k-scale-0 {
  transform: scale(0);
}

.\!k-scale-0 {
  transform: scale(0) !important;
}

.k-scale-0\.25 {
  transform: scale(0.25);
}

.\!k-scale-0\.25 {
  transform: scale(0.25) !important;
}

.k-scale-0\.5 {
  transform: scale(0.5);
}

.\!k-scale-0\.5 {
  transform: scale(0.5) !important;
}

.k-scale-0\.75 {
  transform: scale(0.75);
}

.\!k-scale-0\.75 {
  transform: scale(0.75) !important;
}

.k-scale-1 {
  transform: scale(1);
}

.\!k-scale-1 {
  transform: scale(1) !important;
}

.k-scale-1\.25 {
  transform: scale(1.25);
}

.\!k-scale-1\.25 {
  transform: scale(1.25) !important;
}

.k-scale-1\.5 {
  transform: scale(1.5);
}

.\!k-scale-1\.5 {
  transform: scale(1.5) !important;
}

.k-scale-2 {
  transform: scale(2);
}

.\!k-scale-2 {
  transform: scale(2) !important;
}

.k-scale-x-0 {
  transform: scaleX(0);
}

.\!k-scale-x-0 {
  transform: scaleX(0) !important;
}

.k-scale-x-0\.25 {
  transform: scaleX(0.25);
}

.\!k-scale-x-0\.25 {
  transform: scaleX(0.25) !important;
}

.k-scale-x-0\.5 {
  transform: scaleX(0.5);
}

.\!k-scale-x-0\.5 {
  transform: scaleX(0.5) !important;
}

.k-scale-x-0\.75 {
  transform: scaleX(0.75);
}

.\!k-scale-x-0\.75 {
  transform: scaleX(0.75) !important;
}

.k-scale-x-1 {
  transform: scaleX(1);
}

.\!k-scale-x-1 {
  transform: scaleX(1) !important;
}

.k-scale-x-1\.25 {
  transform: scaleX(1.25);
}

.\!k-scale-x-1\.25 {
  transform: scaleX(1.25) !important;
}

.k-scale-x-1\.5 {
  transform: scaleX(1.5);
}

.\!k-scale-x-1\.5 {
  transform: scaleX(1.5) !important;
}

.k-scale-x-2 {
  transform: scaleX(2);
}

.\!k-scale-x-2 {
  transform: scaleX(2) !important;
}

.k-scale-y-0 {
  transform: scaleY(0);
}

.\!k-scale-y-0 {
  transform: scaleY(0) !important;
}

.k-scale-y-0\.25 {
  transform: scaleY(0.25);
}

.\!k-scale-y-0\.25 {
  transform: scaleY(0.25) !important;
}

.k-scale-y-0\.5 {
  transform: scaleY(0.5);
}

.\!k-scale-y-0\.5 {
  transform: scaleY(0.5) !important;
}

.k-scale-y-0\.75 {
  transform: scaleY(0.75);
}

.\!k-scale-y-0\.75 {
  transform: scaleY(0.75) !important;
}

.k-scale-y-1 {
  transform: scaleY(1);
}

.\!k-scale-y-1 {
  transform: scaleY(1) !important;
}

.k-scale-y-1\.25 {
  transform: scaleY(1.25);
}

.\!k-scale-y-1\.25 {
  transform: scaleY(1.25) !important;
}

.k-scale-y-1\.5 {
  transform: scaleY(1.5);
}

.\!k-scale-y-1\.5 {
  transform: scaleY(1.5) !important;
}

.k-scale-y-2 {
  transform: scaleY(2);
}

.\!k-scale-y-2 {
  transform: scaleY(2) !important;
}

.k-skew-x-0 {
  transform: skewX(0deg);
}

.\!k-skew-x-0 {
  transform: skewX(0deg) !important;
}

.k-skew-x-1 {
  transform: skewX(1deg);
}

.\!k-skew-x-1 {
  transform: skewX(1deg) !important;
}

.k-skew-x-2 {
  transform: skewX(2deg);
}

.\!k-skew-x-2 {
  transform: skewX(2deg) !important;
}

.k-skew-x-3 {
  transform: skewX(3deg);
}

.\!k-skew-x-3 {
  transform: skewX(3deg) !important;
}

.k-skew-x-4 {
  transform: skewX(4deg);
}

.\!k-skew-x-4 {
  transform: skewX(4deg) !important;
}

.k-skew-x-5 {
  transform: skewX(5deg);
}

.\!k-skew-x-5 {
  transform: skewX(5deg) !important;
}

.k-skew-x-6 {
  transform: skewX(6deg);
}

.\!k-skew-x-6 {
  transform: skewX(6deg) !important;
}

.k-skew-x-7 {
  transform: skewX(7deg);
}

.\!k-skew-x-7 {
  transform: skewX(7deg) !important;
}

.k-skew-x-8 {
  transform: skewX(8deg);
}

.\!k-skew-x-8 {
  transform: skewX(8deg) !important;
}

.k-skew-x-9 {
  transform: skewX(9deg);
}

.\!k-skew-x-9 {
  transform: skewX(9deg) !important;
}

.k-skew-x-10 {
  transform: skewX(10deg);
}

.\!k-skew-x-10 {
  transform: skewX(10deg) !important;
}

.k-skew-x-11 {
  transform: skewX(11deg);
}

.\!k-skew-x-11 {
  transform: skewX(11deg) !important;
}

.k-skew-x-12 {
  transform: skewX(12deg);
}

.\!k-skew-x-12 {
  transform: skewX(12deg) !important;
}

.k-skew-y-0 {
  transform: skewY(0deg);
}

.\!k-skew-y-0 {
  transform: skewY(0deg) !important;
}

.k-skew-y-1 {
  transform: skewY(1deg);
}

.\!k-skew-y-1 {
  transform: skewY(1deg) !important;
}

.k-skew-y-2 {
  transform: skewY(2deg);
}

.\!k-skew-y-2 {
  transform: skewY(2deg) !important;
}

.k-skew-y-3 {
  transform: skewY(3deg);
}

.\!k-skew-y-3 {
  transform: skewY(3deg) !important;
}

.k-skew-y-4 {
  transform: skewY(4deg);
}

.\!k-skew-y-4 {
  transform: skewY(4deg) !important;
}

.k-skew-y-5 {
  transform: skewY(5deg);
}

.\!k-skew-y-5 {
  transform: skewY(5deg) !important;
}

.k-skew-y-6 {
  transform: skewY(6deg);
}

.\!k-skew-y-6 {
  transform: skewY(6deg) !important;
}

.k-skew-y-7 {
  transform: skewY(7deg);
}

.\!k-skew-y-7 {
  transform: skewY(7deg) !important;
}

.k-skew-y-8 {
  transform: skewY(8deg);
}

.\!k-skew-y-8 {
  transform: skewY(8deg) !important;
}

.k-skew-y-9 {
  transform: skewY(9deg);
}

.\!k-skew-y-9 {
  transform: skewY(9deg) !important;
}

.k-skew-y-10 {
  transform: skewY(10deg);
}

.\!k-skew-y-10 {
  transform: skewY(10deg) !important;
}

.k-skew-y-11 {
  transform: skewY(11deg);
}

.\!k-skew-y-11 {
  transform: skewY(11deg) !important;
}

.k-skew-y-12 {
  transform: skewY(12deg);
}

.\!k-skew-y-12 {
  transform: skewY(12deg) !important;
}

.k-translate-0 {
  transform: translate(0, 0);
}

.\!k-translate-0 {
  transform: translate(0, 0) !important;
}

.k-translate-0-50 {
  transform: translate(0, 50%);
}

.\!k-translate-0-50 {
  transform: translate(0, 50%) !important;
}

.k-translate-0-100 {
  transform: translate(0, 100%);
}

.\!k-translate-0-100 {
  transform: translate(0, 100%) !important;
}

.k-translate-50-0 {
  transform: translate(50%, 0);
}

.\!k-translate-50-0 {
  transform: translate(50%, 0) !important;
}

.k-translate-50-50 {
  transform: translate(50%, 50%);
}

.\!k-translate-50-50 {
  transform: translate(50%, 50%) !important;
}

.k-translate-50-100 {
  transform: translate(50%, 100%);
}

.\!k-translate-50-100 {
  transform: translate(50%, 100%) !important;
}

.k-translate-100-0 {
  transform: translate(100%, 0);
}

.\!k-translate-100-0 {
  transform: translate(100%, 0) !important;
}

.k-translate-100-50 {
  transform: translate(100%, 50%);
}

.\!k-translate-100-50 {
  transform: translate(100%, 50%) !important;
}

.k-translate-100-100 {
  transform: translate(100%, 100%);
}

.\!k-translate-100-100 {
  transform: translate(100%, 100%) !important;
}

.k-translate-x-0 {
  transform: translateX(0px);
}

.\!k-translate-x-0 {
  transform: translateX(0px) !important;
}

.k-translate-x-1px {
  transform: translateX(1px);
}

.\!k-translate-x-1px {
  transform: translateX(1px) !important;
}

.k-translate-x-0\.5 {
  transform: translateX(0.125rem);
}

.\!k-translate-x-0\.5 {
  transform: translateX(0.125rem) !important;
}

.k-translate-x-1 {
  transform: translateX(0.25rem);
}

.\!k-translate-x-1 {
  transform: translateX(0.25rem) !important;
}

.k-translate-x-1\.5 {
  transform: translateX(0.375rem);
}

.\!k-translate-x-1\.5 {
  transform: translateX(0.375rem) !important;
}

.k-translate-x-2 {
  transform: translateX(0.5rem);
}

.\!k-translate-x-2 {
  transform: translateX(0.5rem) !important;
}

.k-translate-x-2\.5 {
  transform: translateX(0.625rem);
}

.\!k-translate-x-2\.5 {
  transform: translateX(0.625rem) !important;
}

.k-translate-x-3 {
  transform: translateX(0.75rem);
}

.\!k-translate-x-3 {
  transform: translateX(0.75rem) !important;
}

.k-translate-x-3\.5 {
  transform: translateX(0.875rem);
}

.\!k-translate-x-3\.5 {
  transform: translateX(0.875rem) !important;
}

.k-translate-x-4 {
  transform: translateX(1rem);
}

.\!k-translate-x-4 {
  transform: translateX(1rem) !important;
}

.k-translate-x-4\.5 {
  transform: translateX(1.125rem);
}

.\!k-translate-x-4\.5 {
  transform: translateX(1.125rem) !important;
}

.k-translate-x-5 {
  transform: translateX(1.25rem);
}

.\!k-translate-x-5 {
  transform: translateX(1.25rem) !important;
}

.k-translate-x-5\.5 {
  transform: translateX(1.375rem);
}

.\!k-translate-x-5\.5 {
  transform: translateX(1.375rem) !important;
}

.k-translate-x-6 {
  transform: translateX(1.5rem);
}

.\!k-translate-x-6 {
  transform: translateX(1.5rem) !important;
}

.k-translate-x-6\.5 {
  transform: translateX(1.625rem);
}

.\!k-translate-x-6\.5 {
  transform: translateX(1.625rem) !important;
}

.k-translate-x-7 {
  transform: translateX(1.75rem);
}

.\!k-translate-x-7 {
  transform: translateX(1.75rem) !important;
}

.k-translate-x-7\.5 {
  transform: translateX(1.875rem);
}

.\!k-translate-x-7\.5 {
  transform: translateX(1.875rem) !important;
}

.k-translate-x-8 {
  transform: translateX(2rem);
}

.\!k-translate-x-8 {
  transform: translateX(2rem) !important;
}

.k-translate-x-9 {
  transform: translateX(2.25rem);
}

.\!k-translate-x-9 {
  transform: translateX(2.25rem) !important;
}

.k-translate-x-10 {
  transform: translateX(2.5rem);
}

.\!k-translate-x-10 {
  transform: translateX(2.5rem) !important;
}

.k-translate-x-11 {
  transform: translateX(2.75rem);
}

.\!k-translate-x-11 {
  transform: translateX(2.75rem) !important;
}

.k-translate-x-12 {
  transform: translateX(3rem);
}

.\!k-translate-x-12 {
  transform: translateX(3rem) !important;
}

.k-translate-x-13 {
  transform: translateX(3.25rem);
}

.\!k-translate-x-13 {
  transform: translateX(3.25rem) !important;
}

.k-translate-x-14 {
  transform: translateX(3.5rem);
}

.\!k-translate-x-14 {
  transform: translateX(3.5rem) !important;
}

.k-translate-x-15 {
  transform: translateX(3.75rem);
}

.\!k-translate-x-15 {
  transform: translateX(3.75rem) !important;
}

.k-translate-x-16 {
  transform: translateX(4rem);
}

.\!k-translate-x-16 {
  transform: translateX(4rem) !important;
}

.k-translate-x-17 {
  transform: translateX(4.25rem);
}

.\!k-translate-x-17 {
  transform: translateX(4.25rem) !important;
}

.k-translate-x-18 {
  transform: translateX(4.5rem);
}

.\!k-translate-x-18 {
  transform: translateX(4.5rem) !important;
}

.k-translate-x-19 {
  transform: translateX(4.75rem);
}

.\!k-translate-x-19 {
  transform: translateX(4.75rem) !important;
}

.k-translate-x-20 {
  transform: translateX(5rem);
}

.\!k-translate-x-20 {
  transform: translateX(5rem) !important;
}

.k-translate-x-21 {
  transform: translateX(5.25rem);
}

.\!k-translate-x-21 {
  transform: translateX(5.25rem) !important;
}

.k-translate-x-22 {
  transform: translateX(5.5rem);
}

.\!k-translate-x-22 {
  transform: translateX(5.5rem) !important;
}

.k-translate-x-23 {
  transform: translateX(5.75rem);
}

.\!k-translate-x-23 {
  transform: translateX(5.75rem) !important;
}

.k-translate-x-24 {
  transform: translateX(6rem);
}

.\!k-translate-x-24 {
  transform: translateX(6rem) !important;
}

.k-translate-x-25 {
  transform: translateX(7rem);
}

.\!k-translate-x-25 {
  transform: translateX(7rem) !important;
}

.k-translate-x-26 {
  transform: translateX(8rem);
}

.\!k-translate-x-26 {
  transform: translateX(8rem) !important;
}

.k-translate-x-27 {
  transform: translateX(9rem);
}

.\!k-translate-x-27 {
  transform: translateX(9rem) !important;
}

.k-translate-x-28 {
  transform: translateX(10rem);
}

.\!k-translate-x-28 {
  transform: translateX(10rem) !important;
}

.k-translate-x-29 {
  transform: translateX(11rem);
}

.\!k-translate-x-29 {
  transform: translateX(11rem) !important;
}

.k-translate-x-30 {
  transform: translateX(12rem);
}

.\!k-translate-x-30 {
  transform: translateX(12rem) !important;
}

.k-translate-x-1\/2 {
  transform: translateX(50%);
}

.\!k-translate-x-1\/2 {
  transform: translateX(50%) !important;
}

.k-translate-x-1\/3 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-1\/3 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-2\/3 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-2\/3 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-1\/4 {
  transform: translateX(25%);
}

.\!k-translate-x-1\/4 {
  transform: translateX(25%) !important;
}

.k-translate-x-2\/4 {
  transform: translateX(50%);
}

.\!k-translate-x-2\/4 {
  transform: translateX(50%) !important;
}

.k-translate-x-3\/4 {
  transform: translateX(75%);
}

.\!k-translate-x-3\/4 {
  transform: translateX(75%) !important;
}

.k-translate-x-1\/5 {
  transform: translateX(20%);
}

.\!k-translate-x-1\/5 {
  transform: translateX(20%) !important;
}

.k-translate-x-2\/5 {
  transform: translateX(40%);
}

.\!k-translate-x-2\/5 {
  transform: translateX(40%) !important;
}

.k-translate-x-3\/5 {
  transform: translateX(60%);
}

.\!k-translate-x-3\/5 {
  transform: translateX(60%) !important;
}

.k-translate-x-4\/5 {
  transform: translateX(80%);
}

.\!k-translate-x-4\/5 {
  transform: translateX(80%) !important;
}

.k-translate-x-1\/6 {
  transform: translateX(16.666667%);
}

.\!k-translate-x-1\/6 {
  transform: translateX(16.666667%) !important;
}

.k-translate-x-2\/6 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-2\/6 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-3\/6 {
  transform: translateX(50%);
}

.\!k-translate-x-3\/6 {
  transform: translateX(50%) !important;
}

.k-translate-x-4\/6 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-4\/6 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-5\/6 {
  transform: translateX(83.333333%);
}

.\!k-translate-x-5\/6 {
  transform: translateX(83.333333%) !important;
}

.k-translate-x-1\/12 {
  transform: translateX(8.333333%);
}

.\!k-translate-x-1\/12 {
  transform: translateX(8.333333%) !important;
}

.k-translate-x-2\/12 {
  transform: translateX(16.666667%);
}

.\!k-translate-x-2\/12 {
  transform: translateX(16.666667%) !important;
}

.k-translate-x-3\/12 {
  transform: translateX(25%);
}

.\!k-translate-x-3\/12 {
  transform: translateX(25%) !important;
}

.k-translate-x-4\/12 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-4\/12 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-5\/12 {
  transform: translateX(41.666667%);
}

.\!k-translate-x-5\/12 {
  transform: translateX(41.666667%) !important;
}

.k-translate-x-6\/12 {
  transform: translateX(50%);
}

.\!k-translate-x-6\/12 {
  transform: translateX(50%) !important;
}

.k-translate-x-7\/12 {
  transform: translateX(58.333333%);
}

.\!k-translate-x-7\/12 {
  transform: translateX(58.333333%) !important;
}

.k-translate-x-8\/12 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-8\/12 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-9\/12 {
  transform: translateX(75%);
}

.\!k-translate-x-9\/12 {
  transform: translateX(75%) !important;
}

.k-translate-x-10\/12 {
  transform: translateX(83.333333%);
}

.\!k-translate-x-10\/12 {
  transform: translateX(83.333333%) !important;
}

.k-translate-x-11\/12 {
  transform: translateX(91.666667%);
}

.\!k-translate-x-11\/12 {
  transform: translateX(91.666667%) !important;
}

.k-translate-x-full {
  transform: translateX(100%);
}

.\!k-translate-x-full {
  transform: translateX(100%) !important;
}

.k-translate-y-0 {
  transform: translateY(0px);
}

.\!k-translate-y-0 {
  transform: translateY(0px) !important;
}

.k-translate-y-1px {
  transform: translateY(1px);
}

.\!k-translate-y-1px {
  transform: translateY(1px) !important;
}

.k-translate-y-0\.5 {
  transform: translateY(0.125rem);
}

.\!k-translate-y-0\.5 {
  transform: translateY(0.125rem) !important;
}

.k-translate-y-1 {
  transform: translateY(0.25rem);
}

.\!k-translate-y-1 {
  transform: translateY(0.25rem) !important;
}

.k-translate-y-1\.5 {
  transform: translateY(0.375rem);
}

.\!k-translate-y-1\.5 {
  transform: translateY(0.375rem) !important;
}

.k-translate-y-2 {
  transform: translateY(0.5rem);
}

.\!k-translate-y-2 {
  transform: translateY(0.5rem) !important;
}

.k-translate-y-2\.5 {
  transform: translateY(0.625rem);
}

.\!k-translate-y-2\.5 {
  transform: translateY(0.625rem) !important;
}

.k-translate-y-3 {
  transform: translateY(0.75rem);
}

.\!k-translate-y-3 {
  transform: translateY(0.75rem) !important;
}

.k-translate-y-3\.5 {
  transform: translateY(0.875rem);
}

.\!k-translate-y-3\.5 {
  transform: translateY(0.875rem) !important;
}

.k-translate-y-4 {
  transform: translateY(1rem);
}

.\!k-translate-y-4 {
  transform: translateY(1rem) !important;
}

.k-translate-y-4\.5 {
  transform: translateY(1.125rem);
}

.\!k-translate-y-4\.5 {
  transform: translateY(1.125rem) !important;
}

.k-translate-y-5 {
  transform: translateY(1.25rem);
}

.\!k-translate-y-5 {
  transform: translateY(1.25rem) !important;
}

.k-translate-y-5\.5 {
  transform: translateY(1.375rem);
}

.\!k-translate-y-5\.5 {
  transform: translateY(1.375rem) !important;
}

.k-translate-y-6 {
  transform: translateY(1.5rem);
}

.\!k-translate-y-6 {
  transform: translateY(1.5rem) !important;
}

.k-translate-y-6\.5 {
  transform: translateY(1.625rem);
}

.\!k-translate-y-6\.5 {
  transform: translateY(1.625rem) !important;
}

.k-translate-y-7 {
  transform: translateY(1.75rem);
}

.\!k-translate-y-7 {
  transform: translateY(1.75rem) !important;
}

.k-translate-y-7\.5 {
  transform: translateY(1.875rem);
}

.\!k-translate-y-7\.5 {
  transform: translateY(1.875rem) !important;
}

.k-translate-y-8 {
  transform: translateY(2rem);
}

.\!k-translate-y-8 {
  transform: translateY(2rem) !important;
}

.k-translate-y-9 {
  transform: translateY(2.25rem);
}

.\!k-translate-y-9 {
  transform: translateY(2.25rem) !important;
}

.k-translate-y-10 {
  transform: translateY(2.5rem);
}

.\!k-translate-y-10 {
  transform: translateY(2.5rem) !important;
}

.k-translate-y-11 {
  transform: translateY(2.75rem);
}

.\!k-translate-y-11 {
  transform: translateY(2.75rem) !important;
}

.k-translate-y-12 {
  transform: translateY(3rem);
}

.\!k-translate-y-12 {
  transform: translateY(3rem) !important;
}

.k-translate-y-13 {
  transform: translateY(3.25rem);
}

.\!k-translate-y-13 {
  transform: translateY(3.25rem) !important;
}

.k-translate-y-14 {
  transform: translateY(3.5rem);
}

.\!k-translate-y-14 {
  transform: translateY(3.5rem) !important;
}

.k-translate-y-15 {
  transform: translateY(3.75rem);
}

.\!k-translate-y-15 {
  transform: translateY(3.75rem) !important;
}

.k-translate-y-16 {
  transform: translateY(4rem);
}

.\!k-translate-y-16 {
  transform: translateY(4rem) !important;
}

.k-translate-y-17 {
  transform: translateY(4.25rem);
}

.\!k-translate-y-17 {
  transform: translateY(4.25rem) !important;
}

.k-translate-y-18 {
  transform: translateY(4.5rem);
}

.\!k-translate-y-18 {
  transform: translateY(4.5rem) !important;
}

.k-translate-y-19 {
  transform: translateY(4.75rem);
}

.\!k-translate-y-19 {
  transform: translateY(4.75rem) !important;
}

.k-translate-y-20 {
  transform: translateY(5rem);
}

.\!k-translate-y-20 {
  transform: translateY(5rem) !important;
}

.k-translate-y-21 {
  transform: translateY(5.25rem);
}

.\!k-translate-y-21 {
  transform: translateY(5.25rem) !important;
}

.k-translate-y-22 {
  transform: translateY(5.5rem);
}

.\!k-translate-y-22 {
  transform: translateY(5.5rem) !important;
}

.k-translate-y-23 {
  transform: translateY(5.75rem);
}

.\!k-translate-y-23 {
  transform: translateY(5.75rem) !important;
}

.k-translate-y-24 {
  transform: translateY(6rem);
}

.\!k-translate-y-24 {
  transform: translateY(6rem) !important;
}

.k-translate-y-25 {
  transform: translateY(7rem);
}

.\!k-translate-y-25 {
  transform: translateY(7rem) !important;
}

.k-translate-y-26 {
  transform: translateY(8rem);
}

.\!k-translate-y-26 {
  transform: translateY(8rem) !important;
}

.k-translate-y-27 {
  transform: translateY(9rem);
}

.\!k-translate-y-27 {
  transform: translateY(9rem) !important;
}

.k-translate-y-28 {
  transform: translateY(10rem);
}

.\!k-translate-y-28 {
  transform: translateY(10rem) !important;
}

.k-translate-y-29 {
  transform: translateY(11rem);
}

.\!k-translate-y-29 {
  transform: translateY(11rem) !important;
}

.k-translate-y-30 {
  transform: translateY(12rem);
}

.\!k-translate-y-30 {
  transform: translateY(12rem) !important;
}

.k-translate-y-1\/2 {
  transform: translateY(50%);
}

.\!k-translate-y-1\/2 {
  transform: translateY(50%) !important;
}

.k-translate-y-1\/3 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-1\/3 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-2\/3 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-2\/3 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-1\/4 {
  transform: translateY(25%);
}

.\!k-translate-y-1\/4 {
  transform: translateY(25%) !important;
}

.k-translate-y-2\/4 {
  transform: translateY(50%);
}

.\!k-translate-y-2\/4 {
  transform: translateY(50%) !important;
}

.k-translate-y-3\/4 {
  transform: translateY(75%);
}

.\!k-translate-y-3\/4 {
  transform: translateY(75%) !important;
}

.k-translate-y-1\/5 {
  transform: translateY(20%);
}

.\!k-translate-y-1\/5 {
  transform: translateY(20%) !important;
}

.k-translate-y-2\/5 {
  transform: translateY(40%);
}

.\!k-translate-y-2\/5 {
  transform: translateY(40%) !important;
}

.k-translate-y-3\/5 {
  transform: translateY(60%);
}

.\!k-translate-y-3\/5 {
  transform: translateY(60%) !important;
}

.k-translate-y-4\/5 {
  transform: translateY(80%);
}

.\!k-translate-y-4\/5 {
  transform: translateY(80%) !important;
}

.k-translate-y-1\/6 {
  transform: translateY(16.666667%);
}

.\!k-translate-y-1\/6 {
  transform: translateY(16.666667%) !important;
}

.k-translate-y-2\/6 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-2\/6 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-3\/6 {
  transform: translateY(50%);
}

.\!k-translate-y-3\/6 {
  transform: translateY(50%) !important;
}

.k-translate-y-4\/6 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-4\/6 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-5\/6 {
  transform: translateY(83.333333%);
}

.\!k-translate-y-5\/6 {
  transform: translateY(83.333333%) !important;
}

.k-translate-y-1\/12 {
  transform: translateY(8.333333%);
}

.\!k-translate-y-1\/12 {
  transform: translateY(8.333333%) !important;
}

.k-translate-y-2\/12 {
  transform: translateY(16.666667%);
}

.\!k-translate-y-2\/12 {
  transform: translateY(16.666667%) !important;
}

.k-translate-y-3\/12 {
  transform: translateY(25%);
}

.\!k-translate-y-3\/12 {
  transform: translateY(25%) !important;
}

.k-translate-y-4\/12 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-4\/12 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-5\/12 {
  transform: translateY(41.666667%);
}

.\!k-translate-y-5\/12 {
  transform: translateY(41.666667%) !important;
}

.k-translate-y-6\/12 {
  transform: translateY(50%);
}

.\!k-translate-y-6\/12 {
  transform: translateY(50%) !important;
}

.k-translate-y-7\/12 {
  transform: translateY(58.333333%);
}

.\!k-translate-y-7\/12 {
  transform: translateY(58.333333%) !important;
}

.k-translate-y-8\/12 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-8\/12 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-9\/12 {
  transform: translateY(75%);
}

.\!k-translate-y-9\/12 {
  transform: translateY(75%) !important;
}

.k-translate-y-10\/12 {
  transform: translateY(83.333333%);
}

.\!k-translate-y-10\/12 {
  transform: translateY(83.333333%) !important;
}

.k-translate-y-11\/12 {
  transform: translateY(91.666667%);
}

.\!k-translate-y-11\/12 {
  transform: translateY(91.666667%) !important;
}

.k-translate-y-full {
  transform: translateY(100%);
}

.\!k-translate-y-full {
  transform: translateY(100%) !important;
}

.k-appearance-none {
  appearance: none;
}

.\!k-appearance-none {
  appearance: none !important;
}

.k-appearance-auto {
  appearance: auto;
}

.\!k-appearance-auto {
  appearance: auto !important;
}

.k-cursor-none {
  cursor: none;
}

.\!k-cursor-none {
  cursor: none !important;
}

.k-cursor-auto {
  cursor: auto;
}

.\!k-cursor-auto {
  cursor: auto !important;
}

.k-cursor-default {
  cursor: default;
}

.\!k-cursor-default {
  cursor: default !important;
}

.k-cursor-context-menu {
  cursor: context-menu;
}

.\!k-cursor-context-menu {
  cursor: context-menu !important;
}

.k-cursor-help {
  cursor: help;
}

.\!k-cursor-help {
  cursor: help !important;
}

.k-cursor-pointer {
  cursor: pointer;
}

.\!k-cursor-pointer {
  cursor: pointer !important;
}

.k-cursor-progress {
  cursor: progress;
}

.\!k-cursor-progress {
  cursor: progress !important;
}

.k-cursor-wait {
  cursor: wait;
}

.\!k-cursor-wait {
  cursor: wait !important;
}

.k-cursor-cell {
  cursor: cell;
}

.\!k-cursor-cell {
  cursor: cell !important;
}

.k-cursor-crosshair {
  cursor: crosshair;
}

.\!k-cursor-crosshair {
  cursor: crosshair !important;
}

.k-cursor-text {
  cursor: text;
}

.\!k-cursor-text {
  cursor: text !important;
}

.k-cursor-vertical-text {
  cursor: vertical-text;
}

.\!k-cursor-vertical-text {
  cursor: vertical-text !important;
}

.k-cursor-alias {
  cursor: alias;
}

.\!k-cursor-alias {
  cursor: alias !important;
}

.k-cursor-copy {
  cursor: copy;
}

.\!k-cursor-copy {
  cursor: copy !important;
}

.k-cursor-move {
  cursor: move;
}

.\!k-cursor-move {
  cursor: move !important;
}

.k-cursor-no-drop {
  cursor: no-drop;
}

.\!k-cursor-no-drop {
  cursor: no-drop !important;
}

.k-cursor-not-allowed {
  cursor: not-allowed;
}

.\!k-cursor-not-allowed {
  cursor: not-allowed !important;
}

.k-cursor-e-resize {
  cursor: e-resize;
}

.\!k-cursor-e-resize {
  cursor: e-resize !important;
}

.k-cursor-n-resize {
  cursor: n-resize;
}

.\!k-cursor-n-resize {
  cursor: n-resize !important;
}

.k-cursor-ne-resize {
  cursor: ne-resize;
}

.\!k-cursor-ne-resize {
  cursor: ne-resize !important;
}

.k-cursor-nw-resize {
  cursor: nw-resize;
}

.\!k-cursor-nw-resize {
  cursor: nw-resize !important;
}

.k-cursor-s-resize {
  cursor: s-resize;
}

.\!k-cursor-s-resize {
  cursor: s-resize !important;
}

.k-cursor-se-resize {
  cursor: se-resize;
}

.\!k-cursor-se-resize {
  cursor: se-resize !important;
}

.k-cursor-sw-resize {
  cursor: sw-resize;
}

.\!k-cursor-sw-resize {
  cursor: sw-resize !important;
}

.k-cursor-w-resize {
  cursor: w-resize;
}

.\!k-cursor-w-resize {
  cursor: w-resize !important;
}

.k-cursor-ew-resize {
  cursor: ew-resize;
}

.\!k-cursor-ew-resize {
  cursor: ew-resize !important;
}

.k-cursor-ns-resize {
  cursor: ns-resize;
}

.\!k-cursor-ns-resize {
  cursor: ns-resize !important;
}

.k-cursor-nesw-resize {
  cursor: nesw-resize;
}

.\!k-cursor-nesw-resize {
  cursor: nesw-resize !important;
}

.k-cursor-nwse-resize {
  cursor: nwse-resize;
}

.\!k-cursor-nwse-resize {
  cursor: nwse-resize !important;
}

.k-cursor-col-resize {
  cursor: col-resize;
}

.\!k-cursor-col-resize {
  cursor: col-resize !important;
}

.k-cursor-row-resize {
  cursor: row-resize;
}

.\!k-cursor-row-resize {
  cursor: row-resize !important;
}

.k-cursor-all-scroll {
  cursor: all-scroll;
}

.\!k-cursor-all-scroll {
  cursor: all-scroll !important;
}

.k-cursor-zoom-in {
  cursor: zoom-in;
}

.\!k-cursor-zoom-in {
  cursor: zoom-in !important;
}

.k-cursor-zoom-out {
  cursor: zoom-out;
}

.\!k-cursor-zoom-out {
  cursor: zoom-out !important;
}

.k-cursor-grab {
  cursor: grab;
}

.\!k-cursor-grab {
  cursor: grab !important;
}

.k-cursor-grabbing {
  cursor: grabbing;
}

.\!k-cursor-grabbing {
  cursor: grabbing !important;
}

.k-pointer-events-none {
  pointer-events: none;
}

.\!k-pointer-events-none {
  pointer-events: none !important;
}

.k-pointer-events-auto {
  pointer-events: auto;
}

.\!k-pointer-events-auto {
  pointer-events: auto !important;
}

.k-resize {
  resize: both;
}

.\!k-resize {
  resize: both !important;
}

.k-resize-none {
  resize: none;
}

.\!k-resize-none {
  resize: none !important;
}

.k-resize-x {
  resize: horizontal;
}

.\!k-resize-x {
  resize: horizontal !important;
}

.k-resize-y {
  resize: vertical;
}

.\!k-resize-y {
  resize: vertical !important;
}

.k-scroll-auto {
  scroll-behavior: auto;
}

.\!k-scroll-auto {
  scroll-behavior: auto !important;
}

.k-scroll-smooth {
  scroll-behavior: smooth;
}

.\!k-scroll-smooth {
  scroll-behavior: smooth !important;
}

.k-scroll-snap-none {
  scroll-snap-type: none;
}

.\!k-scroll-snap-none {
  scroll-snap-type: none !important;
}

.k-scroll-snap-snap-x {
  scroll-snap-type: x var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-x {
  scroll-snap-type: x var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-snap-y {
  scroll-snap-type: y var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-y {
  scroll-snap-type: y var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-snap-both {
  scroll-snap-type: both var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-both {
  scroll-snap-type: both var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-mandatory {
  --k-scroll-snap-strictness: mandatory;
}

.k-scroll-snap-proximity {
  --k-scroll-snap-strictness: proximity;
}

.k-scroll-snap-stop-normal {
  scroll-snap-stop: normal;
}

.\!k-scroll-snap-stop-normal {
  scroll-snap-stop: normal !important;
}

.k-scroll-snap-stop-always {
  scroll-snap-stop: always;
}

.\!k-scroll-snap-stop-always {
  scroll-snap-stop: always !important;
}

.k-scroll-snap-align-start {
  scroll-snap-align: start;
}

.\!k-scroll-snap-align-start {
  scroll-snap-align: start !important;
}

.k-scroll-snap-align-end {
  scroll-snap-align: end;
}

.\!k-scroll-snap-align-end {
  scroll-snap-align: end !important;
}

.k-scroll-snap-align-center {
  scroll-snap-align: center;
}

.\!k-scroll-snap-align-center {
  scroll-snap-align: center !important;
}

.k-scroll-snap-align-none {
  scroll-snap-align: none;
}

.\!k-scroll-snap-align-none {
  scroll-snap-align: none !important;
}

.k-scroll-m-0 {
  scroll-margin: 0px;
}

.\!k-scroll-m-0 {
  scroll-margin: 0px !important;
}

.k-scroll-m-1px {
  scroll-margin: 1px;
}

.\!k-scroll-m-1px {
  scroll-margin: 1px !important;
}

.k-scroll-m-0\.5 {
  scroll-margin: 0.125rem;
}

.\!k-scroll-m-0\.5 {
  scroll-margin: 0.125rem !important;
}

.k-scroll-m-1 {
  scroll-margin: 0.25rem;
}

.\!k-scroll-m-1 {
  scroll-margin: 0.25rem !important;
}

.k-scroll-m-1\.5 {
  scroll-margin: 0.375rem;
}

.\!k-scroll-m-1\.5 {
  scroll-margin: 0.375rem !important;
}

.k-scroll-m-2 {
  scroll-margin: 0.5rem;
}

.\!k-scroll-m-2 {
  scroll-margin: 0.5rem !important;
}

.k-scroll-m-2\.5 {
  scroll-margin: 0.625rem;
}

.\!k-scroll-m-2\.5 {
  scroll-margin: 0.625rem !important;
}

.k-scroll-m-3 {
  scroll-margin: 0.75rem;
}

.\!k-scroll-m-3 {
  scroll-margin: 0.75rem !important;
}

.k-scroll-m-3\.5 {
  scroll-margin: 0.875rem;
}

.\!k-scroll-m-3\.5 {
  scroll-margin: 0.875rem !important;
}

.k-scroll-m-4 {
  scroll-margin: 1rem;
}

.\!k-scroll-m-4 {
  scroll-margin: 1rem !important;
}

.k-scroll-m-4\.5 {
  scroll-margin: 1.125rem;
}

.\!k-scroll-m-4\.5 {
  scroll-margin: 1.125rem !important;
}

.k-scroll-m-5 {
  scroll-margin: 1.25rem;
}

.\!k-scroll-m-5 {
  scroll-margin: 1.25rem !important;
}

.k-scroll-m-5\.5 {
  scroll-margin: 1.375rem;
}

.\!k-scroll-m-5\.5 {
  scroll-margin: 1.375rem !important;
}

.k-scroll-m-6 {
  scroll-margin: 1.5rem;
}

.\!k-scroll-m-6 {
  scroll-margin: 1.5rem !important;
}

.k-scroll-m-6\.5 {
  scroll-margin: 1.625rem;
}

.\!k-scroll-m-6\.5 {
  scroll-margin: 1.625rem !important;
}

.k-scroll-m-7 {
  scroll-margin: 1.75rem;
}

.\!k-scroll-m-7 {
  scroll-margin: 1.75rem !important;
}

.k-scroll-m-7\.5 {
  scroll-margin: 1.875rem;
}

.\!k-scroll-m-7\.5 {
  scroll-margin: 1.875rem !important;
}

.k-scroll-m-8 {
  scroll-margin: 2rem;
}

.\!k-scroll-m-8 {
  scroll-margin: 2rem !important;
}

.k-scroll-m-9 {
  scroll-margin: 2.25rem;
}

.\!k-scroll-m-9 {
  scroll-margin: 2.25rem !important;
}

.k-scroll-m-10 {
  scroll-margin: 2.5rem;
}

.\!k-scroll-m-10 {
  scroll-margin: 2.5rem !important;
}

.k-scroll-m-11 {
  scroll-margin: 2.75rem;
}

.\!k-scroll-m-11 {
  scroll-margin: 2.75rem !important;
}

.k-scroll-m-12 {
  scroll-margin: 3rem;
}

.\!k-scroll-m-12 {
  scroll-margin: 3rem !important;
}

.k-scroll-m-13 {
  scroll-margin: 3.25rem;
}

.\!k-scroll-m-13 {
  scroll-margin: 3.25rem !important;
}

.k-scroll-m-14 {
  scroll-margin: 3.5rem;
}

.\!k-scroll-m-14 {
  scroll-margin: 3.5rem !important;
}

.k-scroll-m-15 {
  scroll-margin: 3.75rem;
}

.\!k-scroll-m-15 {
  scroll-margin: 3.75rem !important;
}

.k-scroll-m-16 {
  scroll-margin: 4rem;
}

.\!k-scroll-m-16 {
  scroll-margin: 4rem !important;
}

.k-scroll-m-17 {
  scroll-margin: 4.25rem;
}

.\!k-scroll-m-17 {
  scroll-margin: 4.25rem !important;
}

.k-scroll-m-18 {
  scroll-margin: 4.5rem;
}

.\!k-scroll-m-18 {
  scroll-margin: 4.5rem !important;
}

.k-scroll-m-19 {
  scroll-margin: 4.75rem;
}

.\!k-scroll-m-19 {
  scroll-margin: 4.75rem !important;
}

.k-scroll-m-20 {
  scroll-margin: 5rem;
}

.\!k-scroll-m-20 {
  scroll-margin: 5rem !important;
}

.k-scroll-m-21 {
  scroll-margin: 5.25rem;
}

.\!k-scroll-m-21 {
  scroll-margin: 5.25rem !important;
}

.k-scroll-m-22 {
  scroll-margin: 5.5rem;
}

.\!k-scroll-m-22 {
  scroll-margin: 5.5rem !important;
}

.k-scroll-m-23 {
  scroll-margin: 5.75rem;
}

.\!k-scroll-m-23 {
  scroll-margin: 5.75rem !important;
}

.k-scroll-m-24 {
  scroll-margin: 6rem;
}

.\!k-scroll-m-24 {
  scroll-margin: 6rem !important;
}

.k-scroll-m-25 {
  scroll-margin: 7rem;
}

.\!k-scroll-m-25 {
  scroll-margin: 7rem !important;
}

.k-scroll-m-26 {
  scroll-margin: 8rem;
}

.\!k-scroll-m-26 {
  scroll-margin: 8rem !important;
}

.k-scroll-m-27 {
  scroll-margin: 9rem;
}

.\!k-scroll-m-27 {
  scroll-margin: 9rem !important;
}

.k-scroll-m-28 {
  scroll-margin: 10rem;
}

.\!k-scroll-m-28 {
  scroll-margin: 10rem !important;
}

.k-scroll-m-29 {
  scroll-margin: 11rem;
}

.\!k-scroll-m-29 {
  scroll-margin: 11rem !important;
}

.k-scroll-m-30 {
  scroll-margin: 12rem;
}

.\!k-scroll-m-30 {
  scroll-margin: 12rem !important;
}

.k-scroll-mt-0 {
  scroll-margin-top: 0px;
}

.\!k-scroll-mt-0 {
  scroll-margin-top: 0px !important;
}

.k-scroll-mt-1px {
  scroll-margin-top: 1px;
}

.\!k-scroll-mt-1px {
  scroll-margin-top: 1px !important;
}

.k-scroll-mt-0\.5 {
  scroll-margin-top: 0.125rem;
}

.\!k-scroll-mt-0\.5 {
  scroll-margin-top: 0.125rem !important;
}

.k-scroll-mt-1 {
  scroll-margin-top: 0.25rem;
}

.\!k-scroll-mt-1 {
  scroll-margin-top: 0.25rem !important;
}

.k-scroll-mt-1\.5 {
  scroll-margin-top: 0.375rem;
}

.\!k-scroll-mt-1\.5 {
  scroll-margin-top: 0.375rem !important;
}

.k-scroll-mt-2 {
  scroll-margin-top: 0.5rem;
}

.\!k-scroll-mt-2 {
  scroll-margin-top: 0.5rem !important;
}

.k-scroll-mt-2\.5 {
  scroll-margin-top: 0.625rem;
}

.\!k-scroll-mt-2\.5 {
  scroll-margin-top: 0.625rem !important;
}

.k-scroll-mt-3 {
  scroll-margin-top: 0.75rem;
}

.\!k-scroll-mt-3 {
  scroll-margin-top: 0.75rem !important;
}

.k-scroll-mt-3\.5 {
  scroll-margin-top: 0.875rem;
}

.\!k-scroll-mt-3\.5 {
  scroll-margin-top: 0.875rem !important;
}

.k-scroll-mt-4 {
  scroll-margin-top: 1rem;
}

.\!k-scroll-mt-4 {
  scroll-margin-top: 1rem !important;
}

.k-scroll-mt-4\.5 {
  scroll-margin-top: 1.125rem;
}

.\!k-scroll-mt-4\.5 {
  scroll-margin-top: 1.125rem !important;
}

.k-scroll-mt-5 {
  scroll-margin-top: 1.25rem;
}

.\!k-scroll-mt-5 {
  scroll-margin-top: 1.25rem !important;
}

.k-scroll-mt-5\.5 {
  scroll-margin-top: 1.375rem;
}

.\!k-scroll-mt-5\.5 {
  scroll-margin-top: 1.375rem !important;
}

.k-scroll-mt-6 {
  scroll-margin-top: 1.5rem;
}

.\!k-scroll-mt-6 {
  scroll-margin-top: 1.5rem !important;
}

.k-scroll-mt-6\.5 {
  scroll-margin-top: 1.625rem;
}

.\!k-scroll-mt-6\.5 {
  scroll-margin-top: 1.625rem !important;
}

.k-scroll-mt-7 {
  scroll-margin-top: 1.75rem;
}

.\!k-scroll-mt-7 {
  scroll-margin-top: 1.75rem !important;
}

.k-scroll-mt-7\.5 {
  scroll-margin-top: 1.875rem;
}

.\!k-scroll-mt-7\.5 {
  scroll-margin-top: 1.875rem !important;
}

.k-scroll-mt-8 {
  scroll-margin-top: 2rem;
}

.\!k-scroll-mt-8 {
  scroll-margin-top: 2rem !important;
}

.k-scroll-mt-9 {
  scroll-margin-top: 2.25rem;
}

.\!k-scroll-mt-9 {
  scroll-margin-top: 2.25rem !important;
}

.k-scroll-mt-10 {
  scroll-margin-top: 2.5rem;
}

.\!k-scroll-mt-10 {
  scroll-margin-top: 2.5rem !important;
}

.k-scroll-mt-11 {
  scroll-margin-top: 2.75rem;
}

.\!k-scroll-mt-11 {
  scroll-margin-top: 2.75rem !important;
}

.k-scroll-mt-12 {
  scroll-margin-top: 3rem;
}

.\!k-scroll-mt-12 {
  scroll-margin-top: 3rem !important;
}

.k-scroll-mt-13 {
  scroll-margin-top: 3.25rem;
}

.\!k-scroll-mt-13 {
  scroll-margin-top: 3.25rem !important;
}

.k-scroll-mt-14 {
  scroll-margin-top: 3.5rem;
}

.\!k-scroll-mt-14 {
  scroll-margin-top: 3.5rem !important;
}

.k-scroll-mt-15 {
  scroll-margin-top: 3.75rem;
}

.\!k-scroll-mt-15 {
  scroll-margin-top: 3.75rem !important;
}

.k-scroll-mt-16 {
  scroll-margin-top: 4rem;
}

.\!k-scroll-mt-16 {
  scroll-margin-top: 4rem !important;
}

.k-scroll-mt-17 {
  scroll-margin-top: 4.25rem;
}

.\!k-scroll-mt-17 {
  scroll-margin-top: 4.25rem !important;
}

.k-scroll-mt-18 {
  scroll-margin-top: 4.5rem;
}

.\!k-scroll-mt-18 {
  scroll-margin-top: 4.5rem !important;
}

.k-scroll-mt-19 {
  scroll-margin-top: 4.75rem;
}

.\!k-scroll-mt-19 {
  scroll-margin-top: 4.75rem !important;
}

.k-scroll-mt-20 {
  scroll-margin-top: 5rem;
}

.\!k-scroll-mt-20 {
  scroll-margin-top: 5rem !important;
}

.k-scroll-mt-21 {
  scroll-margin-top: 5.25rem;
}

.\!k-scroll-mt-21 {
  scroll-margin-top: 5.25rem !important;
}

.k-scroll-mt-22 {
  scroll-margin-top: 5.5rem;
}

.\!k-scroll-mt-22 {
  scroll-margin-top: 5.5rem !important;
}

.k-scroll-mt-23 {
  scroll-margin-top: 5.75rem;
}

.\!k-scroll-mt-23 {
  scroll-margin-top: 5.75rem !important;
}

.k-scroll-mt-24 {
  scroll-margin-top: 6rem;
}

.\!k-scroll-mt-24 {
  scroll-margin-top: 6rem !important;
}

.k-scroll-mt-25 {
  scroll-margin-top: 7rem;
}

.\!k-scroll-mt-25 {
  scroll-margin-top: 7rem !important;
}

.k-scroll-mt-26 {
  scroll-margin-top: 8rem;
}

.\!k-scroll-mt-26 {
  scroll-margin-top: 8rem !important;
}

.k-scroll-mt-27 {
  scroll-margin-top: 9rem;
}

.\!k-scroll-mt-27 {
  scroll-margin-top: 9rem !important;
}

.k-scroll-mt-28 {
  scroll-margin-top: 10rem;
}

.\!k-scroll-mt-28 {
  scroll-margin-top: 10rem !important;
}

.k-scroll-mt-29 {
  scroll-margin-top: 11rem;
}

.\!k-scroll-mt-29 {
  scroll-margin-top: 11rem !important;
}

.k-scroll-mt-30 {
  scroll-margin-top: 12rem;
}

.\!k-scroll-mt-30 {
  scroll-margin-top: 12rem !important;
}

.k-scroll-mr-0 {
  scroll-margin-right: 0px;
}

.\!k-scroll-mr-0 {
  scroll-margin-right: 0px !important;
}

.k-scroll-mr-1px {
  scroll-margin-right: 1px;
}

.\!k-scroll-mr-1px {
  scroll-margin-right: 1px !important;
}

.k-scroll-mr-0\.5 {
  scroll-margin-right: 0.125rem;
}

.\!k-scroll-mr-0\.5 {
  scroll-margin-right: 0.125rem !important;
}

.k-scroll-mr-1 {
  scroll-margin-right: 0.25rem;
}

.\!k-scroll-mr-1 {
  scroll-margin-right: 0.25rem !important;
}

.k-scroll-mr-1\.5 {
  scroll-margin-right: 0.375rem;
}

.\!k-scroll-mr-1\.5 {
  scroll-margin-right: 0.375rem !important;
}

.k-scroll-mr-2 {
  scroll-margin-right: 0.5rem;
}

.\!k-scroll-mr-2 {
  scroll-margin-right: 0.5rem !important;
}

.k-scroll-mr-2\.5 {
  scroll-margin-right: 0.625rem;
}

.\!k-scroll-mr-2\.5 {
  scroll-margin-right: 0.625rem !important;
}

.k-scroll-mr-3 {
  scroll-margin-right: 0.75rem;
}

.\!k-scroll-mr-3 {
  scroll-margin-right: 0.75rem !important;
}

.k-scroll-mr-3\.5 {
  scroll-margin-right: 0.875rem;
}

.\!k-scroll-mr-3\.5 {
  scroll-margin-right: 0.875rem !important;
}

.k-scroll-mr-4 {
  scroll-margin-right: 1rem;
}

.\!k-scroll-mr-4 {
  scroll-margin-right: 1rem !important;
}

.k-scroll-mr-4\.5 {
  scroll-margin-right: 1.125rem;
}

.\!k-scroll-mr-4\.5 {
  scroll-margin-right: 1.125rem !important;
}

.k-scroll-mr-5 {
  scroll-margin-right: 1.25rem;
}

.\!k-scroll-mr-5 {
  scroll-margin-right: 1.25rem !important;
}

.k-scroll-mr-5\.5 {
  scroll-margin-right: 1.375rem;
}

.\!k-scroll-mr-5\.5 {
  scroll-margin-right: 1.375rem !important;
}

.k-scroll-mr-6 {
  scroll-margin-right: 1.5rem;
}

.\!k-scroll-mr-6 {
  scroll-margin-right: 1.5rem !important;
}

.k-scroll-mr-6\.5 {
  scroll-margin-right: 1.625rem;
}

.\!k-scroll-mr-6\.5 {
  scroll-margin-right: 1.625rem !important;
}

.k-scroll-mr-7 {
  scroll-margin-right: 1.75rem;
}

.\!k-scroll-mr-7 {
  scroll-margin-right: 1.75rem !important;
}

.k-scroll-mr-7\.5 {
  scroll-margin-right: 1.875rem;
}

.\!k-scroll-mr-7\.5 {
  scroll-margin-right: 1.875rem !important;
}

.k-scroll-mr-8 {
  scroll-margin-right: 2rem;
}

.\!k-scroll-mr-8 {
  scroll-margin-right: 2rem !important;
}

.k-scroll-mr-9 {
  scroll-margin-right: 2.25rem;
}

.\!k-scroll-mr-9 {
  scroll-margin-right: 2.25rem !important;
}

.k-scroll-mr-10 {
  scroll-margin-right: 2.5rem;
}

.\!k-scroll-mr-10 {
  scroll-margin-right: 2.5rem !important;
}

.k-scroll-mr-11 {
  scroll-margin-right: 2.75rem;
}

.\!k-scroll-mr-11 {
  scroll-margin-right: 2.75rem !important;
}

.k-scroll-mr-12 {
  scroll-margin-right: 3rem;
}

.\!k-scroll-mr-12 {
  scroll-margin-right: 3rem !important;
}

.k-scroll-mr-13 {
  scroll-margin-right: 3.25rem;
}

.\!k-scroll-mr-13 {
  scroll-margin-right: 3.25rem !important;
}

.k-scroll-mr-14 {
  scroll-margin-right: 3.5rem;
}

.\!k-scroll-mr-14 {
  scroll-margin-right: 3.5rem !important;
}

.k-scroll-mr-15 {
  scroll-margin-right: 3.75rem;
}

.\!k-scroll-mr-15 {
  scroll-margin-right: 3.75rem !important;
}

.k-scroll-mr-16 {
  scroll-margin-right: 4rem;
}

.\!k-scroll-mr-16 {
  scroll-margin-right: 4rem !important;
}

.k-scroll-mr-17 {
  scroll-margin-right: 4.25rem;
}

.\!k-scroll-mr-17 {
  scroll-margin-right: 4.25rem !important;
}

.k-scroll-mr-18 {
  scroll-margin-right: 4.5rem;
}

.\!k-scroll-mr-18 {
  scroll-margin-right: 4.5rem !important;
}

.k-scroll-mr-19 {
  scroll-margin-right: 4.75rem;
}

.\!k-scroll-mr-19 {
  scroll-margin-right: 4.75rem !important;
}

.k-scroll-mr-20 {
  scroll-margin-right: 5rem;
}

.\!k-scroll-mr-20 {
  scroll-margin-right: 5rem !important;
}

.k-scroll-mr-21 {
  scroll-margin-right: 5.25rem;
}

.\!k-scroll-mr-21 {
  scroll-margin-right: 5.25rem !important;
}

.k-scroll-mr-22 {
  scroll-margin-right: 5.5rem;
}

.\!k-scroll-mr-22 {
  scroll-margin-right: 5.5rem !important;
}

.k-scroll-mr-23 {
  scroll-margin-right: 5.75rem;
}

.\!k-scroll-mr-23 {
  scroll-margin-right: 5.75rem !important;
}

.k-scroll-mr-24 {
  scroll-margin-right: 6rem;
}

.\!k-scroll-mr-24 {
  scroll-margin-right: 6rem !important;
}

.k-scroll-mr-25 {
  scroll-margin-right: 7rem;
}

.\!k-scroll-mr-25 {
  scroll-margin-right: 7rem !important;
}

.k-scroll-mr-26 {
  scroll-margin-right: 8rem;
}

.\!k-scroll-mr-26 {
  scroll-margin-right: 8rem !important;
}

.k-scroll-mr-27 {
  scroll-margin-right: 9rem;
}

.\!k-scroll-mr-27 {
  scroll-margin-right: 9rem !important;
}

.k-scroll-mr-28 {
  scroll-margin-right: 10rem;
}

.\!k-scroll-mr-28 {
  scroll-margin-right: 10rem !important;
}

.k-scroll-mr-29 {
  scroll-margin-right: 11rem;
}

.\!k-scroll-mr-29 {
  scroll-margin-right: 11rem !important;
}

.k-scroll-mr-30 {
  scroll-margin-right: 12rem;
}

.\!k-scroll-mr-30 {
  scroll-margin-right: 12rem !important;
}

.k-scroll-mb-0 {
  scroll-margin-bottom: 0px;
}

.\!k-scroll-mb-0 {
  scroll-margin-bottom: 0px !important;
}

.k-scroll-mb-1px {
  scroll-margin-bottom: 1px;
}

.\!k-scroll-mb-1px {
  scroll-margin-bottom: 1px !important;
}

.k-scroll-mb-0\.5 {
  scroll-margin-bottom: 0.125rem;
}

.\!k-scroll-mb-0\.5 {
  scroll-margin-bottom: 0.125rem !important;
}

.k-scroll-mb-1 {
  scroll-margin-bottom: 0.25rem;
}

.\!k-scroll-mb-1 {
  scroll-margin-bottom: 0.25rem !important;
}

.k-scroll-mb-1\.5 {
  scroll-margin-bottom: 0.375rem;
}

.\!k-scroll-mb-1\.5 {
  scroll-margin-bottom: 0.375rem !important;
}

.k-scroll-mb-2 {
  scroll-margin-bottom: 0.5rem;
}

.\!k-scroll-mb-2 {
  scroll-margin-bottom: 0.5rem !important;
}

.k-scroll-mb-2\.5 {
  scroll-margin-bottom: 0.625rem;
}

.\!k-scroll-mb-2\.5 {
  scroll-margin-bottom: 0.625rem !important;
}

.k-scroll-mb-3 {
  scroll-margin-bottom: 0.75rem;
}

.\!k-scroll-mb-3 {
  scroll-margin-bottom: 0.75rem !important;
}

.k-scroll-mb-3\.5 {
  scroll-margin-bottom: 0.875rem;
}

.\!k-scroll-mb-3\.5 {
  scroll-margin-bottom: 0.875rem !important;
}

.k-scroll-mb-4 {
  scroll-margin-bottom: 1rem;
}

.\!k-scroll-mb-4 {
  scroll-margin-bottom: 1rem !important;
}

.k-scroll-mb-4\.5 {
  scroll-margin-bottom: 1.125rem;
}

.\!k-scroll-mb-4\.5 {
  scroll-margin-bottom: 1.125rem !important;
}

.k-scroll-mb-5 {
  scroll-margin-bottom: 1.25rem;
}

.\!k-scroll-mb-5 {
  scroll-margin-bottom: 1.25rem !important;
}

.k-scroll-mb-5\.5 {
  scroll-margin-bottom: 1.375rem;
}

.\!k-scroll-mb-5\.5 {
  scroll-margin-bottom: 1.375rem !important;
}

.k-scroll-mb-6 {
  scroll-margin-bottom: 1.5rem;
}

.\!k-scroll-mb-6 {
  scroll-margin-bottom: 1.5rem !important;
}

.k-scroll-mb-6\.5 {
  scroll-margin-bottom: 1.625rem;
}

.\!k-scroll-mb-6\.5 {
  scroll-margin-bottom: 1.625rem !important;
}

.k-scroll-mb-7 {
  scroll-margin-bottom: 1.75rem;
}

.\!k-scroll-mb-7 {
  scroll-margin-bottom: 1.75rem !important;
}

.k-scroll-mb-7\.5 {
  scroll-margin-bottom: 1.875rem;
}

.\!k-scroll-mb-7\.5 {
  scroll-margin-bottom: 1.875rem !important;
}

.k-scroll-mb-8 {
  scroll-margin-bottom: 2rem;
}

.\!k-scroll-mb-8 {
  scroll-margin-bottom: 2rem !important;
}

.k-scroll-mb-9 {
  scroll-margin-bottom: 2.25rem;
}

.\!k-scroll-mb-9 {
  scroll-margin-bottom: 2.25rem !important;
}

.k-scroll-mb-10 {
  scroll-margin-bottom: 2.5rem;
}

.\!k-scroll-mb-10 {
  scroll-margin-bottom: 2.5rem !important;
}

.k-scroll-mb-11 {
  scroll-margin-bottom: 2.75rem;
}

.\!k-scroll-mb-11 {
  scroll-margin-bottom: 2.75rem !important;
}

.k-scroll-mb-12 {
  scroll-margin-bottom: 3rem;
}

.\!k-scroll-mb-12 {
  scroll-margin-bottom: 3rem !important;
}

.k-scroll-mb-13 {
  scroll-margin-bottom: 3.25rem;
}

.\!k-scroll-mb-13 {
  scroll-margin-bottom: 3.25rem !important;
}

.k-scroll-mb-14 {
  scroll-margin-bottom: 3.5rem;
}

.\!k-scroll-mb-14 {
  scroll-margin-bottom: 3.5rem !important;
}

.k-scroll-mb-15 {
  scroll-margin-bottom: 3.75rem;
}

.\!k-scroll-mb-15 {
  scroll-margin-bottom: 3.75rem !important;
}

.k-scroll-mb-16 {
  scroll-margin-bottom: 4rem;
}

.\!k-scroll-mb-16 {
  scroll-margin-bottom: 4rem !important;
}

.k-scroll-mb-17 {
  scroll-margin-bottom: 4.25rem;
}

.\!k-scroll-mb-17 {
  scroll-margin-bottom: 4.25rem !important;
}

.k-scroll-mb-18 {
  scroll-margin-bottom: 4.5rem;
}

.\!k-scroll-mb-18 {
  scroll-margin-bottom: 4.5rem !important;
}

.k-scroll-mb-19 {
  scroll-margin-bottom: 4.75rem;
}

.\!k-scroll-mb-19 {
  scroll-margin-bottom: 4.75rem !important;
}

.k-scroll-mb-20 {
  scroll-margin-bottom: 5rem;
}

.\!k-scroll-mb-20 {
  scroll-margin-bottom: 5rem !important;
}

.k-scroll-mb-21 {
  scroll-margin-bottom: 5.25rem;
}

.\!k-scroll-mb-21 {
  scroll-margin-bottom: 5.25rem !important;
}

.k-scroll-mb-22 {
  scroll-margin-bottom: 5.5rem;
}

.\!k-scroll-mb-22 {
  scroll-margin-bottom: 5.5rem !important;
}

.k-scroll-mb-23 {
  scroll-margin-bottom: 5.75rem;
}

.\!k-scroll-mb-23 {
  scroll-margin-bottom: 5.75rem !important;
}

.k-scroll-mb-24 {
  scroll-margin-bottom: 6rem;
}

.\!k-scroll-mb-24 {
  scroll-margin-bottom: 6rem !important;
}

.k-scroll-mb-25 {
  scroll-margin-bottom: 7rem;
}

.\!k-scroll-mb-25 {
  scroll-margin-bottom: 7rem !important;
}

.k-scroll-mb-26 {
  scroll-margin-bottom: 8rem;
}

.\!k-scroll-mb-26 {
  scroll-margin-bottom: 8rem !important;
}

.k-scroll-mb-27 {
  scroll-margin-bottom: 9rem;
}

.\!k-scroll-mb-27 {
  scroll-margin-bottom: 9rem !important;
}

.k-scroll-mb-28 {
  scroll-margin-bottom: 10rem;
}

.\!k-scroll-mb-28 {
  scroll-margin-bottom: 10rem !important;
}

.k-scroll-mb-29 {
  scroll-margin-bottom: 11rem;
}

.\!k-scroll-mb-29 {
  scroll-margin-bottom: 11rem !important;
}

.k-scroll-mb-30 {
  scroll-margin-bottom: 12rem;
}

.\!k-scroll-mb-30 {
  scroll-margin-bottom: 12rem !important;
}

.k-scroll-ml-0 {
  scroll-margin-left: 0px;
}

.\!k-scroll-ml-0 {
  scroll-margin-left: 0px !important;
}

.k-scroll-ml-1px {
  scroll-margin-left: 1px;
}

.\!k-scroll-ml-1px {
  scroll-margin-left: 1px !important;
}

.k-scroll-ml-0\.5 {
  scroll-margin-left: 0.125rem;
}

.\!k-scroll-ml-0\.5 {
  scroll-margin-left: 0.125rem !important;
}

.k-scroll-ml-1 {
  scroll-margin-left: 0.25rem;
}

.\!k-scroll-ml-1 {
  scroll-margin-left: 0.25rem !important;
}

.k-scroll-ml-1\.5 {
  scroll-margin-left: 0.375rem;
}

.\!k-scroll-ml-1\.5 {
  scroll-margin-left: 0.375rem !important;
}

.k-scroll-ml-2 {
  scroll-margin-left: 0.5rem;
}

.\!k-scroll-ml-2 {
  scroll-margin-left: 0.5rem !important;
}

.k-scroll-ml-2\.5 {
  scroll-margin-left: 0.625rem;
}

.\!k-scroll-ml-2\.5 {
  scroll-margin-left: 0.625rem !important;
}

.k-scroll-ml-3 {
  scroll-margin-left: 0.75rem;
}

.\!k-scroll-ml-3 {
  scroll-margin-left: 0.75rem !important;
}

.k-scroll-ml-3\.5 {
  scroll-margin-left: 0.875rem;
}

.\!k-scroll-ml-3\.5 {
  scroll-margin-left: 0.875rem !important;
}

.k-scroll-ml-4 {
  scroll-margin-left: 1rem;
}

.\!k-scroll-ml-4 {
  scroll-margin-left: 1rem !important;
}

.k-scroll-ml-4\.5 {
  scroll-margin-left: 1.125rem;
}

.\!k-scroll-ml-4\.5 {
  scroll-margin-left: 1.125rem !important;
}

.k-scroll-ml-5 {
  scroll-margin-left: 1.25rem;
}

.\!k-scroll-ml-5 {
  scroll-margin-left: 1.25rem !important;
}

.k-scroll-ml-5\.5 {
  scroll-margin-left: 1.375rem;
}

.\!k-scroll-ml-5\.5 {
  scroll-margin-left: 1.375rem !important;
}

.k-scroll-ml-6 {
  scroll-margin-left: 1.5rem;
}

.\!k-scroll-ml-6 {
  scroll-margin-left: 1.5rem !important;
}

.k-scroll-ml-6\.5 {
  scroll-margin-left: 1.625rem;
}

.\!k-scroll-ml-6\.5 {
  scroll-margin-left: 1.625rem !important;
}

.k-scroll-ml-7 {
  scroll-margin-left: 1.75rem;
}

.\!k-scroll-ml-7 {
  scroll-margin-left: 1.75rem !important;
}

.k-scroll-ml-7\.5 {
  scroll-margin-left: 1.875rem;
}

.\!k-scroll-ml-7\.5 {
  scroll-margin-left: 1.875rem !important;
}

.k-scroll-ml-8 {
  scroll-margin-left: 2rem;
}

.\!k-scroll-ml-8 {
  scroll-margin-left: 2rem !important;
}

.k-scroll-ml-9 {
  scroll-margin-left: 2.25rem;
}

.\!k-scroll-ml-9 {
  scroll-margin-left: 2.25rem !important;
}

.k-scroll-ml-10 {
  scroll-margin-left: 2.5rem;
}

.\!k-scroll-ml-10 {
  scroll-margin-left: 2.5rem !important;
}

.k-scroll-ml-11 {
  scroll-margin-left: 2.75rem;
}

.\!k-scroll-ml-11 {
  scroll-margin-left: 2.75rem !important;
}

.k-scroll-ml-12 {
  scroll-margin-left: 3rem;
}

.\!k-scroll-ml-12 {
  scroll-margin-left: 3rem !important;
}

.k-scroll-ml-13 {
  scroll-margin-left: 3.25rem;
}

.\!k-scroll-ml-13 {
  scroll-margin-left: 3.25rem !important;
}

.k-scroll-ml-14 {
  scroll-margin-left: 3.5rem;
}

.\!k-scroll-ml-14 {
  scroll-margin-left: 3.5rem !important;
}

.k-scroll-ml-15 {
  scroll-margin-left: 3.75rem;
}

.\!k-scroll-ml-15 {
  scroll-margin-left: 3.75rem !important;
}

.k-scroll-ml-16 {
  scroll-margin-left: 4rem;
}

.\!k-scroll-ml-16 {
  scroll-margin-left: 4rem !important;
}

.k-scroll-ml-17 {
  scroll-margin-left: 4.25rem;
}

.\!k-scroll-ml-17 {
  scroll-margin-left: 4.25rem !important;
}

.k-scroll-ml-18 {
  scroll-margin-left: 4.5rem;
}

.\!k-scroll-ml-18 {
  scroll-margin-left: 4.5rem !important;
}

.k-scroll-ml-19 {
  scroll-margin-left: 4.75rem;
}

.\!k-scroll-ml-19 {
  scroll-margin-left: 4.75rem !important;
}

.k-scroll-ml-20 {
  scroll-margin-left: 5rem;
}

.\!k-scroll-ml-20 {
  scroll-margin-left: 5rem !important;
}

.k-scroll-ml-21 {
  scroll-margin-left: 5.25rem;
}

.\!k-scroll-ml-21 {
  scroll-margin-left: 5.25rem !important;
}

.k-scroll-ml-22 {
  scroll-margin-left: 5.5rem;
}

.\!k-scroll-ml-22 {
  scroll-margin-left: 5.5rem !important;
}

.k-scroll-ml-23 {
  scroll-margin-left: 5.75rem;
}

.\!k-scroll-ml-23 {
  scroll-margin-left: 5.75rem !important;
}

.k-scroll-ml-24 {
  scroll-margin-left: 6rem;
}

.\!k-scroll-ml-24 {
  scroll-margin-left: 6rem !important;
}

.k-scroll-ml-25 {
  scroll-margin-left: 7rem;
}

.\!k-scroll-ml-25 {
  scroll-margin-left: 7rem !important;
}

.k-scroll-ml-26 {
  scroll-margin-left: 8rem;
}

.\!k-scroll-ml-26 {
  scroll-margin-left: 8rem !important;
}

.k-scroll-ml-27 {
  scroll-margin-left: 9rem;
}

.\!k-scroll-ml-27 {
  scroll-margin-left: 9rem !important;
}

.k-scroll-ml-28 {
  scroll-margin-left: 10rem;
}

.\!k-scroll-ml-28 {
  scroll-margin-left: 10rem !important;
}

.k-scroll-ml-29 {
  scroll-margin-left: 11rem;
}

.\!k-scroll-ml-29 {
  scroll-margin-left: 11rem !important;
}

.k-scroll-ml-30 {
  scroll-margin-left: 12rem;
}

.\!k-scroll-ml-30 {
  scroll-margin-left: 12rem !important;
}

.k-scroll-mx-0 {
  scroll-margin-inline: 0px;
}

.\!k-scroll-mx-0 {
  scroll-margin-inline: 0px !important;
}

.k-scroll-mx-1px {
  scroll-margin-inline: 1px;
}

.\!k-scroll-mx-1px {
  scroll-margin-inline: 1px !important;
}

.k-scroll-mx-0\.5 {
  scroll-margin-inline: 0.125rem;
}

.\!k-scroll-mx-0\.5 {
  scroll-margin-inline: 0.125rem !important;
}

.k-scroll-mx-1 {
  scroll-margin-inline: 0.25rem;
}

.\!k-scroll-mx-1 {
  scroll-margin-inline: 0.25rem !important;
}

.k-scroll-mx-1\.5 {
  scroll-margin-inline: 0.375rem;
}

.\!k-scroll-mx-1\.5 {
  scroll-margin-inline: 0.375rem !important;
}

.k-scroll-mx-2 {
  scroll-margin-inline: 0.5rem;
}

.\!k-scroll-mx-2 {
  scroll-margin-inline: 0.5rem !important;
}

.k-scroll-mx-2\.5 {
  scroll-margin-inline: 0.625rem;
}

.\!k-scroll-mx-2\.5 {
  scroll-margin-inline: 0.625rem !important;
}

.k-scroll-mx-3 {
  scroll-margin-inline: 0.75rem;
}

.\!k-scroll-mx-3 {
  scroll-margin-inline: 0.75rem !important;
}

.k-scroll-mx-3\.5 {
  scroll-margin-inline: 0.875rem;
}

.\!k-scroll-mx-3\.5 {
  scroll-margin-inline: 0.875rem !important;
}

.k-scroll-mx-4 {
  scroll-margin-inline: 1rem;
}

.\!k-scroll-mx-4 {
  scroll-margin-inline: 1rem !important;
}

.k-scroll-mx-4\.5 {
  scroll-margin-inline: 1.125rem;
}

.\!k-scroll-mx-4\.5 {
  scroll-margin-inline: 1.125rem !important;
}

.k-scroll-mx-5 {
  scroll-margin-inline: 1.25rem;
}

.\!k-scroll-mx-5 {
  scroll-margin-inline: 1.25rem !important;
}

.k-scroll-mx-5\.5 {
  scroll-margin-inline: 1.375rem;
}

.\!k-scroll-mx-5\.5 {
  scroll-margin-inline: 1.375rem !important;
}

.k-scroll-mx-6 {
  scroll-margin-inline: 1.5rem;
}

.\!k-scroll-mx-6 {
  scroll-margin-inline: 1.5rem !important;
}

.k-scroll-mx-6\.5 {
  scroll-margin-inline: 1.625rem;
}

.\!k-scroll-mx-6\.5 {
  scroll-margin-inline: 1.625rem !important;
}

.k-scroll-mx-7 {
  scroll-margin-inline: 1.75rem;
}

.\!k-scroll-mx-7 {
  scroll-margin-inline: 1.75rem !important;
}

.k-scroll-mx-7\.5 {
  scroll-margin-inline: 1.875rem;
}

.\!k-scroll-mx-7\.5 {
  scroll-margin-inline: 1.875rem !important;
}

.k-scroll-mx-8 {
  scroll-margin-inline: 2rem;
}

.\!k-scroll-mx-8 {
  scroll-margin-inline: 2rem !important;
}

.k-scroll-mx-9 {
  scroll-margin-inline: 2.25rem;
}

.\!k-scroll-mx-9 {
  scroll-margin-inline: 2.25rem !important;
}

.k-scroll-mx-10 {
  scroll-margin-inline: 2.5rem;
}

.\!k-scroll-mx-10 {
  scroll-margin-inline: 2.5rem !important;
}

.k-scroll-mx-11 {
  scroll-margin-inline: 2.75rem;
}

.\!k-scroll-mx-11 {
  scroll-margin-inline: 2.75rem !important;
}

.k-scroll-mx-12 {
  scroll-margin-inline: 3rem;
}

.\!k-scroll-mx-12 {
  scroll-margin-inline: 3rem !important;
}

.k-scroll-mx-13 {
  scroll-margin-inline: 3.25rem;
}

.\!k-scroll-mx-13 {
  scroll-margin-inline: 3.25rem !important;
}

.k-scroll-mx-14 {
  scroll-margin-inline: 3.5rem;
}

.\!k-scroll-mx-14 {
  scroll-margin-inline: 3.5rem !important;
}

.k-scroll-mx-15 {
  scroll-margin-inline: 3.75rem;
}

.\!k-scroll-mx-15 {
  scroll-margin-inline: 3.75rem !important;
}

.k-scroll-mx-16 {
  scroll-margin-inline: 4rem;
}

.\!k-scroll-mx-16 {
  scroll-margin-inline: 4rem !important;
}

.k-scroll-mx-17 {
  scroll-margin-inline: 4.25rem;
}

.\!k-scroll-mx-17 {
  scroll-margin-inline: 4.25rem !important;
}

.k-scroll-mx-18 {
  scroll-margin-inline: 4.5rem;
}

.\!k-scroll-mx-18 {
  scroll-margin-inline: 4.5rem !important;
}

.k-scroll-mx-19 {
  scroll-margin-inline: 4.75rem;
}

.\!k-scroll-mx-19 {
  scroll-margin-inline: 4.75rem !important;
}

.k-scroll-mx-20 {
  scroll-margin-inline: 5rem;
}

.\!k-scroll-mx-20 {
  scroll-margin-inline: 5rem !important;
}

.k-scroll-mx-21 {
  scroll-margin-inline: 5.25rem;
}

.\!k-scroll-mx-21 {
  scroll-margin-inline: 5.25rem !important;
}

.k-scroll-mx-22 {
  scroll-margin-inline: 5.5rem;
}

.\!k-scroll-mx-22 {
  scroll-margin-inline: 5.5rem !important;
}

.k-scroll-mx-23 {
  scroll-margin-inline: 5.75rem;
}

.\!k-scroll-mx-23 {
  scroll-margin-inline: 5.75rem !important;
}

.k-scroll-mx-24 {
  scroll-margin-inline: 6rem;
}

.\!k-scroll-mx-24 {
  scroll-margin-inline: 6rem !important;
}

.k-scroll-mx-25 {
  scroll-margin-inline: 7rem;
}

.\!k-scroll-mx-25 {
  scroll-margin-inline: 7rem !important;
}

.k-scroll-mx-26 {
  scroll-margin-inline: 8rem;
}

.\!k-scroll-mx-26 {
  scroll-margin-inline: 8rem !important;
}

.k-scroll-mx-27 {
  scroll-margin-inline: 9rem;
}

.\!k-scroll-mx-27 {
  scroll-margin-inline: 9rem !important;
}

.k-scroll-mx-28 {
  scroll-margin-inline: 10rem;
}

.\!k-scroll-mx-28 {
  scroll-margin-inline: 10rem !important;
}

.k-scroll-mx-29 {
  scroll-margin-inline: 11rem;
}

.\!k-scroll-mx-29 {
  scroll-margin-inline: 11rem !important;
}

.k-scroll-mx-30 {
  scroll-margin-inline: 12rem;
}

.\!k-scroll-mx-30 {
  scroll-margin-inline: 12rem !important;
}

.k-scroll-my-0 {
  scroll-margin-block: 0px;
}

.\!k-scroll-my-0 {
  scroll-margin-block: 0px !important;
}

.k-scroll-my-1px {
  scroll-margin-block: 1px;
}

.\!k-scroll-my-1px {
  scroll-margin-block: 1px !important;
}

.k-scroll-my-0\.5 {
  scroll-margin-block: 0.125rem;
}

.\!k-scroll-my-0\.5 {
  scroll-margin-block: 0.125rem !important;
}

.k-scroll-my-1 {
  scroll-margin-block: 0.25rem;
}

.\!k-scroll-my-1 {
  scroll-margin-block: 0.25rem !important;
}

.k-scroll-my-1\.5 {
  scroll-margin-block: 0.375rem;
}

.\!k-scroll-my-1\.5 {
  scroll-margin-block: 0.375rem !important;
}

.k-scroll-my-2 {
  scroll-margin-block: 0.5rem;
}

.\!k-scroll-my-2 {
  scroll-margin-block: 0.5rem !important;
}

.k-scroll-my-2\.5 {
  scroll-margin-block: 0.625rem;
}

.\!k-scroll-my-2\.5 {
  scroll-margin-block: 0.625rem !important;
}

.k-scroll-my-3 {
  scroll-margin-block: 0.75rem;
}

.\!k-scroll-my-3 {
  scroll-margin-block: 0.75rem !important;
}

.k-scroll-my-3\.5 {
  scroll-margin-block: 0.875rem;
}

.\!k-scroll-my-3\.5 {
  scroll-margin-block: 0.875rem !important;
}

.k-scroll-my-4 {
  scroll-margin-block: 1rem;
}

.\!k-scroll-my-4 {
  scroll-margin-block: 1rem !important;
}

.k-scroll-my-4\.5 {
  scroll-margin-block: 1.125rem;
}

.\!k-scroll-my-4\.5 {
  scroll-margin-block: 1.125rem !important;
}

.k-scroll-my-5 {
  scroll-margin-block: 1.25rem;
}

.\!k-scroll-my-5 {
  scroll-margin-block: 1.25rem !important;
}

.k-scroll-my-5\.5 {
  scroll-margin-block: 1.375rem;
}

.\!k-scroll-my-5\.5 {
  scroll-margin-block: 1.375rem !important;
}

.k-scroll-my-6 {
  scroll-margin-block: 1.5rem;
}

.\!k-scroll-my-6 {
  scroll-margin-block: 1.5rem !important;
}

.k-scroll-my-6\.5 {
  scroll-margin-block: 1.625rem;
}

.\!k-scroll-my-6\.5 {
  scroll-margin-block: 1.625rem !important;
}

.k-scroll-my-7 {
  scroll-margin-block: 1.75rem;
}

.\!k-scroll-my-7 {
  scroll-margin-block: 1.75rem !important;
}

.k-scroll-my-7\.5 {
  scroll-margin-block: 1.875rem;
}

.\!k-scroll-my-7\.5 {
  scroll-margin-block: 1.875rem !important;
}

.k-scroll-my-8 {
  scroll-margin-block: 2rem;
}

.\!k-scroll-my-8 {
  scroll-margin-block: 2rem !important;
}

.k-scroll-my-9 {
  scroll-margin-block: 2.25rem;
}

.\!k-scroll-my-9 {
  scroll-margin-block: 2.25rem !important;
}

.k-scroll-my-10 {
  scroll-margin-block: 2.5rem;
}

.\!k-scroll-my-10 {
  scroll-margin-block: 2.5rem !important;
}

.k-scroll-my-11 {
  scroll-margin-block: 2.75rem;
}

.\!k-scroll-my-11 {
  scroll-margin-block: 2.75rem !important;
}

.k-scroll-my-12 {
  scroll-margin-block: 3rem;
}

.\!k-scroll-my-12 {
  scroll-margin-block: 3rem !important;
}

.k-scroll-my-13 {
  scroll-margin-block: 3.25rem;
}

.\!k-scroll-my-13 {
  scroll-margin-block: 3.25rem !important;
}

.k-scroll-my-14 {
  scroll-margin-block: 3.5rem;
}

.\!k-scroll-my-14 {
  scroll-margin-block: 3.5rem !important;
}

.k-scroll-my-15 {
  scroll-margin-block: 3.75rem;
}

.\!k-scroll-my-15 {
  scroll-margin-block: 3.75rem !important;
}

.k-scroll-my-16 {
  scroll-margin-block: 4rem;
}

.\!k-scroll-my-16 {
  scroll-margin-block: 4rem !important;
}

.k-scroll-my-17 {
  scroll-margin-block: 4.25rem;
}

.\!k-scroll-my-17 {
  scroll-margin-block: 4.25rem !important;
}

.k-scroll-my-18 {
  scroll-margin-block: 4.5rem;
}

.\!k-scroll-my-18 {
  scroll-margin-block: 4.5rem !important;
}

.k-scroll-my-19 {
  scroll-margin-block: 4.75rem;
}

.\!k-scroll-my-19 {
  scroll-margin-block: 4.75rem !important;
}

.k-scroll-my-20 {
  scroll-margin-block: 5rem;
}

.\!k-scroll-my-20 {
  scroll-margin-block: 5rem !important;
}

.k-scroll-my-21 {
  scroll-margin-block: 5.25rem;
}

.\!k-scroll-my-21 {
  scroll-margin-block: 5.25rem !important;
}

.k-scroll-my-22 {
  scroll-margin-block: 5.5rem;
}

.\!k-scroll-my-22 {
  scroll-margin-block: 5.5rem !important;
}

.k-scroll-my-23 {
  scroll-margin-block: 5.75rem;
}

.\!k-scroll-my-23 {
  scroll-margin-block: 5.75rem !important;
}

.k-scroll-my-24 {
  scroll-margin-block: 6rem;
}

.\!k-scroll-my-24 {
  scroll-margin-block: 6rem !important;
}

.k-scroll-my-25 {
  scroll-margin-block: 7rem;
}

.\!k-scroll-my-25 {
  scroll-margin-block: 7rem !important;
}

.k-scroll-my-26 {
  scroll-margin-block: 8rem;
}

.\!k-scroll-my-26 {
  scroll-margin-block: 8rem !important;
}

.k-scroll-my-27 {
  scroll-margin-block: 9rem;
}

.\!k-scroll-my-27 {
  scroll-margin-block: 9rem !important;
}

.k-scroll-my-28 {
  scroll-margin-block: 10rem;
}

.\!k-scroll-my-28 {
  scroll-margin-block: 10rem !important;
}

.k-scroll-my-29 {
  scroll-margin-block: 11rem;
}

.\!k-scroll-my-29 {
  scroll-margin-block: 11rem !important;
}

.k-scroll-my-30 {
  scroll-margin-block: 12rem;
}

.\!k-scroll-my-30 {
  scroll-margin-block: 12rem !important;
}

.k-scroll-p-0 {
  scroll-padding: 0px;
}

.\!k-scroll-p-0 {
  scroll-padding: 0px !important;
}

.k-scroll-p-1px {
  scroll-padding: 1px;
}

.\!k-scroll-p-1px {
  scroll-padding: 1px !important;
}

.k-scroll-p-0\.5 {
  scroll-padding: 0.125rem;
}

.\!k-scroll-p-0\.5 {
  scroll-padding: 0.125rem !important;
}

.k-scroll-p-1 {
  scroll-padding: 0.25rem;
}

.\!k-scroll-p-1 {
  scroll-padding: 0.25rem !important;
}

.k-scroll-p-1\.5 {
  scroll-padding: 0.375rem;
}

.\!k-scroll-p-1\.5 {
  scroll-padding: 0.375rem !important;
}

.k-scroll-p-2 {
  scroll-padding: 0.5rem;
}

.\!k-scroll-p-2 {
  scroll-padding: 0.5rem !important;
}

.k-scroll-p-2\.5 {
  scroll-padding: 0.625rem;
}

.\!k-scroll-p-2\.5 {
  scroll-padding: 0.625rem !important;
}

.k-scroll-p-3 {
  scroll-padding: 0.75rem;
}

.\!k-scroll-p-3 {
  scroll-padding: 0.75rem !important;
}

.k-scroll-p-3\.5 {
  scroll-padding: 0.875rem;
}

.\!k-scroll-p-3\.5 {
  scroll-padding: 0.875rem !important;
}

.k-scroll-p-4 {
  scroll-padding: 1rem;
}

.\!k-scroll-p-4 {
  scroll-padding: 1rem !important;
}

.k-scroll-p-4\.5 {
  scroll-padding: 1.125rem;
}

.\!k-scroll-p-4\.5 {
  scroll-padding: 1.125rem !important;
}

.k-scroll-p-5 {
  scroll-padding: 1.25rem;
}

.\!k-scroll-p-5 {
  scroll-padding: 1.25rem !important;
}

.k-scroll-p-5\.5 {
  scroll-padding: 1.375rem;
}

.\!k-scroll-p-5\.5 {
  scroll-padding: 1.375rem !important;
}

.k-scroll-p-6 {
  scroll-padding: 1.5rem;
}

.\!k-scroll-p-6 {
  scroll-padding: 1.5rem !important;
}

.k-scroll-p-6\.5 {
  scroll-padding: 1.625rem;
}

.\!k-scroll-p-6\.5 {
  scroll-padding: 1.625rem !important;
}

.k-scroll-p-7 {
  scroll-padding: 1.75rem;
}

.\!k-scroll-p-7 {
  scroll-padding: 1.75rem !important;
}

.k-scroll-p-7\.5 {
  scroll-padding: 1.875rem;
}

.\!k-scroll-p-7\.5 {
  scroll-padding: 1.875rem !important;
}

.k-scroll-p-8 {
  scroll-padding: 2rem;
}

.\!k-scroll-p-8 {
  scroll-padding: 2rem !important;
}

.k-scroll-p-9 {
  scroll-padding: 2.25rem;
}

.\!k-scroll-p-9 {
  scroll-padding: 2.25rem !important;
}

.k-scroll-p-10 {
  scroll-padding: 2.5rem;
}

.\!k-scroll-p-10 {
  scroll-padding: 2.5rem !important;
}

.k-scroll-p-11 {
  scroll-padding: 2.75rem;
}

.\!k-scroll-p-11 {
  scroll-padding: 2.75rem !important;
}

.k-scroll-p-12 {
  scroll-padding: 3rem;
}

.\!k-scroll-p-12 {
  scroll-padding: 3rem !important;
}

.k-scroll-p-13 {
  scroll-padding: 3.25rem;
}

.\!k-scroll-p-13 {
  scroll-padding: 3.25rem !important;
}

.k-scroll-p-14 {
  scroll-padding: 3.5rem;
}

.\!k-scroll-p-14 {
  scroll-padding: 3.5rem !important;
}

.k-scroll-p-15 {
  scroll-padding: 3.75rem;
}

.\!k-scroll-p-15 {
  scroll-padding: 3.75rem !important;
}

.k-scroll-p-16 {
  scroll-padding: 4rem;
}

.\!k-scroll-p-16 {
  scroll-padding: 4rem !important;
}

.k-scroll-p-17 {
  scroll-padding: 4.25rem;
}

.\!k-scroll-p-17 {
  scroll-padding: 4.25rem !important;
}

.k-scroll-p-18 {
  scroll-padding: 4.5rem;
}

.\!k-scroll-p-18 {
  scroll-padding: 4.5rem !important;
}

.k-scroll-p-19 {
  scroll-padding: 4.75rem;
}

.\!k-scroll-p-19 {
  scroll-padding: 4.75rem !important;
}

.k-scroll-p-20 {
  scroll-padding: 5rem;
}

.\!k-scroll-p-20 {
  scroll-padding: 5rem !important;
}

.k-scroll-p-21 {
  scroll-padding: 5.25rem;
}

.\!k-scroll-p-21 {
  scroll-padding: 5.25rem !important;
}

.k-scroll-p-22 {
  scroll-padding: 5.5rem;
}

.\!k-scroll-p-22 {
  scroll-padding: 5.5rem !important;
}

.k-scroll-p-23 {
  scroll-padding: 5.75rem;
}

.\!k-scroll-p-23 {
  scroll-padding: 5.75rem !important;
}

.k-scroll-p-24 {
  scroll-padding: 6rem;
}

.\!k-scroll-p-24 {
  scroll-padding: 6rem !important;
}

.k-scroll-p-25 {
  scroll-padding: 7rem;
}

.\!k-scroll-p-25 {
  scroll-padding: 7rem !important;
}

.k-scroll-p-26 {
  scroll-padding: 8rem;
}

.\!k-scroll-p-26 {
  scroll-padding: 8rem !important;
}

.k-scroll-p-27 {
  scroll-padding: 9rem;
}

.\!k-scroll-p-27 {
  scroll-padding: 9rem !important;
}

.k-scroll-p-28 {
  scroll-padding: 10rem;
}

.\!k-scroll-p-28 {
  scroll-padding: 10rem !important;
}

.k-scroll-p-29 {
  scroll-padding: 11rem;
}

.\!k-scroll-p-29 {
  scroll-padding: 11rem !important;
}

.k-scroll-p-30 {
  scroll-padding: 12rem;
}

.\!k-scroll-p-30 {
  scroll-padding: 12rem !important;
}

.k-scroll-pt-0 {
  scroll-padding-top: 0px;
}

.\!k-scroll-pt-0 {
  scroll-padding-top: 0px !important;
}

.k-scroll-pt-1px {
  scroll-padding-top: 1px;
}

.\!k-scroll-pt-1px {
  scroll-padding-top: 1px !important;
}

.k-scroll-pt-0\.5 {
  scroll-padding-top: 0.125rem;
}

.\!k-scroll-pt-0\.5 {
  scroll-padding-top: 0.125rem !important;
}

.k-scroll-pt-1 {
  scroll-padding-top: 0.25rem;
}

.\!k-scroll-pt-1 {
  scroll-padding-top: 0.25rem !important;
}

.k-scroll-pt-1\.5 {
  scroll-padding-top: 0.375rem;
}

.\!k-scroll-pt-1\.5 {
  scroll-padding-top: 0.375rem !important;
}

.k-scroll-pt-2 {
  scroll-padding-top: 0.5rem;
}

.\!k-scroll-pt-2 {
  scroll-padding-top: 0.5rem !important;
}

.k-scroll-pt-2\.5 {
  scroll-padding-top: 0.625rem;
}

.\!k-scroll-pt-2\.5 {
  scroll-padding-top: 0.625rem !important;
}

.k-scroll-pt-3 {
  scroll-padding-top: 0.75rem;
}

.\!k-scroll-pt-3 {
  scroll-padding-top: 0.75rem !important;
}

.k-scroll-pt-3\.5 {
  scroll-padding-top: 0.875rem;
}

.\!k-scroll-pt-3\.5 {
  scroll-padding-top: 0.875rem !important;
}

.k-scroll-pt-4 {
  scroll-padding-top: 1rem;
}

.\!k-scroll-pt-4 {
  scroll-padding-top: 1rem !important;
}

.k-scroll-pt-4\.5 {
  scroll-padding-top: 1.125rem;
}

.\!k-scroll-pt-4\.5 {
  scroll-padding-top: 1.125rem !important;
}

.k-scroll-pt-5 {
  scroll-padding-top: 1.25rem;
}

.\!k-scroll-pt-5 {
  scroll-padding-top: 1.25rem !important;
}

.k-scroll-pt-5\.5 {
  scroll-padding-top: 1.375rem;
}

.\!k-scroll-pt-5\.5 {
  scroll-padding-top: 1.375rem !important;
}

.k-scroll-pt-6 {
  scroll-padding-top: 1.5rem;
}

.\!k-scroll-pt-6 {
  scroll-padding-top: 1.5rem !important;
}

.k-scroll-pt-6\.5 {
  scroll-padding-top: 1.625rem;
}

.\!k-scroll-pt-6\.5 {
  scroll-padding-top: 1.625rem !important;
}

.k-scroll-pt-7 {
  scroll-padding-top: 1.75rem;
}

.\!k-scroll-pt-7 {
  scroll-padding-top: 1.75rem !important;
}

.k-scroll-pt-7\.5 {
  scroll-padding-top: 1.875rem;
}

.\!k-scroll-pt-7\.5 {
  scroll-padding-top: 1.875rem !important;
}

.k-scroll-pt-8 {
  scroll-padding-top: 2rem;
}

.\!k-scroll-pt-8 {
  scroll-padding-top: 2rem !important;
}

.k-scroll-pt-9 {
  scroll-padding-top: 2.25rem;
}

.\!k-scroll-pt-9 {
  scroll-padding-top: 2.25rem !important;
}

.k-scroll-pt-10 {
  scroll-padding-top: 2.5rem;
}

.\!k-scroll-pt-10 {
  scroll-padding-top: 2.5rem !important;
}

.k-scroll-pt-11 {
  scroll-padding-top: 2.75rem;
}

.\!k-scroll-pt-11 {
  scroll-padding-top: 2.75rem !important;
}

.k-scroll-pt-12 {
  scroll-padding-top: 3rem;
}

.\!k-scroll-pt-12 {
  scroll-padding-top: 3rem !important;
}

.k-scroll-pt-13 {
  scroll-padding-top: 3.25rem;
}

.\!k-scroll-pt-13 {
  scroll-padding-top: 3.25rem !important;
}

.k-scroll-pt-14 {
  scroll-padding-top: 3.5rem;
}

.\!k-scroll-pt-14 {
  scroll-padding-top: 3.5rem !important;
}

.k-scroll-pt-15 {
  scroll-padding-top: 3.75rem;
}

.\!k-scroll-pt-15 {
  scroll-padding-top: 3.75rem !important;
}

.k-scroll-pt-16 {
  scroll-padding-top: 4rem;
}

.\!k-scroll-pt-16 {
  scroll-padding-top: 4rem !important;
}

.k-scroll-pt-17 {
  scroll-padding-top: 4.25rem;
}

.\!k-scroll-pt-17 {
  scroll-padding-top: 4.25rem !important;
}

.k-scroll-pt-18 {
  scroll-padding-top: 4.5rem;
}

.\!k-scroll-pt-18 {
  scroll-padding-top: 4.5rem !important;
}

.k-scroll-pt-19 {
  scroll-padding-top: 4.75rem;
}

.\!k-scroll-pt-19 {
  scroll-padding-top: 4.75rem !important;
}

.k-scroll-pt-20 {
  scroll-padding-top: 5rem;
}

.\!k-scroll-pt-20 {
  scroll-padding-top: 5rem !important;
}

.k-scroll-pt-21 {
  scroll-padding-top: 5.25rem;
}

.\!k-scroll-pt-21 {
  scroll-padding-top: 5.25rem !important;
}

.k-scroll-pt-22 {
  scroll-padding-top: 5.5rem;
}

.\!k-scroll-pt-22 {
  scroll-padding-top: 5.5rem !important;
}

.k-scroll-pt-23 {
  scroll-padding-top: 5.75rem;
}

.\!k-scroll-pt-23 {
  scroll-padding-top: 5.75rem !important;
}

.k-scroll-pt-24 {
  scroll-padding-top: 6rem;
}

.\!k-scroll-pt-24 {
  scroll-padding-top: 6rem !important;
}

.k-scroll-pt-25 {
  scroll-padding-top: 7rem;
}

.\!k-scroll-pt-25 {
  scroll-padding-top: 7rem !important;
}

.k-scroll-pt-26 {
  scroll-padding-top: 8rem;
}

.\!k-scroll-pt-26 {
  scroll-padding-top: 8rem !important;
}

.k-scroll-pt-27 {
  scroll-padding-top: 9rem;
}

.\!k-scroll-pt-27 {
  scroll-padding-top: 9rem !important;
}

.k-scroll-pt-28 {
  scroll-padding-top: 10rem;
}

.\!k-scroll-pt-28 {
  scroll-padding-top: 10rem !important;
}

.k-scroll-pt-29 {
  scroll-padding-top: 11rem;
}

.\!k-scroll-pt-29 {
  scroll-padding-top: 11rem !important;
}

.k-scroll-pt-30 {
  scroll-padding-top: 12rem;
}

.\!k-scroll-pt-30 {
  scroll-padding-top: 12rem !important;
}

.k-scroll-pr-0 {
  scroll-padding-right: 0px;
}

.\!k-scroll-pr-0 {
  scroll-padding-right: 0px !important;
}

.k-scroll-pr-1px {
  scroll-padding-right: 1px;
}

.\!k-scroll-pr-1px {
  scroll-padding-right: 1px !important;
}

.k-scroll-pr-0\.5 {
  scroll-padding-right: 0.125rem;
}

.\!k-scroll-pr-0\.5 {
  scroll-padding-right: 0.125rem !important;
}

.k-scroll-pr-1 {
  scroll-padding-right: 0.25rem;
}

.\!k-scroll-pr-1 {
  scroll-padding-right: 0.25rem !important;
}

.k-scroll-pr-1\.5 {
  scroll-padding-right: 0.375rem;
}

.\!k-scroll-pr-1\.5 {
  scroll-padding-right: 0.375rem !important;
}

.k-scroll-pr-2 {
  scroll-padding-right: 0.5rem;
}

.\!k-scroll-pr-2 {
  scroll-padding-right: 0.5rem !important;
}

.k-scroll-pr-2\.5 {
  scroll-padding-right: 0.625rem;
}

.\!k-scroll-pr-2\.5 {
  scroll-padding-right: 0.625rem !important;
}

.k-scroll-pr-3 {
  scroll-padding-right: 0.75rem;
}

.\!k-scroll-pr-3 {
  scroll-padding-right: 0.75rem !important;
}

.k-scroll-pr-3\.5 {
  scroll-padding-right: 0.875rem;
}

.\!k-scroll-pr-3\.5 {
  scroll-padding-right: 0.875rem !important;
}

.k-scroll-pr-4 {
  scroll-padding-right: 1rem;
}

.\!k-scroll-pr-4 {
  scroll-padding-right: 1rem !important;
}

.k-scroll-pr-4\.5 {
  scroll-padding-right: 1.125rem;
}

.\!k-scroll-pr-4\.5 {
  scroll-padding-right: 1.125rem !important;
}

.k-scroll-pr-5 {
  scroll-padding-right: 1.25rem;
}

.\!k-scroll-pr-5 {
  scroll-padding-right: 1.25rem !important;
}

.k-scroll-pr-5\.5 {
  scroll-padding-right: 1.375rem;
}

.\!k-scroll-pr-5\.5 {
  scroll-padding-right: 1.375rem !important;
}

.k-scroll-pr-6 {
  scroll-padding-right: 1.5rem;
}

.\!k-scroll-pr-6 {
  scroll-padding-right: 1.5rem !important;
}

.k-scroll-pr-6\.5 {
  scroll-padding-right: 1.625rem;
}

.\!k-scroll-pr-6\.5 {
  scroll-padding-right: 1.625rem !important;
}

.k-scroll-pr-7 {
  scroll-padding-right: 1.75rem;
}

.\!k-scroll-pr-7 {
  scroll-padding-right: 1.75rem !important;
}

.k-scroll-pr-7\.5 {
  scroll-padding-right: 1.875rem;
}

.\!k-scroll-pr-7\.5 {
  scroll-padding-right: 1.875rem !important;
}

.k-scroll-pr-8 {
  scroll-padding-right: 2rem;
}

.\!k-scroll-pr-8 {
  scroll-padding-right: 2rem !important;
}

.k-scroll-pr-9 {
  scroll-padding-right: 2.25rem;
}

.\!k-scroll-pr-9 {
  scroll-padding-right: 2.25rem !important;
}

.k-scroll-pr-10 {
  scroll-padding-right: 2.5rem;
}

.\!k-scroll-pr-10 {
  scroll-padding-right: 2.5rem !important;
}

.k-scroll-pr-11 {
  scroll-padding-right: 2.75rem;
}

.\!k-scroll-pr-11 {
  scroll-padding-right: 2.75rem !important;
}

.k-scroll-pr-12 {
  scroll-padding-right: 3rem;
}

.\!k-scroll-pr-12 {
  scroll-padding-right: 3rem !important;
}

.k-scroll-pr-13 {
  scroll-padding-right: 3.25rem;
}

.\!k-scroll-pr-13 {
  scroll-padding-right: 3.25rem !important;
}

.k-scroll-pr-14 {
  scroll-padding-right: 3.5rem;
}

.\!k-scroll-pr-14 {
  scroll-padding-right: 3.5rem !important;
}

.k-scroll-pr-15 {
  scroll-padding-right: 3.75rem;
}

.\!k-scroll-pr-15 {
  scroll-padding-right: 3.75rem !important;
}

.k-scroll-pr-16 {
  scroll-padding-right: 4rem;
}

.\!k-scroll-pr-16 {
  scroll-padding-right: 4rem !important;
}

.k-scroll-pr-17 {
  scroll-padding-right: 4.25rem;
}

.\!k-scroll-pr-17 {
  scroll-padding-right: 4.25rem !important;
}

.k-scroll-pr-18 {
  scroll-padding-right: 4.5rem;
}

.\!k-scroll-pr-18 {
  scroll-padding-right: 4.5rem !important;
}

.k-scroll-pr-19 {
  scroll-padding-right: 4.75rem;
}

.\!k-scroll-pr-19 {
  scroll-padding-right: 4.75rem !important;
}

.k-scroll-pr-20 {
  scroll-padding-right: 5rem;
}

.\!k-scroll-pr-20 {
  scroll-padding-right: 5rem !important;
}

.k-scroll-pr-21 {
  scroll-padding-right: 5.25rem;
}

.\!k-scroll-pr-21 {
  scroll-padding-right: 5.25rem !important;
}

.k-scroll-pr-22 {
  scroll-padding-right: 5.5rem;
}

.\!k-scroll-pr-22 {
  scroll-padding-right: 5.5rem !important;
}

.k-scroll-pr-23 {
  scroll-padding-right: 5.75rem;
}

.\!k-scroll-pr-23 {
  scroll-padding-right: 5.75rem !important;
}

.k-scroll-pr-24 {
  scroll-padding-right: 6rem;
}

.\!k-scroll-pr-24 {
  scroll-padding-right: 6rem !important;
}

.k-scroll-pr-25 {
  scroll-padding-right: 7rem;
}

.\!k-scroll-pr-25 {
  scroll-padding-right: 7rem !important;
}

.k-scroll-pr-26 {
  scroll-padding-right: 8rem;
}

.\!k-scroll-pr-26 {
  scroll-padding-right: 8rem !important;
}

.k-scroll-pr-27 {
  scroll-padding-right: 9rem;
}

.\!k-scroll-pr-27 {
  scroll-padding-right: 9rem !important;
}

.k-scroll-pr-28 {
  scroll-padding-right: 10rem;
}

.\!k-scroll-pr-28 {
  scroll-padding-right: 10rem !important;
}

.k-scroll-pr-29 {
  scroll-padding-right: 11rem;
}

.\!k-scroll-pr-29 {
  scroll-padding-right: 11rem !important;
}

.k-scroll-pr-30 {
  scroll-padding-right: 12rem;
}

.\!k-scroll-pr-30 {
  scroll-padding-right: 12rem !important;
}

.k-scroll-pb-0 {
  scroll-padding-bottom: 0px;
}

.\!k-scroll-pb-0 {
  scroll-padding-bottom: 0px !important;
}

.k-scroll-pb-1px {
  scroll-padding-bottom: 1px;
}

.\!k-scroll-pb-1px {
  scroll-padding-bottom: 1px !important;
}

.k-scroll-pb-0\.5 {
  scroll-padding-bottom: 0.125rem;
}

.\!k-scroll-pb-0\.5 {
  scroll-padding-bottom: 0.125rem !important;
}

.k-scroll-pb-1 {
  scroll-padding-bottom: 0.25rem;
}

.\!k-scroll-pb-1 {
  scroll-padding-bottom: 0.25rem !important;
}

.k-scroll-pb-1\.5 {
  scroll-padding-bottom: 0.375rem;
}

.\!k-scroll-pb-1\.5 {
  scroll-padding-bottom: 0.375rem !important;
}

.k-scroll-pb-2 {
  scroll-padding-bottom: 0.5rem;
}

.\!k-scroll-pb-2 {
  scroll-padding-bottom: 0.5rem !important;
}

.k-scroll-pb-2\.5 {
  scroll-padding-bottom: 0.625rem;
}

.\!k-scroll-pb-2\.5 {
  scroll-padding-bottom: 0.625rem !important;
}

.k-scroll-pb-3 {
  scroll-padding-bottom: 0.75rem;
}

.\!k-scroll-pb-3 {
  scroll-padding-bottom: 0.75rem !important;
}

.k-scroll-pb-3\.5 {
  scroll-padding-bottom: 0.875rem;
}

.\!k-scroll-pb-3\.5 {
  scroll-padding-bottom: 0.875rem !important;
}

.k-scroll-pb-4 {
  scroll-padding-bottom: 1rem;
}

.\!k-scroll-pb-4 {
  scroll-padding-bottom: 1rem !important;
}

.k-scroll-pb-4\.5 {
  scroll-padding-bottom: 1.125rem;
}

.\!k-scroll-pb-4\.5 {
  scroll-padding-bottom: 1.125rem !important;
}

.k-scroll-pb-5 {
  scroll-padding-bottom: 1.25rem;
}

.\!k-scroll-pb-5 {
  scroll-padding-bottom: 1.25rem !important;
}

.k-scroll-pb-5\.5 {
  scroll-padding-bottom: 1.375rem;
}

.\!k-scroll-pb-5\.5 {
  scroll-padding-bottom: 1.375rem !important;
}

.k-scroll-pb-6 {
  scroll-padding-bottom: 1.5rem;
}

.\!k-scroll-pb-6 {
  scroll-padding-bottom: 1.5rem !important;
}

.k-scroll-pb-6\.5 {
  scroll-padding-bottom: 1.625rem;
}

.\!k-scroll-pb-6\.5 {
  scroll-padding-bottom: 1.625rem !important;
}

.k-scroll-pb-7 {
  scroll-padding-bottom: 1.75rem;
}

.\!k-scroll-pb-7 {
  scroll-padding-bottom: 1.75rem !important;
}

.k-scroll-pb-7\.5 {
  scroll-padding-bottom: 1.875rem;
}

.\!k-scroll-pb-7\.5 {
  scroll-padding-bottom: 1.875rem !important;
}

.k-scroll-pb-8 {
  scroll-padding-bottom: 2rem;
}

.\!k-scroll-pb-8 {
  scroll-padding-bottom: 2rem !important;
}

.k-scroll-pb-9 {
  scroll-padding-bottom: 2.25rem;
}

.\!k-scroll-pb-9 {
  scroll-padding-bottom: 2.25rem !important;
}

.k-scroll-pb-10 {
  scroll-padding-bottom: 2.5rem;
}

.\!k-scroll-pb-10 {
  scroll-padding-bottom: 2.5rem !important;
}

.k-scroll-pb-11 {
  scroll-padding-bottom: 2.75rem;
}

.\!k-scroll-pb-11 {
  scroll-padding-bottom: 2.75rem !important;
}

.k-scroll-pb-12 {
  scroll-padding-bottom: 3rem;
}

.\!k-scroll-pb-12 {
  scroll-padding-bottom: 3rem !important;
}

.k-scroll-pb-13 {
  scroll-padding-bottom: 3.25rem;
}

.\!k-scroll-pb-13 {
  scroll-padding-bottom: 3.25rem !important;
}

.k-scroll-pb-14 {
  scroll-padding-bottom: 3.5rem;
}

.\!k-scroll-pb-14 {
  scroll-padding-bottom: 3.5rem !important;
}

.k-scroll-pb-15 {
  scroll-padding-bottom: 3.75rem;
}

.\!k-scroll-pb-15 {
  scroll-padding-bottom: 3.75rem !important;
}

.k-scroll-pb-16 {
  scroll-padding-bottom: 4rem;
}

.\!k-scroll-pb-16 {
  scroll-padding-bottom: 4rem !important;
}

.k-scroll-pb-17 {
  scroll-padding-bottom: 4.25rem;
}

.\!k-scroll-pb-17 {
  scroll-padding-bottom: 4.25rem !important;
}

.k-scroll-pb-18 {
  scroll-padding-bottom: 4.5rem;
}

.\!k-scroll-pb-18 {
  scroll-padding-bottom: 4.5rem !important;
}

.k-scroll-pb-19 {
  scroll-padding-bottom: 4.75rem;
}

.\!k-scroll-pb-19 {
  scroll-padding-bottom: 4.75rem !important;
}

.k-scroll-pb-20 {
  scroll-padding-bottom: 5rem;
}

.\!k-scroll-pb-20 {
  scroll-padding-bottom: 5rem !important;
}

.k-scroll-pb-21 {
  scroll-padding-bottom: 5.25rem;
}

.\!k-scroll-pb-21 {
  scroll-padding-bottom: 5.25rem !important;
}

.k-scroll-pb-22 {
  scroll-padding-bottom: 5.5rem;
}

.\!k-scroll-pb-22 {
  scroll-padding-bottom: 5.5rem !important;
}

.k-scroll-pb-23 {
  scroll-padding-bottom: 5.75rem;
}

.\!k-scroll-pb-23 {
  scroll-padding-bottom: 5.75rem !important;
}

.k-scroll-pb-24 {
  scroll-padding-bottom: 6rem;
}

.\!k-scroll-pb-24 {
  scroll-padding-bottom: 6rem !important;
}

.k-scroll-pb-25 {
  scroll-padding-bottom: 7rem;
}

.\!k-scroll-pb-25 {
  scroll-padding-bottom: 7rem !important;
}

.k-scroll-pb-26 {
  scroll-padding-bottom: 8rem;
}

.\!k-scroll-pb-26 {
  scroll-padding-bottom: 8rem !important;
}

.k-scroll-pb-27 {
  scroll-padding-bottom: 9rem;
}

.\!k-scroll-pb-27 {
  scroll-padding-bottom: 9rem !important;
}

.k-scroll-pb-28 {
  scroll-padding-bottom: 10rem;
}

.\!k-scroll-pb-28 {
  scroll-padding-bottom: 10rem !important;
}

.k-scroll-pb-29 {
  scroll-padding-bottom: 11rem;
}

.\!k-scroll-pb-29 {
  scroll-padding-bottom: 11rem !important;
}

.k-scroll-pb-30 {
  scroll-padding-bottom: 12rem;
}

.\!k-scroll-pb-30 {
  scroll-padding-bottom: 12rem !important;
}

.k-scroll-pl-0 {
  scroll-padding-left: 0px;
}

.\!k-scroll-pl-0 {
  scroll-padding-left: 0px !important;
}

.k-scroll-pl-1px {
  scroll-padding-left: 1px;
}

.\!k-scroll-pl-1px {
  scroll-padding-left: 1px !important;
}

.k-scroll-pl-0\.5 {
  scroll-padding-left: 0.125rem;
}

.\!k-scroll-pl-0\.5 {
  scroll-padding-left: 0.125rem !important;
}

.k-scroll-pl-1 {
  scroll-padding-left: 0.25rem;
}

.\!k-scroll-pl-1 {
  scroll-padding-left: 0.25rem !important;
}

.k-scroll-pl-1\.5 {
  scroll-padding-left: 0.375rem;
}

.\!k-scroll-pl-1\.5 {
  scroll-padding-left: 0.375rem !important;
}

.k-scroll-pl-2 {
  scroll-padding-left: 0.5rem;
}

.\!k-scroll-pl-2 {
  scroll-padding-left: 0.5rem !important;
}

.k-scroll-pl-2\.5 {
  scroll-padding-left: 0.625rem;
}

.\!k-scroll-pl-2\.5 {
  scroll-padding-left: 0.625rem !important;
}

.k-scroll-pl-3 {
  scroll-padding-left: 0.75rem;
}

.\!k-scroll-pl-3 {
  scroll-padding-left: 0.75rem !important;
}

.k-scroll-pl-3\.5 {
  scroll-padding-left: 0.875rem;
}

.\!k-scroll-pl-3\.5 {
  scroll-padding-left: 0.875rem !important;
}

.k-scroll-pl-4 {
  scroll-padding-left: 1rem;
}

.\!k-scroll-pl-4 {
  scroll-padding-left: 1rem !important;
}

.k-scroll-pl-4\.5 {
  scroll-padding-left: 1.125rem;
}

.\!k-scroll-pl-4\.5 {
  scroll-padding-left: 1.125rem !important;
}

.k-scroll-pl-5 {
  scroll-padding-left: 1.25rem;
}

.\!k-scroll-pl-5 {
  scroll-padding-left: 1.25rem !important;
}

.k-scroll-pl-5\.5 {
  scroll-padding-left: 1.375rem;
}

.\!k-scroll-pl-5\.5 {
  scroll-padding-left: 1.375rem !important;
}

.k-scroll-pl-6 {
  scroll-padding-left: 1.5rem;
}

.\!k-scroll-pl-6 {
  scroll-padding-left: 1.5rem !important;
}

.k-scroll-pl-6\.5 {
  scroll-padding-left: 1.625rem;
}

.\!k-scroll-pl-6\.5 {
  scroll-padding-left: 1.625rem !important;
}

.k-scroll-pl-7 {
  scroll-padding-left: 1.75rem;
}

.\!k-scroll-pl-7 {
  scroll-padding-left: 1.75rem !important;
}

.k-scroll-pl-7\.5 {
  scroll-padding-left: 1.875rem;
}

.\!k-scroll-pl-7\.5 {
  scroll-padding-left: 1.875rem !important;
}

.k-scroll-pl-8 {
  scroll-padding-left: 2rem;
}

.\!k-scroll-pl-8 {
  scroll-padding-left: 2rem !important;
}

.k-scroll-pl-9 {
  scroll-padding-left: 2.25rem;
}

.\!k-scroll-pl-9 {
  scroll-padding-left: 2.25rem !important;
}

.k-scroll-pl-10 {
  scroll-padding-left: 2.5rem;
}

.\!k-scroll-pl-10 {
  scroll-padding-left: 2.5rem !important;
}

.k-scroll-pl-11 {
  scroll-padding-left: 2.75rem;
}

.\!k-scroll-pl-11 {
  scroll-padding-left: 2.75rem !important;
}

.k-scroll-pl-12 {
  scroll-padding-left: 3rem;
}

.\!k-scroll-pl-12 {
  scroll-padding-left: 3rem !important;
}

.k-scroll-pl-13 {
  scroll-padding-left: 3.25rem;
}

.\!k-scroll-pl-13 {
  scroll-padding-left: 3.25rem !important;
}

.k-scroll-pl-14 {
  scroll-padding-left: 3.5rem;
}

.\!k-scroll-pl-14 {
  scroll-padding-left: 3.5rem !important;
}

.k-scroll-pl-15 {
  scroll-padding-left: 3.75rem;
}

.\!k-scroll-pl-15 {
  scroll-padding-left: 3.75rem !important;
}

.k-scroll-pl-16 {
  scroll-padding-left: 4rem;
}

.\!k-scroll-pl-16 {
  scroll-padding-left: 4rem !important;
}

.k-scroll-pl-17 {
  scroll-padding-left: 4.25rem;
}

.\!k-scroll-pl-17 {
  scroll-padding-left: 4.25rem !important;
}

.k-scroll-pl-18 {
  scroll-padding-left: 4.5rem;
}

.\!k-scroll-pl-18 {
  scroll-padding-left: 4.5rem !important;
}

.k-scroll-pl-19 {
  scroll-padding-left: 4.75rem;
}

.\!k-scroll-pl-19 {
  scroll-padding-left: 4.75rem !important;
}

.k-scroll-pl-20 {
  scroll-padding-left: 5rem;
}

.\!k-scroll-pl-20 {
  scroll-padding-left: 5rem !important;
}

.k-scroll-pl-21 {
  scroll-padding-left: 5.25rem;
}

.\!k-scroll-pl-21 {
  scroll-padding-left: 5.25rem !important;
}

.k-scroll-pl-22 {
  scroll-padding-left: 5.5rem;
}

.\!k-scroll-pl-22 {
  scroll-padding-left: 5.5rem !important;
}

.k-scroll-pl-23 {
  scroll-padding-left: 5.75rem;
}

.\!k-scroll-pl-23 {
  scroll-padding-left: 5.75rem !important;
}

.k-scroll-pl-24 {
  scroll-padding-left: 6rem;
}

.\!k-scroll-pl-24 {
  scroll-padding-left: 6rem !important;
}

.k-scroll-pl-25 {
  scroll-padding-left: 7rem;
}

.\!k-scroll-pl-25 {
  scroll-padding-left: 7rem !important;
}

.k-scroll-pl-26 {
  scroll-padding-left: 8rem;
}

.\!k-scroll-pl-26 {
  scroll-padding-left: 8rem !important;
}

.k-scroll-pl-27 {
  scroll-padding-left: 9rem;
}

.\!k-scroll-pl-27 {
  scroll-padding-left: 9rem !important;
}

.k-scroll-pl-28 {
  scroll-padding-left: 10rem;
}

.\!k-scroll-pl-28 {
  scroll-padding-left: 10rem !important;
}

.k-scroll-pl-29 {
  scroll-padding-left: 11rem;
}

.\!k-scroll-pl-29 {
  scroll-padding-left: 11rem !important;
}

.k-scroll-pl-30 {
  scroll-padding-left: 12rem;
}

.\!k-scroll-pl-30 {
  scroll-padding-left: 12rem !important;
}

.k-scroll-px-0 {
  scroll-padding-inline: 0px;
}

.\!k-scroll-px-0 {
  scroll-padding-inline: 0px !important;
}

.k-scroll-px-1px {
  scroll-padding-inline: 1px;
}

.\!k-scroll-px-1px {
  scroll-padding-inline: 1px !important;
}

.k-scroll-px-0\.5 {
  scroll-padding-inline: 0.125rem;
}

.\!k-scroll-px-0\.5 {
  scroll-padding-inline: 0.125rem !important;
}

.k-scroll-px-1 {
  scroll-padding-inline: 0.25rem;
}

.\!k-scroll-px-1 {
  scroll-padding-inline: 0.25rem !important;
}

.k-scroll-px-1\.5 {
  scroll-padding-inline: 0.375rem;
}

.\!k-scroll-px-1\.5 {
  scroll-padding-inline: 0.375rem !important;
}

.k-scroll-px-2 {
  scroll-padding-inline: 0.5rem;
}

.\!k-scroll-px-2 {
  scroll-padding-inline: 0.5rem !important;
}

.k-scroll-px-2\.5 {
  scroll-padding-inline: 0.625rem;
}

.\!k-scroll-px-2\.5 {
  scroll-padding-inline: 0.625rem !important;
}

.k-scroll-px-3 {
  scroll-padding-inline: 0.75rem;
}

.\!k-scroll-px-3 {
  scroll-padding-inline: 0.75rem !important;
}

.k-scroll-px-3\.5 {
  scroll-padding-inline: 0.875rem;
}

.\!k-scroll-px-3\.5 {
  scroll-padding-inline: 0.875rem !important;
}

.k-scroll-px-4 {
  scroll-padding-inline: 1rem;
}

.\!k-scroll-px-4 {
  scroll-padding-inline: 1rem !important;
}

.k-scroll-px-4\.5 {
  scroll-padding-inline: 1.125rem;
}

.\!k-scroll-px-4\.5 {
  scroll-padding-inline: 1.125rem !important;
}

.k-scroll-px-5 {
  scroll-padding-inline: 1.25rem;
}

.\!k-scroll-px-5 {
  scroll-padding-inline: 1.25rem !important;
}

.k-scroll-px-5\.5 {
  scroll-padding-inline: 1.375rem;
}

.\!k-scroll-px-5\.5 {
  scroll-padding-inline: 1.375rem !important;
}

.k-scroll-px-6 {
  scroll-padding-inline: 1.5rem;
}

.\!k-scroll-px-6 {
  scroll-padding-inline: 1.5rem !important;
}

.k-scroll-px-6\.5 {
  scroll-padding-inline: 1.625rem;
}

.\!k-scroll-px-6\.5 {
  scroll-padding-inline: 1.625rem !important;
}

.k-scroll-px-7 {
  scroll-padding-inline: 1.75rem;
}

.\!k-scroll-px-7 {
  scroll-padding-inline: 1.75rem !important;
}

.k-scroll-px-7\.5 {
  scroll-padding-inline: 1.875rem;
}

.\!k-scroll-px-7\.5 {
  scroll-padding-inline: 1.875rem !important;
}

.k-scroll-px-8 {
  scroll-padding-inline: 2rem;
}

.\!k-scroll-px-8 {
  scroll-padding-inline: 2rem !important;
}

.k-scroll-px-9 {
  scroll-padding-inline: 2.25rem;
}

.\!k-scroll-px-9 {
  scroll-padding-inline: 2.25rem !important;
}

.k-scroll-px-10 {
  scroll-padding-inline: 2.5rem;
}

.\!k-scroll-px-10 {
  scroll-padding-inline: 2.5rem !important;
}

.k-scroll-px-11 {
  scroll-padding-inline: 2.75rem;
}

.\!k-scroll-px-11 {
  scroll-padding-inline: 2.75rem !important;
}

.k-scroll-px-12 {
  scroll-padding-inline: 3rem;
}

.\!k-scroll-px-12 {
  scroll-padding-inline: 3rem !important;
}

.k-scroll-px-13 {
  scroll-padding-inline: 3.25rem;
}

.\!k-scroll-px-13 {
  scroll-padding-inline: 3.25rem !important;
}

.k-scroll-px-14 {
  scroll-padding-inline: 3.5rem;
}

.\!k-scroll-px-14 {
  scroll-padding-inline: 3.5rem !important;
}

.k-scroll-px-15 {
  scroll-padding-inline: 3.75rem;
}

.\!k-scroll-px-15 {
  scroll-padding-inline: 3.75rem !important;
}

.k-scroll-px-16 {
  scroll-padding-inline: 4rem;
}

.\!k-scroll-px-16 {
  scroll-padding-inline: 4rem !important;
}

.k-scroll-px-17 {
  scroll-padding-inline: 4.25rem;
}

.\!k-scroll-px-17 {
  scroll-padding-inline: 4.25rem !important;
}

.k-scroll-px-18 {
  scroll-padding-inline: 4.5rem;
}

.\!k-scroll-px-18 {
  scroll-padding-inline: 4.5rem !important;
}

.k-scroll-px-19 {
  scroll-padding-inline: 4.75rem;
}

.\!k-scroll-px-19 {
  scroll-padding-inline: 4.75rem !important;
}

.k-scroll-px-20 {
  scroll-padding-inline: 5rem;
}

.\!k-scroll-px-20 {
  scroll-padding-inline: 5rem !important;
}

.k-scroll-px-21 {
  scroll-padding-inline: 5.25rem;
}

.\!k-scroll-px-21 {
  scroll-padding-inline: 5.25rem !important;
}

.k-scroll-px-22 {
  scroll-padding-inline: 5.5rem;
}

.\!k-scroll-px-22 {
  scroll-padding-inline: 5.5rem !important;
}

.k-scroll-px-23 {
  scroll-padding-inline: 5.75rem;
}

.\!k-scroll-px-23 {
  scroll-padding-inline: 5.75rem !important;
}

.k-scroll-px-24 {
  scroll-padding-inline: 6rem;
}

.\!k-scroll-px-24 {
  scroll-padding-inline: 6rem !important;
}

.k-scroll-px-25 {
  scroll-padding-inline: 7rem;
}

.\!k-scroll-px-25 {
  scroll-padding-inline: 7rem !important;
}

.k-scroll-px-26 {
  scroll-padding-inline: 8rem;
}

.\!k-scroll-px-26 {
  scroll-padding-inline: 8rem !important;
}

.k-scroll-px-27 {
  scroll-padding-inline: 9rem;
}

.\!k-scroll-px-27 {
  scroll-padding-inline: 9rem !important;
}

.k-scroll-px-28 {
  scroll-padding-inline: 10rem;
}

.\!k-scroll-px-28 {
  scroll-padding-inline: 10rem !important;
}

.k-scroll-px-29 {
  scroll-padding-inline: 11rem;
}

.\!k-scroll-px-29 {
  scroll-padding-inline: 11rem !important;
}

.k-scroll-px-30 {
  scroll-padding-inline: 12rem;
}

.\!k-scroll-px-30 {
  scroll-padding-inline: 12rem !important;
}

.k-scroll-py-0 {
  scroll-padding-block: 0px;
}

.\!k-scroll-py-0 {
  scroll-padding-block: 0px !important;
}

.k-scroll-py-1px {
  scroll-padding-block: 1px;
}

.\!k-scroll-py-1px {
  scroll-padding-block: 1px !important;
}

.k-scroll-py-0\.5 {
  scroll-padding-block: 0.125rem;
}

.\!k-scroll-py-0\.5 {
  scroll-padding-block: 0.125rem !important;
}

.k-scroll-py-1 {
  scroll-padding-block: 0.25rem;
}

.\!k-scroll-py-1 {
  scroll-padding-block: 0.25rem !important;
}

.k-scroll-py-1\.5 {
  scroll-padding-block: 0.375rem;
}

.\!k-scroll-py-1\.5 {
  scroll-padding-block: 0.375rem !important;
}

.k-scroll-py-2 {
  scroll-padding-block: 0.5rem;
}

.\!k-scroll-py-2 {
  scroll-padding-block: 0.5rem !important;
}

.k-scroll-py-2\.5 {
  scroll-padding-block: 0.625rem;
}

.\!k-scroll-py-2\.5 {
  scroll-padding-block: 0.625rem !important;
}

.k-scroll-py-3 {
  scroll-padding-block: 0.75rem;
}

.\!k-scroll-py-3 {
  scroll-padding-block: 0.75rem !important;
}

.k-scroll-py-3\.5 {
  scroll-padding-block: 0.875rem;
}

.\!k-scroll-py-3\.5 {
  scroll-padding-block: 0.875rem !important;
}

.k-scroll-py-4 {
  scroll-padding-block: 1rem;
}

.\!k-scroll-py-4 {
  scroll-padding-block: 1rem !important;
}

.k-scroll-py-4\.5 {
  scroll-padding-block: 1.125rem;
}

.\!k-scroll-py-4\.5 {
  scroll-padding-block: 1.125rem !important;
}

.k-scroll-py-5 {
  scroll-padding-block: 1.25rem;
}

.\!k-scroll-py-5 {
  scroll-padding-block: 1.25rem !important;
}

.k-scroll-py-5\.5 {
  scroll-padding-block: 1.375rem;
}

.\!k-scroll-py-5\.5 {
  scroll-padding-block: 1.375rem !important;
}

.k-scroll-py-6 {
  scroll-padding-block: 1.5rem;
}

.\!k-scroll-py-6 {
  scroll-padding-block: 1.5rem !important;
}

.k-scroll-py-6\.5 {
  scroll-padding-block: 1.625rem;
}

.\!k-scroll-py-6\.5 {
  scroll-padding-block: 1.625rem !important;
}

.k-scroll-py-7 {
  scroll-padding-block: 1.75rem;
}

.\!k-scroll-py-7 {
  scroll-padding-block: 1.75rem !important;
}

.k-scroll-py-7\.5 {
  scroll-padding-block: 1.875rem;
}

.\!k-scroll-py-7\.5 {
  scroll-padding-block: 1.875rem !important;
}

.k-scroll-py-8 {
  scroll-padding-block: 2rem;
}

.\!k-scroll-py-8 {
  scroll-padding-block: 2rem !important;
}

.k-scroll-py-9 {
  scroll-padding-block: 2.25rem;
}

.\!k-scroll-py-9 {
  scroll-padding-block: 2.25rem !important;
}

.k-scroll-py-10 {
  scroll-padding-block: 2.5rem;
}

.\!k-scroll-py-10 {
  scroll-padding-block: 2.5rem !important;
}

.k-scroll-py-11 {
  scroll-padding-block: 2.75rem;
}

.\!k-scroll-py-11 {
  scroll-padding-block: 2.75rem !important;
}

.k-scroll-py-12 {
  scroll-padding-block: 3rem;
}

.\!k-scroll-py-12 {
  scroll-padding-block: 3rem !important;
}

.k-scroll-py-13 {
  scroll-padding-block: 3.25rem;
}

.\!k-scroll-py-13 {
  scroll-padding-block: 3.25rem !important;
}

.k-scroll-py-14 {
  scroll-padding-block: 3.5rem;
}

.\!k-scroll-py-14 {
  scroll-padding-block: 3.5rem !important;
}

.k-scroll-py-15 {
  scroll-padding-block: 3.75rem;
}

.\!k-scroll-py-15 {
  scroll-padding-block: 3.75rem !important;
}

.k-scroll-py-16 {
  scroll-padding-block: 4rem;
}

.\!k-scroll-py-16 {
  scroll-padding-block: 4rem !important;
}

.k-scroll-py-17 {
  scroll-padding-block: 4.25rem;
}

.\!k-scroll-py-17 {
  scroll-padding-block: 4.25rem !important;
}

.k-scroll-py-18 {
  scroll-padding-block: 4.5rem;
}

.\!k-scroll-py-18 {
  scroll-padding-block: 4.5rem !important;
}

.k-scroll-py-19 {
  scroll-padding-block: 4.75rem;
}

.\!k-scroll-py-19 {
  scroll-padding-block: 4.75rem !important;
}

.k-scroll-py-20 {
  scroll-padding-block: 5rem;
}

.\!k-scroll-py-20 {
  scroll-padding-block: 5rem !important;
}

.k-scroll-py-21 {
  scroll-padding-block: 5.25rem;
}

.\!k-scroll-py-21 {
  scroll-padding-block: 5.25rem !important;
}

.k-scroll-py-22 {
  scroll-padding-block: 5.5rem;
}

.\!k-scroll-py-22 {
  scroll-padding-block: 5.5rem !important;
}

.k-scroll-py-23 {
  scroll-padding-block: 5.75rem;
}

.\!k-scroll-py-23 {
  scroll-padding-block: 5.75rem !important;
}

.k-scroll-py-24 {
  scroll-padding-block: 6rem;
}

.\!k-scroll-py-24 {
  scroll-padding-block: 6rem !important;
}

.k-scroll-py-25 {
  scroll-padding-block: 7rem;
}

.\!k-scroll-py-25 {
  scroll-padding-block: 7rem !important;
}

.k-scroll-py-26 {
  scroll-padding-block: 8rem;
}

.\!k-scroll-py-26 {
  scroll-padding-block: 8rem !important;
}

.k-scroll-py-27 {
  scroll-padding-block: 9rem;
}

.\!k-scroll-py-27 {
  scroll-padding-block: 9rem !important;
}

.k-scroll-py-28 {
  scroll-padding-block: 10rem;
}

.\!k-scroll-py-28 {
  scroll-padding-block: 10rem !important;
}

.k-scroll-py-29 {
  scroll-padding-block: 11rem;
}

.\!k-scroll-py-29 {
  scroll-padding-block: 11rem !important;
}

.k-scroll-py-30 {
  scroll-padding-block: 12rem;
}

.\!k-scroll-py-30 {
  scroll-padding-block: 12rem !important;
}

.k-touch-action-none {
  touch-action: none;
}

.\!k-touch-action-none {
  touch-action: none !important;
}

.k-touch-action-auto {
  touch-action: auto;
}

.\!k-touch-action-auto {
  touch-action: auto !important;
}

.k-touch-action-pan-x {
  touch-action: pan-x;
}

.\!k-touch-action-pan-x {
  touch-action: pan-x !important;
}

.k-touch-action-pan-left {
  touch-action: pan-left;
}

.\!k-touch-action-pan-left {
  touch-action: pan-left !important;
}

.k-touch-action-pan-right {
  touch-action: pan-right;
}

.\!k-touch-action-pan-right {
  touch-action: pan-right !important;
}

.k-touch-action-pan-y {
  touch-action: pan-y;
}

.\!k-touch-action-pan-y {
  touch-action: pan-y !important;
}

.k-touch-action-pan-up {
  touch-action: pan-up;
}

.\!k-touch-action-pan-up {
  touch-action: pan-up !important;
}

.k-touch-action-pan-down {
  touch-action: pan-down;
}

.\!k-touch-action-pan-down {
  touch-action: pan-down !important;
}

.k-touch-action-pinch-zoom {
  touch-action: pinch-zoom;
}

.\!k-touch-action-pinch-zoom {
  touch-action: pinch-zoom !important;
}

.k-touch-action-manipulation {
  touch-action: manipulation;
}

.\!k-touch-action-manipulation {
  touch-action: manipulation !important;
}

.k-user-select-none {
  user-select: none;
}

.\!k-user-select-none {
  user-select: none !important;
}

.k-user-select-auto {
  user-select: auto;
}

.\!k-user-select-auto {
  user-select: auto !important;
}

.k-user-select-text {
  user-select: text;
}

.\!k-user-select-text {
  user-select: text !important;
}

.k-user-select-all {
  user-select: all;
}

.\!k-user-select-all {
  user-select: all !important;
}

.k-user-select-contain {
  user-select: contain;
}

.\!k-user-select-contain {
  user-select: contain !important;
}

.k-will-change-auto {
  will-change: auto;
}

.\!k-will-change-auto {
  will-change: auto !important;
}

.k-will-change-scroll {
  will-change: scroll-position;
}

.\!k-will-change-scroll {
  will-change: scroll-position !important;
}

.k-will-change-contents {
  will-change: contents;
}

.\!k-will-change-contents {
  will-change: contents !important;
}

.k-will-change-transform {
  will-change: transform;
}

.\!k-will-change-transform {
  will-change: transform !important;
}

.k-fill-none {
  fill: none;
}

.\!k-fill-none {
  fill: none !important;
}

.k-fill-inherit {
  fill: inherit;
}

.\!k-fill-inherit {
  fill: inherit !important;
}

.k-fill-current {
  fill: currentColor;
}

.\!k-fill-current {
  fill: currentColor !important;
}

.k-fill-transparent {
  fill: transparent;
}

.\!k-fill-transparent {
  fill: transparent !important;
}

.k-fill-black {
  fill: black;
}

.\!k-fill-black {
  fill: black !important;
}

.k-fill-white {
  fill: white;
}

.\!k-fill-white {
  fill: white !important;
}

.k-stroke-none {
  stroke: none;
}

.\!k-stroke-none {
  stroke: none !important;
}

.k-stroke-inherit {
  stroke: inherit;
}

.\!k-stroke-inherit {
  stroke: inherit !important;
}

.k-stroke-current {
  stroke: currentColor;
}

.\!k-stroke-current {
  stroke: currentColor !important;
}

.k-stroke-transparent {
  stroke: transparent;
}

.\!k-stroke-transparent {
  stroke: transparent !important;
}

.k-stroke-black {
  stroke: black;
}

.\!k-stroke-black {
  stroke: black !important;
}

.k-stroke-white {
  stroke: white;
}

.\!k-stroke-white {
  stroke: white !important;
}

.k-elevation-1 {
  box-shadow: var(--kendo-elevation-1, 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-1 {
  box-shadow: var(--kendo-elevation-1, 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-elevation-2 {
  box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-2 {
  box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-elevation-3 {
  box-shadow: var(--kendo-elevation-3, 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-3 {
  box-shadow: var(--kendo-elevation-3, 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-elevation-4 {
  box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-4 {
  box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-elevation-5 {
  box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-5 {
  box-shadow: var(--kendo-elevation-5, 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-elevation-6 {
  box-shadow: var(--kendo-elevation-6, 0 12px 14px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-6 {
  box-shadow: var(--kendo-elevation-6, 0 12px 14px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-elevation-7 {
  box-shadow: var(--kendo-elevation-7, 0 14px 16px rgba(0, 0, 0, 0.24), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-7 {
  box-shadow: var(--kendo-elevation-7, 0 14px 16px rgba(0, 0, 0, 0.24), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-elevation-8 {
  box-shadow: var(--kendo-elevation-8, 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-8 {
  box-shadow: var(--kendo-elevation-8, 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-elevation-9 {
  box-shadow: var(--kendo-elevation-9, 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.\!k-elevation-9 {
  box-shadow: var(--kendo-elevation-9, 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12)) !important;
}

.k-animation-container {
  position: absolute;
  overflow: hidden;
  z-index: 100;
}
.k-animation-container-fixed {
  position: fixed;
}
.k-animation-container-relative {
  position: relative;
  display: inline-block;
}

.k-animation-container {
  border-radius: 0 0 0.25rem 0.25rem;
}

.k-animation-container-shown {
  overflow: visible;
}

.k-popup {
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.k-popup .k-item {
  outline: none;
}

.k-popup > .k-colorpalette {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-popup.k-popup-transparent {
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
}

.k-popup.k-popup-flush {
  padding: 0;
}

.k-popup > .k-widget,
.k-popup > .k-coloreditor {
  border-width: 0;
}

.k-shadow {
  box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-popup {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
  box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-animation-container {
  border-radius: 0 0 var(--kendo-border-radius-md, 0.25rem) var(--kendo-border-radius-md, 0.25rem);
}

.k-svg-icon {
  width: 16px;
  height: 16px;
  outline: 0;
  line-height: 1;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
}
.k-svg-icon > svg {
  fill: currentColor;
  flex: 1 1 auto;
}

.k-svg-icon.k-icon-xs {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-svg-icon.k-icon-sm {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-svg-icon.k-icon-md {
  width: 16px;
  height: 16px;
}
.k-svg-icon.k-icon-lg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-svg-icon.k-icon-xl {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-svg-icon.k-icon-xxl {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-svg-icon.k-icon-xxxl {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-flip-h.k-svg-icon, .k-svg-icon.k-flip-x,
.k-flip-v.k-svg-icon,
.k-svg-icon.k-flip-y,
.k-flip-h.k-flip-v.k-svg-icon {
  transform: none;
}

.k-flip-h > svg, .k-flip-x > svg {
  transform: scaleX(-1);
}

.k-flip-v > svg, .k-flip-y > svg {
  transform: scaleY(-1);
}

.k-flip-h.k-flip-v > svg, .k-flip-v.k-flip-x > svg, .k-flip-h.k-flip-y > svg, .k-flip-x.k-flip-y > svg,
.k-flip-both > svg {
  transform: scale(-1, -1);
}

.k-rotate-0.k-svg-icon {
  transform: none;
}
.k-rotate-0 > svg {
  transform: rotate(0deg);
}

.k-rotate-45.k-svg-icon {
  transform: none;
}
.k-rotate-45 > svg {
  transform: rotate(45deg);
}

.k-rotate-90.k-svg-icon {
  transform: none;
}
.k-rotate-90 > svg {
  transform: rotate(90deg);
}

.k-rotate-135.k-svg-icon {
  transform: none;
}
.k-rotate-135 > svg {
  transform: rotate(135deg);
}

.k-rotate-180.k-svg-icon {
  transform: none;
}
.k-rotate-180 > svg {
  transform: rotate(180deg);
}

.k-rotate-225.k-svg-icon {
  transform: none;
}
.k-rotate-225 > svg {
  transform: rotate(225deg);
}

.k-rotate-270.k-svg-icon {
  transform: none;
}
.k-rotate-270 > svg {
  transform: rotate(270deg);
}

.k-rotate-315.k-svg-icon {
  transform: none;
}
.k-rotate-315 > svg {
  transform: rotate(315deg);
}

.k-icon-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: flex-start;
  vertical-align: middle;
  position: relative;
}
.k-icon-wrap::before {
  content: "​";
  width: 0;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-icon-wrapper-host {
  display: contents;
}

.k-icon-with-modifier {
  position: relative;
  margin: 0.25em;
}

.k-icon.k-icon-modifier {
  width: 1em;
  height: 1em;
  position: absolute;
  font-size: 0.5em;
  bottom: 0;
  right: 0;
  margin: 0 -0.5em -0.5em 0;
}

.k-i-none::before {
  content: "";
  display: none;
}

.k-icon-action {
  display: inline-flex;
  padding: var(--kendo-spacing-1, 0.25rem);
  line-height: 1;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.k-sprite {
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.k-image {
  display: inline-block;
}

.k-tooltip {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  margin: 0;
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  background-repeat: repeat-x;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height-sm, normal);
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  position: absolute;
  z-index: 12000;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-tooltip *,
.k-tooltip *::before,
.k-tooltip *::after {
  box-sizing: border-box;
}

.k-tooltip-icon {
  margin-inline-end: var(--kendo-spacing-1, 0.25rem);
  flex-shrink: 0;
}

.k-tooltip-title {
  margin-bottom: 0.25em;
  font-size: calc(var(--kendo-font-size, 0.875rem) * 1.25);
  line-height: var(--kendo-line-height-xs, normal);
}

.k-tooltip-content {
  align-self: stretch;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-tooltip-button {
  margin-inline-start: var(--kendo-spacing-1, 0.25rem);
  flex-shrink: 0;
}
.k-tooltip-button .k-icon {
  color: inherit;
  vertical-align: top;
}

.k-callout {
  width: calc(6px * 2);
  height: calc(6px * 2);
  border-width: 6px;
  border-style: solid;
  border-color: transparent;
  position: absolute;
  pointer-events: none;
}

.k-callout-n {
  margin-left: calc(6px * -1);
  border-bottom-color: currentColor;
  top: calc(6px * -1 * 2);
  left: 50%;
  pointer-events: none;
}

.k-callout-e {
  margin-top: calc(6px * -1);
  border-left-color: currentColor;
  top: 50%;
  right: calc(6px * -1 * 2);
  pointer-events: none;
}

.k-callout-s {
  margin-left: calc(6px * -1);
  border-top-color: currentColor;
  bottom: calc(6px * -1 * 2);
  left: 50%;
  pointer-events: none;
}

.k-callout-w {
  margin-top: calc(6px * -1);
  border-right-color: currentColor;
  top: 50%;
  left: calc(6px * -1 * 2);
  pointer-events: none;
}

.k-tooltip {
  border-color: var(--kendo-color-on-app-surface, #3d3d3d);
  color: var(--kendo-color-app-surface, #ffffff);
  background-color: var(--kendo-color-on-app-surface, #3d3d3d);
  box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}
.k-tooltip .k-callout {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-tooltip-inverse {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-tooltip-inverse .k-callout {
  color: var(--kendo-color-dark, #3d3d3d);
}

.k-tooltip-light {
  border-color: var(--kendo-color-light, #ebebeb);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #ebebeb);
}
.k-tooltip-light .k-callout {
  color: var(--kendo-color-light, #ebebeb);
}

.k-tooltip-dark {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-tooltip-dark .k-callout {
  color: var(--kendo-color-dark, #3d3d3d);
}

.k-tooltip-error {
  border-color: var(--kendo-color-error, #f31700);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #f31700);
}
.k-tooltip-error .k-callout {
  color: var(--kendo-color-error, #f31700);
}

.k-tooltip-warning {
  border-color: var(--kendo-color-warning, #ffc000);
  color: var(--kendo-color-on-warning, #3d3d3d);
  background-color: var(--kendo-color-warning, #ffc000);
}
.k-tooltip-warning .k-callout {
  color: var(--kendo-color-warning, #ffc000);
}

.k-tooltip-success {
  border-color: var(--kendo-color-success, #37b400);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #37b400);
}
.k-tooltip-success .k-callout {
  color: var(--kendo-color-success, #37b400);
}

.k-tooltip-info {
  border-color: var(--kendo-color-info, #0058e9);
  color: var(--kendo-color-on-info, #ffffff);
  background-color: var(--kendo-color-info, #0058e9);
}
.k-tooltip-info .k-callout {
  color: var(--kendo-color-info, #0058e9);
}

.k-tooltip-tertiary {
  border-color: var(--kendo-color-tertiary, #03a9f4);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-tooltip-tertiary .k-callout {
  color: var(--kendo-color-tertiary, #03a9f4);
}

.k-tooltip-secondary {
  border-color: var(--kendo-color-secondary, #666666);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #666666);
}
.k-tooltip-secondary .k-callout {
  color: var(--kendo-color-secondary, #666666);
}

.k-tooltip-primary {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-tooltip-primary .k-callout {
  color: var(--kendo-color-primary, #ff6358);
}

.k-checkbox {
  margin: 0;
  padding: 0;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  flex: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='var%28--kendo-color-on-primary, %23ffffff%29' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e");
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='var%28--kendo-color-primary, %23ff6358%29' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M4,8 h8'/%3e%3c/svg%3e");
}

.k-checkbox:disabled,
.k-checkbox.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-checkbox-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: flex-start;
  vertical-align: middle;
  position: relative;
}
.k-checkbox-wrap::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-checkbox-label {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: var(--kendo-spacing-1, 0.25rem);
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.k-checkbox-label .k-ripple {
  visibility: hidden !important;
}

.k-checkbox + .k-label,
.k-checkbox-wrap + .k-label,
.k-checkbox + .k-checkbox-label,
.k-checkbox-wrap + .k-checkbox-label {
  display: inline;
  margin-inline-start: var(--kendo-spacing-1, 0.25rem);
}

.k-checkbox-label:empty {
  display: none !important;
}

.k-checkbox-label.k-no-text {
  min-width: 1px;
}

.k-checkbox-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  list-style: none;
}

.k-checkbox-item,
.k-checkbox-list-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-0, 0px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
}
.k-checkbox-item .k-checkbox-label,
.k-checkbox-list-item .k-checkbox-label {
  margin: 0;
}

.k-checkbox-list-horizontal,
.k-checkbox-list.k-list-horizontal {
  display: flex;
  flex-flow: row wrap;
  gap: var(--kendo-spacing-4, 1rem);
}

.k-ripple-container .k-checkbox::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  z-index: -1;
  transition: opacity 100ms linear, transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
}
.k-ripple-container .k-checkbox:focus,
.k-ripple-container .k-checkbox.k-focus {
  box-shadow: none !important;
}
.k-ripple-container .k-checkbox:disabled::after,
.k-ripple-container .k-checkbox.k-disabled::after {
  display: none;
}

.k-checkbox-sm {
  width: var(--kendo-spacing-3, 0.75rem);
  height: var(--kendo-spacing-3, 0.75rem);
}
.k-checkbox-sm::before {
  font-size: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-ripple-container .k-checkbox-sm::after {
  width: 300%;
  height: 300%;
}

.k-checkbox-md {
  width: var(--kendo-spacing-4, 1rem);
  height: var(--kendo-spacing-4, 1rem);
}
.k-checkbox-md::before {
  font-size: var(--kendo-spacing-3\.5, 0.875rem);
}

.k-ripple-container .k-checkbox-md::after {
  width: 300%;
  height: 300%;
}

.k-checkbox-lg {
  width: var(--kendo-spacing-5, 1.25rem);
  height: var(--kendo-spacing-5, 1.25rem);
}
.k-checkbox-lg::before {
  font-size: var(--kendo-spacing-4\.5, 1.125rem);
}

.k-ripple-container .k-checkbox-lg::after {
  width: 300%;
  height: 300%;
}

.k-checkbox {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-checkbox:focus,
.k-checkbox.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-primary, #ff6358);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-primary, #ff6358) 30%, transparent);
}

.k-checkbox.k-invalid {
  border-color: var(--kendo-color-error, #f31700);
}

.k-checkbox.k-invalid + .k-checkbox-label {
  color: var(--kendo-color-error, #f31700);
}

.k-checkbox-wrap .k-ripple-blob {
  color: var(--kendo-color-primary, #ff6358);
  opacity: 0.25;
}

.k-ripple-container .k-checkbox::after {
  background: var(--kendo-color-primary, #ff6358);
  opacity: 0.25;
}

.k-list-container {
  display: flex;
  flex-flow: column nowrap;
}
.k-list-container > .k-list {
  flex: 1;
  height: 100%;
}

.k-list {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--kendo-font-family, inherit);
  display: flex;
  flex-flow: column nowrap;
  outline: none;
  position: relative;
  overflow: hidden;
}
.k-list *,
.k-list *::before,
.k-list *::after {
  box-sizing: border-box;
}

.k-popup > .k-list {
  height: 100%;
  border-width: 0;
}

.k-list-group-sticky-header {
  border-width: 0;
  border-width: 0 0 1px;
  border-style: solid;
  font-weight: var(--kendo-font-weight-bold, normal);
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.k-list-content {
  border-color: inherit;
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}

.k-list-ul {
  margin: 0;
  padding: 0;
  border-width: 0;
  border-color: inherit;
  height: auto;
  list-style: none;
}

.k-list-item, .k-list-optionlabel {
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
  position: relative;
  transition-property: color, background-color, outline-color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
.k-list-item.k-first::before, .k-first.k-list-optionlabel::before {
  content: "";
  border-width: 1px 0 0;
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.k-list-item-text::before,
.k-list-header-text::before,
.k-list-optionlabel::before {
  content: "​";
  width: 0px;
  overflow: hidden;
}

.k-list-optionlabel {
  column-gap: 0;
}

.k-list-group-item {
  border-width: 0;
  border-width: 1px 0 0;
  border-style: solid;
  font-weight: var(--kendo-font-weight-bold, normal);
  cursor: default;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
  position: relative;
}

.k-list-item-group-label {
  padding-block: 0;
  padding-inline: 0.5em;
  font-size: 0.75em;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
}

.k-virtual-content,
.k-virtual-list .k-list-content {
  overflow-y: scroll;
}

.k-virtual-list .k-list-item, .k-virtual-list .k-list-optionlabel,
.k-virtual-list .k-list-group-item,
.k-virtual-content .k-list-item,
.k-virtual-content .k-list-optionlabel,
.k-virtual-content .k-list-group-item {
  position: absolute;
  width: 100%;
}

.k-virtual-list .k-list-item-text,
.k-virtual-list .k-list-header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-virtual-list .k-list-optionlabel {
  position: relative;
}

.k-list-filter {
  display: block;
  position: relative;
  padding: var(--kendo-spacing-2, 0.5rem);
  box-sizing: border-box;
  flex: none;
}

.k-list-sm {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-list-sm .k-list-group-sticky-header {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-sm .k-list-item, .k-list-sm .k-list-optionlabel {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-sm .k-list-group-item {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-list-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-list-md .k-list-group-sticky-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-md .k-list-item, .k-list-md .k-list-optionlabel {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-md .k-list-group-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-list-lg {
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}
.k-list-lg .k-list-group-sticky-header {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-lg .k-list-item, .k-list-lg .k-list-optionlabel {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-list-lg .k-list-group-item {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-no-data, .k-nodata {
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  text-align: center;
  white-space: normal;
}

.k-list {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-list-group-sticky-header {
  border-color: inherit;
  box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-list-item:hover, .k-list-optionlabel:hover, .k-list-item.k-hover, .k-hover.k-list-optionlabel {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-list-item:focus, .k-list-optionlabel:focus, .k-list-item.k-focus, .k-focus.k-list-optionlabel {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-list-item.k-selected:hover, .k-selected.k-list-optionlabel:hover, .k-list-item.k-selected.k-hover, .k-selected.k-hover.k-list-optionlabel {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary-hover, #ea5a51);
}

.k-list-optionlabel,
.k-list-optionlabel.k-hover,
.k-list-optionlabel:hover {
  color: var(--kendo-color-subtle, #666666);
}

.k-list-group-item {
  border-color: inherit;
}

.k-list-item-group-label {
  color: var(--kendo-color-surface-alt, #ffffff);
  background-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-no-data, .k-nodata {
  color: var(--kendo-color-subtle, #666666);
}

.k-panelbar {
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  list-style: none;
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-panelbar *,
.k-panelbar *::before,
.k-panelbar *::after {
  box-sizing: border-box;
}
.k-panelbar > .k-panelbar-header {
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  display: block;
}
.k-panelbar > .k-panelbar-header > .k-link {
  padding-block: var(--kendo-spacing-3, 0.75rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  color: inherit;
  background: none;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  flex-flow: row nowrap;
  gap: var(--kendo-spacing-1, 0.25rem);
  align-items: center;
  align-content: center;
  position: relative;
  user-select: none;
  cursor: default;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.k-panelbar > .k-panelbar-header + .k-panelbar-header {
  border-top-width: 1px;
}
.k-panelbar .k-panelbar-group {
  margin: 0;
  padding: 0;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background-color: transparent;
  list-style: none;
}
.k-panelbar .k-panelbar-group > .k-panelbar-item {
  display: block;
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-flow: row nowrap;
  gap: var(--kendo-spacing-1, 0.25rem);
  align-items: center;
  align-content: center;
  position: relative;
  user-select: none;
  cursor: default;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
  padding-left: calc(var(--kendo-spacing-4, 1rem) * 1);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link {
  padding-left: calc(var(--kendo-spacing-4, 1rem) * 2);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link {
  padding-left: calc(var(--kendo-spacing-4, 1rem) * 3);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link {
  padding-left: calc(var(--kendo-spacing-4, 1rem) * 4);
}
.k-panelbar .k-panelbar-item {
  outline-style: none;
}
.k-panelbar .k-panelbar-content {
  display: flow-root;
}
.k-panelbar .k-panelbar-expand,
.k-panelbar .k-panelbar-collapse,
.k-panelbar .k-panelbar-toggle {
  margin-inline-start: auto;
}
.k-panelbar .k-panelbar-group .k-panelbar-expand,
.k-panelbar .k-panelbar-group .k-panelbar-collapse,
.k-panelbar .k-panelbar-group .k-panelbar-toggle {
  margin-inline-end: calc(var(--kendo-spacing-4, 1rem) - var(--kendo-spacing-4, 1rem));
}
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link, .k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link, .k-panelbar[dir=rtl] .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
  padding-left: var(--kendo-spacing-4, 1rem);
  padding-right: calc(var(--kendo-spacing-4, 1rem) * 1);
}
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link, .k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link, .k-panelbar[dir=rtl] .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link {
  padding-left: var(--kendo-spacing-4, 1rem);
  padding-right: calc(var(--kendo-spacing-4, 1rem) * 2);
}
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link, .k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link, .k-panelbar[dir=rtl] .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link {
  padding-left: var(--kendo-spacing-4, 1rem);
  padding-right: calc(var(--kendo-spacing-4, 1rem) * 3);
}
.k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link, .k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link, .k-panelbar[dir=rtl] .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link {
  padding-left: var(--kendo-spacing-4, 1rem);
  padding-right: calc(var(--kendo-spacing-4, 1rem) * 4);
}

.k-panelbar {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-panelbar > .k-panelbar-header > .k-link {
  color: var(--kendo-color-primary, #ff6358);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-panelbar > .k-panelbar-header > .k-link .k-icon,
.k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-panelbar > .k-panelbar-header > .k-link:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-hover {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-panelbar > .k-panelbar-header > .k-link:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-panelbar > .k-panelbar-header > .k-link.k-selected {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-panelbar > .k-panelbar-header > .k-link.k-selected .k-icon,
.k-panelbar > .k-panelbar-header > .k-link.k-selected .k-panelbar-item-icon {
  color: inherit;
}
.k-panelbar > .k-panelbar-header > .k-link.k-selected:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected.k-hover {
  background-color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:hover,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-hover {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:focus,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected:hover,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-selected.k-hover {
  background-color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-listgroup {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.k-listgroup > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.k-listgroup .k-listgroup-item + .k-listgroup-item {
  border-top-width: 1px;
}

.k-listgroup-flush {
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
}

.k-listgroup-item {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  position: relative;
}
.k-listgroup-item > .k-link {
  margin-block: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  margin-inline: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  color: inherit;
  text-decoration: none;
  outline: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  position: relative;
}
.k-listgroup-item > .k-link > .k-select {
  padding: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  transform: translateY(-50%);
}

.k-listgroup-item-segmented {
  align-items: stretch;
}
.k-listgroup-item-segmented > .k-link {
  margin-right: 0;
}
.k-listgroup-item-segmented > .k-select {
  margin: calc(var(--kendo-spacing-2, 0.5rem) * -1) calc(var(--kendo-spacing-2, 0.5rem) * -1) calc(var(--kendo-spacing-2, 0.5rem) * -1) 0;
  padding: var(--kendo-spacing-2, 0.5rem);
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: inherit;
  cursor: pointer;
}

.k-listgroup-form-row {
  margin-block: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  margin-inline: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  position: relative;
}
.k-listgroup-form-row .k-listgroup-form-field-label {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
  position: relative;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper select,
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=text],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=password],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=date],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=time],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=datetime-local],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=number],
.k-listgroup-form-row .k-listgroup-form-field-wrapper textarea {
  width: 100%;
  box-sizing: border-box;
  flex: 1 1 100%;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper textarea {
  min-height: 4em;
  resize: vertical;
}

.k-listgroup-item.k-listgroup-form-row {
  margin: 0;
}

[dir=rtl] .k-listgroup-item > .k-link > .k-select {
  right: auto;
  left: 0;
}
[dir=rtl] .k-listgroup-item-segmented > .k-link {
  margin-right: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  margin-left: 0;
}
[dir=rtl] .k-listgroup-item-segmented > .k-select {
  margin-right: 0;
  margin-left: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  border-right-width: 1px;
  border-left-width: 0;
}

.k-noflexbox .k-listgroup,
.k-noflexbox .k-listgroup > ul .k-listgroup-item,
.k-noflexbox .k-listgroup-item > .k-link {
  display: block;
}

.k-listgroup {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-radio {
  border-radius: 50%;
  margin: 0;
  padding: 0;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  flex: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
}

.k-radio:checked,
.k-radio.k-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3ccircle cx='50%25' cy='50%25' r='4' fill='var%28--kendo-color-on-primary, %23ffffff%29'/%3e%3c/svg%3e");
}

.k-radio:disabled,
.k-radio.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-radio-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: start;
  vertical-align: middle;
  position: relative;
}
.k-radio-wrap::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-radio-label {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: var(--kendo-spacing-1, 0.25rem);
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.k-radio-label .k-ripple {
  visibility: hidden !important;
}

.k-radio + .k-label,
.k-radio-wrap + .k-label,
.k-radio + .k-radio-label,
.k-radio-wrap + .k-radio-label {
  display: inline;
  margin-inline-start: var(--kendo-spacing-1, 0.25rem);
}

.k-radio-label:empty {
  display: none !important;
}

.k-radio-label.k-no-text {
  min-width: 1px;
}

.k-radio-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  list-style: none;
}

.k-radio-item,
.k-radio-list-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-0, 0px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
}
.k-radio-item .k-radio-label,
.k-radio-list-item .k-radio-label {
  margin: 0;
}

.k-radio-list-horizontal,
.k-radio-list.k-list-horizontal {
  display: flex;
  flex-flow: row wrap;
  gap: var(--kendo-spacing-4, 1rem);
}

.k-ripple-container .k-radio::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  z-index: -1;
  transition: opacity 100ms linear, transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
}
.k-ripple-container .k-radio:focus,
.k-ripple-container .k-radio.k-focus {
  box-shadow: none !important;
}
.k-ripple-container .k-radio:disabled::after,
.k-ripple-container .k-radio.k-disabled::after {
  display: none;
}
.k-ripple-container .k-radio:disabled::after,
.k-ripple-container .k-radio.k-disabled::after {
  display: none;
}

.k-radio-sm {
  width: var(--kendo-spacing-3, 0.75rem);
  height: var(--kendo-spacing-3, 0.75rem);
}
.k-radio-sm::before {
  font-size: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-ripple-container .k-radio-sm::after {
  width: 300%;
  height: 300%;
}

.k-radio-md {
  width: var(--kendo-spacing-4, 1rem);
  height: var(--kendo-spacing-4, 1rem);
}
.k-radio-md::before {
  font-size: var(--kendo-spacing-3\.5, 0.875rem);
}

.k-ripple-container .k-radio-md::after {
  width: 300%;
  height: 300%;
}

.k-radio-lg {
  width: var(--kendo-spacing-5, 1.25rem);
  height: var(--kendo-spacing-5, 1.25rem);
}
.k-radio-lg::before {
  font-size: var(--kendo-spacing-4\.5, 1.125rem);
}

.k-ripple-container .k-radio-lg::after {
  width: 300%;
  height: 300%;
}

.k-radio {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-radio:focus,
.k-radio.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-radio.k-invalid,
.k-radio.ng-invalid.ng-touched,
.k-radio.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #f31700);
}

.k-radio.k-invalid + .k-radio-label,
.k-radio.ng-invalid.ng-touched + .k-radio-label,
.k-radio.ng-invalid.ng-dirty + .k-radio-label {
  color: var(--kendo-color-error, #f31700);
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-radio:checked:focus,
.k-radio.k-checked.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-primary, #ff6358) 30%, transparent);
}

.k-ripple-container .k-radio::after {
  background: var(--kendo-color-primary, #ff6358);
  opacity: 0.25;
}

.k-switch {
  box-sizing: border-box;
  outline: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-switch *,
.k-switch *::before,
.k-switch *::after {
  box-sizing: border-box;
}
.k-switch [type=checkbox] {
  display: none;
}
.k-switch[aria-readonly=true] {
  pointer-events: none;
}

.k-switch.k-readonly {
  pointer-events: none;
}

.k-switch-track {
  border-width: 1px;
  border-style: solid;
  outline: 0;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  position: relative;
  transition: background-color 200ms ease-in-out;
}

.k-switch-thumb-wrap {
  width: 0;
  height: 0;
  overflow: visible;
  position: absolute;
  transition: left 200ms ease-in-out;
  top: 50%;
}

.k-switch-thumb {
  border-width: 1px;
  border-style: solid;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
}

.k-switch-label-on,
.k-switch-label-off {
  text-transform: uppercase;
  display: inline;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
}

.k-switch-sm {
  width: 52px;
  height: 26px;
  font-size: 10px;
}
.k-switch-sm .k-switch-track {
  width: 52px;
  height: 26px;
}
.k-switch-sm .k-switch-label-on {
  left: 4px;
}
.k-switch-sm .k-switch-label-off {
  right: 4px;
}
.k-switch-sm .k-switch-thumb {
  width: 26px;
  height: 26px;
}
.k-switch-sm.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 13px - 0px);
}
.k-switch-sm.k-switch-off .k-switch-thumb-wrap {
  left: calc(13px + 0px);
}

.k-switch-sm[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-sm .k-switch-label-on,
.k-rtl .k-switch-sm .k-switch-label-on {
  left: auto;
  right: 4px;
}
.k-switch-sm[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-sm .k-switch-label-off,
.k-rtl .k-switch-sm .k-switch-label-off {
  right: auto;
  left: 4px;
}
.k-switch-sm[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-sm.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-on .k-switch-thumb-wrap {
  left: calc(13px + 0px);
}
.k-switch-sm[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-sm.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 13px - 0px);
}

.k-switch-md {
  width: 60px;
  height: 30px;
  font-size: 10px;
}
.k-switch-md .k-switch-track {
  width: 60px;
  height: 30px;
}
.k-switch-md .k-switch-label-on {
  left: 6px;
}
.k-switch-md .k-switch-label-off {
  right: 6px;
}
.k-switch-md .k-switch-thumb {
  width: 30px;
  height: 30px;
}
.k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 15px - 0px);
}
.k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: calc(15px + 0px);
}

.k-switch-md[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-md .k-switch-label-on,
.k-rtl .k-switch-md .k-switch-label-on {
  left: auto;
  right: 6px;
}
.k-switch-md[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-md .k-switch-label-off,
.k-rtl .k-switch-md .k-switch-label-off {
  right: auto;
  left: 6px;
}
.k-switch-md[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-md.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(15px + 0px);
}
.k-switch-md[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-md.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 15px - 0px);
}

.k-switch-lg {
  width: 68px;
  height: 34px;
  font-size: 10px;
}
.k-switch-lg .k-switch-track {
  width: 68px;
  height: 34px;
}
.k-switch-lg .k-switch-label-on {
  left: 8px;
}
.k-switch-lg .k-switch-label-off {
  right: 8px;
}
.k-switch-lg .k-switch-thumb {
  width: 34px;
  height: 34px;
}
.k-switch-lg.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 17px - 0px);
}
.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
  left: calc(17px + 0px);
}

.k-switch-lg[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-lg .k-switch-label-on,
.k-rtl .k-switch-lg .k-switch-label-on {
  left: auto;
  right: 8px;
}
.k-switch-lg[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-lg .k-switch-label-off,
.k-rtl .k-switch-lg .k-switch-label-off {
  right: auto;
  left: 8px;
}
.k-switch-lg[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-lg.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-on .k-switch-thumb-wrap {
  left: calc(17px + 0px);
}
.k-switch-lg[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-lg.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 17px - 0px);
}

.k-switch[dir=rtl] .k-switch-thumb,
[dir=rtl] .k-switch .k-switch-thumb,
.k-switch.k-rtl .k-switch-thumb,
.k-rtl .k-switch .k-switch-thumb {
  transform: translate(50%, -50%);
}

.k-switch-off .k-switch-track {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-switch-off .k-switch-thumb {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-switch-off:focus .k-switch-track, .k-switch-off.k-focus .k-switch-track {
  outline: 2px solid color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-switch-off .k-switch-label-on {
  color: transparent;
}

.k-switch-on .k-switch-track {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-switch-on .k-switch-thumb {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-switch-on:focus .k-switch-track, .k-switch-on.k-focus .k-switch-track {
  outline: 2px solid color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent);
}
.k-switch-on .k-switch-label-off {
  color: transparent;
}

.k-badge {
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  box-sizing: border-box;
  color: inherit;
  background-color: transparent;
  font: inherit;
  text-align: center;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-badge *,
.k-badge *::before,
.k-badge *::after {
  box-sizing: border-box;
}

.k-badge > * {
  color: inherit;
}

a.k-badge,
.k-badge > a {
  text-decoration: none;
}

a.k-badge:hover,
.k-badge > a:hover {
  text-decoration: underline;
}

.k-badge-icon {
  max-width: 1em;
  max-height: 1em;
  font-size: inherit;
}

.k-badge-icon.k-svg-icon svg {
  fill: currentColor;
}

.k-badge-sm {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
  font-size: var(--kendo-font-size-xs, inherit);
  line-height: 1;
  min-width: calc(1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 1px * 2);
}
.k-badge-sm:empty {
  padding: var(--kendo-spacing-0\.5, 0.125rem);
  min-width: auto;
}

.k-badge-md {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  font-size: var(--kendo-font-size-xs, inherit);
  line-height: 1;
  min-width: calc(1em + var(--kendo-spacing-1, 0.25rem) * 2 + 1px * 2);
}
.k-badge-md:empty {
  padding: var(--kendo-spacing-1, 0.25rem);
  min-width: auto;
}

.k-badge-lg {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-1\.5, 0.375rem);
  font-size: var(--kendo-font-size-xs, inherit);
  line-height: 1;
  min-width: calc(1em + var(--kendo-spacing-1\.5, 0.375rem) * 2 + 1px * 2);
}
.k-badge-lg:empty {
  padding: var(--kendo-spacing-1\.5, 0.375rem);
  min-width: auto;
}

kendo-badge-container {
  display: inline-block;
}

.k-badge-container {
  position: relative;
  overflow: visible;
}

.k-badge-inside {
  position: absolute;
  z-index: 9999;
}
.k-badge-edge {
  position: absolute;
  z-index: 9999;
}
.k-badge-edge.k-top-start {
  transform: translate(-50%, -50%);
}
.k-badge-edge.k-top-end {
  transform: translate(50%, -50%);
}
.k-badge-edge.k-bottom-start {
  transform: translate(-50%, 50%);
}
.k-badge-edge.k-bottom-end {
  transform: translate(50%, 50%);
}

.k-rtl .k-badge-edge.k-top-start,
[dir=rtl].k-badge-edge.k-top-start,
[dir=rtl] .k-badge-edge.k-top-start {
  transform: translate(50%, -50%);
}
.k-rtl .k-badge-edge.k-top-end,
[dir=rtl].k-badge-edge.k-top-end,
[dir=rtl] .k-badge-edge.k-top-end {
  transform: translate(-50%, -50%);
}
.k-rtl .k-badge-edge.k-bottom-start,
[dir=rtl].k-badge-edge.k-bottom-start,
[dir=rtl] .k-badge-edge.k-bottom-start {
  transform: translate(50%, 50%);
}
.k-rtl .k-badge-edge.k-bottom-end,
[dir=rtl].k-badge-edge.k-bottom-end,
[dir=rtl] .k-badge-edge.k-bottom-end {
  transform: translate(-50%, 50%);
}

.k-badge-outside {
  position: absolute;
  z-index: 9999;
}
.k-badge-outside.k-top-start {
  transform: translate(-100%, -100%);
}
.k-badge-outside.k-top-end {
  transform: translate(100%, -100%);
}
.k-badge-outside.k-bottom-start {
  transform: translate(-100%, 100%);
}
.k-badge-outside.k-bottom-end {
  transform: translate(100%, 100%);
}

.k-rtl .k-badge-outside.k-top-start,
[dir=rtl].k-badge-outside.k-top-start,
[dir=rtl] .k-badge-outside.k-top-start {
  transform: translate(100%, -100%);
}
.k-rtl .k-badge-outside.k-top-end,
[dir=rtl].k-badge-outside.k-top-end,
[dir=rtl] .k-badge-outside.k-top-end {
  transform: translate(-100%, -100%);
}
.k-rtl .k-badge-outside.k-bottom-start,
[dir=rtl].k-badge-outside.k-bottom-start,
[dir=rtl] .k-badge-outside.k-bottom-start {
  transform: translate(100%, 100%);
}
.k-rtl .k-badge-outside.k-bottom-end,
[dir=rtl].k-badge-outside.k-bottom-end,
[dir=rtl] .k-badge-outside.k-bottom-end {
  transform: translate(-100%, 100%);
}

.k-badge-border-cutout {
  box-shadow: 0 0 0 2px var(--kendo-color-surface-alt, #ffffff);
}

.k-badge-solid-primary {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-badge-solid-secondary {
  border-color: var(--kendo-color-secondary, #666666);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #666666);
}

.k-badge-solid-tertiary {
  border-color: var(--kendo-color-tertiary, #03a9f4);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #03a9f4);
}

.k-badge-solid-info {
  border-color: var(--kendo-color-info, #0058e9);
  color: var(--kendo-color-on-info, #ffffff);
  background-color: var(--kendo-color-info, #0058e9);
}

.k-badge-solid-success {
  border-color: var(--kendo-color-success, #37b400);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #37b400);
}

.k-badge-solid-warning {
  border-color: var(--kendo-color-warning, #ffc000);
  color: var(--kendo-color-on-warning, #3d3d3d);
  background-color: var(--kendo-color-warning, #ffc000);
}

.k-badge-solid-error {
  border-color: var(--kendo-color-error, #f31700);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #f31700);
}

.k-badge-solid-dark {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}

.k-badge-solid-light {
  border-color: var(--kendo-color-light, #ebebeb);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #ebebeb);
}

.k-badge-solid-inverse {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}

.k-badge-outline {
  border-color: currentColor;
  background-color: transparent;
}

.k-badge-outline-primary {
  color: var(--kendo-color-primary, #ff6358);
}

.k-badge-outline-secondary {
  color: var(--kendo-color-secondary, #666666);
}

.k-badge-outline-tertiary {
  color: var(--kendo-color-tertiary, #03a9f4);
}

.k-badge-outline-info {
  color: var(--kendo-color-info, #0058e9);
}

.k-badge-outline-success {
  color: var(--kendo-color-success, #37b400);
}

.k-badge-outline-warning {
  color: var(--kendo-color-warning, #ffc000);
}

.k-badge-outline-error {
  color: var(--kendo-color-error, #f31700);
}

.k-badge-outline-dark {
  color: var(--kendo-color-dark, #3d3d3d);
}

.k-badge-outline-light {
  color: var(--kendo-color-light, #ebebeb);
}

.k-badge-outline-inverse {
  color: var(--kendo-color-dark, #3d3d3d);
}

.k-button {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  color: inherit;
  background: none;
  font-family: var(--kendo-font-family, inherit);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  transition: color 0.2s ease-in-out;
}
.k-button *,
.k-button *::before,
.k-button *::after {
  box-sizing: border-box;
}
.k-button:disabled, .k-button.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
.k-button::-moz-focus-inner {
  padding: 0;
  border: 0;
  outline: 0;
}
.k-button:hover, .k-button:focus {
  text-decoration: none;
  outline: 0;
}

.k-icon-button {
  gap: 0;
}
.k-icon-button .k-icon {
  display: inline-flex;
}

.k-button-icon {
  color: inherit;
  align-self: center;
  position: relative;
}

.k-button-sm {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-button-sm.k-icon-button {
  padding: var(--kendo-spacing-0\.5, 0.125rem);
}
.k-button-sm.k-icon-button .k-button-icon {
  min-width: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
  min-height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-sm.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-sm.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-md {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-button-md.k-icon-button {
  padding: var(--kendo-spacing-1, 0.25rem);
}
.k-button-md.k-icon-button .k-button-icon {
  min-width: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
  min-height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal));
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-md.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-md.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-lg {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}

.k-button-lg.k-icon-button {
  padding: var(--kendo-spacing-2, 0.5rem);
}
.k-button-lg.k-icon-button .k-button-icon {
  min-width: calc(var(--kendo-font-size-lg, inherit) * var(--kendo-line-height-lg, normal));
  min-height: calc(var(--kendo-font-size-lg, inherit) * var(--kendo-line-height-lg, normal));
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-lg.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-lg.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-square {
  aspect-ratio: 1;
}

.k-button-group {
  margin: 0;
  padding: 0;
  border-width: 0;
  box-sizing: border-box;
  list-style: none;
  outline: 0;
  display: inline-flex;
  flex-flow: row nowrap;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-button-group > .k-button + .k-button {
  margin-inline-start: -1px;
}
.k-button-group > .k-button:hover,
.k-button-group > .k-button.k-hover,
.k-button-group > .k-button:focus,
.k-button-group > .k-button.k-focus,
.k-button-group > .k-button:active,
.k-button-group > .k-button.k-active,
.k-button-group > .k-button.k-selected {
  z-index: 2;
}
.k-button-group .k-button:not(:first-child):not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.k-button-group > .k-button:first-child:not(:only-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
.k-button-group > .k-button:last-child:not(:only-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.k-button-group:disabled, .k-button-group[disabled], .k-button-group.k-disabled {
  opacity: 1;
  filter: none;
}

.k-button-group-stretched {
  width: 100%;
}
.k-button-group-stretched > * {
  flex: 1 0 0%;
  overflow: hidden;
}

.k-button-flat {
  border-color: transparent !important;
  color: inherit;
  background: none !important;
  box-shadow: none !important;
}
.k-button-flat::before {
  display: block !important;
}
.k-button-flat::after {
  box-shadow: inset 0 0 0 2px currentColor;
  display: block !important;
}
.k-button-flat:focus::after, .k-button-flat.k-focus::after {
  opacity: 0.12;
}

.k-button-outline {
  border-color: currentColor;
  color: inherit;
  background: none;
}

.k-button-link {
  border-color: transparent;
  color: inherit;
  text-decoration: none;
  background: none;
}
.k-button-link:hover, .k-button-link.k-hover {
  text-decoration: underline;
}

.k-button-clear {
  border-color: transparent !important;
  color: inherit;
  background: none !important;
  box-shadow: none !important;
}

.k-button-overlay, .k-button::before {
  border-radius: inherit;
  content: "";
  background: currentColor;
  opacity: 0;
  display: none;
  pointer-events: none;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  z-index: 0;
  transition: opacity 0.2s ease-in-out;
}

.k-button:hover::before, .k-button.k-hover::before {
  opacity: 0.04;
}
.k-button.k-no-focus:not(:hover)::before, .k-button.k-no-focus:not(.k-hover)::before {
  opacity: 0;
}
.k-button:active::before, .k-button.k-active::before {
  opacity: 0.16;
}
.k-button.k-selected::before {
  opacity: 0.2;
}

.k-button::after {
  border-radius: inherit;
  content: "";
  opacity: 0;
  display: none;
  pointer-events: none;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  z-index: 0;
  transition: opacity 0.2s ease-in-out;
}

.k-button-solid-base {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-base, #3d3d3d);
  background-color: var(--kendo-color-base, #f5f5f5);
}
.k-button-solid-base:hover, .k-button-solid-base.k-hover {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-button-solid-base:focus, .k-button-solid-base.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-button-solid-base:active, .k-button-solid-base.k-active {
  background-color: var(--kendo-color-base-active, #d6d6d6);
}
.k-button-solid-base.k-selected {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-button-solid-primary {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-button-solid-primary:hover, .k-button-solid-primary.k-hover {
  border-color: var(--kendo-color-primary-hover, #ea5a51);
  background-color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-button-solid-primary:focus, .k-button-solid-primary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-primary, #ff6358) 30%, transparent);
}
.k-button-solid-primary:active, .k-button-solid-primary.k-active {
  border-color: var(--kendo-color-primary-active, #d45349);
  background-color: var(--kendo-color-primary-active, #d45349);
}
.k-button-solid-primary.k-selected {
  border-color: var(--kendo-color-primary-active, #d45349);
  background-color: var(--kendo-color-primary-active, #d45349);
}
.k-button-solid-secondary {
  border-color: var(--kendo-color-secondary, #666666);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #666666);
}
.k-button-solid-secondary:hover, .k-button-solid-secondary.k-hover {
  border-color: var(--kendo-color-secondary-hover, #525252);
  background-color: var(--kendo-color-secondary-hover, #525252);
}
.k-button-solid-secondary:focus, .k-button-solid-secondary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-secondary, #666666) 30%, transparent);
}
.k-button-solid-secondary:active, .k-button-solid-secondary.k-active {
  border-color: var(--kendo-color-secondary-active, #3d3d3d);
  background-color: var(--kendo-color-secondary-active, #3d3d3d);
}
.k-button-solid-secondary.k-selected {
  border-color: var(--kendo-color-secondary-active, #3d3d3d);
  background-color: var(--kendo-color-secondary-active, #3d3d3d);
}
.k-button-solid-tertiary {
  border-color: var(--kendo-color-tertiary, #03a9f4);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-solid-tertiary:hover, .k-button-solid-tertiary.k-hover {
  border-color: var(--kendo-color-tertiary-hover, #039ae0);
  background-color: var(--kendo-color-tertiary-hover, #039ae0);
}
.k-button-solid-tertiary:focus, .k-button-solid-tertiary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-tertiary, #03a9f4) 30%, transparent);
}
.k-button-solid-tertiary:active, .k-button-solid-tertiary.k-active {
  border-color: var(--kendo-color-tertiary-active, #028ccb);
  background-color: var(--kendo-color-tertiary-active, #028ccb);
}
.k-button-solid-tertiary.k-selected {
  border-color: var(--kendo-color-tertiary-active, #028ccb);
  background-color: var(--kendo-color-tertiary-active, #028ccb);
}
.k-button-solid-info {
  border-color: var(--kendo-color-info, #0058e9);
  color: var(--kendo-color-on-info, #ffffff);
  background-color: var(--kendo-color-info, #0058e9);
}
.k-button-solid-info:hover, .k-button-solid-info.k-hover {
  border-color: var(--kendo-color-info-hover, #0052d6);
  background-color: var(--kendo-color-info-hover, #0052d6);
}
.k-button-solid-info:focus, .k-button-solid-info.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 30%, transparent);
}
.k-button-solid-info:active, .k-button-solid-info.k-active {
  border-color: var(--kendo-color-info-active, #004ac2);
  background-color: var(--kendo-color-info-active, #004ac2);
}
.k-button-solid-info.k-selected {
  border-color: var(--kendo-color-info-active, #004ac2);
  background-color: var(--kendo-color-info-active, #004ac2);
}
.k-button-solid-success {
  border-color: var(--kendo-color-success, #37b400);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #37b400);
}
.k-button-solid-success:hover, .k-button-solid-success.k-hover {
  border-color: var(--kendo-color-success-hover, #32a500);
  background-color: var(--kendo-color-success-hover, #32a500);
}
.k-button-solid-success:focus, .k-button-solid-success.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 30%, transparent);
}
.k-button-solid-success:active, .k-button-solid-success.k-active {
  border-color: var(--kendo-color-success-active, #2d9600);
  background-color: var(--kendo-color-success-active, #2d9600);
}
.k-button-solid-success.k-selected {
  border-color: var(--kendo-color-success-active, #2d9600);
  background-color: var(--kendo-color-success-active, #2d9600);
}
.k-button-solid-warning {
  border-color: var(--kendo-color-warning, #ffc000);
  color: var(--kendo-color-on-warning, #3d3d3d);
  background-color: var(--kendo-color-warning, #ffc000);
}
.k-button-solid-warning:hover, .k-button-solid-warning.k-hover {
  border-color: var(--kendo-color-warning-hover, #eaaf00);
  background-color: var(--kendo-color-warning-hover, #eaaf00);
}
.k-button-solid-warning:focus, .k-button-solid-warning.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 30%, transparent);
}
.k-button-solid-warning:active, .k-button-solid-warning.k-active {
  border-color: var(--kendo-color-warning-active, #d49f00);
  background-color: var(--kendo-color-warning-active, #d49f00);
}
.k-button-solid-warning.k-selected {
  border-color: var(--kendo-color-warning-active, #d49f00);
  background-color: var(--kendo-color-warning-active, #d49f00);
}
.k-button-solid-error {
  border-color: var(--kendo-color-error, #f31700);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #f31700);
}
.k-button-solid-error:hover, .k-button-solid-error.k-hover {
  border-color: var(--kendo-color-error-hover, #df1600);
  background-color: var(--kendo-color-error-hover, #df1600);
}
.k-button-solid-error:focus, .k-button-solid-error.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 30%, transparent);
}
.k-button-solid-error:active, .k-button-solid-error.k-active {
  border-color: var(--kendo-color-error-active, #ca1400);
  background-color: var(--kendo-color-error-active, #ca1400);
}
.k-button-solid-error.k-selected {
  border-color: var(--kendo-color-error-active, #ca1400);
  background-color: var(--kendo-color-error-active, #ca1400);
}
.k-button-solid-dark {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-solid-dark:hover, .k-button-solid-dark.k-hover {
  border-color: var(--kendo-color-dark-hover, #292929);
  background-color: var(--kendo-color-dark-hover, #292929);
}
.k-button-solid-dark:focus, .k-button-solid-dark.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-dark, #3d3d3d) 30%, transparent);
}
.k-button-solid-dark:active, .k-button-solid-dark.k-active {
  border-color: var(--kendo-color-dark-active, #1f1f1f);
  background-color: var(--kendo-color-dark-active, #1f1f1f);
}
.k-button-solid-dark.k-selected {
  border-color: var(--kendo-color-dark-active, #1f1f1f);
  background-color: var(--kendo-color-dark-active, #1f1f1f);
}
.k-button-solid-light {
  border-color: var(--kendo-color-light, #ebebeb);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #ebebeb);
}
.k-button-solid-light:hover, .k-button-solid-light.k-hover {
  border-color: var(--kendo-color-light-hover, #e0e0e0);
  background-color: var(--kendo-color-light-hover, #e0e0e0);
}
.k-button-solid-light:focus, .k-button-solid-light.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-light, #ebebeb) 30%, transparent);
}
.k-button-solid-light:active, .k-button-solid-light.k-active {
  border-color: var(--kendo-color-light-active, #d6d6d6);
  background-color: var(--kendo-color-light-active, #d6d6d6);
}
.k-button-solid-light.k-selected {
  border-color: var(--kendo-color-light-active, #d6d6d6);
  background-color: var(--kendo-color-light-active, #d6d6d6);
}
.k-button-solid-inverse {
  border-color: var(--kendo-color-inverse, #3d3d3d);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-inverse, #3d3d3d);
}
.k-button-solid-inverse:hover, .k-button-solid-inverse.k-hover {
  border-color: var(--kendo-color-inverse-hover, #292929);
  background-color: var(--kendo-color-inverse-hover, #292929);
}
.k-button-solid-inverse:focus, .k-button-solid-inverse.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-inverse, #3d3d3d) 30%, transparent);
}
.k-button-solid-inverse:active, .k-button-solid-inverse.k-active {
  border-color: var(--kendo-color-inverse-active, #1f1f1f);
  background-color: var(--kendo-color-inverse-active, #1f1f1f);
}
.k-button-solid-inverse.k-selected {
  border-color: var(--kendo-color-inverse-active, #1f1f1f);
  background-color: var(--kendo-color-inverse-active, #1f1f1f);
}
.k-button-outline-primary {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-primary-on-surface, #ff6358);
  background-color: transparent;
}
.k-button-outline-primary:hover, .k-button-outline-primary.k-hover {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-button-outline-primary:focus, .k-button-outline-primary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-primary, #ff6358) 30%, transparent);
}
.k-button-outline-primary:active, .k-button-outline-primary.k-active {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-button-outline-primary.k-selected {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-button-outline-secondary {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-secondary-on-surface, #292929);
  background-color: transparent;
}
.k-button-outline-secondary:hover, .k-button-outline-secondary.k-hover {
  border-color: var(--kendo-color-secondary, #666666);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #666666);
}
.k-button-outline-secondary:focus, .k-button-outline-secondary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-secondary, #666666) 30%, transparent);
}
.k-button-outline-secondary:active, .k-button-outline-secondary.k-active {
  border-color: var(--kendo-color-secondary, #666666);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #666666);
}
.k-button-outline-secondary.k-selected {
  border-color: var(--kendo-color-secondary, #666666);
  color: var(--kendo-color-on-secondary, #ffffff);
  background-color: var(--kendo-color-secondary, #666666);
}
.k-button-outline-tertiary {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-tertiary-on-surface, #028ccb);
  background-color: transparent;
}
.k-button-outline-tertiary:hover, .k-button-outline-tertiary.k-hover {
  border-color: var(--kendo-color-tertiary, #03a9f4);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-outline-tertiary:focus, .k-button-outline-tertiary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-tertiary, #03a9f4) 30%, transparent);
}
.k-button-outline-tertiary:active, .k-button-outline-tertiary.k-active {
  border-color: var(--kendo-color-tertiary, #03a9f4);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-outline-tertiary.k-selected {
  border-color: var(--kendo-color-tertiary, #03a9f4);
  color: var(--kendo-color-on-tertiary, #ffffff);
  background-color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-outline-info {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-info-on-surface, #004ac2);
  background-color: transparent;
}
.k-button-outline-info:hover, .k-button-outline-info.k-hover {
  border-color: var(--kendo-color-info, #0058e9);
  color: var(--kendo-color-on-info, #ffffff);
  background-color: var(--kendo-color-info, #0058e9);
}
.k-button-outline-info:focus, .k-button-outline-info.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 30%, transparent);
}
.k-button-outline-info:active, .k-button-outline-info.k-active {
  border-color: var(--kendo-color-info, #0058e9);
  color: var(--kendo-color-on-info, #ffffff);
  background-color: var(--kendo-color-info, #0058e9);
}
.k-button-outline-info.k-selected {
  border-color: var(--kendo-color-info, #0058e9);
  color: var(--kendo-color-on-info, #ffffff);
  background-color: var(--kendo-color-info, #0058e9);
}
.k-button-outline-success {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-success-on-surface, #2d9600);
  background-color: transparent;
}
.k-button-outline-success:hover, .k-button-outline-success.k-hover {
  border-color: var(--kendo-color-success, #37b400);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #37b400);
}
.k-button-outline-success:focus, .k-button-outline-success.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 30%, transparent);
}
.k-button-outline-success:active, .k-button-outline-success.k-active {
  border-color: var(--kendo-color-success, #37b400);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #37b400);
}
.k-button-outline-success.k-selected {
  border-color: var(--kendo-color-success, #37b400);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success, #37b400);
}
.k-button-outline-warning {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-warning-on-surface, #ffc000);
  background-color: transparent;
}
.k-button-outline-warning:hover, .k-button-outline-warning.k-hover {
  border-color: var(--kendo-color-warning, #ffc000);
  color: var(--kendo-color-on-warning, #3d3d3d);
  background-color: var(--kendo-color-warning, #ffc000);
}
.k-button-outline-warning:focus, .k-button-outline-warning.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 30%, transparent);
}
.k-button-outline-warning:active, .k-button-outline-warning.k-active {
  border-color: var(--kendo-color-warning, #ffc000);
  color: var(--kendo-color-on-warning, #3d3d3d);
  background-color: var(--kendo-color-warning, #ffc000);
}
.k-button-outline-warning.k-selected {
  border-color: var(--kendo-color-warning, #ffc000);
  color: var(--kendo-color-on-warning, #3d3d3d);
  background-color: var(--kendo-color-warning, #ffc000);
}
.k-button-outline-error {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-error-on-surface, #ca1400);
  background-color: transparent;
}
.k-button-outline-error:hover, .k-button-outline-error.k-hover {
  border-color: var(--kendo-color-error, #f31700);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #f31700);
}
.k-button-outline-error:focus, .k-button-outline-error.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 30%, transparent);
}
.k-button-outline-error:active, .k-button-outline-error.k-active {
  border-color: var(--kendo-color-error, #f31700);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #f31700);
}
.k-button-outline-error.k-selected {
  border-color: var(--kendo-color-error, #f31700);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error, #f31700);
}
.k-button-outline-dark {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-dark-on-surface, #141414);
  background-color: transparent;
}
.k-button-outline-dark:hover, .k-button-outline-dark.k-hover {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-dark:focus, .k-button-outline-dark.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-dark, #3d3d3d) 30%, transparent);
}
.k-button-outline-dark:active, .k-button-outline-dark.k-active {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-dark.k-selected {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-light {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-light-on-surface, #e0e0e0);
  background-color: transparent;
}
.k-button-outline-light:hover, .k-button-outline-light.k-hover {
  border-color: var(--kendo-color-light, #ebebeb);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #ebebeb);
}
.k-button-outline-light:focus, .k-button-outline-light.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-light, #ebebeb) 30%, transparent);
}
.k-button-outline-light:active, .k-button-outline-light.k-active {
  border-color: var(--kendo-color-light, #ebebeb);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #ebebeb);
}
.k-button-outline-light.k-selected {
  border-color: var(--kendo-color-light, #ebebeb);
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #ebebeb);
}
.k-button-outline-inverse {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-inverse-on-surface, #141414);
  background-color: transparent;
}
.k-button-outline-inverse:hover, .k-button-outline-inverse.k-hover {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-inverse:focus, .k-button-outline-inverse.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-inverse, #3d3d3d) 30%, transparent);
}
.k-button-outline-inverse:active, .k-button-outline-inverse.k-active {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-inverse.k-selected {
  border-color: var(--kendo-color-dark, #3d3d3d);
  color: var(--kendo-color-on-inverse, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-outline-base {
  box-shadow: none;
  border-color: currentColor;
  color: var(--kendo-color-base-on-surface, #3d3d3d);
  background-color: transparent;
}
.k-button-outline-base:hover, .k-button-outline-base.k-hover {
  border-color: var(--kendo-color-on-base, #3d3d3d);
  color: var(--kendo-color-base, #f5f5f5);
  background-color: var(--kendo-color-on-base, #3d3d3d);
}
.k-button-outline-base:focus, .k-button-outline-base.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 30%, transparent);
}
.k-button-outline-base:active, .k-button-outline-base.k-active {
  border-color: var(--kendo-color-on-base, #3d3d3d);
  color: var(--kendo-color-base, #f5f5f5);
  background-color: var(--kendo-color-on-base, #3d3d3d);
}
.k-button-outline-base.k-selected {
  border-color: var(--kendo-color-on-base, #3d3d3d);
  color: var(--kendo-color-base, #f5f5f5);
  background-color: var(--kendo-color-on-base, #3d3d3d);
}
.k-button-flat-primary {
  color: var(--kendo-color-primary-on-surface, #ff6358);
}
.k-button-flat-secondary {
  color: var(--kendo-color-secondary-on-surface, #292929);
}
.k-button-flat-tertiary {
  color: var(--kendo-color-tertiary-on-surface, #028ccb);
}
.k-button-flat-info {
  color: var(--kendo-color-info-on-surface, #004ac2);
}
.k-button-flat-success {
  color: var(--kendo-color-success-on-surface, #2d9600);
}
.k-button-flat-warning {
  color: var(--kendo-color-warning-on-surface, #ffc000);
}
.k-button-flat-error {
  color: var(--kendo-color-error-on-surface, #ca1400);
}
.k-button-flat-dark {
  color: var(--kendo-color-dark-on-surface, #141414);
}
.k-button-flat-light {
  color: var(--kendo-color-light-on-surface, #e0e0e0);
}
.k-button-flat-inverse {
  color: var(--kendo-color-inverse-on-surface, #141414);
}
.k-button-flat-base {
  color: inherit;
}
.k-button-link-primary {
  color: var(--kendo-color-primary, #ff6358);
}
.k-button-link-primary:hover, .k-button-link-primary.k-hover {
  color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-button-link-primary:focus, .k-button-link-primary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-primary, #ff6358) 30%, transparent);
}
.k-button-link-primary:active, .k-button-link-primary.k-active {
  color: var(--kendo-color-primary-active, #d45349);
}
.k-button-link-primary.k-selected {
  color: var(--kendo-color-primary-active, #d45349);
}
.k-button-link-secondary {
  color: var(--kendo-color-secondary, #666666);
}
.k-button-link-secondary:hover, .k-button-link-secondary.k-hover {
  color: var(--kendo-color-secondary-hover, #525252);
}
.k-button-link-secondary:focus, .k-button-link-secondary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-secondary, #666666) 30%, transparent);
}
.k-button-link-secondary:active, .k-button-link-secondary.k-active {
  color: var(--kendo-color-secondary-active, #3d3d3d);
}
.k-button-link-secondary.k-selected {
  color: var(--kendo-color-secondary-active, #3d3d3d);
}
.k-button-link-tertiary {
  color: var(--kendo-color-tertiary, #03a9f4);
}
.k-button-link-tertiary:hover, .k-button-link-tertiary.k-hover {
  color: var(--kendo-color-tertiary-hover, #039ae0);
}
.k-button-link-tertiary:focus, .k-button-link-tertiary.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-tertiary, #03a9f4) 30%, transparent);
}
.k-button-link-tertiary:active, .k-button-link-tertiary.k-active {
  color: var(--kendo-color-tertiary-active, #028ccb);
}
.k-button-link-tertiary.k-selected {
  color: var(--kendo-color-tertiary-active, #028ccb);
}
.k-button-link-info {
  color: var(--kendo-color-info, #0058e9);
}
.k-button-link-info:hover, .k-button-link-info.k-hover {
  color: var(--kendo-color-info-hover, #0052d6);
}
.k-button-link-info:focus, .k-button-link-info.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 30%, transparent);
}
.k-button-link-info:active, .k-button-link-info.k-active {
  color: var(--kendo-color-info-active, #004ac2);
}
.k-button-link-info.k-selected {
  color: var(--kendo-color-info-active, #004ac2);
}
.k-button-link-success {
  color: var(--kendo-color-success, #37b400);
}
.k-button-link-success:hover, .k-button-link-success.k-hover {
  color: var(--kendo-color-success-hover, #32a500);
}
.k-button-link-success:focus, .k-button-link-success.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 30%, transparent);
}
.k-button-link-success:active, .k-button-link-success.k-active {
  color: var(--kendo-color-success-active, #2d9600);
}
.k-button-link-success.k-selected {
  color: var(--kendo-color-success-active, #2d9600);
}
.k-button-link-warning {
  color: var(--kendo-color-warning, #ffc000);
}
.k-button-link-warning:hover, .k-button-link-warning.k-hover {
  color: var(--kendo-color-warning-hover, #eaaf00);
}
.k-button-link-warning:focus, .k-button-link-warning.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 30%, transparent);
}
.k-button-link-warning:active, .k-button-link-warning.k-active {
  color: var(--kendo-color-warning-active, #d49f00);
}
.k-button-link-warning.k-selected {
  color: var(--kendo-color-warning-active, #d49f00);
}
.k-button-link-error {
  color: var(--kendo-color-error, #f31700);
}
.k-button-link-error:hover, .k-button-link-error.k-hover {
  color: var(--kendo-color-error-hover, #df1600);
}
.k-button-link-error:focus, .k-button-link-error.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 30%, transparent);
}
.k-button-link-error:active, .k-button-link-error.k-active {
  color: var(--kendo-color-error-active, #ca1400);
}
.k-button-link-error.k-selected {
  color: var(--kendo-color-error-active, #ca1400);
}
.k-button-link-dark {
  color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-link-dark:hover, .k-button-link-dark.k-hover {
  color: var(--kendo-color-dark-hover, #292929);
}
.k-button-link-dark:focus, .k-button-link-dark.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-dark, #3d3d3d) 30%, transparent);
}
.k-button-link-dark:active, .k-button-link-dark.k-active {
  color: var(--kendo-color-dark-active, #1f1f1f);
}
.k-button-link-dark.k-selected {
  color: var(--kendo-color-dark-active, #1f1f1f);
}
.k-button-link-light {
  color: var(--kendo-color-light, #ebebeb);
}
.k-button-link-light:hover, .k-button-link-light.k-hover {
  color: var(--kendo-color-light-hover, #e0e0e0);
}
.k-button-link-light:focus, .k-button-link-light.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-light, #ebebeb) 30%, transparent);
}
.k-button-link-light:active, .k-button-link-light.k-active {
  color: var(--kendo-color-light-active, #d6d6d6);
}
.k-button-link-light.k-selected {
  color: var(--kendo-color-light-active, #d6d6d6);
}
.k-button-link-inverse {
  color: var(--kendo-color-dark, #3d3d3d);
}
.k-button-link-inverse:hover, .k-button-link-inverse.k-hover {
  color: var(--kendo-color-inverse-hover, #292929);
}
.k-button-link-inverse:focus, .k-button-link-inverse.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-inverse, #3d3d3d) 30%, transparent);
}
.k-button-link-inverse:active, .k-button-link-inverse.k-active {
  color: var(--kendo-color-inverse-active, #1f1f1f);
}
.k-button-link-inverse.k-selected {
  color: var(--kendo-color-inverse-active, #1f1f1f);
}
.k-button-link-base {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-button-link-base:hover, .k-button-link-base.k-hover {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-button-link-base:focus, .k-button-link-base.k-focus {
  box-shadow: 0 0 0px 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 30%, transparent);
}
.k-button-link-base:active, .k-button-link-base.k-active {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-button-link-base.k-selected {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-button-clear-primary {
  color: var(--kendo-color-primary-on-surface, #ff6358);
}
.k-button-clear-primary:focus::after, .k-button-clear-primary.k-focus::after, .k-button-clear-primary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-secondary {
  color: var(--kendo-color-secondary-on-surface, #292929);
}
.k-button-clear-secondary:focus::after, .k-button-clear-secondary.k-focus::after, .k-button-clear-secondary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-tertiary {
  color: var(--kendo-color-tertiary-on-surface, #028ccb);
}
.k-button-clear-tertiary:focus::after, .k-button-clear-tertiary.k-focus::after, .k-button-clear-tertiary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-info {
  color: var(--kendo-color-info-on-surface, #004ac2);
}
.k-button-clear-info:focus::after, .k-button-clear-info.k-focus::after, .k-button-clear-info:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-success {
  color: var(--kendo-color-success-on-surface, #2d9600);
}
.k-button-clear-success:focus::after, .k-button-clear-success.k-focus::after, .k-button-clear-success:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-warning {
  color: var(--kendo-color-warning-on-surface, #ffc000);
}
.k-button-clear-warning:focus::after, .k-button-clear-warning.k-focus::after, .k-button-clear-warning:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-error {
  color: var(--kendo-color-error-on-surface, #ca1400);
}
.k-button-clear-error:focus::after, .k-button-clear-error.k-focus::after, .k-button-clear-error:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-dark {
  color: var(--kendo-color-dark-on-surface, #141414);
}
.k-button-clear-dark:focus::after, .k-button-clear-dark.k-focus::after, .k-button-clear-dark:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-light {
  color: var(--kendo-color-light-on-surface, #e0e0e0);
}
.k-button-clear-light:focus::after, .k-button-clear-light.k-focus::after, .k-button-clear-light:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-inverse {
  color: var(--kendo-color-inverse-on-surface, #141414);
}
.k-button-clear-inverse:focus::after, .k-button-clear-inverse.k-focus::after, .k-button-clear-inverse:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-base {
  color: var(--kendo-color-base-on-surface, #3d3d3d);
}
.k-button-clear-base:focus::after, .k-button-clear-base.k-focus::after, .k-button-clear-base:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-menu-bar, .k-menu {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-menu-bar *, .k-menu *,
.k-menu-bar *::before,
.k-menu *::before,
.k-menu-bar *::after,
.k-menu *::after {
  box-sizing: border-box;
}

.k-menu-item {
  border-width: 0;
  outline: 0;
  display: flex;
  flex-flow: column nowrap;
  flex: none;
  position: relative;
  user-select: none;
}

.k-menu-item-content {
  display: block;
  height: auto;
  overflow: visible;
}
.k-menu-item-content .k-actions, .k-menu-item-content .k-form-buttons, .k-menu-item-content .k-edit-buttons,
.k-menu-item-content .k-action-buttons,
.k-menu-item-content .k-columnmenu-actions {
  margin-top: 0;
}

.k-menu-link {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
  outline: 0;
  color: inherit;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  gap: var(--kendo-spacing-1, 0.25rem);
  align-items: center;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
}

.k-menu-link-text {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-menu-expand-arrow {
  margin-inline-end: calc(var(--kendo-spacing-1, 0.25rem) * -1);
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  flex: none;
  position: relative;
}

.k-menu-horizontal {
  flex-direction: row;
}
.k-menu-horizontal > .k-menu-item + .k-menu-item {
  margin-inline-start: var(--kendo-spacing-0, 0px);
}
.k-menu-horizontal > .k-separator {
  margin-block: 0;
  margin-inline: var(--kendo-spacing-1, 0.25rem);
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
  border-style: solid;
}

.k-menu-vertical {
  flex-direction: column;
}
.k-menu-vertical > .k-menu-item + .k-menu-item {
  margin-top: var(--kendo-spacing-0, 0px);
}
.k-menu-vertical > .k-menu-item > .k-menu-link {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  padding-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px);
}
.k-menu-vertical > .k-menu-item > .k-menu-link > .k-menu-expand-arrow {
  margin-inline-start: var(--kendo-spacing-2, 0.5rem);
  margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px) - var(--kendo-spacing-2, 0.5rem)/2));
}
.k-menu-vertical > .k-separator {
  margin-block: var(--kendo-spacing-1, 0.25rem);
  margin-inline: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  display: block;
}

.k-menu-popup {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  overflow: auto;
  max-height: 80vh;
}
.k-menu-popup *,
.k-menu-popup *::before,
.k-menu-popup *::after {
  box-sizing: border-box;
}

.k-menu-group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
}
.k-menu-group .k-menu-item + .k-menu-item {
  margin-top: var(--kendo-spacing-0, 0px);
}
.k-menu-group .k-separator {
  margin-block: var(--kendo-spacing-1, 0.25rem);
  margin-inline: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  display: block;
}

.k-menu-popup .k-menu-group {
  position: relative;
}

.k-menu-group-sm {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-sm .k-menu-item {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-sm .k-menu-link {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  padding-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px);
}
.k-menu-group-sm .k-menu-expand-arrow {
  margin-inline-start: var(--kendo-spacing-2, 0.5rem);
  margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px) - var(--kendo-spacing-2, 0.5rem)/2));
}

.k-menu-group-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-md .k-menu-item {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-menu-group-md .k-menu-link {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  padding-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px);
}
.k-menu-group-md .k-menu-expand-arrow {
  margin-inline-start: var(--kendo-spacing-2, 0.5rem);
  margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px) - var(--kendo-spacing-2, 0.5rem)/2));
}

.k-menu-group-lg {
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}
.k-menu-group-lg .k-menu-item {
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}
.k-menu-group-lg .k-menu-link {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  padding-inline-end: calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px);
}
.k-menu-group-lg .k-menu-expand-arrow {
  margin-inline-start: var(--kendo-spacing-2, 0.5rem);
  margin-inline-end: calc(-1 * (calc(var(--kendo-spacing-2, 0.5rem) * 2 + 16px) - var(--kendo-spacing-2, 0.5rem)/2));
}

.k-popups-wrapper {
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
}

.k-context-menu {
  margin: 0;
  border-width: 1px;
  border-style: solid;
}
.k-animation-container .k-context-menu.k-menu-horizontal {
  display: flex !important;
  flex-wrap: nowrap;
}

.k-context-menu-popup {
  z-index: 12000;
}
.k-context-menu-popup .k-context-menu {
  border-width: 0;
}

.k-popup .k-context-menu,
.k-context-menu-popup .k-context-menu {
  border-width: 0;
}

.k-menu-scroll-wrapper {
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
}
.k-menu-scroll-wrapper .k-menu {
  overflow: hidden;
  flex-wrap: nowrap;
}
.k-menu-scroll-wrapper .k-menu-scroll-button {
  border-radius: 0;
  padding: 0;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: inherit;
  position: absolute;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-left {
  top: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 16px;
  border-right-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-right {
  top: 0;
  inset-inline-end: 0;
  height: 100%;
  width: 16px;
  border-left-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-up {
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 16px;
  border-bottom-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-down {
  bottom: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 16px;
  border-top-width: 1px;
}

.k-menu:not(.k-context-menu) {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: transparent;
}
.k-menu:not(.k-context-menu) > .k-item {
  color: var(--kendo-color-primary, #ff6358);
}
.k-menu:not(.k-context-menu) > .k-item:hover, .k-menu:not(.k-context-menu) > .k-item.k-hover {
  color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-menu:not(.k-context-menu) > .k-item:active, .k-menu:not(.k-context-menu) > .k-item.k-active {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-menu:not(.k-context-menu) > .k-item:focus, .k-menu:not(.k-context-menu) > .k-item.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-menu-group,
.k-menu.k-context-menu {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-menu-group .k-item > .k-link:hover, .k-menu-group .k-item > .k-link.k-hover,
.k-menu.k-context-menu .k-item > .k-link:hover,
.k-menu.k-context-menu .k-item > .k-link.k-hover {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-menu-group .k-item > .k-link:active, .k-menu-group .k-item > .k-link.k-active, .k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-menu-group .k-item:focus > .k-link,
.k-menu-group .k-item.k-focus > .k-link,
.k-menu.k-context-menu .k-item:focus > .k-link,
.k-menu.k-context-menu .k-item.k-focus > .k-link {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-menu-scroll-wrapper .k-menu-scroll-button {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-primary, #ff6358);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover {
  color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover::before {
  opacity: 0;
}

.k-toolbar {
  margin: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-toolbar::before {
  content: "";
  height: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
}
.k-toolbar.k-toolbar-resizable {
  flex-wrap: nowrap;
}
.k-toolbar > * {
  flex-shrink: 0;
  display: inline-flex;
  align-content: center;
  vertical-align: middle;
}
.k-toolbar > * > label {
  align-self: center;
}
.k-toolbar .k-input,
.k-toolbar .k-picker {
  width: 10em;
}
.k-toolbar .k-color-picker, .k-toolbar .k-colorpicker {
  width: min-content;
}
.k-toolbar .k-toolbar-overflow-button {
  margin-inline-start: auto;
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
  width: 0;
  height: calc(1.4285714286 * 1em);
  border-width: 0 0 0 1px;
  border-style: solid;
  align-self: center;
}
.k-toolbar .k-toolbar-spacer {
  height: calc(1.4285714286 * 1em);
  flex: 1 0 0%;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
  text-decoration: none;
  outline: 0;
}

.k-toolbar-group {
  display: flex;
  flex-direction: inherit;
  flex-wrap: nowrap;
  gap: inherit;
}

.k-toolbar-renderer {
  display: inline-block;
  border-color: inherit;
}

.k-toolbar-sm {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  gap: var(--kendo-spacing-1\.5, 0.375rem);
}
.k-toolbar-sm::before {
  margin-inline-start: calc(var(--kendo-spacing-1\.5, 0.375rem) * -1);
}
.k-toolbar-sm > * > label {
  margin-inline-end: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-toolbar-md {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-toolbar-md::before {
  margin-inline-start: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-toolbar-md > * > label {
  margin-inline-end: var(--kendo-spacing-2, 0.5rem);
}

.k-toolbar-lg {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
  padding-inline: var(--kendo-spacing-2\.5, 0.625rem);
  gap: var(--kendo-spacing-2\.5, 0.625rem);
}
.k-toolbar-lg::before {
  margin-inline-start: calc(var(--kendo-spacing-2\.5, 0.625rem) * -1);
}
.k-toolbar-lg > * > label {
  margin-inline-end: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-toolbar-sm::before {
  height: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 2px);
}

.k-toolbar-md::before {
  height: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
}

.k-toolbar-lg::before {
  height: calc(var(--kendo-line-height-lg, normal) * 1em + var(--kendo-spacing-2, 0.5rem) * 2 + 2px);
}

.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
  padding-block: var(--kendo-spacing-2, 0.5rem) !important;
  padding-inline: var(--kendo-spacing-2, 0.5rem) !important;
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-flow: row nowrap;
  gap: var(--kendo-spacing-2, 0.5rem);
  align-items: center;
}
.k-floating-toolbar .k-toolbar,
.editorToolbarWindow.k-window-content .k-toolbar {
  padding: 0;
  border-width: 0;
  flex-shrink: 1;
  color: inherit;
  background: none;
}

.k-editortoolbar-dragHandle {
  cursor: move;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
}

.k-overflow-container {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-overflow-container > .k-item {
  border-color: inherit;
}
.k-overflow-container .k-separator {
  margin: var(--kendo-spacing-1, 0.25rem) 0;
}
.k-overflow-container .k-overflow-tool-group {
  display: block;
}
.k-overflow-container .k-overflow-button {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  width: 100%;
  height: auto;
  border-width: 0;
  border-radius: 0;
  aspect-ratio: auto;
  color: inherit;
  background-color: transparent;
  background-image: none;
  font: inherit;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
  justify-content: flex-start;
  position: relative;
}
.k-overflow-container .k-button-group {
  box-shadow: none;
  display: flex;
  flex-flow: column nowrap;
}
.k-overflow-container .k-button-group .k-button {
  margin: 0;
}
.k-overflow-container .k-split-button {
  display: flex;
  flex-direction: column;
}
.k-overflow-container .k-overflow-hidden {
  display: none;
}

.k-toolbar.k-toolbar-flat {
  border-width: 1px 0;
  border-top-color: transparent !important;
  border-bottom-color: inherit;
  color: inherit;
  background: none !important;
  box-shadow: none;
}

.k-toolbar {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
  border-color: inherit;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-overflow-container .k-button:hover, .k-overflow-container .k-button.k-hover {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background: var(--kendo-color-base-hover, #ebebeb);
}
.k-overflow-container .k-button:focus, .k-overflow-container .k-button.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-overflow-container .k-button:active, .k-overflow-container .k-button.k-active {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background: var(--kendo-color-base-hover, #ebebeb);
}
.k-overflow-container .k-button.k-selected {
  color: var(--kendo-color-on-primary, #ffffff);
  background: var(--kendo-color-primary, #ff6358);
}
.k-overflow-container .k-button:disabled, .k-overflow-container .k-button.k-disabled {
  color: inherit;
}

.k-pane-wrapper {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pane-wrapper .k-pane {
  width: 100%;
  height: 100%;
  user-select: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow-x: hidden;
}
.k-pane-wrapper .k-view {
  top: 0;
  left: 0;
  position: relative;
  border: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pane-wrapper .k-content {
  min-height: 1px;
  flex: 1;
  align-items: stretch;
  display: block;
  width: auto;
  overflow: hidden;
  position: relative;
}

.k-pane-wrapper > div.k-pane {
  box-shadow: none;
  font-weight: normal;
}
.k-pane-wrapper .k-popup-edit-form .k-content,
.k-pane-wrapper .k-grid-edit-form .k-content,
.k-pane-wrapper .k-grid-header-menu .k-content,
.k-pane-wrapper .k-grid-filter-menu .k-content {
  overflow-y: auto;
}
.k-pane-wrapper .k-popup-edit-form .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-edit-form .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-header-menu .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-filter-menu .k-content > .k-scroll-container {
  position: absolute;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}
.k-pane-wrapper .k-grid-edit-form .k-popup-edit-form,
.k-pane-wrapper .k-grid-edit-form .k-edit-form-container {
  width: auto;
  min-width: auto;
}

.k-grid.k-grid-mobile {
  border-width: 0;
}

.k-grid-mobile .k-resize-handle-inner::before {
  content: "\e01e";
  position: absolute;
  font: 16px/1 "WebComponentsIcons";
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.2em;
}
.k-grid-mobile .k-header a {
  user-select: none;
}

.k-pane-wrapper .k-scheduler-edit-form .k-recur-view {
  padding: 0;
  flex-direction: column;
  align-items: stretch;
}
.k-pane-wrapper .k-scheduler-edit-form .k-recur-view > .k-listgroup-form-row {
  margin: 0;
}
.k-pane-wrapper .k-scheduler-edit-form .k-recur-items-wrap {
  width: 100%;
  margin-block: -1px;
  margin-inline: 0;
}
.k-pane-wrapper .k-scheduler-edit-form .k-scheduler-recur-end-wrap {
  white-space: nowrap;
}

.k-scheduler.k-scheduler-mobile {
  border-width: 0;
}

.k-scheduler-mobile th {
  font-weight: normal;
}
.k-scheduler-mobile .k-event:hover .k-resize-handle {
  visibility: hidden;
}
.k-scheduler-mobile .k-scheduler-toolbar {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-scheduler-mobile .k-scheduler-toolbar > * {
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar::before {
  display: none;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-current {
  line-height: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-views-wrapper .k-views-dropdown {
  display: inline-block;
}
.k-scheduler-mobile .k-scheduler-footer {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  justify-content: space-between;
}
.k-scheduler-mobile .k-scheduler-footer > * {
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-footer::before {
  display: none;
}
.k-scheduler-mobile .k-scheduler-monthview .k-hidden {
  height: 40px;
}
.k-scheduler-mobile .k-scheduler-monthview .k-scheduler-table td {
  height: 40px;
  vertical-align: top;
  text-align: center;
}
.k-scheduler-mobile .k-scheduler-monthview .k-events-container {
  position: absolute;
  text-align: center;
  height: 6px;
  line-height: 6px;
}
.k-scheduler-mobile .k-scheduler-monthview .k-event {
  position: static;
  padding: 4px;
  border-radius: 50%;
  display: inline-block;
  width: 4px;
  height: 4px;
  min-height: 0;
  margin: 1px;
}
.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header.k-mobile-horizontal-header .k-scheduler-times table tr:first-child {
  display: none;
}
.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header .k-scheduler-header .k-scheduler-date-group {
  display: none;
}
.k-scheduler-mobile .k-scheduler-header-wrap > div {
  overflow: visible;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-mobile-header {
  display: none;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table {
  table-layout: auto;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table .k-scheduler-groupcolumn {
  width: 1%;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table td {
  white-space: normal;
}
.k-scheduler-mobile .k-mobile-header .k-scheduler-table td,
.k-scheduler-mobile .k-mobile-header .k-scheduler-table th {
  height: 1.5em;
}
.k-scheduler-mobile .k-time-text,
.k-scheduler-mobile .k-time-period {
  display: block;
  line-height: 1;
}
.k-scheduler-mobile .k-time-period {
  font-size: 0.7em;
}
.k-scheduler-mobile .k-scheduler-table td,
.k-scheduler-mobile .k-scheduler-table th {
  height: 2em;
  vertical-align: middle;
}
.k-scheduler-mobile .k-scheduler-datecolumn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.k-scheduler-mobile .k-task {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.k-scheduler-mobile .k-task .k-scheduler-mark {
  border-radius: 50%;
  margin: 0;
}
.k-scheduler-mobile .k-task .k-scheduler-task-text {
  flex: 1 1 0%;
}
.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell,
.k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn {
  vertical-align: top;
}
.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn .k-scheduler-group-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
}
.k-scheduler-mobile .k-scrollbar-h tr + tr .k-scheduler-times {
  border-bottom-width: 0;
}

.k-pane-wrapper .k-appbar {
  padding: var(--kendo-spacing-1, 0.25rem);
}
.k-pane-wrapper .k-list-title,
.k-pane-wrapper .k-filter-help-text {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  display: block;
}
.k-pane-wrapper .k-listgroup-title {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  font-weight: bold;
  text-transform: uppercase;
}
.k-pane-wrapper .k-listgroup .k-listgroup-item {
  border-color: inherit;
}
.k-pane-wrapper .k-listgroup + .k-listgroup {
  margin-top: var(--kendo-spacing-4, 1rem);
}
.k-pane-wrapper .k-column-menu {
  padding: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-pane-wrapper .k-column-menu .k-filter-item .k-filterable * {
  pointer-events: none;
}
.k-pane-wrapper .k-column-menu .k-list-title,
.k-pane-wrapper .k-column-menu .k-listgroup-title {
  padding: 0;
}
.k-pane-wrapper .k-column-menu .k-listgroup {
  margin-inline: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-pane-wrapper .k-filter-menu {
  padding: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-pane-wrapper .k-filter-menu .k-list-title {
  padding: 0;
}
.k-pane-wrapper .k-filter-menu .k-list-filter {
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: inherit;
}
.k-pane-wrapper .k-filter-menu .k-filter-tools {
  display: flex;
  flex-flow: row nowrap;
  gap: inherit;
}
.k-pane-wrapper .k-filter-menu .k-listgroup {
  margin-inline: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-pane-wrapper .k-popup-edit-form .k-recur-editor-wrap {
  display: flex;
}
.k-pane-wrapper .k-popup-edit-form .k-mobiletimezoneeditor {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex: 1 1 auto;
}
.k-pane-wrapper .k-multicheck-wrap.k-listgroup {
  overflow-y: auto;
}
.k-pane-wrapper .k-pager-wrap.k-pager-sm,
.k-pane-wrapper .k-pager.k-pager-sm {
  justify-content: center;
}
.k-pane-wrapper .k-pager-wrap.k-pager-sm .k-pager-refresh,
.k-pane-wrapper .k-pager.k-pager-sm .k-pager-refresh {
  display: none;
}
.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li, .k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-scheduler-toolbar > ul > li, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li {
  border: 0;
}
.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload, .k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-task .k-i-reload, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload {
  margin-left: 0.5em;
  margin-right: 0;
}

.k-pane-wrapper,
.k-view {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-pane-wrapper .k-pane > .k-view > .k-content {
  background-position: 50% 50%;
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-column-active + th.k-header {
  border-left-color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-resize-handle-inner::before {
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-nav-current {
  color: var(--kendo-color-primary, #ff6358);
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendadate {
  color: inherit;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-time-period,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendaweekday {
  color: var(--kendo-color-subtle, #666666);
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-wrap,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-datecolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-group-cell,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-all-day {
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-tools > li {
  display: inline-block;
  background-color: inherit;
}
.k-pane-wrapper .k-pane .k-grid-edit-form .k-item,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-link,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-link,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-link,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-item,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-link {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-pane-wrapper .k-pane .k-grid-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-resetSeries {
  color: var(--kendo-color-primary, #ff6358);
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected {
  color: var(--kendo-color-primary, #ff6358);
  background: none;
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected .k-link {
  color: inherit;
}

.k-progressbar {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  --kendo-progressbar-value: 0;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: var(--kendo-line-height-xs, normal);
  display: inline-grid;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-progressbar *,
.k-progressbar *::before,
.k-progressbar *::after {
  box-sizing: border-box;
}
.k-progressbar > .k-progressbar-value {
  border-width: 0;
  border-style: solid;
  display: flex;
  overflow: hidden;
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.k-progressbar > .k-progressbar-chunks {
  border-width: inherit;
  border-style: inherit;
  white-space: nowrap;
  display: flex;
  align-items: stretch;
  flex: 1;
  gap: 1px;
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.k-progressbar .k-progressbar-chunk {
  display: block;
  border-width: 0;
  flex: 1;
}

.k-progress-status-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.k-progress-status-wrap.k-progress-start {
  justify-content: flex-start;
}
.k-progress-status-wrap.k-progress-center {
  justify-content: center;
}
.k-progress-status-wrap.k-progress-end {
  justify-content: flex-end;
}

.k-progress-status {
  padding-block: 0;
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  min-width: 10px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
}

.k-progressbar-horizontal {
  width: 100%;
  height: var(--kendo-progressbar-height, 22px);
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
}
.k-progressbar-horizontal .k-progress-status-wrap {
  flex-direction: row;
}
.k-progressbar-horizontal > .k-progressbar-value {
  width: calc(var(--kendo-progressbar-value, 0) * 1%);
  flex-direction: row;
}
.k-progressbar-horizontal > .k-progressbar-value > .k-progress-status-wrap {
  width: calc(100% * 100 / var(--kendo-progressbar-value, 1));
}
.k-progressbar-horizontal > .k-progressbar-chunks {
  flex-direction: row;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-progressbar-value {
  flex-direction: row-reverse;
  justify-self: flex-end;
}
.k-progressbar-horizontal.k-progressbar-reverse > .k-progressbar-chunks {
  flex-direction: row-reverse;
}

.k-progressbar-vertical {
  width: var(--kendo-progressbar-height, 22px);
  height: 27em;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
}
.k-progressbar-vertical .k-progress-status-wrap {
  flex-direction: column-reverse;
}
.k-progressbar-vertical .k-progress-status {
  writing-mode: vertical-lr;
}
.k-progressbar-vertical > .k-progressbar-value {
  height: calc(var(--kendo-progressbar-value, 0) * 1%);
  flex-direction: column-reverse;
  align-self: flex-end;
  align-items: flex-end;
}
.k-progressbar-vertical > .k-progressbar-value > .k-progress-status-wrap {
  height: calc(100% * 100 / var(--kendo-progressbar-value, 1));
}
.k-progressbar-vertical > .k-progressbar-chunks {
  flex-direction: column-reverse;
}
.k-progressbar-vertical.k-progressbar-reverse {
  flex-direction: column;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-progressbar-value {
  flex-direction: column;
  align-self: flex-start;
}
.k-progressbar-vertical.k-progressbar-reverse > .k-progressbar-chunks {
  flex-direction: column;
}

.k-progressbar-indeterminate .k-progress-status-wrap,
.k-progressbar-indeterminate .k-progressbar-value,
.k-progressbar-indeterminate .k-progressbar-chunk {
  display: none;
}

.telerik-blazor.k-progressbar-horizontal > .k-progressbar-value,
.telerik-blazor.k-progressbar-horizontal > .k-progressbar-value > .k-progress-status-wrap {
  transition: width 0.1s ease-in-out;
}

.k-rtl .k-progressbar,
.k-progressbar[dir=rtl] {
  direction: rtl;
}

.k-circular-progressbar {
  display: inline-block;
  text-align: start;
  position: relative;
}

.k-circular-progressbar-surface {
  height: 100%;
}
.k-circular-progressbar-surface > div {
  width: 100%;
  height: 100%;
}
.k-circular-progressbar-surface svg {
  width: 100%;
  height: 100%;
}

.k-circular-progressbar-scale {
  fill: none;
}

.k-circular-progressbar-arc {
  transform-box: fill-box;
  transform-origin: center center;
  transform: rotate(-90deg);
  stroke-linecap: round;
  fill: none;
}

.k-circular-progressbar-label {
  position: absolute;
  text-align: center;
  padding: 0;
  margin: 0;
}

@keyframes kendo-progressbar-indeterminate-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 22px 0;
  }
}
.k-progressbar {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-subtle, #ebebeb);
}
.k-progressbar .k-selected {
  border-color: var(--kendo-color-primary-active, #d45349);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-chunk-progressbar {
  background-color: transparent;
}

.k-progressbar-chunk {
  background-color: var(--kendo-color-base-subtle, #ebebeb);
}
.k-progressbar-chunk .k-selected {
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-progressbar-indeterminate {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-subtle, #ebebeb);
  background-image: linear-gradient(45deg, color-mix(in srgb, var(--kendo-color-base-emphasis, #c2c2c2) 55%, transparent) 25%, transparent 25%, transparent 50%, color-mix(in srgb, var(--kendo-color-base-emphasis, #c2c2c2) 55%, transparent) 50%, color-mix(in srgb, var(--kendo-color-base-emphasis, #c2c2c2) 55%, transparent) 75%, transparent 75%, transparent);
  background-size: 22px 22px;
  animation: kendo-progressbar-indeterminate-animation 1s linear infinite;
}

.k-circular-progressbar-scale {
  stroke: var(--kendo-color-base-subtle, #ebebeb);
}

.k-circular-progressbar-arc {
  stroke: var(--kendo-color-primary, #ff6358);
  transition: stroke 0.5s ease;
}

.k-actions, .k-form-buttons, .k-edit-buttons,
.k-action-buttons,
.k-columnmenu-actions {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  box-sizing: border-box;
  border-width: 0px 0 0 0;
  border-style: solid;
  border-color: inherit;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  overflow: hidden;
  clear: both;
}
.k-actions *, .k-form-buttons *, .k-edit-buttons *,
.k-action-buttons *,
.k-columnmenu-actions *,
.k-actions *::before,
.k-form-buttons *::before,
.k-edit-buttons *::before,
.k-action-buttons *::before,
.k-columnmenu-actions *::before,
.k-actions *::after,
.k-form-buttons *::after,
.k-edit-buttons *::after,
.k-action-buttons *::after,
.k-columnmenu-actions *::after {
  box-sizing: border-box;
}

.k-popup .k-actions, .k-popup .k-form-buttons, .k-popup .k-edit-buttons,
.k-popup .k-action-buttons,
.k-popup .k-columnmenu-actions {
  margin-top: var(--kendo-spacing-4, 1rem);
}

.k-actions-start {
  justify-content: flex-start;
}

.k-actions-center {
  justify-content: center;
}

.k-actions-end {
  justify-content: flex-end;
}

.k-actions-stretched > * {
  flex: 1 0 0%;
}

.k-actions-horizontal {
  width: 100%;
  flex-flow: row nowrap;
}

.k-actions-vertical {
  flex-flow: column nowrap;
}

.k-input,
.k-picker {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  font-weight: normal;
  text-align: start;
  box-shadow: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: stretch;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}
.k-input *,
.k-input *::before,
.k-input *::after,
.k-picker *,
.k-picker *::before,
.k-picker *::after {
  box-sizing: border-box;
}
.k-input > .k-input,
.k-input > .k-picker,
.k-picker > .k-input,
.k-picker > .k-picker {
  border-width: 0 !important;
  color: inherit;
  background: none;
  font: inherit;
  outline: 0;
  box-shadow: none;
}
.k-input > *,
.k-picker > * {
  margin: 0;
}
.k-input::placeholder,
.k-picker::placeholder {
  color: var(--kendo-color-subtle, #666666);
  opacity: 1;
  user-select: none;
}

input.k-input,
textarea.k-textarea {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
input.k-input:disabled, input.k-input[disabled],
textarea.k-textarea:disabled,
textarea.k-textarea[disabled] {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
[disabled] input.k-input:disabled, .k-disabled input.k-input:disabled, [disabled] input.k-input[disabled], .k-disabled input.k-input[disabled],
[disabled] textarea.k-textarea:disabled,
.k-disabled textarea.k-textarea:disabled,
[disabled] textarea.k-textarea[disabled],
.k-disabled textarea.k-textarea[disabled] {
  opacity: 1;
  filter: grayscale(0);
}

textarea.k-textarea {
  overflow: auto;
}

.k-picker {
  cursor: pointer;
}

select.k-picker {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  appearance: auto;
}
select.k-picker:disabled, select.k-picker[disabled] {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
[disabled] select.k-picker:disabled, .k-disabled select.k-picker:disabled, [disabled] select.k-picker[disabled], .k-disabled select.k-picker[disabled] {
  opacity: 1;
  filter: grayscale(0);
}

.k-input-inner {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  width: 100%;
  border: 0;
  outline: 0;
  color: inherit;
  background: none;
  font: inherit;
  flex: 1;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}
.k-input-inner::placeholder {
  color: var(--kendo-color-subtle, #666666);
  opacity: 1;
  user-select: none;
}
.k-input-inner:invalid {
  box-shadow: none;
}

span.k-input-inner {
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

textarea.k-input-inner {
  margin: 0;
}

.k-input-value-icon {
  flex: none;
}

.k-input-value-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-input-value-text::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-input-values {
  min-width: 0px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: text;
}

.k-input-values .k-chip-list {
  display: contents;
}

.k-icon-picker .k-input-inner {
  padding: var(--kendo-spacing-1, 0.25rem);
  justify-content: center;
}

.k-input-prefix,
.k-input-suffix {
  border-color: inherit;
  display: flex;
  align-items: center;
  flex: none;
}
.k-input-prefix > *,
.k-input-suffix > * {
  flex-shrink: 0;
  border: none;
}
.k-input-prefix-vertical,
.k-input-suffix-vertical {
  flex-flow: column wrap;
}
.k-input-prefix-horizontal,
.k-input-suffix-horizontal {
  flex-flow: row wrap;
}

.k-input-separator {
  margin: 0;
  border-style: solid;
  border-color: inherit;
  border-width: 0 0 0 1px;
  height: 16px;
  align-self: center;
}
.k-input-separator-horizontal {
  height: auto;
  margin-inline: var(--kendo-spacing-1, 0.25rem);
  align-self: stretch;
  border-width: 1px 0 0;
}
.k-input-separator-vertical {
  height: auto;
  margin-block: var(--kendo-spacing-1, 0.25rem);
  align-self: stretch;
  border-width: 0 0 0 1px;
}

.k-input-icon,
.k-input-validation-icon,
.k-input-loading-icon {
  flex: none;
  align-self: center;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
}

.k-input-loading-icon {
  width: 1em;
  height: 1em;
  font-size: 16px;
}

.k-input-icon.k-svg-icon > svg, .k-input-icon.k-icon-md > svg,
.k-input-validation-icon.k-svg-icon > svg,
.k-input-validation-icon.k-icon-md > svg,
.k-input-loading-icon.k-svg-icon > svg,
.k-input-loading-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-input-icon.k-icon-xs > svg,
.k-input-validation-icon.k-icon-xs > svg,
.k-input-loading-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-input-icon.k-icon-sm > svg,
.k-input-validation-icon.k-icon-sm > svg,
.k-input-loading-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-input-icon.k-icon-lg > svg,
.k-input-validation-icon.k-icon-lg > svg,
.k-input-loading-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-input-icon.k-icon-xl > svg,
.k-input-validation-icon.k-icon-xl > svg,
.k-input-loading-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-input-icon.k-icon-xxl > svg,
.k-input-validation-icon.k-icon-xxl > svg,
.k-input-loading-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-input-icon.k-icon-xxxl > svg,
.k-input-validation-icon.k-icon-xxxl > svg,
.k-input-loading-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-clear-value {
  outline: 0;
  flex: none;
  align-self: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
}

.k-clear-value:hover,
.k-clear-value.k-hover {
  opacity: 1;
}

.k-clear-value:focus-visible {
  outline: 1px dotted;
  outline-offset: -1px;
}

.k-input-button {
  width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2);
  border-width: 0;
  border-inline-start-width: 1px;
  flex: none;
  aspect-ratio: auto;
  box-shadow: none;
}
.k-input-button > .k-button-icon {
  min-width: auto !important;
}
.k-input-button:focus {
  box-shadow: none;
}

.k-picker .k-input-button {
  color: inherit;
  background: none;
  border-color: transparent;
}

.k-input-spinner {
  width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2);
  flex: none;
  display: flex;
  flex-flow: column nowrap;
}
.k-input-spinner .k-spinner-increase,
.k-input-spinner .k-spinner-decrease {
  border-width: 0;
  border-inline-start-width: 1px;
  flex: 1 1 50%;
  box-shadow: none;
  position: relative;
  aspect-ratio: auto;
}
.k-input-spinner .k-spinner-increase .k-button-icon,
.k-input-spinner .k-spinner-decrease .k-button-icon {
  margin-block: -1em;
  margin-inline: 0;
  min-width: auto !important;
  min-height: auto !important;
}
.k-input-spinner .k-spinner-increase .k-icon {
  bottom: auto;
}
.k-input-spinner .k-spinner-decrease .k-icon {
  top: auto;
}

.k-searchbar {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
}

.k-picker-wrap,
.k-dropdown-wrap,
.k-dateinput-wrap,
.k-multiselect-wrap,
.k-numeric-wrap {
  width: 100%;
  border-width: 0;
  border-color: inherit;
  box-sizing: border-box;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  position: relative;
}

.k-input-flat,
.k-picker-flat {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-width: 1px 0;
  border-top-color: transparent !important;
  background-image: none !important;
}

.k-input-outline {
  background: none !important;
}

.k-input-sm,
.k-picker-sm {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-input-sm .k-input-values,
.k-picker-sm .k-input-values {
  padding: calc(var(--kendo-spacing-0\.5, 0.125rem) / 2);
  gap: calc(var(--kendo-spacing-0\.5, 0.125rem) / 2);
}
.k-input-sm .k-input-values > .k-searchbar,
.k-input-sm .k-input-values > .k-input-inner,
.k-picker-sm .k-input-values > .k-searchbar,
.k-picker-sm .k-input-values > .k-input-inner {
  margin: calc(var(--kendo-spacing-0\.5, 0.125rem) / 2 * -1);
}
.k-input-sm .k-input-inner,
.k-picker-sm .k-input-inner {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-sm .k-input-button,
.k-input-sm .k-spinner-increase,
.k-input-sm .k-spinner-decrease,
.k-picker-sm .k-input-button,
.k-picker-sm .k-spinner-increase,
.k-picker-sm .k-spinner-decrease {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
}
.k-input-sm .k-input-icon,
.k-input-sm .k-input-validation-icon,
.k-input-sm .k-input-loading-icon,
.k-input-sm .k-clear-value,
.k-input-sm .k-input-prefix > .k-icon,
.k-input-sm .k-input-prefix > .k-input-prefix-text,
.k-input-sm .k-input-suffix > .k-icon,
.k-input-sm .k-input-suffix > .k-input-suffix-text,
.k-picker-sm .k-input-icon,
.k-picker-sm .k-input-validation-icon,
.k-picker-sm .k-input-loading-icon,
.k-picker-sm .k-clear-value,
.k-picker-sm .k-input-prefix > .k-icon,
.k-picker-sm .k-input-prefix > .k-input-prefix-text,
.k-picker-sm .k-input-suffix > .k-icon,
.k-picker-sm .k-input-suffix > .k-input-suffix-text {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
  box-sizing: content-box;
}
.k-input-sm .k-input-separator-horizontal,
.k-picker-sm .k-input-separator-horizontal {
  margin-inline: var(--kendo-spacing-0\.5, 0.125rem);
}
.k-input-sm .k-input-separator-vertical,
.k-picker-sm .k-input-separator-vertical {
  margin-block: var(--kendo-spacing-0\.5, 0.125rem);
}
.k-input-sm.k-icon-picker .k-input-inner,
.k-picker-sm.k-icon-picker .k-input-inner {
  width: calc(var(--kendo-line-height, normal) * 1em);
  height: calc(var(--kendo-line-height, normal) * 1em);
  padding: var(--kendo-spacing-0\.5, 0.125rem);
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-sm .k-input-button {
  padding: var(--kendo-spacing-0\.5, 0.125rem);
}

select.k-picker-sm {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-input-md,
.k-picker-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-input-md .k-input-values,
.k-picker-md .k-input-values {
  padding: calc(var(--kendo-spacing-1, 0.25rem) / 2);
  gap: calc(var(--kendo-spacing-1, 0.25rem) / 2);
}
.k-input-md .k-input-values > .k-searchbar,
.k-input-md .k-input-values > .k-input-inner,
.k-picker-md .k-input-values > .k-searchbar,
.k-picker-md .k-input-values > .k-input-inner {
  margin: calc(var(--kendo-spacing-1, 0.25rem) / 2 * -1);
}
.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-input-md .k-input-icon,
.k-input-md .k-input-validation-icon,
.k-input-md .k-input-loading-icon,
.k-input-md .k-clear-value,
.k-input-md .k-input-prefix > .k-icon,
.k-input-md .k-input-prefix > .k-input-prefix-text,
.k-input-md .k-input-suffix > .k-icon,
.k-input-md .k-input-suffix > .k-input-suffix-text,
.k-picker-md .k-input-icon,
.k-picker-md .k-input-validation-icon,
.k-picker-md .k-input-loading-icon,
.k-picker-md .k-clear-value,
.k-picker-md .k-input-prefix > .k-icon,
.k-picker-md .k-input-prefix > .k-input-prefix-text,
.k-picker-md .k-input-suffix > .k-icon,
.k-picker-md .k-input-suffix > .k-input-suffix-text {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  box-sizing: content-box;
}
.k-input-md .k-input-separator-horizontal,
.k-picker-md .k-input-separator-horizontal {
  margin-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-input-md .k-input-separator-vertical,
.k-picker-md .k-input-separator-vertical {
  margin-block: var(--kendo-spacing-1, 0.25rem);
}
.k-input-md.k-icon-picker .k-input-inner,
.k-picker-md.k-icon-picker .k-input-inner {
  width: calc(var(--kendo-line-height, normal) * 1em);
  height: calc(var(--kendo-line-height, normal) * 1em);
  padding: var(--kendo-spacing-1, 0.25rem);
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-md .k-input-button {
  padding: var(--kendo-spacing-1, 0.25rem);
}

select.k-picker-md {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-input-lg,
.k-picker-lg {
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}
.k-input-lg .k-input-values,
.k-picker-lg .k-input-values {
  padding: calc(var(--kendo-spacing-2, 0.5rem) / 2);
  gap: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}
.k-input-lg .k-input-values > .k-searchbar,
.k-input-lg .k-input-values > .k-input-inner,
.k-picker-lg .k-input-values > .k-searchbar,
.k-picker-lg .k-input-values > .k-input-inner {
  margin: calc(var(--kendo-spacing-2, 0.5rem) / 2 * -1);
}
.k-input-lg .k-input-inner,
.k-picker-lg .k-input-inner {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-lg .k-input-button,
.k-input-lg .k-spinner-increase,
.k-input-lg .k-spinner-decrease,
.k-picker-lg .k-input-button,
.k-picker-lg .k-spinner-increase,
.k-picker-lg .k-spinner-decrease {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-lg .k-input-icon,
.k-input-lg .k-input-validation-icon,
.k-input-lg .k-input-loading-icon,
.k-input-lg .k-clear-value,
.k-input-lg .k-input-prefix > .k-icon,
.k-input-lg .k-input-prefix > .k-input-prefix-text,
.k-input-lg .k-input-suffix > .k-icon,
.k-input-lg .k-input-suffix > .k-input-suffix-text,
.k-picker-lg .k-input-icon,
.k-picker-lg .k-input-validation-icon,
.k-picker-lg .k-input-loading-icon,
.k-picker-lg .k-clear-value,
.k-picker-lg .k-input-prefix > .k-icon,
.k-picker-lg .k-input-prefix > .k-input-prefix-text,
.k-picker-lg .k-input-suffix > .k-icon,
.k-picker-lg .k-input-suffix > .k-input-suffix-text {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  box-sizing: content-box;
}
.k-input-lg .k-input-separator-horizontal,
.k-picker-lg .k-input-separator-horizontal {
  margin-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-input-lg .k-input-separator-vertical,
.k-picker-lg .k-input-separator-vertical {
  margin-block: var(--kendo-spacing-2, 0.5rem);
}
.k-input-lg.k-icon-picker .k-input-inner,
.k-picker-lg.k-icon-picker .k-input-inner {
  width: calc(var(--kendo-line-height-lg, normal) * 1em);
  height: calc(var(--kendo-line-height-lg, normal) * 1em);
  padding: var(--kendo-spacing-2, 0.5rem);
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-lg .k-input-button {
  padding: var(--kendo-spacing-2, 0.5rem);
}

select.k-picker-lg {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-input > kendo-popup,
.k-picker > kendo-popup {
  position: fixed;
}

@keyframes autoFillStart {}
@keyframes autoFillEnd {}
.k-autofill:-webkit-autofill {
  animation-name: autoFillStart;
}

.k-autofill:not(:-webkit-autofill) {
  animation-name: autoFillEnd;
}

.k-input:-webkit-autofill {
  animation-name: autoFillStart;
}

.k-input:not(:-webkit-autofill) {
  animation-name: autoFillEnd;
}

.k-input-solid {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-input-solid:hover, .k-input-solid.k-hover {
  border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}
.k-input-solid:focus, .k-input-solid.k-focus {
  border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-solid:focus-within {
  border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-solid.k-invalid, .k-input-solid.ng-invalid.ng-touched, .k-input-solid.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #f31700);
}
.k-input-solid.k-invalid .k-input-validation-icon, .k-input-solid.ng-invalid.ng-touched .k-input-validation-icon, .k-input-solid.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #f31700);
}
.k-input-solid .k-input-prefix {
  color: var(--kendo-color-subtle, #666666);
}
.k-input-solid .k-input-suffix {
  color: var(--kendo-color-subtle, #666666);
}
.k-input-solid .k-input-separator {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-picker-solid {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-base, #3d3d3d);
  background-color: var(--kendo-color-base, #f5f5f5);
}
.k-picker-solid:hover, .k-picker-solid.k-hover {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-picker-solid:focus, .k-picker-solid.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-solid:focus-within {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-solid.k-invalid, .k-picker-solid.ng-invalid.ng-touched, .k-picker-solid.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #f31700);
}
.k-picker-solid.k-invalid .k-input-validation-icon, .k-picker-solid.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-solid.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #f31700);
}
.k-input-outline {
  border-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 50%, transparent);
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-input-outline > .k-input-button {
  border-color: inherit;
}
.k-input-outline > .k-input-spinner {
  border-color: inherit;
}
.k-input-outline > .k-input-spinner > .k-spinner-increase, .k-input-outline > .k-input-spinner > .k-spinner-decrease {
  border-color: inherit;
}
.k-input-outline:hover, .k-input-outline.k-hover {
  border-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 80%, transparent);
}
.k-input-outline:focus, .k-input-outline.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-outline:focus-within {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-outline.k-invalid, .k-input-outline.ng-invalid.ng-touched, .k-input-outline.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #f31700);
}
.k-input-outline.k-invalid .k-input-validation-icon, .k-input-outline.ng-invalid.ng-touched .k-input-validation-icon, .k-input-outline.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #f31700);
}
.k-input-outline .k-input-prefix {
  color: var(--kendo-color-subtle, #666666);
}
.k-input-outline .k-input-suffix {
  color: var(--kendo-color-subtle, #666666);
}
.k-input-outline .k-input-separator {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-picker-outline {
  border-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 50%, transparent);
  color: var(--kendo-color-on-base, #3d3d3d);
}
.k-picker-outline:hover, .k-picker-outline.k-hover {
  border-color: var(--kendo-color-on-base, #3d3d3d);
  color: var(--kendo-color-base, #f5f5f5);
  background-color: var(--kendo-color-on-base, #3d3d3d);
}
.k-picker-outline:focus, .k-picker-outline.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-outline:focus-within {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-outline.k-invalid, .k-picker-outline.ng-invalid.ng-touched, .k-picker-outline.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #f31700);
}
.k-picker-outline.k-invalid .k-input-validation-icon, .k-picker-outline.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-outline.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #f31700);
}
.k-input-flat {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-input-flat:hover, .k-input-flat.k-hover {
  border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
}
.k-input-flat:focus, .k-input-flat.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-flat:focus-within {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-input-flat.k-invalid, .k-input-flat.ng-invalid.ng-touched, .k-input-flat.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #f31700);
}
.k-input-flat.k-invalid .k-input-validation-icon, .k-input-flat.ng-invalid.ng-touched .k-input-validation-icon, .k-input-flat.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #f31700);
}
.k-input-flat .k-input-prefix {
  color: var(--kendo-color-subtle, #666666);
}
.k-input-flat .k-input-suffix {
  color: var(--kendo-color-subtle, #666666);
}
.k-input-flat .k-input-separator {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-picker-flat {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-base, #3d3d3d);
}
.k-picker-flat:hover, .k-picker-flat.k-hover {
  border-color: var(--kendo-color-border-alt, rgba(0, 0, 0, 0.16));
  background-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 4%, transparent);
}
.k-picker-flat:focus, .k-picker-flat.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-flat:focus-within {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-picker-flat.k-invalid, .k-picker-flat.ng-invalid.ng-touched, .k-picker-flat.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #f31700);
}
.k-picker-flat.k-invalid .k-input-validation-icon, .k-picker-flat.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-flat.ng-invalid.ng-dirty .k-input-validation-icon {
  color: var(--kendo-color-error, #f31700);
}
.k-floating-label-container {
  padding-top: calc(var(--kendo-line-height, normal) * var(--kendo-font-size, inherit));
  box-sizing: border-box;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  flex-direction: column;
  justify-content: stretch;
}
.k-floating-label-container *,
.k-floating-label-container *::before,
.k-floating-label-container *::after {
  box-sizing: border-box;
}
.k-floating-label-container > .k-floating-label {
  max-width: 90%;
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: var(--kendo-floating-label-offset-y, calc(calc(var(--kendo-line-height, normal) * var(--kendo-font-size, inherit)) + 1px + var(--kendo-spacing-1, 0.25rem)));
  left: var(--kendo-floating-label-offset-x, calc(var(--kendo-spacing-2, 0.5rem) + 1px));
  overflow: hidden;
  cursor: text;
  transform-origin: left center;
  transition: transform 0.2s ease-out, color 0.2s ease-out, top 0.2s ease-out, left 0.2s ease-out;
}
.k-floating-label-container.k-empty > .k-floating-label {
  top: var(--kendo-floating-label-offset-y, calc(calc(var(--kendo-line-height, normal) * var(--kendo-font-size, inherit)) + 1px + var(--kendo-spacing-1, 0.25rem)));
  left: var(--kendo-floating-label-offset-x, calc(var(--kendo-spacing-2, 0.5rem) + 1px));
  transform: scale(1);
  pointer-events: none;
}
.k-floating-label-container > .k-floating-label, .k-floating-label-container.k-focus > .k-floating-label {
  top: var(--kendo-floating-label-focus-offset-y, 0);
  left: var(--kendo-floating-label-focus-offset-x, 0);
  transform: scale(1);
}
.k-floating-label-container:focus-within > .k-floating-label {
  top: var(--kendo-floating-label-focus-offset-y, 0);
  left: var(--kendo-floating-label-focus-offset-x, 0);
  transform: scale(1);
}
.k-floating-label-container.k-empty:not(.k-focus):not(:focus-within) ::placeholder {
  color: transparent;
}
[dir=rtl] .k-floating-label-container > .k-floating-label, .k-rtl .k-floating-label-container > .k-floating-label, .k-floating-label-container[dir=rtl] > .k-floating-label {
  transform-origin: right center;
  transition: transform 0.2s ease-out, color 0.2s ease-out, top 0.2s ease-out, right 0.2s ease-out;
}
[dir=rtl] .k-floating-label-container.k-empty > .k-floating-label, .k-rtl .k-floating-label-container.k-empty > .k-floating-label, .k-floating-label-container[dir=rtl].k-empty > .k-floating-label {
  left: auto;
  right: var(--kendo-floating-label-offset-x, calc(var(--kendo-spacing-2, 0.5rem) + 1px));
}
[dir=rtl] .k-floating-label-container > .k-floating-label, [dir=rtl] .k-floating-label-container.k-focus > .k-floating-label, .k-rtl .k-floating-label-container > .k-floating-label, .k-rtl .k-floating-label-container.k-focus > .k-floating-label, .k-floating-label-container[dir=rtl] > .k-floating-label, .k-floating-label-container[dir=rtl].k-focus > .k-floating-label {
  left: auto;
  right: var(--kendo-floating-label-focus-offset-x, 0);
}
[dir=rtl] .k-floating-label-container:focus-within > .k-floating-label, .k-rtl .k-floating-label-container:focus-within > .k-floating-label, .k-floating-label-container[dir=rtl]:focus-within > .k-floating-label {
  left: auto;
  right: var(--kendo-floating-label-focus-offset-x, 0);
}

.k-floating-label-container.k-invalid > .k-floating-label, .k-floating-label-container.ng-invalid.ng-touched > .k-floating-label, .k-floating-label-container.ng-invalid.ng-dirty > .k-floating-label {
  color: var(--kendo-color-error, #f31700);
}

.k-validator-tooltip {
  margin-top: calc(6px + 0px);
  width: auto;
  white-space: normal;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.k-grid .k-validator-tooltip {
  max-width: 300px;
}

.k-calendar {
  inline-size: var(--INTERNAL--kendo-calendar-width, min-content);
  block-size: var(--INTERNAL--kendo-calendar-height, min-content);
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  position: relative;
  overflow: hidden;
  display: inline-flex;
  flex-flow: column nowrap;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-calendar > .k-calendar {
  border: 0;
}

.k-calendar-table {
  margin-block: 0;
  margin-inline: auto;
  border-width: 0;
  border-color: inherit;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  text-align: center;
  outline: 0;
  display: table;
  position: relative;
  z-index: 1;
}

.k-calendar-caption,
.k-calendar-caption.k-calendar-th,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  height: 32px;
  box-sizing: border-box;
  text-transform: none;
  text-align: start;
  font-weight: var(--kendo-font-weight-bold, normal);
  cursor: default;
}

.k-calendar-th,
.k-calendar-td {
  border-width: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  border-color: inherit;
  font-weight: normal;
  cursor: default;
}

.k-calendar-th {
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: 2;
  text-transform: uppercase;
}

.k-calendar-td {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
  border-color: transparent;
}

.k-calendar-td.k-alt {
  font-size: 0.75rem;
}

.k-calendar .k-calendar-cell-inner, .k-calendar .k-link {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  padding-inline: var(--INTERNAL--kendo-calendar-cell-padding-x, 0.25em);
  padding-block: var(--INTERNAL--kendo-calendar-cell-padding-y, 0.25em);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: normal;
  position: relative;
  overflow: hidden;
}

.k-calendar .k-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  z-index: 2;
}

.k-calendar-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  min-width: 256px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}
.k-calendar-header .k-calendar-nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.k-calendar-footer,
.k-calendar .k-footer {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  text-align: center;
  clear: both;
}

.k-calendar-view {
  margin: auto;
  padding-block: 0;
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  width: 256px;
  inline-size: var(--INTERNAL--kendo-calendar-view-width, 256px);
  min-height: 224px;
  box-sizing: content-box;
  gap: var(--kendo-spacing-4, 1rem);
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.k-calendar-view .k-today {
  font-weight: bold;
}

.k-month-calendar .k-calendar-td,
.k-calendar-monthview .k-calendar-td {
  width: 32px;
  height: 32px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
}

.k-year-calendar .k-calendar-td,
.k-calendar-yearview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-decade-calendar .k-calendar-td,
.k-calendar-decadeview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-century-calendar .k-calendar-td,
.k-calendar-centuryview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}
.k-century-calendar .k-calendar-cell-inner, .k-century-calendar .k-link,
.k-calendar-centuryview .k-calendar-cell-inner,
.k-calendar-centuryview .k-link {
  text-align: start;
}

.k-popup .k-calendar {
  height: 100%;
}

.k-calendar-container,
.k-datetime-container {
  padding: 0;
}
.k-calendar-container .k-calendar,
.k-datetime-container .k-calendar {
  border-width: 0;
}

.k-calendar .k-content.k-scrollable,
.k-calendar .k-calendar-content.k-scrollable {
  box-sizing: content-box;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  padding-right: 100px;
  padding-left: 100px;
  margin-left: -100px;
  margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}

.k-calendar .k-scrollable-placeholder {
  position: absolute;
  z-index: -1;
  width: 1px;
  top: 0;
  right: 0;
}

.k-calendar-sm {
  --INTERNAL--kendo-calendar-view-width: 224px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-sm-cell-padding-x, var(--kendo-spacing-0\.5, 0.125rem) );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-sm-cell-padding-y, var(--kendo-spacing-0\.5, 0.125rem) );
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-calendar-sm.k-month-calendar,
.k-calendar-sm .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-month-cell-size, 28px );
}
.k-calendar-sm.k-year-calendar,
.k-calendar-sm .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-year-cell-size, 56px );
}
.k-calendar-sm.k-decade-calendar,
.k-calendar-sm .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-decade-cell-size, 56px );
}
.k-calendar-sm.k-century-calendar,
.k-calendar-sm .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-century-cell-size, 56px );
}

.k-calendar-md {
  --INTERNAL--kendo-calendar-view-width: 256px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-md-cell-padding-x, var(--kendo-spacing-1, 0.25rem) );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-md-cell-padding-y, var(--kendo-spacing-1, 0.25rem) );
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-calendar-md.k-month-calendar,
.k-calendar-md .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-month-cell-size, 32px );
}
.k-calendar-md.k-year-calendar,
.k-calendar-md .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-year-cell-size, 64px );
}
.k-calendar-md.k-decade-calendar,
.k-calendar-md .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-decade-cell-size, 64px );
}
.k-calendar-md.k-century-calendar,
.k-calendar-md .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-century-cell-size, 64px );
}

.k-calendar-lg {
  --INTERNAL--kendo-calendar-view-width: 320px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-lg-cell-padding-x, var(--kendo-spacing-2, 0.5rem) );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-lg-cell-padding-y, var(--kendo-spacing-2, 0.5rem) );
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}
.k-calendar-lg.k-month-calendar,
.k-calendar-lg .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-month-cell-size, 40px );
}
.k-calendar-lg.k-year-calendar,
.k-calendar-lg .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-year-cell-size, 80px );
}
.k-calendar-lg.k-decade-calendar,
.k-calendar-lg .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-decade-cell-size, 80px );
}
.k-calendar-lg.k-century-calendar,
.k-calendar-lg .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-century-cell-size, 80px );
}

.k-calendar-infinite {
  box-sizing: content-box;
  display: inline-flex;
  flex-flow: row nowrap;
}
.k-calendar-infinite .k-calendar-view {
  padding-block: var(--kendo-spacing-0, 0px);
  padding-inline: var(--kendo-spacing-4, 1rem);
  height: 288px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  overflow: hidden;
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  position: relative;
}
.k-calendar-infinite .k-calendar-view::after {
  display: block;
  position: absolute;
  bottom: 0;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 150%;
  left: -25%;
  box-shadow: 0 0 32px 16px var(--kendo-color-surface-alt, #ffffff);
}
.k-calendar-infinite .k-calendar-header {
  margin-left: calc(var(--kendo-spacing-4, 1rem) * -1);
  margin-right: calc(var(--kendo-spacing-4, 1rem) * -1);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  width: auto;
  min-width: 0;
}
.k-calendar-infinite .k-calendar-weekdays {
  flex: 0 0 auto;
}

.k-calendar-navigation {
  width: 5em;
  text-align: center;
  flex: 0 0 auto;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.k-calendar-navigation::before, .k-calendar-navigation::after {
  display: block;
  position: absolute;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 200%;
  left: -50%;
  box-shadow: 0 0 6em 3em var(--kendo-color-surface, #fafafa);
}
.k-calendar-navigation::before {
  top: 0;
}
.k-calendar-navigation::after {
  bottom: 0;
}
.k-calendar-navigation .k-content,
.k-calendar-navigation .k-calendar-content {
  background: transparent;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.k-calendar-navigation .k-content ul,
.k-calendar-navigation .k-calendar-content ul {
  width: 5em;
}
.k-calendar-navigation .k-content li,
.k-calendar-navigation .k-calendar-content li {
  height: 2em;
  line-height: 2em;
  cursor: pointer;
  padding-block: 0;
  padding-inline: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}
.k-calendar-navigation .k-calendar-navigation-marker {
  font-weight: bold;
}
.k-calendar-navigation .k-calendar-navigation-highlight {
  width: 100%;
  border-width: 1px 0;
  border-style: solid;
  height: 2em;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-calendar-range {
  width: auto;
}
.k-calendar-range .k-calendar-view {
  width: auto;
  white-space: nowrap;
}
.k-calendar-range .k-calendar-view::after {
  display: none;
}
.k-calendar-range .k-calendar-view:focus {
  outline: 0;
}

.k-range-start {
  border-radius: var(--kendo-border-radius-lg, 0.375rem) 0 0 var(--kendo-border-radius-lg, 0.375rem);
}
.k-range-start .k-calendar-cell-inner,
.k-range-start .k-link {
  border-color: inherit;
  border-radius: inherit;
}

.k-range-end {
  border-radius: 0 var(--kendo-border-radius-lg, 0.375rem) var(--kendo-border-radius-lg, 0.375rem) 0;
}
.k-range-end .k-calendar-cell-inner,
.k-range-end .k-link {
  border-color: inherit;
  border-radius: inherit;
}

.k-range-mid {
  border-color: inherit;
  border-radius: 0;
}

.k-range-start.k-range-end {
  border-radius: var(--kendo-border-radius-lg, 0.375rem);
}

.k-rtl .k-calendar .k-content.k-scrollable,
.k-rtl .k-calendar .k-calendar-content.k-scrollable,
[dir=rtl] .k-calendar .k-content.k-scrollable,
[dir=rtl] .k-calendar .k-calendar-content.k-scrollable,
.k-calendar.k-rtl .k-content.k-scrollable,
.k-calendar.k-rtl .k-calendar-content.k-scrollable,
.k-calendar[dir=rtl] .k-content.k-scrollable,
.k-calendar[dir=rtl] .k-calendar-content.k-scrollable {
  padding-right: 100px;
  padding-left: 100px;
  margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
  margin-right: -100px;
}
.k-rtl .k-calendar .k-range-start,
[dir=rtl] .k-calendar .k-range-start,
.k-calendar.k-rtl .k-range-start,
.k-calendar[dir=rtl] .k-range-start {
  border-radius: 0 var(--kendo-border-radius-lg, 0.375rem) var(--kendo-border-radius-lg, 0.375rem) 0;
}
.k-rtl .k-calendar .k-range-end,
[dir=rtl] .k-calendar .k-range-end,
.k-calendar.k-rtl .k-range-end,
.k-calendar[dir=rtl] .k-range-end {
  border-radius: var(--kendo-border-radius-lg, 0.375rem) 0 0 var(--kendo-border-radius-lg, 0.375rem);
}

.k-calendar {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-calendar .k-header {
  border-color: inherit;
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.k-calendar .k-calendar-th,
.k-calendar .k-calendar-caption,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
  color: var(--kendo-color-subtle, #666666);
}
.k-calendar .k-calendar-view .k-today {
  color: var(--kendo-color-primary, #ff6358);
}
.k-calendar .k-other-month {
  color: var(--kendo-color-subtle, #666666);
}
.k-calendar .k-alt {
  background-color: transparent;
  color: var(--kendo-color-subtle, #666666);
}
.k-calendar .k-calendar-td:hover .k-calendar-cell-inner, .k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected .k-link {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-calendar .k-calendar-td:focus .k-calendar-cell-inner, .k-calendar .k-calendar-td:focus .k-link,
.k-calendar .k-calendar-td.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-focus .k-link {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-td.k-selected:focus .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected:focus .k-link,
.k-calendar .k-calendar-td.k-selected.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-focus .k-link {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-navigation {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
  box-shadow: inset -1px 0 var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-hover {
  color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-calendar .k-calendar-navigation-highlight {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-calendar[dir=rtl] .k-calendar-navigation, .k-rtl .k-calendar .k-calendar-navigation {
  box-shadow: inset 1px 0 var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-calendar.k-invalid, .k-calendar.k-invalid:hover, .k-calendar.ng-invalid.ng-touched, .k-calendar.ng-invalid.ng-dirty {
  border-color: var(--kendo-color-error, #f31700);
}

.k-scheduler .k-calendar .k-other-month {
  color: var(--kendo-color-subtle, #666666);
  background-color: transparent;
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent);
}
.k-calendar .k-range-start .k-calendar-cell-inner, .k-calendar .k-range-start .k-link,
.k-calendar .k-range-end .k-calendar-cell-inner,
.k-calendar .k-range-end .k-link {
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-calendar .k-range-start.k-active .k-calendar-cell-inner, .k-calendar .k-range-start.k-active .k-link,
.k-calendar .k-range-end.k-active .k-calendar-cell-inner,
.k-calendar .k-range-end.k-active .k-link {
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.k-calendar .k-range-split-start,
.k-calendar .k-range-split-end {
  position: relative;
}
.k-calendar .k-range-split-start::after,
.k-calendar .k-range-split-end::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
}
.k-calendar .k-range-split-start::after {
  left: -5px;
  right: auto;
  background-image: linear-gradient(to left, color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent), transparent 100%);
}
.k-calendar .k-range-split-end::after {
  right: -5px;
  left: auto;
  background-image: linear-gradient(to right, color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent), transparent 100%);
}

.k-timeselector {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-popup > .k-timeselector {
  border-width: 0;
}

.k-time-header,
.k-time-selector-header {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}
.k-time-header .k-title,
.k-time-header .k-time-selector-header-title,
.k-time-selector-header .k-title,
.k-time-selector-header .k-time-selector-header-title {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  font-weight: bold;
  display: inline-block;
}
.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  border-width: 0;
  line-height: inherit;
  cursor: pointer;
}

.k-actions.k-time-fast-selection, .k-time-fast-selection.k-form-buttons, .k-time-fast-selection.k-edit-buttons,
.k-time-fast-selection.k-action-buttons,
.k-time-fast-selection.k-columnmenu-actions {
  margin-top: 0;
}

.k-time-list-container {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
}

.k-time-highlight,
.k-time-list-highlight {
  width: 100%;
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
  border-width: 1px 0px;
  border-style: solid;
  box-sizing: border-box;
  position: absolute;
  top: calc(50% + calc((var(--kendo-font-size-sm, inherit)) * (var(--kendo-line-height-lg, normal)))/2);
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.k-time-list-wrapper {
  min-width: 4em;
  height: 240px;
  box-sizing: content-box;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  text-align: center;
  flex: 1 1 auto;
}
.k-time-list-wrapper .k-title {
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: var(--kendo-line-height-lg, normal);
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  display: block;
}
.k-time-list-wrapper.k-focus::before, .k-time-list-wrapper.k-focus::after {
  display: block;
  content: "​";
  position: absolute;
  width: 100%;
  left: 0;
  pointer-events: none;
  height: calc(50% - calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2)/2);
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}
.k-time-list-wrapper.k-focus::before {
  top: calc((var(--kendo-font-size-sm, inherit)) * (var(--kendo-line-height-lg, normal)));
}
.k-time-list-wrapper.k-focus::after {
  bottom: 0;
}

.k-time-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  flex: 1;
  position: relative;
  z-index: 1;
  outline: 0;
  overflow: hidden;
}
.k-time-list::before, .k-time-list::after {
  display: block;
  position: absolute;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 200%;
  left: -50%;
}
.k-time-list::before {
  top: 0;
}
.k-time-list::after {
  bottom: 0;
}

.k-time-container,
.k-time-list-content {
  position: relative;
  flex: 1 1 auto;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 100px;
  padding-left: 100px;
  margin-left: -100px;
  margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}
.k-time-container > ul,
.k-time-list-content > ul {
  height: auto;
  width: 4em;
  margin: auto;
}
.k-time-container .k-scrollable-placeholder,
.k-time-list-content .k-scrollable-placeholder {
  position: absolute;
  width: 1px;
  top: 0;
  right: 0;
}

.k-time-list-item,
.k-time-list .k-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-time-separator {
  width: 0;
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
  align-self: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 11;
  top: calc(calc((var(--kendo-font-size-sm, inherit)) * (var(--kendo-line-height-lg, normal)))/2);
}

.k-timeselector-sm {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-timeselector-sm .k-time-highlight,
.k-timeselector-sm .k-time-list-highlight {
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-0\.5, 0.125rem) * 2);
}
.k-timeselector-sm .k-time-separator {
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-0\.5, 0.125rem) * 2);
}
.k-timeselector-sm .k-time-list-item,
.k-timeselector-sm .k-time-list .k-item {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-timeselector-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-timeselector-md .k-time-highlight,
.k-timeselector-md .k-time-list-highlight {
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-timeselector-md .k-time-separator {
  height: calc(var(--kendo-font-size, inherit) * var(--kendo-line-height, normal) + var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-timeselector-md .k-time-list-item,
.k-timeselector-md .k-time-list .k-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-timeselector-lg {
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}
.k-timeselector-lg .k-time-highlight,
.k-timeselector-lg .k-time-list-highlight {
  height: calc(var(--kendo-font-size-lg, inherit) * var(--kendo-line-height-lg, normal) + var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-timeselector-lg .k-time-separator {
  height: calc(var(--kendo-font-size-lg, inherit) * var(--kendo-line-height-lg, normal) + var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-timeselector-lg .k-time-list-item,
.k-timeselector-lg .k-time-list .k-item {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-rtl .k-time-container,
.k-rtl .k-time-list-content,
[dir=rtl] .k-time-container,
[dir=rtl] .k-time-list-content {
  padding-right: 100px;
  padding-left: 100px;
  margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
  margin-right: -100px;
}

.k-timeselector {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  color: var(--kendo-color-primary, #ff6358);
}
.k-time-header .k-time-now:hover,
.k-time-selector-header .k-time-now:hover {
  color: var(--kendo-color-primary-hover, #ea5a51);
}

.k-time-list-wrapper .k-title {
  color: var(--kendo-color-subtle, #666666);
}
.k-time-list-wrapper.k-focus .k-title {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-time-list-wrapper.k-focus::before, .k-time-list-wrapper.k-focus::after {
  background-color: rgba(0, 0, 0, 0.04);
}

.k-time-list::before, .k-time-list::after {
  box-shadow: 0 0 3em 1.5em var(--kendo-color-surface-alt, #ffffff);
}
.k-time-list .k-item:hover {
  color: var(--kendo-color-primary, #ff6358);
}

.k-time-container {
  background: transparent;
}

.k-time-highlight {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-datetime-selector {
  display: flex;
  transition: transform 0.2s;
}

.k-datetime-wrap {
  width: calc(32px * 8 + var(--kendo-spacing-4, 1rem) * 2);
  overflow: hidden;
}
.k-datetime-wrap .k-datetime-buttongroup {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-datetime-wrap .k-calendar,
.k-datetime-wrap .k-timeselector {
  width: 100%;
  border-width: 0;
}

.k-datetime-calendar-wrap,
.k-datetime-time-wrap {
  text-align: center;
  flex: 0 0 calc(32px * 8 + var(--kendo-spacing-4, 1rem) * 2);
}

.k-date-tab .k-datetime-selector {
  transform: translateX(0);
}

.k-time-tab .k-datetime-selector {
  transform: translateX(-100%);
}

.k-time-tab .k-datetime-selector.k-rtl, .k-time-tab .k-datetime-selector[dir=rtl], .k-rtl .k-time-tab .k-datetime-selector, [dir=rtl] .k-time-tab .k-datetime-selector {
  transform: translateX(100%);
}

.k-dropdown-operator {
  width: min-content;
  flex: none;
}
.k-dropdown-operator .k-input-button {
  border-width: 0;
  width: min-content;
}
.k-dropdown-operator .k-input-button .k-button-icon {
  width: auto;
  aspect-ratio: 1;
}
.k-dropdown-operator .k-input-inner {
  display: none;
}

.k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}

.k-overlay {
  background-color: #000000;
}

.k-overlay-primary {
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-overlay-secondary {
  background-color: var(--kendo-color-secondary, #666666);
}

.k-overlay-tertiary {
  background-color: var(--kendo-color-tertiary, #03a9f4);
}

.k-overlay-info {
  background-color: var(--kendo-color-info, #0058e9);
}

.k-overlay-success {
  background-color: var(--kendo-color-success, #37b400);
}

.k-overlay-warning {
  background-color: var(--kendo-color-warning, #ffc000);
}

.k-overlay-error {
  background-color: var(--kendo-color-error, #f31700);
}

.k-overlay-dark {
  background-color: var(--kendo-color-dark, #3d3d3d);
}

.k-overlay-light {
  background-color: var(--kendo-color-light, #ebebeb);
}

.k-overlay-inverse {
  background-color: var(--kendo-color-dark, #3d3d3d);
}

.k-form,
.k-form-inline {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-fieldset {
  margin: var(--kendo-spacing-7\.5, 1.875rem);
  border-width: 1px 0 0;
  border-style: solid;
  padding: 25px 0 0;
}
.k-fieldset > legend {
  margin-left: 0;
  padding: 0 var(--kendo-spacing-2, 0.5rem) 0 0;
  text-transform: uppercase;
}

kendo-label > .k-label {
  margin: 0;
}

.k-label-optional {
  margin-left: var(--kendo-spacing-1\.5, 0.375rem);
  font-size: var(--kendo-font-size-sm, inherit);
  font-style: italic;
  align-self: center;
}

.k-label-empty::before {
  content: "";
  display: inline-block;
}

.k-form {
  border: 0;
}
.k-form .k-form-field > .k-label,
.k-form .k-form-field > kendo-label,
.k-form .k-form-label {
  margin-bottom: var(--kendo-spacing-0, 0px);
  display: flex;
  flex-flow: row nowrap;
}
.k-form .k-label + .k-radio {
  margin-left: 0;
}
.k-form .k-form-fieldset {
  margin: 2em 0 0;
  padding: var(--kendo-spacing-0, 0px);
  border: 0;
}
.k-form .k-form-fieldset > *:not(.k-hidden):first-child, .k-form .k-form-fieldset > *.k-hidden + :not(.k-hidden) {
  margin-top: 0;
}
.k-form .k-form-legend {
  margin: 0 0 1em;
  padding: var(--kendo-spacing-0, 0px);
  border-width: 0 0 2px;
  border-style: solid;
  width: 100%;
  font-size: var(--kendo-font-size, inherit);
  text-transform: uppercase;
}
.k-form .k-form-group {
  padding: 0;
}
.k-form > *:not(.k-hidden):first-child, .k-form > *.k-hidden + :not(.k-hidden) {
  margin-top: 0;
}
.k-form .k-form-field,
.k-form .k-form-field-wrap {
  display: block;
}
.k-form .k-form-field-disabled .k-label,
.k-form .k-form-field-disabled .k-form-label {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
.k-form .k-alert-error {
  font-size: var(--kendo-font-size-sm, inherit);
  margin-top: calc(var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-form .k-field-info {
  display: inline-block;
  font-size: var(--kendo-font-size-xs, inherit);
  line-height: 1;
  margin-block: 0;
  margin-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-form .k-multiselect,
.k-form .k-floating-label-container,
.k-form .k-daterangepicker .k-dateinput,
.k-form .k-signature {
  display: inline-flex;
  width: 100%;
}

.k-form-buttons {
  padding: 0;
  overflow: visible;
}

.k-form-hint,
.k-form-error {
  margin-top: var(--kendo-spacing-1, 0.25rem);
  display: flex;
  font-size: var(--kendo-font-size-sm, inherit);
  font-style: italic;
}

.k-text-start,
.k-buttons-start {
  justify-content: flex-start;
}

.k-text-end,
.k-buttons-end {
  justify-content: flex-end;
}

.k-form-separator {
  margin: var(--kendo-spacing-3\.5, 0.875rem) 0 0;
  border-width: 1px 0 0;
  border-style: solid;
  display: block;
  flex: 0 0 auto;
}

.k-form-horizontal .k-form-field {
  display: flex;
}
.k-form-horizontal .k-form-field > .k-label,
.k-form-horizontal .k-form-field > kendo-label,
.k-form-horizontal .k-form-field > .k-form-label {
  margin-right: var(--kendo-spacing-2\.5, 0.625rem);
  padding-top: calc(var(--kendo-spacing-2\.5, 0.625rem) / 2);
  width: 25%;
  text-align: end;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.k-form-horizontal .k-form-field .k-label-optional {
  margin: 0;
  align-self: inherit;
}
.k-form-horizontal .k-form-field-wrap {
  max-width: calc(100% - 25% - var(--kendo-spacing-2\.5, 0.625rem));
  flex: 1 1 auto;
}

.k-form [dir=rtl] .k-label-optional, .k-rtl .k-form .k-label-optional {
  margin-left: 0;
  margin-right: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-form-horizontal [dir=rtl].k-form-field > .k-label,
.k-form-horizontal [dir=rtl].k-form-field > kendo-label,
.k-form-horizontal [dir=rtl].k-form-field > .k-form-label,
.k-form-horizontal [dir=rtl] .k-form-field > .k-label,
.k-form-horizontal [dir=rtl] .k-form-field > kendo-label,
.k-form-horizontal [dir=rtl] .k-form-field > .k-form-label, .k-rtl .k-form-horizontal.k-form-field > .k-label,
.k-rtl .k-form-horizontal.k-form-field > kendo-label,
.k-rtl .k-form-horizontal.k-form-field > .k-form-label,
.k-rtl .k-form-horizontal .k-form-field > .k-label,
.k-rtl .k-form-horizontal .k-form-field > kendo-label,
.k-rtl .k-form-horizontal .k-form-field > .k-form-label {
  margin-right: 0;
  margin-left: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-form-sm .k-form-field,
.k-form-sm .k-form-buttons {
  margin-top: var(--kendo-spacing-2\.5, 0.625rem);
}

.k-form-md .k-form-field,
.k-form-md .k-form-buttons {
  margin-top: var(--kendo-spacing-3\.5, 0.875rem);
}

.k-form-lg .k-form-field,
.k-form-lg .k-form-buttons {
  margin-top: var(--kendo-spacing-4\.5, 1.125rem);
}

.k-form-inline {
  padding: calc(var(--kendo-spacing-2, 0.5rem) * 2);
}
.k-form-inline fieldset {
  border-width: 1px 0 0;
  border-style: solid;
  margin: calc(calc(var(--kendo-spacing-2, 0.5rem) * 2) * 2) 0;
  padding: 0;
}
.k-form-inline fieldset:first-child:first-of-type {
  margin-top: 0;
}
.k-form-inline fieldset:last-child:last-of-type {
  margin-bottom: 0;
}
.k-form-inline legend {
  font-size: var(--kendo-font-size-sm, inherit);
  text-align: start;
  font-weight: 600;
  line-height: 1;
  margin-bottom: calc(var(--kendo-spacing-1\.5, 0.375rem) * 2);
  text-transform: uppercase;
  padding: 0 var(--kendo-spacing-2, 0.5rem) 0 0;
  width: auto;
}
.k-form-inline .k-form-field {
  display: flex;
  align-items: flex-start;
  text-align: start;
  margin-bottom: calc(var(--kendo-spacing-1\.5, 0.375rem) * 2);
}
.k-form-inline .k-form-field > span:not(.k-widget) {
  width: 25%;
  text-align: end;
  line-height: var(--kendo-line-height, normal);
  padding: calc(var(--kendo-spacing-1, 0.25rem) + 1px) 0;
  padding-right: var(--kendo-spacing-3, 0.75rem);
  align-self: center;
}
.k-form-inline .k-form-field > input {
  align-self: center;
}
.k-form-inline .k-form-field > input:not(.k-checkbox):not(.k-radio) {
  flex: 1 1 auto;
}
.k-form-inline .k-form-field .k-alert-error {
  font-size: var(--kendo-font-size-sm, inherit);
  margin-top: calc(var(--kendo-spacing-1, 0.25rem) * 2);
}
.k-form-inline .k-form-field .k-field-info {
  display: block;
  font-size: var(--kendo-font-size-xs, inherit);
  line-height: 1;
  margin: 0;
}
.k-form-inline .k-form-field:last-child {
  margin-bottom: 0;
}

.k-edit-form-container {
  width: 400px;
  min-width: 400px;
  border-color: inherit;
  position: relative;
}

.k-popup-edit-form > .k-actions, .k-popup-edit-form > .k-edit-buttons,
.k-popup-edit-form > .k-action-buttons,
.k-popup-edit-form > .k-columnmenu-actions, .k-popup-edit-form > .k-form-buttons,
.k-edit-form-container .k-actions,
.k-edit-form-container .k-edit-buttons,
.k-edit-form-container .k-action-buttons,
.k-edit-form-container .k-columnmenu-actions,
.k-edit-form-container .k-form-buttons {
  margin: var(--kendo-spacing-4, 1rem) -var(--kendo-spacing-4, 1rem) -var(--kendo-spacing-4, 1rem);
}

.k-edit-label {
  margin: 0 0 var(--kendo-spacing-4, 1rem) 0;
  padding: calc(var(--kendo-spacing-1, 0.25rem) + 1px) 0;
  width: 30%;
  line-height: var(--kendo-line-height, normal);
  text-align: end;
  float: left;
  clear: both;
}

.k-edit-field {
  margin: 0 0 var(--kendo-spacing-4, 1rem) 0;
  width: 65%;
  float: right;
  clear: right;
  position: relative;
}
.k-edit-field.k-no-editor {
  padding: calc(var(--kendo-spacing-1, 0.25rem) + 1px) 0;
}

.k-edit-field > .k-widget {
  width: 100%;
  box-sizing: border-box;
}
.k-edit-field input[type=radio]:not(.k-radio),
.k-edit-field input[type=checkbox]:not(.k-checkbox) {
  margin-right: 0.4ex;
}
.k-edit-field .k-radio-label,
.k-edit-field .k-checkbox-label {
  margin-right: var(--kendo-spacing-4, 1rem);
}
.k-edit-field > .k-reset > li + li {
  margin-top: var(--kendo-spacing-2, 0.5rem);
}
.k-edit-field .k-reset .k-widget {
  margin: 0 0.4ex 0 1ex;
}

.k-form,
.k-form-inline {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-form fieldset legend,
.k-form-inline fieldset legend {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-form .k-form-legend,
.k-form-inline .k-form-legend {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-form .k-field-info,
.k-form-inline .k-field-info {
  color: var(--kendo-color-subtle, #666666);
}
.k-form .k-alert-error,
.k-form-inline .k-alert-error {
  color: var(--kendo-color-error, #f31700);
}

.k-form-error,
.k-text-error,
.k-form-field-error .k-label {
  color: var(--kendo-color-error, #f31700);
}

.k-form-separator {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-window {
  border-radius: 0px;
  padding: 0;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height-sm, normal);
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  z-index: 10002;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-window.k-window-maximized {
  max-width: 100vw;
  max-height: 100vh;
  box-shadow: none;
}

.k-window-sm {
  width: 300px;
}

.k-window-md {
  width: 800px;
}

.k-window-lg {
  width: 1200px;
}

.k-window-titlebar {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding-block: var(--kendo-spacing-3, 0.75rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  border-width: 0 0 1px;
  border-style: solid;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
}
.k-window-minimized .k-window-titlebar {
  border-width: 0;
}

.k-window-title {
  padding-block: 0.5em;
  padding-inline: 0;
  margin-block: -0.5em;
  margin-inline: 0;
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-sm, normal);
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
}
.k-window-title::before {
  content: "​";
}

.k-window-titlebar-actions {
  margin-block: -5em;
  margin-inline: 0;
  margin-inline-end: calc(var(--kendo-spacing-3, 0.75rem) - var(--kendo-spacing-4, 1rem));
  line-height: 1;
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  vertical-align: top;
}

.k-window-titlebar-action {
  flex-shrink: 0;
}

.k-window-content,
.k-prompt-container {
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.k-window-content + .k-prompt-container {
  margin-top: -var(--kendo-spacing-4, 1rem)/2;
}

.k-window-content:first-child {
  padding-top: clamp(var(--kendo-spacing-4, 1rem), var(--kendo-spacing-3, 0.75rem), var(--kendo-spacing-3, 0.75rem));
}

.k-window-content:last-child {
  padding-bottom: clamp(var(--kendo-spacing-4, 1rem), var(--kendo-spacing-3, 0.75rem), var(--kendo-spacing-3, 0.75rem));
}

.k-window-iframecontent {
  padding: 0;
  overflow: visible;
}
.k-window-iframecontent .k-content-frame {
  vertical-align: top;
  border: 0;
  width: 100%;
  height: 100%;
}

.k-window-actions {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  overflow: hidden;
}

.k-prompt-container > .k-textarea {
  width: 100%;
}

.k-window .k-resize-n {
  top: 0;
}
.k-window .k-resize-e {
  right: 0;
}
.k-window .k-resize-s {
  bottom: 0;
}
.k-window .k-resize-w {
  left: 0;
}

.k-window {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
  box-shadow: var(--kendo-elevation-8, 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-window:focus,
.k-window.k-focus {
  box-shadow: var(--kendo-elevation-9, 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-window-titlebar {
  border-color: inherit;
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-window-primary .k-window-titlebar {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-window-light .k-window-titlebar {
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #ebebeb);
}

.k-window-dark .k-window-titlebar {
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}

.k-dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
}
.k-dialog-wrapper .k-dialog {
  position: relative;
}

.k-dialog {
  padding: 0;
  position: fixed;
  box-sizing: border-box;
}
.k-dialog.k-dialog-centered {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.k-dialog .k-multiselect {
  width: 100%;
}

.k-dialog-actions {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: var(--kendo-spacing-2, 0.5rem);
  overflow: hidden;
}

.k-dialog-titlebar {
  border-color: inherit;
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-dialog-primary .k-dialog-titlebar {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-dialog-light .k-dialog-titlebar {
  color: var(--kendo-color-on-light, #000000);
  background-color: var(--kendo-color-light, #ebebeb);
}

.k-dialog-dark .k-dialog-titlebar {
  color: var(--kendo-color-on-dark, #ffffff);
  background-color: var(--kendo-color-dark, #3d3d3d);
}

.k-pager {
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: default;
  flex: 0 0 auto;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pager *,
.k-pager *::before,
.k-pager *::after {
  box-sizing: border-box;
}

.k-pager-info, .k-pager-sizes, .k-pager-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.k-pager-nav {
  color: inherit;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.k-pager-nav:hover {
  z-index: 2;
}
.k-disabled.k-pager-nav {
  color: inherit;
}

.k-pager-numbers-wrap {
  display: flex;
  flex-direction: row;
  position: relative;
}
.k-pager-numbers-wrap select.k-dropdown-list, .k-pager-numbers-wrap select.k-dropdown,
.k-pager-numbers-wrap select.k-dropdownlist {
  width: 5em;
}

.k-pager-numbers {
  display: flex;
  flex-direction: row;
}
.k-pager-numbers .k-selected {
  cursor: inherit;
  z-index: 2;
}

.k-pager-input {
  gap: 1ex;
}
.k-pager-input .k-textbox,
.k-pager-input .k-numerictextbox {
  margin-block: 0;
  width: 5em;
}

.k-pager-sizes {
  gap: 1ex;
}
.k-pager-sizes .k-input-inner,
.k-pager-sizes .k-input-value-text {
  text-overflow: clip;
}

.k-pager-info {
  text-align: end;
  justify-content: flex-end;
  flex: 1 1 0%;
  order: 9;
}

.k-pager-refresh {
  margin-inline-start: auto;
  color: inherit;
  order: 10;
}

.k-pager-sm {
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  padding-block: var(--kendo-spacing-1, 0.25rem);
  gap: var(--kendo-spacing-3, 0.75rem);
}
.k-pager-sm .k-pager-numbers-wrap .k-button {
  min-width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 2px);
}
.k-pager-sm .k-pager-numbers-wrap .k-pager-input,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-sm .k-pager-numbers-wrap > select.k-dropdownlist {
  margin-inline-start: var(--kendo-spacing-3, 0.75rem);
  margin-inline-end: var(--kendo-spacing-3, 0.75rem);
}
.k-pager-sm .k-pager-sizes .k-dropdown-list, .k-pager-sm .k-pager-sizes .k-dropdown,
.k-pager-sm .k-pager-sizes .k-dropdownlist,
.k-pager-sm .k-pager-sizes > select {
  width: 5em;
}

.k-pager-md {
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  gap: var(--kendo-spacing-3\.5, 0.875rem);
}
.k-pager-md .k-pager-numbers-wrap .k-button {
  min-width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
}
.k-pager-md .k-pager-numbers-wrap .k-pager-input,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-md .k-pager-numbers-wrap > select.k-dropdownlist {
  margin-inline-start: var(--kendo-spacing-3\.5, 0.875rem);
  margin-inline-end: var(--kendo-spacing-3\.5, 0.875rem);
}
.k-pager-md .k-pager-sizes .k-dropdown-list, .k-pager-md .k-pager-sizes .k-dropdown,
.k-pager-md .k-pager-sizes .k-dropdownlist,
.k-pager-md .k-pager-sizes > select {
  width: 5em;
}

.k-pager-lg {
  padding-inline: var(--kendo-spacing-2\.5, 0.625rem);
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
  gap: var(--kendo-spacing-4, 1rem);
}
.k-pager-lg .k-pager-numbers-wrap .k-button {
  min-width: calc(var(--kendo-line-height-lg, normal) * 1em + var(--kendo-spacing-2, 0.5rem) * 2 + 2px);
}
.k-pager-lg .k-pager-numbers-wrap .k-pager-input,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdown-list,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdown,
.k-pager-lg .k-pager-numbers-wrap > select.k-dropdownlist {
  margin-inline-start: var(--kendo-spacing-4, 1rem);
  margin-inline-end: var(--kendo-spacing-4, 1rem);
}
.k-pager-lg .k-pager-sizes .k-dropdown-list, .k-pager-lg .k-pager-sizes .k-dropdown,
.k-pager-lg .k-pager-sizes .k-dropdownlist,
.k-pager-lg .k-pager-sizes > select {
  width: 5em;
}

.k-pager {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-pager:focus, .k-pager.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}

.k-chip {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: 1;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}
.k-chip *,
.k-chip *::before,
.k-chip *::after {
  box-sizing: border-box;
}
.k-chip .k-selected-icon-wrapper {
  display: none !important;
}

.k-chip-content {
  padding-block: 0.5em;
  padding-inline: 0;
  margin-block: -0.5em;
  margin-inline: 0;
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
  flex: 1 1 auto;
}
.k-chip-content .k-icon-wrapper-host {
  display: initial;
}

.k-chip-content:first-child {
  margin-inline-start: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-content:last-child {
  margin-inline-end: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-text,
.k-chip-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
}

.k-chip-avatar {
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  flex: none;
}

.k-chip-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.k-chip-actions {
  margin-block: -5em;
  margin-inline: 0;
  flex: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: center;
}

.k-chip-action {
  flex: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: center;
}

.k-remove-icon {
  font-size: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.k-chip-list {
  min-width: 0px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.k-chip-sm {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  font-size: var(--kendo-font-size, inherit);
  line-height: 1;
}
.k-chip-sm .k-chip-action {
  padding: var(--kendo-spacing-0\.5, 0.125rem);
}

.k-chip-avatar {
  width: 1em;
  height: 1em;
  flex-basis: 1em;
}

.k-chip-md {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  font-size: var(--kendo-font-size, inherit);
  line-height: 1;
}
.k-chip-md .k-chip-action {
  padding: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-avatar {
  width: 1em;
  height: 1em;
  flex-basis: 1em;
}

.k-chip-lg {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  font-size: var(--kendo-font-size, inherit);
  line-height: 1;
}
.k-chip-lg .k-chip-action {
  padding: var(--kendo-spacing-1\.5, 0.375rem);
}

.k-chip-avatar {
  width: 1em;
  height: 1em;
  flex-basis: 1em;
}

.k-chip-list-sm {
  gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-list-md {
  gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-list-lg {
  gap: var(--kendo-spacing-1, 0.25rem);
}

.k-chip-solid-base {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-base, #3d3d3d);
  background-color: var(--kendo-color-base-subtle, #ebebeb);
}
.k-chip-solid-base:focus, .k-chip-solid-base.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-chip-solid-base:hover, .k-chip-solid-base.k-hover {
  background-color: var(--kendo-color-base-subtle-hover, #e0e0e0);
}
.k-chip-solid-base.k-selected {
  background-color: var(--kendo-color-base-active, #d6d6d6);
}

.k-chip-solid-error {
  border-color: var(--kendo-color-error-emphasis, #f76f60);
  color: var(--kendo-color-error-on-subtle, #7a0c00);
  background-color: var(--kendo-color-error-subtle, #fcddda);
}
.k-chip-solid-error:focus, .k-chip-solid-error.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 16%, transparent);
}
.k-chip-solid-error:hover, .k-chip-solid-error.k-hover {
  background-color: var(--kendo-color-error-subtle-hover, #fbc8c3);
}
.k-chip-solid-error.k-selected {
  background-color: var(--kendo-color-error-subtle-active, #f98b80);
}

.k-chip-solid-info {
  border-color: var(--kendo-color-info-emphasis, #6098f2);
  color: var(--kendo-color-info-on-subtle, #002259);
  background-color: var(--kendo-color-info-subtle, #d2e2fb);
}
.k-chip-solid-info:focus, .k-chip-solid-info.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 16%, transparent);
}
.k-chip-solid-info:hover, .k-chip-solid-info.k-hover {
  background-color: var(--kendo-color-info-subtle-hover, #bdd4f8);
}
.k-chip-solid-info.k-selected {
  background-color: var(--kendo-color-info-subtle-active, #80acf4);
}

.k-chip-solid-warning {
  border-color: var(--kendo-color-warning-emphasis, #ffd760);
  color: var(--kendo-color-warning-on-subtle, #5e4700);
  background-color: var(--kendo-color-warning-subtle, #fff4d3);
}
.k-chip-solid-warning:focus, .k-chip-solid-warning.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 16%, transparent);
}
.k-chip-solid-warning:hover, .k-chip-solid-warning.k-hover {
  background-color: var(--kendo-color-warning-subtle-hover, #ffeebd);
}
.k-chip-solid-warning.k-selected {
  background-color: var(--kendo-color-warning-subtle-active, #ffe79e);
}

.k-chip-solid-success {
  border-color: var(--kendo-color-success-emphasis, #81d15f);
  color: var(--kendo-color-success-on-subtle, #1c5a00);
  background-color: var(--kendo-color-success-subtle, #dcf0d3);
}
.k-chip-solid-success:focus, .k-chip-solid-success.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 16%, transparent);
}
.k-chip-solid-success:hover, .k-chip-solid-success.k-hover {
  background-color: var(--kendo-color-success-subtle-hover, #cbe9bf);
}
.k-chip-solid-success.k-selected {
  background-color: var(--kendo-color-success-subtle-active, #b7e1a5);
}

.k-chip-outline-base {
  border-color: var(--kendo-color-base-on-surface, #3d3d3d);
  color: var(--kendo-color-base-on-surface, #3d3d3d);
  background-color: transparent;
}
.k-chip-outline-base:focus, .k-chip-outline-base.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}
.k-chip-outline-base:hover, .k-chip-outline-base.k-hover {
  color: var(--kendo-color-base, #f5f5f5);
  background-color: var(--kendo-color-base-on-surface, #3d3d3d);
}
.k-chip-outline-base.k-selected {
  color: var(--kendo-color-base, #f5f5f5);
  background-color: var(--kendo-color-base-on-surface, #3d3d3d);
}

.k-chip-outline-error {
  border-color: var(--kendo-color-error-on-surface, #ca1400);
  color: var(--kendo-color-error-on-surface, #ca1400);
  background-color: transparent;
}
.k-chip-outline-error:focus, .k-chip-outline-error.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-error, #f31700) 16%, transparent);
}
.k-chip-outline-error:hover, .k-chip-outline-error.k-hover {
  border-color: var(--kendo-color-error-hover, #df1600);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error-hover, #df1600);
}
.k-chip-outline-error.k-selected {
  border-color: var(--kendo-color-error-active, #ca1400);
  color: var(--kendo-color-on-error, #ffffff);
  background-color: var(--kendo-color-error-active, #ca1400);
}

.k-chip-outline-info {
  border-color: var(--kendo-color-info-on-surface, #004ac2);
  color: var(--kendo-color-info-on-surface, #004ac2);
  background-color: transparent;
}
.k-chip-outline-info:focus, .k-chip-outline-info.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-info, #0058e9) 16%, transparent);
}
.k-chip-outline-info:hover, .k-chip-outline-info.k-hover {
  border-color: var(--kendo-color-info-hover, #0052d6);
  color: var(--kendo-color-on-info, #ffffff);
  background-color: var(--kendo-color-info-hover, #0052d6);
}
.k-chip-outline-info.k-selected {
  border-color: var(--kendo-color-info-active, #004ac2);
  color: var(--kendo-color-on-info, #ffffff);
  background-color: var(--kendo-color-info-active, #004ac2);
}

.k-chip-outline-warning {
  border-color: var(--kendo-color-warning, #ffc000);
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: transparent;
}
.k-chip-outline-warning:focus, .k-chip-outline-warning.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-warning, #ffc000) 16%, transparent);
}
.k-chip-outline-warning:hover, .k-chip-outline-warning.k-hover {
  color: var(--kendo-color-on-warning, #3d3d3d);
  background-color: var(--kendo-color-warning, #ffc000);
}
.k-chip-outline-warning.k-selected {
  color: var(--kendo-color-on-warning, #3d3d3d);
  background-color: var(--kendo-color-warning, #ffc000);
}

.k-chip-outline-success {
  border-color: var(--kendo-color-success-on-surface, #2d9600);
  color: var(--kendo-color-success-on-surface, #2d9600);
  background-color: transparent;
}
.k-chip-outline-success:focus, .k-chip-outline-success.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-success, #37b400) 16%, transparent);
}
.k-chip-outline-success:hover, .k-chip-outline-success.k-hover {
  border-color: var(--kendo-color-success-hover, #32a500);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success-hover, #32a500);
}
.k-chip-outline-success.k-selected {
  border-color: var(--kendo-color-success-active, #2d9600);
  color: var(--kendo-color-on-success, #ffffff);
  background-color: var(--kendo-color-success-active, #2d9600);
}

.k-skeleton, .k-placeholder-line {
  display: block;
}

.k-skeleton-text, .k-placeholder-line {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  transform: scale(1, 0.6);
}
.k-skeleton-text:empty::before, .k-placeholder-line:empty::before {
  content: "​";
}

.k-skeleton-rect {
  border-radius: 0;
}

.k-skeleton-circle {
  border-radius: 9999px;
}

@keyframes k-skeleton-wave {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.k-skeleton-wave .k-skeleton, .k-skeleton-wave .k-placeholder-line, .k-skeleton-wave.k-skeleton, .k-skeleton-wave.k-placeholder-line {
  position: relative;
  overflow: hidden;
}
.k-skeleton-wave .k-skeleton::after, .k-skeleton-wave .k-placeholder-line::after, .k-skeleton-wave.k-skeleton::after, .k-skeleton-wave.k-placeholder-line::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  animation: k-skeleton-wave 1.6s linear 0.5s infinite;
}

@keyframes k-skeleton-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.k-skeleton-pulse .k-skeleton, .k-skeleton-pulse .k-placeholder-line, .k-skeleton-pulse.k-skeleton, .k-skeleton-pulse.k-placeholder-line {
  animation: k-skeleton-pulse 1.5s ease-in-out 0.5s infinite;
}

.k-skeleton, .k-placeholder-line {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 20%, transparent);
}

.k-skeleton-wave .k-skeleton::after, .k-skeleton-wave .k-placeholder-line::after, .k-skeleton-wave.k-skeleton::after, .k-skeleton-wave.k-placeholder-line::after {
  background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.04), transparent);
}

.k-table {
  width: 100%;
  max-width: none;
  border-width: 1px;
  border-style: solid;
  text-align: start;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  outline: none;
}

.k-data-table {
  border-width: 1px;
  border-style: solid;
}
.k-data-table .k-table {
  table-layout: fixed;
}

.k-table-thead,
.k-table-tbody,
.k-table-tfoot,
.k-table-row,
.k-table-alt-row {
  border-color: inherit;
  text-align: inherit;
}

.k-table-th,
.k-table-td {
  border-width: 0 0 0px 1px;
  border-style: solid;
  border-color: inherit;
  box-sizing: border-box;
  font-weight: normal;
  text-align: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: table-cell;
  overflow: hidden;
  position: relative;
}
.k-table-th:first-child,
.k-table-td:first-child {
  border-left-width: 0;
}

.k-table-th {
  border-bottom-width: 1px;
}

.k-table-header {
  padding-inline-end: var(--kendo-scrollbar-width);
  border-width: 0 0 1px;
  border-style: solid;
  box-sizing: border-box;
}
.k-table-header .k-table {
  border-width: 0;
}

.k-table-header-wrap {
  margin-right: -1px;
  width: 100%;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: inherit;
  overflow: hidden;
}

.k-table-header > .k-table,
.k-table-header-wrap > .k-table {
  margin-bottom: -1px;
}

.k-table-group-sticky-header {
  flex: none;
}
.k-table-group-sticky-header .k-table-th {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
}

.k-table-list {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none;
  border-width: 0;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  empty-cells: show;
  list-style: none;
  outline: none;
}
.k-table-list .k-table-row {
  position: relative;
}
.k-table-list .k-table-row,
.k-table-list .k-table-group-row {
  width: 100%;
  box-sizing: border-box;
  display: table-row;
}
.k-table-list .k-table-row.k-first {
  border-top: 1px solid currentColor;
}
.k-table-list .k-table-th,
.k-table-list .k-table-td {
  vertical-align: middle;
}
.k-table-list .k-table-group-row {
  position: sticky;
}
.k-table-list .k-table-group-row::before {
  content: "​";
  padding-inline: 0;
  width: 0;
  display: block;
  overflow: hidden;
}
.k-table-list .k-table-group-row .k-table-th {
  width: 100%;
  border-color: inherit;
  color: inherit;
  background-color: inherit;
  position: absolute;
  top: 0;
}
.k-table-list .k-table-spacer-td {
  padding: 0 !important;
  width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}
.k-table-list .k-table-group-td {
  padding: 0 !important;
  width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  overflow: visible;
}
.k-table-list .k-table-group-td > span {
  font-size: 0.75em;
  position: absolute;
  top: 0;
  right: 0;
}

.k-virtual-table .k-table-row,
.k-virtual-table .k-table-group-row {
  position: absolute;
  width: 100%;
}

.k-table-scroller {
  position: relative;
  overflow: auto;
}
.k-table-scroller > .k-table {
  border-width: 0;
}

.k-table-footer {
  padding-inline-end: var(--kendo-scrollbar-width);
  border-width: 1px 0 0;
  border-style: solid;
  box-sizing: border-box;
}
.k-table-footer .k-table {
  border-width: 0;
}

.k-table-footer-wrap {
  margin-right: -1px;
  width: 100%;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: inherit;
  overflow: hidden;
}

.k-table-sm {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-table-sm .k-table-th,
.k-table-sm .k-table-td {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-table-sm .k-table-list .k-table-group-td > span {
  padding-block: 0;
  padding-inline: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}

.k-table-sm .k-table-list .k-table-group-row::before {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: 0;
}

.k-table-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-table-md .k-table-th,
.k-table-md .k-table-td {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
}

.k-table-md .k-table-list .k-table-group-td > span {
  padding-block: 0;
  padding-inline: calc(var(--kendo-spacing-3, 0.75rem) / 2);
}

.k-table-md .k-table-list .k-table-group-row::before {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: 0;
}

.k-table-lg {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}

.k-table-lg .k-table-th,
.k-table-lg .k-table-td {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-table-lg .k-table-list .k-table-group-td > span {
  padding-block: 0;
  padding-inline: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}

.k-table-lg .k-table-list .k-table-group-row::before {
  padding-block: var(--kendo-spacing-2\.5, 0.625rem);
  padding-inline: 0;
}

.k-rtl.k-table .k-table-th,
.k-rtl.k-table .k-table-td,
.k-rtl .k-table .k-table-th,
.k-rtl .k-table .k-table-td,
[dir=rtl].k-table .k-table-th,
[dir=rtl].k-table .k-table-td,
[dir=rtl] .k-table .k-table-th,
[dir=rtl] .k-table .k-table-td {
  border-left-width: 0;
  border-right-width: 1px;
}
.k-rtl.k-table .k-table-th:first-child,
.k-rtl.k-table .k-table-td:first-child,
.k-rtl .k-table .k-table-th:first-child,
.k-rtl .k-table .k-table-td:first-child,
[dir=rtl].k-table .k-table-th:first-child,
[dir=rtl].k-table .k-table-td:first-child,
[dir=rtl] .k-table .k-table-th:first-child,
[dir=rtl] .k-table .k-table-td:first-child {
  border-right-width: 0;
}
.k-rtl.k-table .k-table-header-wrap,
.k-rtl.k-table .k-table-footer-wrap,
.k-rtl .k-table .k-table-header-wrap,
.k-rtl .k-table .k-table-footer-wrap,
[dir=rtl].k-table .k-table-header-wrap,
[dir=rtl].k-table .k-table-footer-wrap,
[dir=rtl] .k-table .k-table-header-wrap,
[dir=rtl] .k-table .k-table-footer-wrap {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
  border-right-width: 0;
}
.k-rtl .k-table-list .k-table-group-td > span,
[dir=rtl] .k-table-list .k-table-group-td > span {
  left: 0;
  right: auto;
}

.k-table,
.k-data-table {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-table-tfoot,
.k-table-footer {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-table-group-row {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-table-group-td > span {
  color: var(--kendo-color-surface-alt, #ffffff);
  background-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-table-alt-row {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 5%, transparent);
}

.k-table-tbody .k-table-row:hover,
.k-table-list .k-table-row:hover,
.k-table-tbody .k-table-row.k-hover,
.k-table-list .k-table-row.k-hover {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 11%, transparent);
}

.k-table-tbody .k-table-row.k-focus, .k-table-tbody .k-table-row:focus,
.k-table-list .k-table-row.k-focus,
.k-table-list .k-table-row:focus,
.k-table-tbody .k-table-td.k-focus,
.k-table-tbody .k-table-td:focus,
.k-table-list .k-table-td.k-focus,
.k-table-list .k-table-td:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-table-tbody .k-table-row.k-selected > .k-table-td,
.k-table-list .k-table-row.k-selected {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent);
}

.k-tabstrip-wrapper {
  padding-block: var(--kendo-spacing-0, 0px);
  padding-inline: var(--kendo-spacing-0, 0px);
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  position: relative;
}

.k-tabstrip {
  border-width: 0;
  border-color: transparent;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  color: inherit;
  background-color: transparent;
  display: flex;
  flex-flow: column nowrap;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-tabstrip-wrapper > .k-tabstrip {
  flex: 1 1 auto;
}

.k-tabstrip-items-wrapper {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  position: relative;
}

.k-tabstrip-items {
  box-sizing: border-box;
  outline: 0;
  display: flex;
  flex-direction: inherit;
  flex-wrap: wrap;
  flex: 1 1 auto;
  gap: var(--kendo-spacing-0, 0px);
}
.k-tabstrip-items *,
.k-tabstrip-items *::before,
.k-tabstrip-items *::after {
  box-sizing: border-box;
}
.k-tabstrip-items .k-item {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: stretch;
  outline: 0;
}
.k-tabstrip-items .k-tab-on-top {
  z-index: 1;
}
.k-tabstrip-items .k-link {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  flex: 1 1 auto;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.k-tabstrip-content,
.k-tabstrip > .k-content {
  margin: 0 !important;
  padding-block: var(--kendo-spacing-4, 1rem);
  padding-inline: var(--kendo-spacing-4, 1rem);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  display: none;
  overflow: auto;
  flex: 1 1 auto;
  position: relative;
}
.k-tabstrip-content.k-active,
.k-tabstrip > .k-content.k-active {
  display: block;
}
.k-tabstrip-content:focus, .k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
  outline-width: 1px;
  outline-style: dotted;
  outline-offset: -1px;
}

.k-tabstrip-items .k-loading {
  width: 20%;
  height: 0;
  border: 0;
  border-top: 1px solid transparent;
  border-color: inherit;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.2s linear;
  display: none;
}
.k-tabstrip-items .k-loading.k-complete {
  width: 100%;
  border-top-width: 0;
}

.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
}
.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  width: auto;
  height: auto;
  flex: none;
  align-self: stretch;
  aspect-ratio: auto;
}
.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button .k-button-icon {
  min-width: auto;
  min-height: auto;
}

.k-tabstrip-horizontal, .k-tabstrip-bottom > .k-tabstrip-items-wrapper, .k-tabstrip-top > .k-tabstrip-items-wrapper {
  flex-direction: row;
}

.k-tabstrip-vertical, .k-tabstrip-right > .k-tabstrip-items-wrapper, .k-tabstrip-left > .k-tabstrip-items-wrapper {
  flex-direction: column;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-bottom-width: 1px;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-width: 0;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-bottom: -1px;
  border-bottom-width: 1px;
  border-bottom-color: transparent !important;
}
.k-tabstrip-top > .k-content,
.k-tabstrip-top > .k-tabstrip-content {
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-top-width: 0 !important;
}

.k-tabstrip-bottom > .k-tabstrip-items-wrapper {
  border-top-width: 1px;
}
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item {
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-top-width: 0;
}
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-top: -1px;
  border-top-width: 1px;
  border-top-color: transparent !important;
}
.k-tabstrip-bottom > .k-content,
.k-tabstrip-bottom > .k-tabstrip-content {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-width: 0 !important;
}

.k-tabstrip-left {
  flex-direction: row;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper {
  border-right-width: 1px;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-right-width: 0;
}
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-right: -1px;
  border-right-width: 1px;
  border-right-color: transparent !important;
}
.k-tabstrip-left > .k-content,
.k-tabstrip-left > .k-tabstrip-content {
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-left-width: 0 !important;
}

.k-tabstrip-right {
  flex-direction: row-reverse;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper {
  border-left-width: 1px;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item {
  border-top-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-right-radius: var(--kendo-border-radius-md, 0.25rem);
  border-left-width: 0;
}
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-left: -1px;
  border-left-width: 1px;
  border-left-color: transparent !important;
}
.k-tabstrip-right > .k-content,
.k-tabstrip-right > .k-tabstrip-content {
  border-top-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-bottom-left-radius: var(--kendo-border-radius-md, 0.25rem);
  border-right-width: 0;
}

.k-tabstrip-items-start {
  justify-content: flex-start;
}

.k-tabstrip-items-center {
  justify-content: center;
}

.k-tabstrip-items-end {
  justify-content: flex-end;
}

.k-tabstrip-items-justify {
  justify-content: space-between;
}

.k-tabstrip-items-stretched > * {
  flex: 1 0 0;
}

.k-rtl .k-tabstrip.k-tabstrip-left > .k-tabstrip-items-wrapper, .k-rtl .k-tabstrip.k-tabstrip-right > .k-tabstrip-items-wrapper,
.k-tabstrip[dir=rtl].k-tabstrip-left > .k-tabstrip-items-wrapper,
.k-tabstrip[dir=rtl].k-tabstrip-right > .k-tabstrip-items-wrapper {
  order: 1;
}
.k-rtl .k-tabstrip.k-tabstrip-left > .k-content, .k-rtl .k-tabstrip.k-tabstrip-right > .k-content,
.k-tabstrip[dir=rtl].k-tabstrip-left > .k-content,
.k-tabstrip[dir=rtl].k-tabstrip-right > .k-content {
  order: 0;
}
.k-rtl .k-tabstrip.k-tabstrip-left > .k-tabstrip-content, .k-rtl .k-tabstrip.k-tabstrip-right > .k-tabstrip-content,
.k-tabstrip[dir=rtl].k-tabstrip-left > .k-tabstrip-content,
.k-tabstrip[dir=rtl].k-tabstrip-right > .k-tabstrip-content {
  order: 0;
}

.k-tabstrip-items-wrapper {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-tabstrip-items-wrapper .k-item {
  color: var(--kendo-color-primary, #ff6358);
}
.k-tabstrip-items-wrapper .k-item:hover, .k-tabstrip-items-wrapper .k-item.k-hover {
  color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-tabstrip-items-wrapper .k-item:active, .k-tabstrip-items-wrapper .k-item.k-active, .k-tabstrip-items-wrapper .k-item.k-selected {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-tabstrip-items-wrapper .k-item:focus, .k-tabstrip-items-wrapper .k-item.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-tabstrip-items-wrapper .k-item.k-tabstrip-dragging {
  box-shadow: var(--kendo-elevation-3, 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12));
}
.k-tabstrip-content,
.k-tabstrip > .k-content {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-tabstrip-content:focus, .k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
  outline-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-grid {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-grid.k-grid-display-block {
  display: block;
}
.k-grid .k-grid-container {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}
.k-grid .k-grid-aria-root {
  border-color: inherit;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}
.k-grid .k-table {
  margin: 0;
  width: 100%;
  max-width: none;
  border-width: 0;
  border-color: inherit;
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: content-box;
  empty-cells: show;
  outline: 0;
}
.k-grid .k-grid-header .k-table,
.k-grid .k-grid-content .k-table,
.k-grid .k-grid-content-locked .k-table,
.k-grid .k-grid-footer .k-table {
  table-layout: fixed;
}
.k-grid .k-table-thead,
.k-grid .k-table-tbody,
.k-grid .k-table-tfoot {
  text-align: start;
  border-color: inherit;
}
.k-grid .k-table-row {
  border-color: inherit;
}
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td {
  border-width: 0;
  border-inline-start-width: 1px;
  border-style: solid;
  border-color: inherit;
  outline: 0;
  font-weight: 400;
  text-align: inherit;
  position: static;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: content-box;
}
.k-grid .k-table-th:first-child,
.k-grid td:first-child,
.k-grid .k-table-td:first-child {
  border-inline-start-width: 0;
}
.k-grid .k-table-th:focus,
.k-grid td:focus,
.k-grid .k-table-td:focus {
  outline: none;
}
.k-grid .k-table-th > .k-radio,
.k-grid .k-table-th > .k-radio-wrap,
.k-grid .k-table-th > .k-checkbox,
.k-grid .k-table-th > .k-checkbox-wrap,
.k-grid td > .k-radio,
.k-grid td > .k-radio-wrap,
.k-grid td > .k-checkbox,
.k-grid td > .k-checkbox-wrap,
.k-grid .k-table-td > .k-radio,
.k-grid .k-table-td > .k-radio-wrap,
.k-grid .k-table-td > .k-checkbox,
.k-grid .k-table-td > .k-checkbox-wrap {
  vertical-align: top;
}
.k-grid .k-table-th {
  border-block-end-width: 1px;
  white-space: nowrap;
}
.k-grid td,
.k-grid .k-table-td {
  border-block-end-width: 0px;
  white-space: unset;
  vertical-align: middle;
}
.k-grid a {
  color: inherit;
  text-decoration: none;
}
.k-grid a:hover {
  text-decoration: none;
}
.k-grid .k-grid-header-wrap {
  box-sizing: content-box;
}
.k-grid .k-grid-header {
  border-bottom-width: 1px;
}
.k-grid .k-grid-header .k-table {
  margin-bottom: -1px;
}
.k-grid .k-grid-header.k-grid-draggable-header {
  user-select: none;
  touch-action: none;
}
.k-grid .k-grid-header .k-table-th {
  position: relative;
  vertical-align: bottom;
  cursor: default;
}
.k-grid .k-grid-header .k-table-th:first-child {
  border-inline-start-width: 0;
}
.k-grid .k-grid-header .k-table-th.k-first {
  border-inline-start-width: 1px;
}
.k-grid .k-grid-header .k-table-th > .k-link {
  line-height: inherit;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: 0;
}
.k-grid .k-grid-header .k-table-th > .k-link:focus {
  text-decoration: none;
}
.k-grid .k-grid-header .k-grid-filter,
.k-grid .k-grid-header .k-header-column-menu,
.k-grid .k-grid-header .k-grid-header-menu {
  box-sizing: border-box;
  outline: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  inset-inline-end: var(--kendo-spacing-1, 0.25rem);
  z-index: 1;
}
.k-grid .k-grid-header .k-grid-filter:hover,
.k-grid .k-grid-header .k-header-column-menu:hover,
.k-grid .k-grid-header .k-grid-header-menu:hover {
  cursor: pointer;
}
.k-grid .k-grid-header .k-cell-inner > .k-link > .k-sort-icon {
  vertical-align: text-top;
  margin-inline-start: calc(var(--kendo-spacing-2, 0.5rem) - 1px);
}
.k-grid .k-grid-header .k-cell-inner > .k-link > .k-sort-icon .k-icon {
  display: flex;
}
.k-grid .k-grid-header .k-sort-order {
  display: inline-block;
  vertical-align: top;
  height: 16px;
  font-size: var(--kendo-font-size-sm, inherit);
  margin-top: calc(var(--kendo-spacing-1, 0.25rem) / 2);
  margin-inline-start: calc(calc(var(--kendo-spacing-1, 0.25rem) / 2) * -1);
}
.k-grid .k-grid-header .k-filterable > .k-cell-inner .k-link {
  padding-inline-end: 0;
}
.k-grid .k-cell-inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: inherit;
  overflow: hidden;
}
.k-grid .k-cell-inner > .k-link {
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: inherit;
  flex: 1;
  overflow: hidden;
}
.k-grid .k-cell-inner > .k-link > .k-sort-icon {
  margin-inline-start: 0;
  display: inline-block;
  flex-shrink: 0;
}
.k-grid .k-cell-inner .k-sort-order {
  flex-shrink: 0;
  line-height: normal;
}
.k-grid .k-cell-inner .k-grid-filter,
.k-grid .k-cell-inner .k-header-column-menu,
.k-grid .k-cell-inner .k-grid-header-menu {
  position: static;
  margin-inline-end: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-group-col,
.k-grid .k-hierarchy-col {
  padding: 0;
  width: 32px;
}
.k-grid .k-grouping-row p {
  margin: 0;
  display: flex;
  align-items: center;
  align-content: center;
}
.k-grid .k-grouping-row td,
.k-grid .k-grouping-row .k-table-td {
  overflow: visible;
}
.k-grid .k-grouping-row + .k-table-row td,
.k-grid .k-grouping-row + .k-table-row .k-table-td {
  border-top-width: 1px;
}
.k-grid .k-grouping-row .k-group-cell,
.k-grid .k-grouping-row + .k-table-row .k-group-cell {
  border-top-width: 0;
  text-overflow: clip;
}
.k-grid .k-grouping-row .k-icon {
  margin-inline-start: calc(8px - var(--kendo-spacing-3, 0.75rem));
  margin-inline-end: 8px;
  text-decoration: none;
}
.k-grid .k-table .k-group-footer td,
.k-grid .k-table .k-group-footer .k-table-td {
  border-style: solid;
  border-width: 1px 0;
}
.k-grid .k-group-footer .k-group-cell + td,
.k-grid .k-group-footer .k-group-cell + .k-table-td {
  border-inline-start-width: 1px;
}
.k-grid .k-hierarchy-cell,
.k-grid .k-drag-cell {
  text-align: center;
  overflow: visible;
}
.k-grid .k-hierarchy-cell > .k-font-icon,
.k-grid .k-drag-cell > .k-font-icon {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  outline: 0;
}
.k-grid .k-hierarchy-cell + .k-grid-content-sticky {
  border-inline-start-width: 1px;
}
.k-grid .k-detail-row .k-detail-cell {
  border-inline-start-width: 0;
}
.k-grid .k-dirty-cell {
  position: relative;
}
.k-grid .k-dirty-cell.k-edit-cell {
  position: static;
}
.k-grid .k-dirty-cell.k-grid-content-sticky {
  position: sticky;
}
.k-grid .k-dirty {
  border-width: 5px;
  border-color: transparent;
  border-block-start-color: currentColor;
  border-inline-start-color: currentColor;
  inset-inline-start: 0;
  inset-inline-end: auto;
}
.k-grid .k-grid-content-locked + .k-grid-content {
  box-sizing: content-box;
}
.k-grid .k-grid-content-expander {
  position: absolute;
  visibility: hidden;
  height: 1px;
  bottom: 1px;
}
.k-grid .k-grid-filter,
.k-grid .k-header-column-menu,
.k-grid .k-grid-header-menu,
.k-grid .k-hierarchy-cell .k-icon {
  border-width: 0;
}
.k-grid .k-drag-col {
  padding: 0;
  width: 36px;
}
.k-grid .k-drag-cell {
  cursor: move;
}

.k-grid-toolbar {
  border-width: 0 0 1px;
  flex-shrink: 0;
}

.k-grid-toolbar-bottom {
  border-width: 1px 0 0;
}

.k-grouping-header {
  border-width: 0 0 1px;
  border-style: solid;
  border-color: inherit;
  white-space: normal;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}
.k-grouping-header::before {
  content: "";
  height: calc(1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
  display: inline-block;
  vertical-align: middle;
}
.k-grouping-header .k-chip-list {
  flex-grow: 0;
  display: contents;
}
.k-grouping-header > .k-grouping-drop-container {
  flex-grow: 1;
  padding-block: var(--kendo-spacing-2, 0.5rem);
  margin-block: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  display: inline-flex;
  align-self: stretch;
  align-items: center;
}

.k-grouping-dropclue {
  width: 12px;
  position: absolute;
  top: 3px;
  box-sizing: content-box;
}
.k-grouping-dropclue::before, .k-grouping-dropclue::after {
  display: inline-block;
  content: "";
  position: absolute;
}
.k-grouping-dropclue::before {
  border-width: 6px;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  top: 0;
}
.k-grouping-dropclue::after {
  width: 2px;
  height: calc(100% - 6px);
  top: 6px;
  inset-inline-start: calc(6px - 1px);
}

.k-grid-header-wrap,
.k-grid-footer-wrap {
  margin-inline-end: -1px;
  width: 100%;
  border-width: 0;
  border-inline-end-width: 1px;
  border-style: solid;
  border-color: inherit;
  position: relative;
  overflow: hidden;
}

.k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
  margin-inline-end: 0;
}

.k-grid-header,
.k-grid-footer {
  padding-inline-start: 0;
  padding-inline-end: var(--kendo-scrollbar-width, 17px);
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
}

div.k-grid-header,
div.k-grid-footer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.k-column-resizer {
  width: 0.5em;
  height: 1000%;
  display: block;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  cursor: col-resize;
}

.k-row-resizer {
  display: block;
  height: var(--kendo-spacing-0\.5, 0.125rem);
  width: 100%;
  background: none;
}

.k-grid-footer {
  border-top-width: 1px;
}
.k-grid-footer td,
.k-grid-footer .k-table-td {
  height: calc(1.4285714286 * 1em);
}

.k-grid-footer td,
.k-group-footer td,
.k-grouping-row td,
.k-grid-footer .k-table-td,
.k-group-footer .k-table-td,
.k-grouping-row .k-table-td {
  font-weight: bold;
}

.k-grid-filter-popup {
  min-width: 230px;
  max-width: 320px;
}

.k-grid-column-menu-standalone a.k-grid-filter {
  position: initial;
  display: inline-block;
  margin-block: -4px;
  margin-inline: 0;
  padding-block: 4px;
  padding-inline: 8px;
}

.k-filter-row {
  line-height: var(--kendo-line-height, normal);
}
.k-filter-row td,
.k-filter-row .k-table-td,
.k-filter-row .k-table-th {
  border-width: 0;
  border-block-end-width: 1px;
  border-inline-start-width: 1px;
  white-space: nowrap;
}
.k-filter-row td:first-child,
.k-filter-row .k-table-td:first-child {
  border-inline-start-width: 0;
}
.k-filter-row .k-multiselect {
  height: auto;
}

.k-filtercell {
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.k-filtercell > span,
.k-filtercell .k-filtercell-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 1 auto;
}
.k-filtercell > span > .k-button,
.k-filtercell .k-filtercell-wrapper > .k-button {
  flex: none;
}

.k-grid-content,
.k-grid-content-locked {
  border-color: inherit;
  outline: 0;
}
.k-grid-content .k-table-row:last-child > td,
.k-grid-content .k-table-row:last-child > .k-table-td,
.k-grid-content-locked .k-table-row:last-child > td,
.k-grid-content-locked .k-table-row:last-child > .k-table-td {
  border-bottom-width: 0;
}

.k-grid-content {
  width: 100%;
  min-height: 0;
  overflow: auto;
  overflow-x: auto;
  overflow-y: scroll;
  position: relative;
  flex: 1;
}

.k-virtual-scrollable-wrap {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.k-grid .k-edit-cell,
.k-grid .k-command-cell,
.k-grid .k-grid-edit-row td,
.k-grid .k-grid-edit-row .k-table-td {
  text-overflow: clip;
}

.k-grid .k-edit-cell > input:not([type=checkbox]):not([type=radio]),
.k-grid .k-edit-cell > select,
.k-grid .k-edit-cell > .k-widget:not(.k-switch),
.k-grid .k-grid-edit-row td > input:not([type=checkbox]):not([type=radio]),
.k-grid .k-grid-edit-row td > select,
.k-grid .k-grid-edit-row td > .k-widget:not(.k-switch),
.k-grid .k-grid-edit-row .k-table-td > input:not([type=checkbox]):not([type=radio]),
.k-grid .k-grid-edit-row .k-table-td > select,
.k-grid .k-grid-edit-row .k-table-td > .k-widget:not(.k-switch) {
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
}
.k-grid .k-edit-cell > .k-radio,
.k-grid .k-edit-cell > .k-checkbox,
.k-grid .k-edit-cell > .k-radio-wrap,
.k-grid .k-edit-cell > .k-checkbox-wrap,
.k-grid .k-grid-edit-row td > .k-radio,
.k-grid .k-grid-edit-row td > .k-checkbox,
.k-grid .k-grid-edit-row td > .k-radio-wrap,
.k-grid .k-grid-edit-row td > .k-checkbox-wrap,
.k-grid .k-grid-edit-row .k-table-td > .k-radio,
.k-grid .k-grid-edit-row .k-table-td > .k-checkbox,
.k-grid .k-grid-edit-row .k-table-td > .k-radio-wrap,
.k-grid .k-grid-edit-row .k-table-td > .k-checkbox-wrap {
  vertical-align: middle;
}

.k-grid .k-command-cell > .k-button {
  margin-inline-end: var(--kendo-spacing-2, 0.5rem);
  vertical-align: middle;
}
.k-grid .k-command-cell > .k-button:last-child {
  margin-inline-end: unset;
}

.k-grid > .k-resize-handle,
.k-grid-header .k-resize-handle {
  height: 25px;
  cursor: col-resize;
  position: absolute;
  z-index: 2;
}

.k-selection-aggregates {
  border-width: 1px 0 0;
  border-style: solid;
  line-height: var(--kendo-line-height, normal);
  display: flex;
  justify-content: flex-end;
  gap: var(--kendo-spacing-2, 0.5rem);
}

.k-selection-aggregates-item-value {
  font-weight: var(--kendo-font-weight-bold, normal);
}

.k-grid-pager {
  border-width: 1px 0 0;
  border-color: inherit;
  font-size: inherit;
}

.k-grid-pager-top {
  border-width: 0 0 1px;
}

.k-grid-virtual .k-grid-content .k-grid-table-wrap {
  float: left;
  width: 100%;
}
.k-grid-virtual .k-grid-content .k-grid-table {
  position: relative;
  float: left;
  z-index: 1;
}
.k-grid-virtual .k-grid-content > .k-height-container {
  position: relative;
  float: left;
}
.k-grid-virtual .k-grid-content::after {
  content: "";
  display: block;
  clear: both;
}

.k-width-container {
  position: absolute;
  visibility: hidden;
}

.k-width-container div {
  height: 1px;
}

.k-grid-add-row td,
.k-grid-add-row .k-table-td {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.k-grid-lockedcolumns {
  white-space: nowrap;
}

.k-grid-content,
.k-grid-content-locked {
  white-space: normal;
}

.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked {
  flex: 0 0 auto;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  box-sizing: content-box;
}
.k-grid-content-locked + .k-grid-content.k-auto-scrollable,
.k-grid-footer-locked + .k-grid-content.k-auto-scrollable,
.k-grid-header-locked + .k-grid-content.k-auto-scrollable {
  display: inline-block;
}
.k-grid-content-locked .k-table,
.k-grid-footer-locked .k-table,
.k-grid-header-locked .k-table {
  border-width: 0;
}

.k-grid-content,
.k-grid-footer-wrap,
.k-grid-header-wrap {
  flex: 1 1 auto;
  display: inline-block;
  vertical-align: top;
}
.k-grid-content.k-auto-scrollable,
.k-grid-footer-wrap.k-auto-scrollable,
.k-grid-header-wrap.k-auto-scrollable {
  display: block;
}

.k-grid-header-locked > .k-grid-header-table,
.k-grid-header-wrap > .k-grid-header-table {
  margin-bottom: -1px;
}

.k-grid-header .k-table-th.k-grid-header-sticky,
.k-grid-header .k-filter-row .k-grid-header-sticky,
.k-grid .k-grid-content-sticky,
.k-grid .k-grid-row-sticky,
.k-grid .k-grid-footer-sticky {
  position: sticky;
  z-index: 2;
}
.k-grid-header .k-table-th.k-grid-header-sticky.k-edit-cell,
.k-grid-header .k-filter-row .k-grid-header-sticky.k-edit-cell,
.k-grid .k-grid-content-sticky.k-edit-cell,
.k-grid .k-grid-row-sticky.k-edit-cell,
.k-grid .k-grid-footer-sticky.k-edit-cell {
  overflow: visible;
  z-index: 3;
}

.k-master-row .k-grid-content-sticky::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

kendo-grid .k-table-row.k-grid-row-sticky {
  border: 0;
  position: static;
  z-index: auto;
}
kendo-grid .k-grid-row-sticky td,
kendo-grid .k-grid-row-sticky .k-table-td {
  border-bottom-width: 1px;
  border-top-width: 1px;
  position: sticky;
  top: inherit;
  bottom: inherit;
  z-index: 2;
}
kendo-grid .k-grid-row-sticky td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky.k-table-alt-row td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky .k-table-td.k-grid-content-sticky,
kendo-grid .k-grid-row-sticky.k-table-alt-row .k-table-td.k-grid-content-sticky {
  z-index: 3;
}
kendo-grid .k-grid-header-locked .k-table-th.k-grid-header-sticky:last-child,
kendo-grid .k-grid-content-locked .k-table-row .k-grid-content-sticky:last-child {
  border-inline-end: 0;
}
kendo-grid .k-grid-header .k-grid-column-menu,
kendo-grid .k-grid-header .k-grid-header-menu {
  z-index: 1;
}

.k-grid-content-sticky.k-grid-row-sticky {
  z-index: 3;
}

.k-grid .k-table .k-grid-header-sticky,
.k-grid .k-table .k-grid-content-sticky,
.k-grid .k-table .k-grid-footer-sticky {
  border-inline-end-width: 1px;
}
.k-grid .k-table .k-grid-header-sticky:not([style*="display: none"]) + td, .k-grid .k-table .k-grid-header-sticky:not([style*="display: none"]) + .k-table-td, .k-grid .k-table .k-grid-header-sticky:not([style*="display: none"]) + .k-table-th,
.k-grid .k-table .k-grid-content-sticky:not([style*="display: none"]) + td,
.k-grid .k-table .k-grid-content-sticky:not([style*="display: none"]) + .k-table-td,
.k-grid .k-table .k-grid-content-sticky:not([style*="display: none"]) + .k-table-th,
.k-grid .k-table .k-grid-footer-sticky:not([style*="display: none"]) + td,
.k-grid .k-table .k-grid-footer-sticky:not([style*="display: none"]) + .k-table-td,
.k-grid .k-table .k-grid-footer-sticky:not([style*="display: none"]) + .k-table-th {
  border-inline-start-width: 0;
}

.k-grid .k-grid-row-sticky {
  border-bottom-width: 1px;
  border-top-width: 1px;
}

.k-grid-header-sticky.k-table-th.k-grid-no-left-border.k-first {
  border-inline-start-width: 0;
}

.k-grid.k-grid-no-scrollbar .k-grid-header-wrap,
.k-grid.k-grid-no-scrollbar .k-grid-footer-wrap {
  margin: 0;
  border-width: 0;
}
.k-grid.k-grid-no-scrollbar .k-grid-header,
.k-grid.k-grid-no-scrollbar .k-grid-footer {
  padding: 0;
}
.k-grid.k-grid-no-scrollbar .k-grid-content {
  overflow-y: auto;
}

.k-grid-norecords {
  text-align: center;
}

div.k-grid-norecords {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.k-grid-norecords-template {
  box-sizing: border-box;
  margin-block: 0;
  margin-inline: auto;
  width: 20em;
  height: 4em;
  border: 1px solid;
  line-height: 4em;
}

.k-column-title {
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
  overflow: hidden;
}

.k-grid .k-grid-sm .k-table-th,
.k-grid-sm .k-table-th {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-grid-sm td,
.k-grid .k-grid-sm .k-table-td,
.k-grid-sm td,
.k-grid-sm .k-table-td {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-grid-sm .k-grouping-header,
.k-grid-sm .k-grouping-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  gap: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm .k-grouping-header::before,
.k-grid-sm .k-grouping-header::before {
  margin-inline-start: calc(var(--kendo-spacing-1, 0.25rem) * -1);
}
.k-grid .k-grid-sm .k-grid-header .k-table-th > .k-link,
.k-grid-sm .k-grid-header .k-table-th > .k-link {
  margin-block: calc(var(--kendo-spacing-1, 0.25rem) * -1);
  margin-inline: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-grid-sm .k-grid-header .k-grid-filter,
.k-grid .k-grid-sm .k-grid-header .k-header-column-menu,
.k-grid .k-grid-sm .k-grid-header .k-grid-header-menu,
.k-grid-sm .k-grid-header .k-grid-filter,
.k-grid-sm .k-grid-header .k-header-column-menu,
.k-grid-sm .k-grid-header .k-grid-header-menu {
  padding: var(--kendo-spacing-0\.5, 0.125rem);
  width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 2px);
  height: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 2px);
  bottom: calc(var(--kendo-spacing-1, 0.25rem) + calc(1.4285714286 * 1em)/2 - calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2)/2);
}
.k-grid .k-grid-sm .k-table-th > .k-cell-inner,
.k-grid-sm .k-table-th > .k-cell-inner {
  margin-block: calc(var(--kendo-spacing-1, 0.25rem) * -1);
  margin-inline: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-grid .k-grid-sm .k-table-th > .k-cell-inner > .k-link,
.k-grid-sm .k-table-th > .k-cell-inner > .k-link {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-grid-sm .k-grouping-row .k-icon,
.k-grid-sm .k-grouping-row .k-icon {
  margin-inline-start: calc(8px - var(--kendo-spacing-2, 0.5rem));
}
.k-grid .k-grid-sm .k-grouping-dropclue,
.k-grid-sm .k-grouping-dropclue {
  height: calc(calc(1em + var(--kendo-spacing-0\.5, 0.125rem) * 2 + 2px) + 6px);
}
.k-grid .k-grid-sm .k-hierarchy-cell,
.k-grid .k-grid-sm .k-drag-cell,
.k-grid-sm .k-hierarchy-cell,
.k-grid-sm .k-drag-cell {
  padding: 0;
}
.k-grid .k-grid-sm .k-hierarchy-cell > .k-icon,
.k-grid .k-grid-sm .k-drag-cell > .k-icon,
.k-grid-sm .k-hierarchy-cell > .k-icon,
.k-grid-sm .k-drag-cell > .k-icon {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: 0;
}
.k-grid .k-grid-sm .k-edit-cell,
.k-grid .k-grid-sm .k-command-cell,
.k-grid .k-grid-sm .k-grid-edit-row td,
.k-grid .k-grid-sm .k-grid-edit-row .k-table-td,
.k-grid-sm .k-edit-cell,
.k-grid-sm .k-command-cell,
.k-grid-sm .k-grid-edit-row td,
.k-grid-sm .k-grid-edit-row .k-table-td {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-grid-sm .k-filter-row td,
.k-grid .k-grid-sm .k-filter-row .k-table-td,
.k-grid .k-grid-sm .k-filter-row .k-table-th,
.k-grid-sm .k-filter-row td,
.k-grid-sm .k-filter-row .k-table-td,
.k-grid-sm .k-filter-row .k-table-th {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-1\.5, 0.375rem);
}
.k-grid .k-grid-sm .k-filtercell > span,
.k-grid .k-grid-sm .k-filtercell .k-filtercell-wrapper,
.k-grid-sm .k-filtercell > span,
.k-grid-sm .k-filtercell .k-filtercell-wrapper {
  gap: calc(var(--kendo-spacing-1, 0.25rem) / 2);
}
.k-grid .k-grid-sm .k-selection-aggregates,
.k-grid-sm .k-selection-aggregates {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}
.k-grid .k-grid-sm .k-resizer-wrap,
.k-grid-sm .k-resizer-wrap {
  display: block;
  width: 100%;
  padding-block-start: calc(var(--kendo-spacing-0\.5, 0.125rem) - var(--kendo-spacing-0\.5, 0.125rem));
  padding-block-end: var(--kendo-spacing-0\.5, 0.125rem);
  position: absolute;
  background: none;
  cursor: row-resize;
  z-index: 2;
}

.k-grid .k-grid-md .k-table-th,
.k-grid-md .k-table-th {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
}
.k-grid .k-grid-md td,
.k-grid .k-grid-md .k-table-td,
.k-grid-md td,
.k-grid-md .k-table-td {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
}
.k-grid .k-grid-md .k-grouping-header,
.k-grid-md .k-grouping-header {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-grid-md .k-grouping-header::before,
.k-grid-md .k-grouping-header::before {
  margin-inline-start: calc(var(--kendo-spacing-2, 0.5rem) * -1);
}
.k-grid .k-grid-md .k-grid-header .k-table-th > .k-link,
.k-grid-md .k-grid-header .k-table-th > .k-link {
  margin-block: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  margin-inline: calc(var(--kendo-spacing-3, 0.75rem) * -1);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
}
.k-grid .k-grid-md .k-grid-header .k-grid-filter,
.k-grid .k-grid-md .k-grid-header .k-header-column-menu,
.k-grid .k-grid-md .k-grid-header .k-grid-header-menu,
.k-grid-md .k-grid-header .k-grid-filter,
.k-grid-md .k-grid-header .k-header-column-menu,
.k-grid-md .k-grid-header .k-grid-header-menu {
  padding: var(--kendo-spacing-1, 0.25rem);
  width: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
  height: calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px);
  bottom: calc(var(--kendo-spacing-2, 0.5rem) + calc(1.4285714286 * 1em)/2 - calc(var(--kendo-line-height, normal) * 1em + var(--kendo-spacing-1, 0.25rem) * 2)/2);
}
.k-grid .k-grid-md .k-table-th > .k-cell-inner,
.k-grid-md .k-table-th > .k-cell-inner {
  margin-block: calc(var(--kendo-spacing-2, 0.5rem) * -1);
  margin-inline: calc(var(--kendo-spacing-3, 0.75rem) * -1);
}
.k-grid .k-grid-md .k-table-th > .k-cell-inner > .k-link,
.k-grid-md .k-table-th > .k-cell-inner > .k-link {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
}
.k-grid .k-grid-md .k-grouping-row .k-icon,
.k-grid-md .k-grouping-row .k-icon {
  margin-inline-start: calc(8px - var(--kendo-spacing-3, 0.75rem));
}
.k-grid .k-grid-md .k-grouping-dropclue,
.k-grid-md .k-grouping-dropclue {
  height: calc(calc(1em + var(--kendo-spacing-1, 0.25rem) * 2 + 2px) + 6px);
}
.k-grid .k-grid-md .k-hierarchy-cell,
.k-grid .k-grid-md .k-drag-cell,
.k-grid-md .k-hierarchy-cell,
.k-grid-md .k-drag-cell {
  padding: 0;
}
.k-grid .k-grid-md .k-hierarchy-cell > .k-icon,
.k-grid .k-grid-md .k-drag-cell > .k-icon,
.k-grid-md .k-hierarchy-cell > .k-icon,
.k-grid-md .k-drag-cell > .k-icon {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: 0;
}
.k-grid .k-grid-md .k-edit-cell,
.k-grid .k-grid-md .k-command-cell,
.k-grid .k-grid-md .k-grid-edit-row td,
.k-grid .k-grid-md .k-grid-edit-row .k-table-td,
.k-grid-md .k-edit-cell,
.k-grid-md .k-command-cell,
.k-grid-md .k-grid-edit-row td,
.k-grid-md .k-grid-edit-row .k-table-td {
  padding-block: calc(var(--kendo-spacing-1\.5, 0.375rem) / 2);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
}
.k-grid .k-grid-md .k-filter-row td,
.k-grid .k-grid-md .k-filter-row .k-table-td,
.k-grid .k-grid-md .k-filter-row .k-table-th,
.k-grid-md .k-filter-row td,
.k-grid-md .k-filter-row .k-table-td,
.k-grid-md .k-filter-row .k-table-th {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-grid-md .k-filtercell > span,
.k-grid .k-grid-md .k-filtercell .k-filtercell-wrapper,
.k-grid-md .k-filtercell > span,
.k-grid-md .k-filtercell .k-filtercell-wrapper {
  gap: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}
.k-grid .k-grid-md .k-selection-aggregates,
.k-grid-md .k-selection-aggregates {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-grid .k-grid-md .k-resizer-wrap,
.k-grid-md .k-resizer-wrap {
  display: block;
  width: 100%;
  padding-block-start: calc(calc(var(--kendo-spacing-1\.5, 0.375rem) / 2) - var(--kendo-spacing-0\.5, 0.125rem));
  padding-block-end: calc(var(--kendo-spacing-1\.5, 0.375rem) / 2);
  position: absolute;
  background: none;
  cursor: row-resize;
  z-index: 2;
}

.k-loading-pdf-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.k-loading-pdf-mask > .k-i-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 64px;
}
.k-loading-pdf-mask .k-loading-pdf-progress {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.k-pdf-export .k-loading-pdf-mask {
  display: none;
}

.k-grid-pdf-export-element {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
.k-grid-pdf-export-element .k-filter-row {
  display: none;
}

.k-pdf-export-shadow .k-grid {
  float: left;
  width: auto !important;
}
.k-pdf-export-shadow .k-grid,
.k-pdf-export-shadow .k-grid-content,
.k-pdf-export-shadow .k-grid-content-locked {
  height: auto !important;
  overflow: visible;
}
.k-pdf-export-shadow .k-grid-header-locked + .k-grid-header-wrap, .k-pdf-export-shadow .k-grid-header-locked + .k-grid-content, .k-pdf-export-shadow .k-grid-header-locked + .k-grid-footer-wrap {
  width: auto !important;
}
.k-pdf-export-shadow .k-grid-header,
.k-pdf-export-shadow .k-grid[data-role=grid] .k-grid-footer {
  padding: 0 !important;
}

.k-filter-menu-popup {
  min-width: 230px;
  max-width: 320px;
}

.k-filter-menu {
  box-sizing: border-box;
}

.k-filter-menu-container {
  padding: var(--kendo-spacing-2, 0.5rem);
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: var(--kendo-spacing-2, 0.5rem);
}
.k-filter-menu-container .k-filter-and {
  width: min-content;
  align-self: start;
}
.k-filter-menu-container .k-actions, .k-filter-menu-container .k-edit-buttons,
.k-filter-menu-container .k-action-buttons,
.k-filter-menu-container .k-columnmenu-actions, .k-filter-menu-container .k-form-buttons {
  margin: 0;
  padding: 0;
}
.k-filter-menu-container kendo-numeric-filter-menu,
.k-filter-menu-container kendo-grid-string-filter-menu,
.k-filter-menu-container kendo-grid-date-filter-menu,
.k-filter-menu-container kendo-grid-numeric-filter-menu,
.k-filter-menu-container kendo-grid-filter-menu-input-wrapper,
.k-filter-menu-container kendo-treelist-string-filter-menu,
.k-filter-menu-container kendo-treelist-date-filter-menu,
.k-filter-menu-container kendo-treelist-numeric-filter-menu,
.k-filter-menu-container kendo-treelist-filter-menu-input-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: var(--kendo-spacing-2, 0.5rem);
}

.k-filter-menu.k-popup .k-filter-menu-container,
.k-grid-filter-popup.k-popup .k-filter-menu-container {
  min-width: 230px;
  max-width: 320px;
}

.k-popup .k-multicheck-wrap {
  margin: 0;
  padding: 0;
  max-height: 300px;
  white-space: nowrap;
  overflow: auto;
  list-style: none;
}
.k-popup .k-multicheck-wrap .k-item,
.k-popup .k-multicheck-wrap .k-check-all-wrap {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  flex-flow: row nowrap;
}

.k-filter-selected-items {
  font-weight: bold;
  line-height: normal;
  white-space: nowrap;
}

.k-autofitting {
  width: auto !important;
  table-layout: auto !important;
}
.k-autofitting .k-table-th,
.k-autofitting td,
.k-autofitting .k-table-td {
  white-space: nowrap !important;
}
.k-autofitting .k-detail-row {
  display: none !important;
}

.k-column-menu-popup,
.k-grid-columnmenu-popup {
  min-width: 230px;
  box-sizing: border-box;
}
.k-column-menu-popup.k-popup,
.k-grid-columnmenu-popup.k-popup {
  max-width: 320px;
}
.k-column-menu-popup .k-actions, .k-column-menu-popup .k-edit-buttons,
.k-column-menu-popup .k-action-buttons,
.k-column-menu-popup .k-columnmenu-actions, .k-column-menu-popup .k-form-buttons,
.k-grid-columnmenu-popup .k-actions,
.k-grid-columnmenu-popup .k-edit-buttons,
.k-grid-columnmenu-popup .k-action-buttons,
.k-grid-columnmenu-popup .k-columnmenu-actions,
.k-grid-columnmenu-popup .k-form-buttons {
  margin: 0;
}

.k-column-menu {
  box-sizing: border-box;
}
.k-column-menu .k-menu:not(.k-context-menu) {
  font-weight: 400;
}
.k-column-menu .k-expander {
  border: 0;
  background: inherit;
}
.k-column-menu .k-expander .k-columnmenu-item {
  display: flex;
  align-items: center;
}

.k-column-menu-tabbed {
  border-radius: var(--kendo-border-radius-md, 0.25rem) var(--kendo-border-radius-md, 0.25rem) 0 0;
}
.k-column-menu-tabbed .k-tabstrip-items {
  margin: -1px -1px 0;
}
.k-column-menu-tabbed .k-tabstrip-items .k-item {
  flex: 1;
}
.k-column-menu-tabbed .k-tabstrip-items .k-link {
  justify-content: center;
}
.k-column-menu-tabbed .k-tabstrip-content {
  padding-inline: var(--kendo-spacing-0, 0px);
  padding-block: var(--kendo-spacing-2, 0.5rem);
  border-width: 0;
}

.k-column-list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.k-column-chooser-title,
.k-column-list-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-column-list-item {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  position: relative;
}
.k-column-list-item .k-checkbox-label {
  margin: 0;
}
.k-column-list-item:focus {
  outline: none;
}

.k-columns-items-wrap {
  padding-block: var(--kendo-spacing-1\.5, 0.375rem);
  padding-inline: var(--kendo-spacing-0, 0px);
}

.k-columnmenu-item {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  outline: 0;
  cursor: pointer;
}
.k-columnmenu-item > .k-icon,
.k-columnmenu-item > .k-expander-indicator {
  margin-inline-end: var(--kendo-spacing-1, 0.25rem);
}

.k-columnmenu-item-wrapper + .k-columnmenu-item-wrapper {
  border-top: 1px solid;
  border-top-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-columnmenu-item-content {
  overflow: hidden;
}

.k-column-menu-group-header {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  display: flex;
  flex: 0 0 auto;
  position: relative;
  user-select: none;
}

.k-column-menu-group-header-text {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: var(--kendo-font-size-sm, inherit);
  line-height: var(--kendo-line-height, normal);
  font-weight: bold;
  text-transform: uppercase;
  flex: 1 1 auto;
}

.k-rtl .k-grid-virtual .k-grid-content .k-grid-table-wrap,
.k-rtl .k-grid-virtual .k-grid-content .k-grid-table,
.k-rtl .k-grid-virtual .k-grid-content > .k-height-container,
[dir=rtl] .k-grid-virtual .k-grid-content .k-grid-table-wrap,
[dir=rtl] .k-grid-virtual .k-grid-content .k-grid-table,
[dir=rtl] .k-grid-virtual .k-grid-content > .k-height-container,
.k-rtl.k-grid-virtual .k-grid-content .k-grid-table-wrap,
.k-rtl.k-grid-virtual .k-grid-content .k-grid-table,
.k-rtl.k-grid-virtual .k-grid-content > .k-height-container,
[dir=rtl].k-grid-virtual .k-grid-content .k-grid-table-wrap,
[dir=rtl].k-grid-virtual .k-grid-content .k-grid-table,
[dir=rtl].k-grid-virtual .k-grid-content > .k-height-container {
  float: right;
}

.k-grid-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row > .k-table-th,
.k-filter-row > td,
.k-filter-row > .k-table-td {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-grid .k-table {
  background-color: inherit;
}

.k-grid-toolbar {
  border-color: inherit;
  box-shadow: none;
}

.k-grid-content {
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-group-footer td,
.k-grouping-row td,
.k-group-footer .k-table-td,
.k-grouping-row .k-table-td,
.k-table-tbody .k-group-cell {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-grouping-dropclue::before {
  border-color: var(--kendo-color-on-app-surface, #3d3d3d) transparent transparent;
}
.k-grouping-dropclue::after {
  background-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-grid {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-grid .k-table-row.k-table-alt-row {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 5%, transparent);
}
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 11%, transparent);
}
.k-grid td.k-selected,
.k-grid .k-table-row.k-selected > td,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected > .k-table-td {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent);
}
.k-grid .k-table-th:focus, .k-grid .k-table-th.k-focus,
.k-grid td:focus,
.k-grid td.k-focus,
.k-grid .k-table-td:focus,
.k-grid .k-table-td.k-focus,
.k-grid .k-master-row > td:focus,
.k-grid .k-master-row > td.k-focus,
.k-grid .k-grouping-row > td:focus,
.k-grid .k-grouping-row > td.k-focus,
.k-grid .k-detail-row > td:focus,
.k-grid .k-detail-row > td.k-focus,
.k-grid .k-group-footer > td:focus,
.k-grid .k-group-footer > td.k-focus,
.k-grid .k-master-row > .k-table-td:focus,
.k-grid .k-master-row > .k-table-td.k-focus,
.k-grid .k-grouping-row > .k-table-td:focus,
.k-grid .k-grouping-row > .k-table-td.k-focus,
.k-grid .k-detail-row > .k-table-td:focus,
.k-grid .k-detail-row > .k-table-td.k-focus,
.k-grid .k-group-footer > .k-table-td:focus,
.k-grid .k-group-footer > .k-table-td.k-focus,
.k-grid .k-grid-pager:focus,
.k-grid .k-grid-pager.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-grid .k-grid-filter,
.k-grid .k-header-column-menu,
.k-grid .k-grid-header-menu,
.k-grid .k-hierarchy-cell .k-icon {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-grid .k-grouping-row {
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-grid .k-grouping-row .k-icon {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-grid .k-grouping-row .k-grid-content-sticky {
  border-color: rgba(0, 0, 0, 0.3);
  border-top-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-grid .k-grid-header-locked,
.k-grid .k-grid-content-locked,
.k-grid .k-grid-header-locked .k-table-th,
.k-grid .k-grid-content-locked td,
.k-grid .k-grid-content-locked .k-table-td {
  border-color: rgba(0, 0, 0, 0.3);
}
.k-grid .k-grid-content-locked .k-group-footer td,
.k-grid .k-grid-content-locked .k-group-footer .k-table-td,
.k-grid .k-grid-content-locked .k-group-cell {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-grid .k-grid-content-locked .k-grouping-row + .k-table-row td,
.k-grid .k-grid-content-locked .k-grouping-row + .k-table-row .k-table-td {
  border-top-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-grid .k-grid-content-locked .k-selected td,
.k-grid .k-grid-content-locked .k-selected .k-table-td {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent);
}
.k-grid .k-grid-content-locked .k-selected.k-table-alt-row td,
.k-grid .k-grid-content-locked .k-selected.k-table-alt-row .k-table-td {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 24%, transparent);
}
.k-grid .k-grid-content-locked .k-selected:hover td,
.k-grid .k-grid-content-locked .k-selected.k-hover td,
.k-grid .k-grid-content-locked .k-selected:hover .k-table-td,
.k-grid .k-grid-content-locked .k-selected.k-hover .k-table-td {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 24%, transparent);
}
.k-grid .k-grid-header-locked .k-table-th {
  border-bottom-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

col.k-sorted,
.k-table-th.k-sorted {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 3%, transparent);
}

.k-grid-header .k-sort-icon,
.k-grid-header .k-sort-order {
  color: var(--kendo-color-primary, #ff6358);
}
.k-grid-header .k-grid-filter:hover,
.k-grid-header .k-header-column-menu:hover,
.k-grid-header .k-grid-header-menu:hover,
.k-grid-header .k-hierarchy-cell .k-icon:hover {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-grid-header .k-grid-filter:focus, .k-grid-header .k-grid-filter.k-focus,
.k-grid-header .k-header-column-menu:focus,
.k-grid-header .k-header-column-menu.k-focus,
.k-grid-header .k-grid-header-menu:focus,
.k-grid-header .k-grid-header-menu.k-focus,
.k-grid-header .k-hierarchy-cell .k-icon:focus,
.k-grid-header .k-hierarchy-cell .k-icon.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.k-grid-header .k-grid-filter.k-active,
.k-grid-header .k-header-column-menu.k-active,
.k-grid-header .k-grid-header-menu.k-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-active {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-grid-header .k-table-th.k-grid-header-sticky,
.k-grid-header td.k-grid-header-sticky,
.k-grid-header .k-table-td.k-grid-header-sticky,
.k-grid-header .k-grid-header-sticky.k-sorted {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
  border-right-color: rgba(0, 0, 0, 0.3);
  border-left-color: rgba(0, 0, 0, 0.3);
}

.k-grid-footer {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-grid-footer .k-grid-footer-sticky {
  border-left-color: rgba(0, 0, 0, 0.3);
  border-right-color: rgba(0, 0, 0, 0.3);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-selection-aggregates {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-master-row .k-grid-content-sticky {
  border-color: rgba(0, 0, 0, 0.3);
  border-top-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-master-row .k-grid-row-sticky {
  border-top-color: rgba(0, 0, 0, 0.3);
  border-bottom-color: rgba(0, 0, 0, 0.3);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-master-row.k-table-alt-row .k-grid-content-sticky,
.k-master-row.k-table-alt-row .k-grid-row-sticky {
  background-color: var(--kendo-color-base, #f5f5f5);
}
.k-master-row.k-table-row.k-selected td.k-grid-content-sticky, .k-master-row.k-table-row.k-selected .k-table-td.k-grid-row-sticky, .k-master-row.k-table-row td.k-grid-content-sticky.k-selected, .k-master-row.k-table-row .k-table-td.k-grid-content-sticky.k-selected {
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-master-row.k-table-row.k-selected td.k-grid-content-sticky::before, .k-master-row.k-table-row.k-selected .k-table-td.k-grid-row-sticky::before, .k-master-row.k-table-row td.k-grid-content-sticky.k-selected::before, .k-master-row.k-table-row .k-table-td.k-grid-content-sticky.k-selected::before {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent);
}
.k-master-row.k-selected.k-table-alt-row td.k-grid-content-sticky, .k-master-row.k-selected.k-table-alt-row .k-table-td.k-grid-row-sticky, .k-master-row.k-table-alt-row td.k-grid-content-sticky.k-selected, .k-master-row.k-table-alt-row .k-table-td.k-grid-content-sticky.k-selected {
  background-color: var(--kendo-color-base, #f5f5f5);
}
.k-master-row.k-selected.k-table-alt-row td.k-grid-content-sticky::before, .k-master-row.k-selected.k-table-alt-row .k-table-td.k-grid-row-sticky::before, .k-master-row.k-table-alt-row td.k-grid-content-sticky.k-selected::before, .k-master-row.k-table-alt-row .k-table-td.k-grid-content-sticky.k-selected::before {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 24%, transparent);
}
.k-master-row:hover .k-grid-content-sticky, .k-master-row:hover .k-grid-row-sticky, .k-master-row.k-hover .k-grid-content-sticky, .k-master-row.k-hover .k-grid-row-sticky {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-master-row.k-selected:hover td.k-grid-content-sticky, .k-master-row.k-selected:hover .k-table-td.k-grid-row-sticky, .k-master-row.k-selected.k-hover td.k-grid-content-sticky, .k-master-row.k-selected.k-hover .k-table-td.k-grid-row-sticky, .k-master-row:hover td.k-grid-content-sticky.k-selected, .k-master-row.k-hover td.k-grid-content-sticky.k-selected, .k-master-row:hover .k-table-td.k-grid-content-sticky.k-selected, .k-master-row.k-hover .k-table-td.k-grid-content-sticky.k-selected {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-master-row.k-selected:hover td.k-grid-content-sticky::before, .k-master-row.k-selected:hover .k-table-td.k-grid-row-sticky::before, .k-master-row.k-selected.k-hover td.k-grid-content-sticky::before, .k-master-row.k-selected.k-hover .k-table-td.k-grid-row-sticky::before, .k-master-row:hover td.k-grid-content-sticky.k-selected::before, .k-master-row.k-hover td.k-grid-content-sticky.k-selected::before, .k-master-row:hover .k-table-td.k-grid-content-sticky.k-selected::before, .k-master-row.k-hover .k-table-td.k-grid-content-sticky.k-selected::before {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 24%, transparent);
}

kendo-grid.k-grid .k-grid-content-sticky {
  border-top-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  border-left-color: rgba(0, 0, 0, 0.3);
  border-right-color: rgba(0, 0, 0, 0.3);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
kendo-grid.k-grid .k-grid-content-sticky:hover, kendo-grid.k-grid .k-grid-content-sticky.k-hover {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
kendo-grid.k-grid .k-grid-row-sticky td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td {
  border-top-color: rgba(0, 0, 0, 0.3);
  border-bottom-color: rgba(0, 0, 0, 0.3);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
kendo-grid.k-grid .k-grid-row-sticky:hover td, kendo-grid.k-grid .k-grid-row-sticky.k-hover td, kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td, kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
kendo-grid.k-grid .k-table-alt-row .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row .k-table-td {
  background-color: var(--kendo-color-base, #f5f5f5);
}
kendo-grid.k-grid .k-table-row.k-selected .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-grid-row-sticky td,
kendo-grid.k-grid .k-grid-row-sticky td.k-selected,
kendo-grid.k-grid .k-selected.k-grid-row-sticky .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td.k-selected,
kendo-grid.k-grid .k-selected.k-grid-content-sticky {
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
kendo-grid.k-grid .k-table-row.k-selected .k-grid-content-sticky::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky td::before,
kendo-grid.k-grid .k-grid-row-sticky td.k-selected::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky .k-table-td::before,
kendo-grid.k-grid .k-grid-row-sticky .k-table-td.k-selected::before,
kendo-grid.k-grid .k-selected.k-grid-content-sticky::before {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 25%, transparent);
}
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky .k-table-td,
kendo-grid.k-grid .k-table-alt-row .k-selected.k-grid-content-sticky {
  background-color: var(--kendo-color-base, #f5f5f5);
}
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row .k-grid-content-sticky::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky .k-table-td::before,
kendo-grid.k-grid .k-table-alt-row .k-selected.k-grid-content-sticky::before {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 24%, transparent);
}
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row:hover td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row.k-hover td,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row:hover .k-table-td,
kendo-grid.k-grid .k-grid-row-sticky.k-table-alt-row.k-hover .k-table-td,
kendo-grid.k-grid .k-table-alt-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-alt-row.k-hover .k-grid-content-sticky {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
kendo-grid.k-grid .k-table-row.k-selected:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-selected.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover .k-table-td,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover .k-table-td,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row:hover .k-grid-content-sticky,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row.k-hover .k-grid-content-sticky,
kendo-grid.k-grid .k-grid-row-sticky:hover td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td.k-selected,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td.k-selected,
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky.k-selected,
kendo-grid.k-grid .k-table-row.k-hover .k-grid-content-sticky.k-selected {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
kendo-grid.k-grid .k-table-row.k-selected:hover .k-grid-content-sticky::before,
kendo-grid.k-grid .k-table-row.k-selected.k-hover .k-grid-content-sticky::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover td::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover td::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky:hover .k-table-td::before,
kendo-grid.k-grid .k-selected.k-grid-row-sticky.k-hover .k-table-td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky:hover .k-table-td::before,
kendo-grid.k-grid .k-selected.k-table-alt-row.k-grid-row-sticky.k-hover .k-table-td::before,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row:hover .k-grid-content-sticky::before,
kendo-grid.k-grid .k-table-row.k-selected.k-table-alt-row.k-hover .k-grid-content-sticky::before,
kendo-grid.k-grid .k-grid-row-sticky:hover td.k-selected::before,
kendo-grid.k-grid .k-grid-row-sticky.k-hover td.k-selected::before,
kendo-grid.k-grid .k-grid-row-sticky:hover .k-table-td.k-selected::before,
kendo-grid.k-grid .k-grid-row-sticky.k-hover .k-table-td.k-selected::before,
kendo-grid.k-grid .k-table-row:hover .k-grid-content-sticky.k-selected::before,
kendo-grid.k-grid .k-table-row.k-hover .k-grid-content-sticky.k-selected::before {
  background-color: color-mix(in srgb, var(--kendo-color-primary, #ff6358) 24%, transparent);
}

.k-grouping-row .k-grid-content-sticky {
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-grouping-row:hover .k-grid-content-sticky, .k-grouping-row.k-hover .k-grid-content-sticky {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}

.k-column-list-item:hover,
.k-columnmenu-item:hover {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-hover, #ebebeb);
}

.k-column-list-item:focus,
.k-column-list-item.k-focus,
.k-columnmenu-item:focus,
.k-columnmenu-item.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-columnmenu-item.k-selected {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-column-menu .k-menu:not(.k-context-menu) {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item:hover, .k-column-menu .k-menu:not(.k-context-menu) .k-item.k-hover {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-selected {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-column-menu .k-menu:not(.k-context-menu) .k-item:focus, .k-column-menu .k-menu:not(.k-context-menu) .k-item.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-column-menu-tabbed {
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-column-menu-group-header-text {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-check-all-wrap {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-grid-norecords-template {
  background-color: var(--kendo-color-surface-alt, #ffffff);
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-resizer-wrap.k-hover .k-row-resizer {
  background-color: color-mix(in srgb, var(--kendo-color-on-base, #3d3d3d) 20%, transparent);
}
.k-resizer-wrap.k-active .k-row-resizer {
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-treeview {
  padding: 0;
  border-width: 0;
  background: none;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: block;
  cursor: default;
  overflow: auto;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-treeview *,
.k-treeview *::before,
.k-treeview *::after {
  box-sizing: border-box;
}

.k-treeview-filter {
  padding: var(--kendo-spacing-2, 0.5rem);
  display: block;
  position: relative;
  flex: none;
}

.k-treeview-group {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  outline: 0;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-treeview-group.ng-animating {
  overflow: hidden;
}

.k-treeview-top,
.k-treeview-mid,
.k-treeview-bot {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.k-treeview-item {
  outline-style: none;
  margin: 0;
  padding: 0 0 0 24px;
  border-width: 0;
  display: block;
}

.k-treeview-toggle {
  margin-inline-start: -24px;
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
}

.k-treeview-toggle .k-icon {
  padding: var(--kendo-spacing-1, 0.25rem);
  box-sizing: content-box;
}

.k-treeview-loading {
  margin-right: var(--kendo-spacing-1, 0.25rem);
}

.k-treeview .k-checkbox-wrap,
.k-treeview .k-checkbox-wrapper {
  align-self: center;
}

.k-treeview-leaf {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
  border: 0px solid transparent;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  position: relative;
}
.k-treeview-leaf .k-icon,
.k-treeview-leaf .k-image,
.k-treeview-leaf .k-sprite {
  margin-right: var(--kendo-spacing-1, 0.25rem);
}
.k-treeview-leaf.k-focus {
  z-index: 1;
}

.k-treeview .k-treeview-load-more-button {
  cursor: pointer;
}
.k-treeview .k-treeview-load-more-button:hover, .k-treeview .k-treeview-load-more-button.k-hover, .k-treeview .k-treeview-load-more-button:focus, .k-treeview .k-treeview-load-more-button.k-focus {
  text-decoration: underline;
}

.k-rtl .k-treeview .k-treeview-item,
[dir=rtl] .k-treeview .k-treeview-item,
.k-treeview.k-rtl .k-treeview-item,
.k-treeview[dir=rtl] .k-treeview-item {
  padding-left: 0;
  padding-right: 24px;
}
.k-rtl .k-treeview .k-treeview-loading,
[dir=rtl] .k-treeview .k-treeview-loading,
.k-treeview.k-rtl .k-treeview-loading,
.k-treeview[dir=rtl] .k-treeview-loading {
  margin-right: 0;
  margin-left: var(--kendo-spacing-1, 0.25rem);
}
.k-rtl .k-treeview .k-treeview-leaf .k-icon,
.k-rtl .k-treeview .k-treeview-leaf .k-image,
.k-rtl .k-treeview .k-treeview-leaf .k-sprite,
.k-rtl .k-treeview .k-in .k-icon,
.k-rtl .k-treeview .k-in .k-image,
.k-rtl .k-treeview .k-in .k-sprite,
[dir=rtl] .k-treeview .k-treeview-leaf .k-icon,
[dir=rtl] .k-treeview .k-treeview-leaf .k-image,
[dir=rtl] .k-treeview .k-treeview-leaf .k-sprite,
[dir=rtl] .k-treeview .k-in .k-icon,
[dir=rtl] .k-treeview .k-in .k-image,
[dir=rtl] .k-treeview .k-in .k-sprite,
.k-treeview.k-rtl .k-treeview-leaf .k-icon,
.k-treeview.k-rtl .k-treeview-leaf .k-image,
.k-treeview.k-rtl .k-treeview-leaf .k-sprite,
.k-treeview.k-rtl .k-in .k-icon,
.k-treeview.k-rtl .k-in .k-image,
.k-treeview.k-rtl .k-in .k-sprite,
.k-treeview[dir=rtl] .k-treeview-leaf .k-icon,
.k-treeview[dir=rtl] .k-treeview-leaf .k-image,
.k-treeview[dir=rtl] .k-treeview-leaf .k-sprite,
.k-treeview[dir=rtl] .k-in .k-icon,
.k-treeview[dir=rtl] .k-in .k-image,
.k-treeview[dir=rtl] .k-in .k-sprite {
  margin-right: 0;
  margin-left: var(--kendo-spacing-1, 0.25rem);
}

.k-treeview-sm {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-treeview-sm .k-treeview-leaf {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-treeview-sm .k-checkbox-wrap {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.k-treeview-md {
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
}
.k-treeview-md .k-treeview-leaf {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-treeview-md .k-checkbox-wrap {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.k-treeview-lg {
  font-size: var(--kendo-font-size-lg, inherit);
  line-height: var(--kendo-line-height-lg, normal);
}
.k-treeview-lg .k-treeview-leaf {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}
.k-treeview-lg .k-checkbox-wrap {
  padding-inline: var(--kendo-spacing-1, 0.25rem);
}

.k-treeview {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-treeview-leaf:hover, .k-treeview-leaf.k-hover {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-hover, #ebebeb);
}
.k-treeview-leaf:focus, .k-treeview-leaf.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}
.k-treeview-leaf.k-selected {
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-treeview .k-treeview-load-more-button {
  color: var(--kendo-color-primary, #ff6358);
  background-color: transparent;
}
.k-treeview .k-treeview-load-more-button:hover, .k-treeview .k-treeview-load-more-button.k-hover {
  color: var(--kendo-color-primary-hover, #ea5a51);
  background-color: transparent;
}
.k-treeview .k-treeview-load-more-button:focus, .k-treeview .k-treeview-load-more-button.k-focus {
  color: var(--kendo-color-primary-hover, #ea5a51);
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}

.k-popup-dropdowntree .k-treeview,
.k-dropdowntree-popup .k-treeview,
.k-multiselecttree-popup .k-treeview {
  padding-block: calc(var(--kendo-spacing-2, 0.5rem) / 2);
  padding-inline: calc(var(--kendo-spacing-2, 0.5rem) / 2);
}
.k-popup-dropdowntree .k-check-all,
.k-dropdowntree-popup .k-check-all,
.k-multiselecttree-popup .k-check-all {
  padding-block: var(--kendo-spacing-2, 0.5rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-multiselecttree .k-input-values {
  width: auto;
  max-width: 100%;
}
.k-multiselecttree .k-input-inner::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-color-preview {
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  box-sizing: border-box;
  border-style: solid;
  display: inline-flex;
  flex-flow: row nowrap;
  position: relative;
  overflow: hidden;
}
.k-color-preview *,
.k-color-preview *::before,
.k-color-preview *::after {
  box-sizing: border-box;
}

.k-coloreditor-current-color {
  cursor: pointer;
}

.k-icon-color-preview {
  border-width: 0;
  border-radius: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.k-icon-color-preview .k-color-preview-mask {
  width: calc(16px - 2px);
  height: 2px;
}

.k-color-preview-mask {
  width: 100%;
  height: 100%;
  position: relative;
}

.k-color-preview-mask::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAuSURBVHgBxYyxDQAwDMJIL+YT+DjtzFRliUfLcklqBCRT4eCTxbD6kdL2/LgYXqpvCbs3kBv/AAAAAElFTkSuQmCC");
  background-size: contain;
  background-position: 0 0;
}

.k-no-color .k-color-preview-mask::before {
  content: "";
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' version='1.1'%3e%3cline x1='0' x2='20' y1='0' y2='20' stroke='var%28--kendo-color-error, %23f31700%29' stroke-width='1'/%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: 0 0;
}

.k-color-preview {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}
.k-color-preview:hover, .k-color-preview.k-hover {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-colorpalette {
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  font-size: var(--kendo-font-size, inherit);
  font-family: var(--kendo-font-family, inherit);
  line-height: 0;
  display: inline-flex;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-colorpalette *,
.k-colorpalette *::before,
.k-colorpalette *::after {
  box-sizing: border-box;
}

.k-colorpalette-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  position: relative;
}

.k-colorpalette-tile {
  width: var(--kendo-spacing-6, 1.5rem);
  height: var(--kendo-spacing-6, 1.5rem);
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}
.k-colorpalette-tile:hover, .k-colorpalette-tile.k-hover {
  position: relative;
  z-index: 101;
}
.k-colorpalette-tile:focus, .k-colorpalette-tile.k-focus, .k-colorpalette-tile.k-selected, .k-colorpalette-tile.k-selected:hover {
  position: relative;
  z-index: 100;
}

.k-colorpalette-tile:focus, .k-colorpalette-tile.k-focus {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.k-colorpalette-tile:hover, .k-colorpalette-tile.k-hover {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.8);
}
.k-colorpalette-tile.k-selected, .k-colorpalette-tile.k-selected:hover {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3), inset 0 0 0 1px white;
}

.k-slider {
  border: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  background: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-slider *,
.k-slider *::before,
.k-slider *::after {
  box-sizing: border-box;
}
.k-slider .k-label {
  width: auto;
  font-size: 0.92em;
  line-height: 1;
  white-space: nowrap;
  position: absolute;
}
.k-slider .k-tick,
.k-slider .k-slider-track {
  cursor: pointer;
}
.k-slider .k-tick {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  position: relative;
}
.k-slider.k-disabled .k-tick,
.k-slider.k-disabled .k-slider-track,
.k-slider.k-disabled .k-draghandle {
  cursor: default;
}

.k-slider {
  width: min-content;
  height: min-content;
  gap: calc(14px / 2);
}
.k-slider .k-button {
  flex: none;
}
.k-slider .k-slider-track-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-flow: inherit;
  position: relative;
  touch-action: none;
}
.k-slider .k-slider-track-wrap .k-slider-items {
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1 1 100%;
  display: flex;
  flex-flow: inherit;
  justify-content: space-between;
  user-select: none;
}
.k-slider .k-slider-track-wrap .k-tick {
  flex: 0 0 1px;
}
.k-slider .k-slider-track-wrap .k-tick-horizontal {
  width: 1px;
  min-height: 100%;
}
.k-slider .k-slider-track-wrap .k-tick-vertical {
  width: 100%;
  min-height: 1px;
}
.k-slider .k-slider-track-wrap .k-draghandle {
  position: absolute;
}
.k-slider-horizontal .k-slider-track-wrap {
  height: 26px;
}
.k-slider-horizontal .k-slider-track-wrap .k-slider-track {
  width: 100%;
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle {
  top: 50%;
  transform: translate(-50%, -50%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle:focus,
.k-slider-horizontal .k-slider-track-wrap .k-draghandle:active {
  transform: translate(-50%, -50%) scale(1);
}
.k-slider-horizontal .k-slider-track-wrap .k-slider-selection {
  width: calc((var(--kendo-slider-end, 0) - var(--kendo-slider-start, 0)) * 1%);
  inset-inline-start: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle-start {
  inset-inline-start: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-horizontal .k-slider-track-wrap .k-draghandle-end {
  inset-inline-start: calc(var(--kendo-slider-end, 0) * 1%);
}
.k-slider-vertical .k-slider-track-wrap {
  width: 26px;
}
.k-slider-vertical .k-slider-track-wrap .k-slider-track {
  height: 100%;
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle {
  left: 50%;
  transform: translate(-50%, 50%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle:focus,
.k-slider-vertical .k-slider-track-wrap .k-draghandle:active {
  transform: translate(-50%, 50%) scale(1);
}
.k-slider-vertical .k-slider-track-wrap .k-slider-selection {
  bottom: calc(var(--kendo-slider-start, 0) * 1%);
  height: calc((var(--kendo-slider-end, 0) - var(--kendo-slider-start, 0)) * 1%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle-start {
  bottom: calc(var(--kendo-slider-start, 0) * 1%);
}
.k-slider-vertical .k-slider-track-wrap .k-draghandle-end {
  bottom: calc(var(--kendo-slider-end, 0) * 1%);
}

.k-slider-vertical {
  height: 200px;
  flex-flow: column-reverse nowrap;
}
.k-slider-vertical .k-tick {
  text-align: end;
  margin-left: 2px;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  text-align: start;
}
.k-slider-vertical .k-tick {
  background-position: -94px center;
}
.k-slider-vertical .k-slider-topleft .k-tick {
  background-position: -124px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick {
  background-position: -154px center;
}
.k-slider-vertical .k-tick-large {
  display: flex;
  align-items: center;
  background-position: -4px center;
}
.k-slider-vertical .k-slider-topleft .k-tick-large {
  background-position: -34px center;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large {
  background-position: -64px center;
}
.k-slider-vertical .k-first {
  background-position: -94px 100%;
}
.k-slider-vertical .k-tick-large.k-first {
  background-position: -4px 100%;
}
.k-slider-vertical .k-slider-topleft .k-first {
  background-position: -124px 100%;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
  background-position: -34px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-first {
  background-position: -154px 100%;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
  background-position: -64px 100%;
}
.k-slider-vertical .k-last {
  background-position: -94px 0;
}
.k-slider-vertical .k-tick-large.k-last {
  background-position: -4px 0;
}
.k-slider-vertical .k-slider-topleft .k-last {
  background-position: -124px 0;
}
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
  background-position: -34px 0;
}
.k-slider-vertical .k-slider-bottomright .k-last {
  background-position: -154px 0;
}
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
  background-position: -64px 0;
}
.k-slider-vertical .k-label {
  text-align: start;
  inset-inline-start: 120%;
  top: 50%;
  transform: translateY(-50%);
}
.k-slider-vertical .k-first .k-label {
  top: 100%;
}
.k-slider-vertical .k-last .k-label {
  top: 0;
}
.k-slider-vertical .k-slider-topleft .k-label {
  inset-inline-start: auto;
  inset-inline-end: 120%;
}

.k-slider-horizontal {
  width: 200px;
  flex-flow: row nowrap;
}
.k-slider-horizontal .k-tick {
  background-position: center -92px;
}
.k-slider-horizontal .k-slider-topleft .k-tick {
  background-position: center -122px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick {
  background-position: center -152px;
}
.k-slider-horizontal .k-tick-large {
  background-position: center -2px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large {
  background-position: center -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large {
  background-position: center -62px;
}
.k-slider-horizontal .k-first {
  background-position: 0 -92px;
}
.k-slider-horizontal .k-tick-large.k-first {
  background-position: 0 -2px;
}
.k-slider-horizontal .k-slider-topleft .k-first {
  background-position: 0 -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
  background-position: 0 -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-first {
  background-position: 0 -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
  background-position: 0 -62px;
}
.k-slider-horizontal .k-last {
  background-position: 100% -92px;
}
.k-slider-horizontal .k-tick-large.k-last {
  background-position: 100% -2px;
}
.k-slider-horizontal .k-slider-topleft .k-last {
  background-position: 100% -122px;
}
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
  background-position: 100% -32px;
}
.k-slider-horizontal .k-slider-bottomright .k-last {
  background-position: 100% -152px;
}
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
  background-position: 100% -62px;
}
.k-slider-horizontal .k-label {
  inset-inline-start: 50%;
  bottom: -1.2em;
  transform: translateX(-50%);
}
.k-slider-horizontal .k-first .k-label {
  inset-inline-start: 0;
}
.k-slider-horizontal .k-last .k-label {
  inset-inline-start: 100%;
}
.k-slider-horizontal .k-slider-topleft .k-label {
  top: -1.2em;
}

.k-slider-track,
.k-slider-selection {
  margin: 0;
  padding: 0;
  position: absolute;
}
.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection {
  height: 4px;
  left: 0;
  margin-top: -2px;
  top: 50%;
}
.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection {
  bottom: 0;
  left: 50%;
  margin-left: -2px;
  width: 4px;
}

.k-draghandle {
  background-color: transparent;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  outline: 0;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 14px;
  height: 14px;
}
.k-slider-transitions.k-slider-horizontal .k-draghandle {
  transition: inset-inline-start 0.3s ease-out, background-color 0.3s ease-out, transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.k-slider-transitions.k-slider-vertical .k-draghandle {
  transition: bottom 0.3s ease-out, background-color 0.3s ease-out, transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.k-draghandle.k-pressed {
  transition: none;
}

.k-slider-transitions.k-slider-horizontal .k-slider-selection {
  transition: width 0.3s ease-out;
}
.k-slider-transitions.k-slider-vertical .k-slider-selection {
  transition: height 0.3s ease-out;
}
.k-slider-selection.k-pressed {
  transition: none;
}

.k-slider.k-readonly .k-button,
.k-slider.k-readonly .k-slider-track,
.k-slider.k-readonly .k-tick,
.k-slider.k-readonly .k-draghandle {
  pointer-events: none;
}

.k-slider-tooltip .k-callout-n,
.k-slider-tooltip .k-callout-s {
  margin-inline-start: -3px;
}
.k-slider-tooltip .k-callout-w,
.k-slider-tooltip .k-callout-e {
  margin-top: -3px;
}

.k-slider-rtl.k-slider-horizontal .k-draghandle, .k-slider-horizontal.k-slider[dir=rtl] .k-draghandle,
[dir=rtl] .k-slider-horizontal.k-slider .k-draghandle,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle {
  transform: translate(50%, -50%);
}
.k-slider-rtl.k-slider-horizontal .k-draghandle:focus, .k-slider-horizontal.k-slider[dir=rtl] .k-draghandle:focus,
[dir=rtl] .k-slider-horizontal.k-slider .k-draghandle:focus,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle:focus,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle:focus,
.k-slider-rtl.k-slider-horizontal .k-draghandle:active,
.k-slider-horizontal.k-slider[dir=rtl] .k-draghandle:active,
[dir=rtl] .k-slider-horizontal.k-slider .k-draghandle:active,
.k-slider-horizontal.k-slider.k-rtl .k-draghandle:active,
.k-rtl .k-slider-horizontal.k-slider .k-draghandle:active {
  transform: translate(50%, -50%) scale(1);
}
.k-slider-rtl.k-slider-horizontal .k-label, .k-slider-horizontal.k-slider[dir=rtl] .k-label,
[dir=rtl] .k-slider-horizontal.k-slider .k-label,
.k-slider-horizontal.k-slider.k-rtl .k-label,
.k-rtl .k-slider-horizontal.k-slider .k-label {
  transform: translateX(50%);
}

.k-slider kendo-resize-sensor {
  position: absolute;
}

.k-slider {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-slider .k-slider-track,
.k-slider .k-slider-selection {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
}
.k-slider .k-slider-track {
  background-color: var(--kendo-color-base-emphasis, #c2c2c2);
}
.k-slider .k-slider-selection {
  background-color: var(--kendo-color-primary, #ff6358);
}
.k-slider .k-draghandle {
  border-color: var(--kendo-color-primary, #ff6358);
  color: var(--kendo-color-on-primary, #ffffff);
  background-color: var(--kendo-color-primary, #ff6358);
  border-radius: 50%;
}
.k-slider .k-draghandle:hover {
  border-color: var(--kendo-color-primary, #ff6358);
  background-color: var(--kendo-color-primary-hover, #ea5a51);
}
.k-slider .k-draghandle:active, .k-slider .k-draghandle.k-pressed {
  border-color: var(--kendo-color-primary-active, #d45349);
  background-color: var(--kendo-color-primary-active, #d45349);
}
.k-slider .k-draghandle:focus, .k-slider .k-draghandle.k-focus {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-primary, #ff6358) 30%, transparent);
}
.k-slider.k-focus .k-draghandle {
  box-shadow: 0 0 0 2px color-mix(in srgb, var(--kendo-color-primary, #ff6358) 30%, transparent);
}
.k-slider-horizontal .k-tick {
  background-image: url(data:image/gif;base64,R0lGODlhAQC0AIABALi4uAAAACH5BAEAAAEALAAAAAABALQAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7);
}

.k-slider-vertical .k-tick {
  background-image: url(data:image/gif;base64,R0lGODlhtAABAIABALi4uAAAACH5BAEAAAEALAAAAAC0AAEAAAIWjIGJxqzazlux2ovlzND2rAHgSIZWAQA7);
}

.k-colorgradient {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  width: 272px;
  padding-block: var(--kendo-spacing-3, 0.75rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0;
  font-size: var(--kendo-font-size, inherit);
  font-family: var(--kendo-font-family, inherit);
  line-height: var(--kendo-line-height, normal);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--kendo-spacing-3, 0.75rem);
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-colorgradient *,
.k-colorgradient *::before,
.k-colorgradient *::after {
  box-sizing: border-box;
}

.k-colorgradient-canvas {
  gap: var(--kendo-spacing-3, 0.75rem);
}
.k-colorgradient-canvas .k-hsv-rectangle {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  position: relative;
  flex: 1 1 auto;
  user-select: none;
}
.k-colorgradient-canvas .k-hsv-gradient {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  height: 180px;
}
.k-colorgradient-canvas .k-hsv-draghandle {
  margin-top: -7px;
  margin-left: -7px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  cursor: pointer;
}
.k-colorgradient-canvas .k-hsv-controls {
  position: relative;
  flex-shrink: 0;
  gap: var(--kendo-spacing-3, 0.75rem);
}

.k-colorgradient .k-draghandle {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  outline-width: 1px;
  outline-style: solid;
  box-sizing: border-box;
}

.k-colorgradient-slider.k-slider-vertical {
  width: 10px;
  height: 180px;
  flex: 0 0 10px;
}
.k-colorgradient-slider.k-slider-vertical .k-slider-track {
  width: 10px;
  border-radius: 10px;
  margin-left: -5px;
}
.k-colorgradient-slider.k-slider-horizontal {
  width: 100%;
  height: 10px;
  flex: 0 0 10px;
}
.k-colorgradient-slider.k-slider-horizontal .k-slider-track {
  height: 10px;
  border-radius: 10px;
  margin-top: -5px;
}
.k-colorgradient-slider.k-alpha-slider .k-slider-track::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
  position: relative;
  z-index: -1;
}
.k-colorgradient-slider .k-slider-track-wrap {
  z-index: 1;
}
.k-colorgradient-slider .k-slider-selection {
  display: none;
}
.k-colorgradient-slider .k-draghandle {
  border-width: 3px;
}

.k-colorgradient-inputs {
  gap: calc(var(--kendo-spacing-3, 0.75rem) / 1.5);
}
.k-colorgradient-inputs .k-colorgradient-input-label {
  text-transform: uppercase;
}
.k-colorgradient-inputs > .k-vstack, .k-colorgradient-inputs > .k-vbox {
  gap: calc(var(--kendo-spacing-3, 0.75rem) / 3);
}
.k-colorgradient-inputs .k-numerictextbox {
  width: 46px;
}
.k-colorgradient-inputs .k-input-inner {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  text-align: center;
  text-overflow: clip;
}

.k-colorgradient-color-contrast > div {
  display: flex;
  flex-flow: row nowrap;
  gap: calc(var(--kendo-spacing-3, 0.75rem) / 1.5);
}
.k-colorgradient-color-contrast .k-contrast-ratio {
  margin-bottom: calc(var(--kendo-spacing-3, 0.75rem) / 1.5);
}
.k-colorgradient-color-contrast .k-contrast-ratio-text {
  font-weight: var(--kendo-font-weight-bold, normal);
}
.k-colorgradient-color-contrast .k-contrast-validation {
  display: inline-flex;
  align-items: center;
  gap: calc(calc(var(--kendo-spacing-3, 0.75rem) / 1.5) / 2);
}
.k-colorgradient-color-contrast .k-contrast-validation .k-icon {
  vertical-align: middle;
}
.k-colorgradient-color-contrast .k-contrast-validation .k-icon + .k-icon,
.k-colorgradient-color-contrast .k-contrast-validation .k-icon-wrapper-host + .k-icon-wrapper-host .k-icon {
  margin-inline-start: -13px;
}

.k-color-contrast-svg {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  pointer-events: none;
}

.k-colorgradient {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-colorgradient:focus,
.k-colorgradient.k-focus {
  box-shadow: var(--kendo-elevation-3, 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-colorgradient-canvas .k-hsv-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), black), linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.k-colorgradient .k-draghandle {
  border-color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
  outline-color: #000000;
  box-shadow: rgba(0, 0, 0, 0.5);
}
.k-colorgradient .k-draghandle:focus, .k-colorgradient .k-draghandle.k-focus {
  border-color: #ffffff;
  background-color: transparent;
  outline-color: #000000;
  box-shadow: rgba(0, 0, 0, 0.5);
}
.k-colorgradient .k-draghandle:hover, .k-colorgradient .k-draghandle.k-hover, .k-colorgradient .k-draghandle.k-active, .k-colorgradient .k-draghandle:active {
  border-color: #ffffff;
  background-color: transparent;
  outline-color: #000000;
}

.k-colorgradient-slider.k-hue-slider.k-slider-horizontal .k-slider-track {
  background: linear-gradient(to right, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}
.k-colorgradient-slider.k-hue-slider.k-slider-vertical .k-slider-track {
  background: linear-gradient(to top, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}

.k-colorgradient-slider.k-alpha-slider .k-slider-track::before {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAuSURBVHgBxYyxDQAwDMJIL+YT+DjtzFRliUfLcklqBCRT4eCTxbD6kdL2/LgYXqpvCbs3kBv/AAAAAElFTkSuQmCC) center repeat;
}

.k-colorgradient-input-label {
  color: var(--kendo-color-subtle, #666666);
}

.k-rtl .k-colorgradient .k-hue-slider.k-slider-horizontal .k-slider-track,
[dir=rtl] .k-colorgradient .k-hue-slider.k-slider-horizontal .k-slider-track {
  background: linear-gradient(to left, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}

.k-coloreditor {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  min-width: 272px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-size: var(--kendo-font-size, inherit);
  font-family: var(--kendo-font-family, inherit);
  line-height: var(--kendo-line-height, normal);
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}
.k-coloreditor *,
.k-coloreditor *::before,
.k-coloreditor *::after {
  box-sizing: border-box;
}

.k-coloreditor-header {
  padding: var(--kendo-spacing-3, 0.75rem) var(--kendo-spacing-3, 0.75rem) 0;
}

.k-coloreditor-header-actions {
  gap: calc(var(--kendo-spacing-3, 0.75rem) / 1.5);
}

.k-coloreditor-preview {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: center;
  gap: var(--kendo-spacing-1, 0.25rem);
  position: relative;
  z-index: 1;
}

.k-coloreditor-preview .k-color-preview {
  width: 32px;
  height: 12px;
}

.k-coloreditor-views {
  min-width: calc(var(--kendo-color-preview-width, var(--kendo-spacing-6, 1.5rem)) * var(--kendo-color-preview-columns, 10));
  padding-block: var(--kendo-spacing-3, 0.75rem);
  padding-inline: var(--kendo-spacing-3, 0.75rem);
  gap: var(--kendo-spacing-3, 0.75rem);
}
.k-coloreditor-views .k-colorgradient {
  padding: 0;
  width: auto;
  border-width: 0;
}
.k-coloreditor-views .k-colorgradient:focus,
.k-coloreditor-views .k-colorgradient.k-focus {
  outline: 2px dotted;
  outline-offset: 4px;
  border-radius: 0;
}
.k-coloreditor-views .k-colorpalette {
  align-self: center;
}
.k-coloreditor-views .k-colorpalette-tile {
  width: var(--kendo-color-preview-width, var(--kendo-spacing-6, 1.5rem));
  height: var(--kendo-color-preview-height, var(--kendo-spacing-6, 1.5rem));
}

.k-coloreditor-footer.k-actions, .k-coloreditor-footer.k-edit-buttons,
.k-coloreditor-footer.k-action-buttons,
.k-coloreditor-footer.k-columnmenu-actions, .k-coloreditor-footer.k-form-buttons {
  margin: 0;
}

.k-coloreditor {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-coloreditor .k-colorgradient:focus,
.k-coloreditor .k-colorgradient.k-focus {
  box-shadow: none;
  outline-color: rgba(0, 0, 0, 0.3);
}

.k-color-picker, .k-colorpicker {
  width: min-content;
}

.k-textarea:has(.k-input-inner[style*=width]) {
  width: max-content;
}

.k-splitter {
  height: 300px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-splitter .k-pane {
  overflow: hidden;
  box-sizing: border-box;
}
.k-splitter .k-scrollable {
  overflow: auto;
}
.k-splitter .k-splitter-resizing {
  overflow: hidden;
}
.k-pane > .k-splitter {
  border-width: 0;
  overflow: hidden;
}
.k-splitter .k-pane-loading {
  position: static;
  top: 50%;
  left: 50%;
}

.k-ghost-splitbar,
.k-splitbar {
  border-style: solid;
  outline: 0;
  position: absolute;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.k-ghost-splitbar .k-collapse-prev,
.k-ghost-splitbar .k-collapse-next,
.k-splitbar .k-collapse-prev,
.k-splitbar .k-collapse-next {
  cursor: pointer;
  padding: var(--kendo-spacing-0\.5, 0.125rem);
}

.k-splitbar-draggable-horizontal {
  cursor: col-resize;
}

.k-splitbar-draggable-vertical {
  cursor: row-resize;
}

.k-ghost-splitbar-horizontal,
.k-splitbar-horizontal {
  width: var(--kendo-spacing-3, 0.75rem);
  border-width: 0;
  background-repeat: repeat-y;
  flex-direction: column;
  top: 0;
}

.k-ghost-splitbar-vertical,
.k-splitbar-vertical {
  height: var(--kendo-spacing-3, 0.75rem);
  border-width: 0;
  background-repeat: repeat-x;
  flex-direction: row;
  left: 0;
}

.k-splitbar-static-horizontal {
  width: 1px;
}

.k-splitbar-static-vertical {
  height: 1px;
}

.k-splitbar-draggable-horizontal .k-resize-handle {
  width: 2px;
  height: 20px;
  position: static;
  z-index: 1;
}

.k-splitbar .k-resize-handle {
  display: none;
  background-color: currentColor;
}

.k-splitbar-draggable-horizontal .k-resize-handle,
.k-splitbar-draggable-vertical .k-resize-handle {
  display: block;
}

.k-splitbar-horizontal .k-collapse-prev {
  margin-bottom: calc(var(--kendo-spacing-3\.5, 0.875rem) / 2);
}

.k-splitbar-horizontal .k-collapse-next {
  margin-top: calc(var(--kendo-spacing-3\.5, 0.875rem) / 2);
}

.k-splitbar-vertical .k-collapse-prev {
  margin-right: calc(var(--kendo-spacing-3\.5, 0.875rem) / 2);
}

.k-splitbar-vertical .k-collapse-next {
  margin-left: calc(var(--kendo-spacing-3\.5, 0.875rem) / 2);
}

.k-splitbar-draggable-vertical .k-resize-handle {
  width: 20px;
  height: 2px;
  position: static;
  z-index: 1;
}

.k-pane > .k-splitter-overlay {
  opacity: 0;
  position: absolute;
}

.k-splitter-flex {
  display: flex;
  width: 100%;
  height: auto;
}
.k-splitter-flex .k-pane {
  position: relative;
  flex: 1 1 auto;
  min-width: 0;
  max-width: 100%;
  min-height: 0;
  max-height: 100%;
  height: 100%;
}
.k-splitter-flex .k-pane-static {
  flex-grow: 0;
  flex-shrink: 0;
}
.k-splitter-flex .k-pane-flex {
  display: flex;
}
.k-splitter-flex .k-splitbar {
  position: static;
  flex: 0 0 auto;
}
.k-splitter-flex .k-pane.k-hidden, .k-splitter-flex .k-pane[hidden],
.k-splitter-flex .k-pane-flex.k-hidden,
.k-splitter-flex .k-pane-flex[hidden] {
  flex: 0 1 0% !important;
  overflow: hidden !important;
  display: block !important;
}
.k-splitter-flex.k-splitter-horizontal {
  flex-direction: row;
}
.k-splitter-flex.k-splitter-vertical {
  flex-direction: column;
}

.k-splitter {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-splitbar {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-splitbar:hover,
.k-splitbar.k-hover,
.k-splitbar-horizontal-hover,
.k-splitbar-vertical-hover {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base-hover, #ebebeb);
}

.k-splitbar:focus,
.k-splitbar.k-focus {
  color: var(--kendo-color-on-primary, #ffffff);
  background: var(--kendo-color-primary, #ff6358);
}

.k-ghost-splitbar {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}

.k-barcode {
  display: inline-block;
}
.k-barcode > div {
  height: 150px;
}

.k-var--chart-font {
  font-size: var(--kendo-font-size, inherit);
}

.k-var--chart-title-font {
  font-size: 1.143em;
}

.k-var--chart-pane-title-font {
  font-size: 0.857em;
  font-weight: var(--kendo-font-weight-normal, normal);
}

.k-var--chart-label-font {
  font-size: 0.857em;
}

.k-chart,
.k-sparkline,
.k-stockchart {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-chart,
.k-stockchart {
  display: block;
  height: 400px;
}

.k-chart-surface {
  height: 100%;
}

.k-chart .k-popup {
  border-width: 0;
}

.k-chart-tooltip-wrapper .k-animation-container-shown,
.k-chart-tooltip-wrapper.k-animation-container-shown {
  transition: left 300ms cubic-bezier(0, 0.33, 0.19, 1), top 300ms cubic-bezier(0, 0.33, 0.19, 1);
}

.k-sparkline-tooltip-wrapper,
.k-chart-tooltip-wrapper {
  z-index: 12000;
}
.k-sparkline-tooltip-wrapper .k-popup,
.k-chart-tooltip-wrapper .k-popup {
  padding: 0;
  border-width: 0;
  background: transparent;
}

.k-chart-tooltip table {
  border-spacing: 0;
  border-collapse: collapse;
}

.k-chart-tooltip {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  font-size: calc(0.875rem * 0.929);
  line-height: var(--kendo-line-height, normal);
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-2, 0.5rem);
}

.k-chart-tooltip th {
  width: auto;
  text-align: center;
  padding: 1px;
}

.k-chart-tooltip td {
  width: auto;
  text-align: start;
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  line-height: var(--kendo-line-height, normal);
  vertical-align: middle;
}

.k-chart-crosshair-tooltip,
.k-chart-shared-tooltip {
  border-width: 1px;
  border-style: solid;
}

.k-chart-shared-tooltip .k-chart-shared-tooltip-marker {
  display: block;
  width: 15px;
  height: 3px;
  vertical-align: middle;
}

.k-selector {
  position: absolute;
  transform: translateZ(0);
}

.k-selection {
  position: absolute;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-bottom: 0;
}

.k-selection-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.k-handle {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  border-width: 1px;
  border-style: solid;
  z-index: 1;
  position: absolute;
  box-sizing: content-box;
}

.k-handle div {
  width: 100%;
  height: 100%;
}

.k-left-handle {
  left: -11px;
}

.k-right-handle {
  right: -11px;
}

.k-left-handle div {
  margin: -22px 0 0 -14.6666666667px;
  padding: 44px 29.3333333333px 0 0;
}

.k-right-handle div {
  margin: -22px 0 0 -14.6666666667px;
  padding: 44px 0 0 29.3333333333px;
}

.k-left-handle.k-handle-active div {
  margin-left: -44px;
  padding-left: 58.6666666667px;
}

.k-right-handle.k-handle-active div {
  margin-left: -44px;
  padding-right: 58.6666666667px;
}

.k-mask {
  position: absolute;
  height: 100%;
}

.k-navigator-hint div {
  position: absolute;
}

.k-navigator-hint .k-scroll {
  border-radius: var(--kendo-border-radius-md, 0.25rem);
  position: absolute;
  height: 4px;
}

.k-navigator-hint .k-tooltip {
  margin-top: 20px;
  min-width: 160px;
  opacity: 1;
  text-align: center;
}

.k-sparkline,
.k-sparkline span {
  display: inline-block;
  vertical-align: top;
}

.k-sparkline span {
  height: 100%;
  width: 100%;
}

.k-chart-dragging {
  user-select: none;
}

.k-chart-donut-center {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
  border: 4px solid transparent;
  box-sizing: border-box;
}

.k-pdf-export .k-chart .k-animation-container,
.k-pdf-export .k-sparkline .k-animation-container,
.k-pdf-export .k-stockchart .k-animation-container {
  display: none;
}

.k-diagram {
  height: 600px;
}

.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.k-canvas-container {
  width: 100%;
  height: 100%;
}

.k-sankey text {
  pointer-events: none;
}

.k-treemap {
  height: 400px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: var(--kendo-font-family, inherit);
  font-size: var(--kendo-font-size, inherit);
  line-height: var(--kendo-line-height, normal);
  display: block;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-treemap .k-treemap-tile {
  margin: -1px 0 0 -1px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid;
  border-color: inherit;
  color: inherit;
  background-color: inherit;
  overflow: hidden;
  position: absolute;
}
.k-treemap > .k-treemap-tile {
  position: relative;
}
.k-treemap .k-treemap-title {
  padding-block: var(--kendo-spacing-0\.5, 0.125rem);
  padding-inline: var(--kendo-spacing-1, 0.25rem);
  border-width: 0 0 1px;
  border-style: solid;
  border-color: inherit;
  font-size: var(--kendo-font-size, inherit);
  background-position: 0 0;
  background-repeat: repeat-x;
}
.k-treemap .k-treemap-title-vertical {
  padding-block: var(--kendo-spacing-1, 0.25rem);
  padding-inline: var(--kendo-spacing-0\.5, 0.125rem);
  width: calc(1.4285714286 * 1em);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  bottom: 0;
}
.k-treemap .k-treemap-title-vertical > div {
  transform-origin: right;
  transform: rotate(-90deg);
  position: absolute;
  top: 0;
  right: 1em;
}
.k-treemap .k-treemap-wrap {
  border-color: inherit;
  color: inherit;
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.k-treemap .k-treemap-title + .k-treemap-wrap {
  top: calc(var(--kendo-spacing-0\.5, 0.125rem) * 2 + var(--kendo-line-height, normal) * var(--kendo-font-size, inherit) + 1px);
}
.k-treemap .k-treemap-title-vertical + .k-treemap-wrap {
  left: calc(var(--kendo-spacing-0\.5, 0.125rem) * 2 + var(--kendo-line-height, normal) * var(--kendo-font-size, inherit) + 1px);
}
.k-treemap .k-leaf {
  padding: var(--kendo-spacing-1, 0.25rem);
}

.k-gauge {
  text-align: start;
  position: relative;
}

.k-arcgauge,
.k-circulargauge {
  display: inline-block;
}

.k-arcgauge-label,
.k-circulargauge-label {
  position: absolute;
  text-align: center;
  padding: 0;
  margin: 0;
}

.k-qrcode {
  display: inline-block;
}
.k-qrcode > div {
  height: 150px;
}

.k-var--primary {
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-var--primary-contrast {
  background-color: var(--kendo-color-on-primary, #ffffff);
}

.k-var--base {
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-var--background {
  background-color: var(--kendo-color-surface-alt, #ffffff);
}

.k-var--border-radius {
  background-color: var(--kendo-border-radius-md, 0.25rem);
}

.k-var--normal-background {
  background-color: var(--kendo-color-surface, #fafafa);
}

.k-var--normal-text-color {
  background-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-var--hover-background {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}

.k-var--hover-text-color {
  background-color: var(--kendo-color-on-app-surface, #3d3d3d);
}

.k-var--selected-background {
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-var--selected-text-color {
  background-color: var(--kendo-color-on-primary, #ffffff);
}

.k-var--success {
  background-color: var(--kendo-color-success, #37b400);
}

.k-var--info {
  background-color: var(--kendo-color-info, #0058e9);
}

.k-var--warning {
  background-color: var(--kendo-color-warning, #ffc000);
}

.k-var--error {
  background-color: var(--kendo-color-error, #f31700);
}

.k-var--series-a {
  background-color: var(--kendo-color-series-a, #ff6358);
}

.k-var--series-b {
  background-color: var(--kendo-color-series-b, #ffe162);
}

.k-var--series-c {
  background-color: var(--kendo-color-series-c, #4cd180);
}

.k-var--series-d {
  background-color: var(--kendo-color-series-d, #4b5ffa);
}

.k-var--series-e {
  background-color: var(--kendo-color-series-e, #ac58ff);
}

.k-var--series-f {
  background-color: var(--kendo-color-series-f, #ff5892);
}

.k-var--series-1 {
  background-color: var(--kendo-color-series-a, #ff6358);
}

.k-var--series-2 {
  background-color: var(--kendo-color-series-b, #ffe162);
}

.k-var--series-3 {
  background-color: var(--kendo-color-series-c, #4cd180);
}

.k-var--series-4 {
  background-color: var(--kendo-color-series-d, #4b5ffa);
}

.k-var--series-5 {
  background-color: var(--kendo-color-series-e, #ac58ff);
}

.k-var--series-6 {
  background-color: var(--kendo-color-series-f, #ff5892);
}

.k-var--series-7 {
  background-color: var(--kendo-color-series-a-subtle, #ffb1ac);
}

.k-var--series-8 {
  background-color: var(--kendo-color-series-b-subtle, #fff0b1);
}

.k-var--series-9 {
  background-color: var(--kendo-color-series-c-subtle, #a6e8c0);
}

.k-var--series-10 {
  background-color: var(--kendo-color-series-d-subtle, #a5affd);
}

.k-var--series-11 {
  background-color: var(--kendo-color-series-e-subtle, #d6acff);
}

.k-var--series-12 {
  background-color: var(--kendo-color-series-f-subtle, #ffacc9);
}

.k-var--series-13 {
  background-color: var(--kendo-color-series-a-bold, #bf4a42);
}

.k-var--series-14 {
  background-color: var(--kendo-color-series-b-bold, #bfa94a);
}

.k-var--series-15 {
  background-color: var(--kendo-color-series-c-bold, #399d60);
}

.k-var--series-16 {
  background-color: var(--kendo-color-series-d-bold, #3847bc);
}

.k-var--series-17 {
  background-color: var(--kendo-color-series-e-bold, #8142bf);
}

.k-var--series-18 {
  background-color: var(--kendo-color-series-f-bold, #bf426e);
}

.k-var--series-19 {
  background-color: var(--kendo-color-series-a-subtler, #ff8a82);
}

.k-var--series-20 {
  background-color: var(--kendo-color-series-b-subtler, #ffe989);
}

.k-var--series-21 {
  background-color: var(--kendo-color-series-c-subtler, #79dda0);
}

.k-var--series-22 {
  background-color: var(--kendo-color-series-d-subtler, #7887fb);
}

.k-var--series-23 {
  background-color: var(--kendo-color-series-e-subtler, #c182ff);
}

.k-var--series-24 {
  background-color: var(--kendo-color-series-f-subtler, #ff82ae);
}

.k-var--series-25 {
  background-color: var(--kendo-color-series-a-bolder, #80322c);
}

.k-var--series-26 {
  background-color: var(--kendo-color-series-b-bolder, #807131);
}

.k-var--series-27 {
  background-color: var(--kendo-color-series-c-bolder, #266940);
}

.k-var--series-28 {
  background-color: var(--kendo-color-series-d-bolder, #26307d);
}

.k-var--series-29 {
  background-color: var(--kendo-color-series-e-bolder, #562c80);
}

.k-var--series-30 {
  background-color: var(--kendo-color-series-f-bolder, #802c49);
}

.k-var--gauge-pointer {
  background-color: var(--kendo-color-primary, #ff6358);
}

.k-var--gauge-track {
  background-color: var(--kendo-color-base-emphasis, #c2c2c2);
}

.k-var--chart-inactive {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 50%, transparent);
}

.k-var--chart-major-lines {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}

.k-var--chart-minor-lines {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 4%, transparent);
}

.k-var--chart-area-opacity {
  opacity: 0.6;
}

.k-var--chart-area-inactive-opacity {
  opacity: 0.1;
}

.k-var--chart-line-inactive-opacity {
  opacity: 0.3;
}

.k-var--chart-notes-background {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 50%, transparent);
}

.k-var--chart-notes-border {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 50%, transparent);
}

.k-var--chart-notes-lines {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 50%, transparent);
}

.k-var--chart-crosshair-background {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 50%, transparent);
}

.k-var--chart-error-bars-background {
  background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 50%, transparent);
}

.k-chart,
.k-sparkline,
.k-stockchart {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: transparent;
}
.k-chart .k-popup,
.k-sparkline .k-popup,
.k-stockchart .k-popup {
  background: transparent;
}

.k-chart-tooltip {
  color: #ffffff;
}

.k-chart-tooltip-inverse {
  color: #000000;
}

.k-chart-crosshair-tooltip,
.k-chart-shared-tooltip {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-base, #f5f5f5);
  border-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
}

.k-selection {
  border-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 8%, transparent);
  box-shadow: inset 0 1px 7px color-mix(in srgb, var(--kendo-color-on-app-surface, #3d3d3d) 15%, transparent);
}

.k-selection-bg {
  background-color: transparent;
}

.k-handle {
  cursor: e-resize;
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-base, #3d3d3d);
  background-color: var(--kendo-color-base, #f5f5f5);
}
.k-handle:hover {
  background-color: var(--kendo-color-base-hover, #ebebeb);
}

.k-handle div {
  background-color: transparent;
}

.k-mask {
  background-color: var(--kendo-color-surface-alt, #ffffff);
  opacity: 0.8;
}

.k-treemap {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface-alt, #ffffff);
}
.k-treemap .k-treemap-title {
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
  color: var(--kendo-color-on-app-surface, #3d3d3d);
  background-color: var(--kendo-color-surface, #fafafa);
}
.k-treemap .k-leaf {
  color: var(--kendo-color-surface-alt, #ffffff);
}
.k-treemap .k-leaf.k-inverse {
  color: var(--kendo-color-on-app-surface, #3d3d3d);
}
.k-treemap .k-leaf:hover,
.k-treemap .k-leaf.k-hover {
  box-shadow: inset 0 0 0 3px var(--kendo-color-border, rgba(0, 0, 0, 0.08));
}

.k-popover {
  border-radius: 6px;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-size: 14px;
  font-family: inherit;
  line-height: 1.4285714286;
  display: flex;
  flex-flow: column nowrap;
  z-index: 12000;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-popover *,
.k-popover *::before,
.k-popover *::after {
  box-sizing: border-box;
}

.k-popover-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 12px 16px;
  border-width: 0 0 1px;
  border-style: solid;
  z-index: 999;
}

.k-popover-body {
  padding: 16px 16px;
}

.k-popover-actions {
  border-width: 1px 0 0;
}

.k-popover-callout {
  margin: 0;
  width: 20px;
  height: 20px;
  border-width: 1px 1px 0 0;
  border-style: solid;
  position: absolute;
}

.k-popover-callout.k-callout-n {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.k-popover-callout.k-callout-e {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%) rotate(135deg) scaleX(-1);
}

.k-popover-callout.k-callout-s {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) rotate(135deg);
}

.k-popover-callout.k-callout-w {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%) rotate(-135deg);
}

.k-popover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-popover-header {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
}

.k-popover-callout {
  border-color: rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.k-popover-callout.k-callout-e {
  box-shadow: rgba(0, 0, 0, 0.03) 2px -1px 4px 0px;
}

.k-popover-callout.k-callout-s {
  box-shadow: rgba(0, 0, 0, 0.06) 2px -2px 4px 0px;
}

.k-popover-callout.k-callout-w {
  box-shadow: rgba(0, 0, 0, 0.03) 2px -1px 4px 0px;
}

.k-popover-callout.k-callout-n {
  left: 47.5%;
  width: 35px;
  height: 35px;
}

.k-picker-solid.k-invalid, .k-picker-solid.ng-invalid.ng-touched, .k-picker-solid.ng-invalid.ng-dirty {
  border-color: rgba(0, 0, 0, 0.16);
}

.k-grid, .k-grid-header, .k-input {
  color: #000;
}

kendo-grid-filter-cell-operators button {
  width: 18px !important;
  height: 18px !important;
  padding: 2px;
}