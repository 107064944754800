.custom-dialog-container .mat-mdc-dialog-container {
  padding: 24px !important;
  max-height: calc(100vh - 24px);
  background-color: #e5f6ff;
}

.custom-dialog-container-confirmation .mat-mdc-dialog-container {
  padding: 0px !important;
  max-height: calc(100vh - 24px);
  background-color: #e5f6ff;
}

.custom-dialog-container .mat-mdc-card {
  margin: 0;
}

.custom-dialog-error-container {
  position: absolute !important;
  bottom: 10px;
  background-color: #ffd3d3;
}

.custom-dialog-error-container .mat-mdc-dialog-container {
  background-color: #ffd3d3;
}

.mat-mdc-dialog-container {
  max-width: unset !important;
}

.border-danger {
  border: 2px solid red !important;
}

.mat-custom-dimensions .mat-button-wrapper {
  padding: 0 !important;
  display: table-row !important;
  line-height: 0px !important;
  width: inherit;
  height: inherit;
}

.mat-custom-dimensions .mat-button-wrapper .mat-icon {
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
}

.custom-tooltip {
  font-size: 13px !important;
}

.client-search .k-dropdown-wrap .k-searchbar .k-input {
  padding-left: 32px;
}

.mat-mdc-input-no-label .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  border-top: 0px;
  padding: 0px;
}

.mat-mdc-input-no-label .mat-mdc-text-field-wrapper {
  padding-bottom: 10px !important;
}

.mat-mdc-input-no-label .mat-mdc-text-field-wrapper .mat-form-field-underline {
  position: unset !important;
}

.label-field-inline > div {
  width: 100%;
}

.hierarchy-column-hidden .k-hierarchy-cell {
  width: 0;
}

.hierarchy-column-hidden .k-header.k-hierarchy-cell {
  border: none;
}

.hierarchy-column-hidden .k-hierarchy-cell .k-icon {
  display: none;
}

.hierarchy-column-hidden .k-hierarchy-col {
  width: 0px;
}

.combo-3px-padding .k-input {
  padding-left: 3px;
  padding-right: 3px;
}

[class*=" flag:"],
[class^="flag:"] {
  font-size: 24px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 85%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  position: relative;
  top: -1px;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  color: #ffffff;
  background-color: #ff0707;
}

.errorField {
  border-radius: 5px !important;
  border: solid 2px #e91e63 !important;
  padding: 3px !important;
}

.errorLabel {
  font-size: 95%;
  padding-left: 10px;
  padding-top: 5px;
}

.mat-mdc-raised-button.mat-cancel:enabled {
  background-color: #EB5E30;
  color: white;
}

.mat-datepicker-toggle-active {
  color: #00a05b !important;
}

.mat-calendar-body-selected {
  background-color: #00a05b !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00a05b !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #00a05b !important;
}

.mat-mini-fab.mat-primary {
  background-color: #00a05b !important;
}

.mat-mdc-raised-button.mat-button-base.mat-primary {
  background-color: #00a05b !important;
  color: white;
}

.mat-mdc-raised-button {
  letter-spacing: normal !important;
}

.time-picker {
  width: 7em;
}

.custom-dialog-details {
  background-color: transparent;
}

.button-edit-entity {
  font-size: 11px !important;
  width: 10px !important;
  height: 0px !important;
}

.icon-edit-entity {
  width: 10px !important;
  font-size: 11px !important;
}

.errorIconTab {
  color: #e91e63;
  font-size: 20px;
}

.tab-group {
  height: 100% !important;
}

.tab-group-new {
  height: 90% !important;
}

.mat-drawer-container,
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
.mat-tab-label,
.mat-tab-link {
  color: #000;
}

kendo-grid-toolbar {
  border-color: rgba(0, 0, 0, 0.08);
  color: #656565;
  background-color: #f6f6f6;
  background-clip: padding-box;
  margin: 0;
  padding: 8px 8px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.custom-label-error {
  color: #e91e63 !important;
  font-weight: 400;
  line-height: 1.125;
  font-family: Poppins;
  letter-spacing: normal;
  font-size: 75%;
  display: block;
  padding-top: 15px;
}

/*======= Google Map Styling - Remove footer ============*/
.gmnoprint {
  display: none;
}

.gm-style > div > div > a > div > img {
  display: none;
}

.gm-style-cc:last-child {
  display: none !important;
}

.pac-container:after {
  content: none !important;
}

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  color: white !important;
  background-color: #e91e63 !important;
}

.mat-mdc-snack-bar-container.bg-success .mat-mdc-snackbar-surface {
  color: white !important;
  background-color: rgba(0, 88, 233, 0.8196078431) !important;
}

.mat-mdc-dialog-title {
  padding: 0px !important;
}

.mat-mdc-dialog-title::before {
  display: inline !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-tab-group {
  --mat-tab-header-active-hover-indicator-color: #00a05b !important;
  --mat-tab-header-active-hover-label-text-color: #00a05b !important;
  --mat-tab-header-active-focus-indicator-color: #00a05b !important;
  --mat-tab-header-active-focus-label-text-color: #00a05b !important;
  --mat-tab-header-inactive-ripple-color: #00a05b !important;
  --mat-tab-header-active-label-text-color: #00a05b !important;
  --mat-tab-header-active-ripple-color: #00a05b !important;
  --mdc-tab-indicator-active-indicator-color: #00a05b !important;
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #00a05b !important;
  --mat-outlined-button-state-layer-color: #00a05b !important;
}

.k-grid-toolbar {
  justify-content: end !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #00a05b !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #00a05b !important;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: inherit !important;
  width: inherit !important;
}

.mat-drawer-inner-container {
  overflow-y: initial !important;
}

.mat-mdc-button .mat-mdc-button-touch-target {
  height: inherit !important;
}

.button-primary-internord {
  background-color: #00a05b !important;
  color: white !important;
}

.button-warm-internord {
  background-color: #e91e63 !important;
  color: white !important;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  opacity: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #00a05b !important;
}

.mat-mdc-option .mat-pseudo-checkbox {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00a05b !important;
}

#snav .mat-mdc-nav-list .mat-mdc-list-item.separator-root {
  padding: 0 !important; /* Pas de padding pour les separators */
}

.mat-mdc-list-item-interactive::before {
  opacity: 0 !important;
  transition: none !important;
  margin: 0 16px;
}

#snav .mat-mdc-focus-indicator {
  margin: 0 16px;
}

.mat-mdc-raised-button.mat-primary {
  background-color: #00a05b;
}

#snav .mat-ripple-element {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 85% !important;
  height: 85% !important;
  border-radius: inherit !important;
  transform: translate(-50%, -50%) !important;
}

#snav .separator-root .mat-ripple-element {
  opacity: 0 !important;
}